/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  Space,
  notification,
  Divider,
  InputNumber,
  Select,
  RadioChangeEvent,
  Card,
  Collapse,
  Modal,
  Checkbox,
  DatePicker,
  Empty,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import type { RangePickerProps } from "antd/es/date-picker";

import debounce from "lodash/debounce";

import API_SERVICE from "shared/services/api-service";
import AppLoading from "shared/components/AppLoading/AppLoading";

import * as _ from "lodash";

import {
  PHONE_NUMBER_REGEX,
  POSITIVE_VALUE_REGEX,
  STRING_ONLY,
} from "shared/constants/AppConstants";
import "./index.scss";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import home from "../../../../assets/images/home-white.svg";
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  FieldTemplateProps,
  RJSFSchema,
} from "@rjsf/utils";
import AddtionalFieldsFlowable from "./AdditionalFieldsFlowable";
import FormDOB from "components/FormDOB/FormDOB";
import { numberWithCommas } from "shared/services/Utility";
import iconVerified from "../../../../assets/images/right-border.svg";
import LeadEditAdditional from "shared/components/LeadNew/LeadDetail/LeadEditAdditional/LeadEditAdditional";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import FlexiPincodeAvailibility from "./FlexiPincodeAvailibility";
import { RootState } from "shared/redux/store";
// import iconVerified from "../assets/images/right-border.svg";

const { Title, Text } = Typography;
const { TextArea } = Input;

const AdditionalField: React.FunctionComponent<any> = ({
  leadData,
  additionalFields,
  setShowAdditionalFields,
  selectedLander,
  callbackAdditionalField,
  setFaircentStatus,
  callBack,
  formData,
  taskDetails,
  currLenderId,
  currLoanProductId,
  currLeadId,
  customUI,
  setTaskDetails,
  setCustomUI,
  lenderLoginHandler,
  setAdditionalFields,
  callBackRejected,
  setCallbackReqDoc,
  getLeadById,
  skippedLoginStatus,
  setIsLastTask,
  isLastTask,
}) => {
  const [openFlowablePath, setOpenFlowablePath] = useState(false);
  const [flowableLeadData, setFlowableLeadData] = useState(leadData);
  const [showFlexiReason, setShowFlexiReason] = useState("");
  const [getNextTaskPayload, setGetNextTaskPayload] = useState(formData);
  const [errorMessage, setErrorMessage] = useState('');
  const [showValueForBajaj, setShowValueForBajaj] = useState("");
  const [showValuesForPrefr, setShowValuesForPrefr] = useState("");

  let { id } = useParams() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const MadTextWidget = (props: any) => {
    var valueHolder = "";
    const { value, onChange } = props;
    const handleChange = (evt) => {
      valueHolder = evt.target.value;
    };
    const manageOnClick = (e) => {
      console.log(valueHolder);
    };

    return (
      <>
        <Input
          className="ant-input ant-input-status-success custom-input"
          value={value}
          // required={props.required}
          onChange={handleChange}
        />
        <Button
          size="small"
          type="primary"
          onClick={manageOnClick}
          style={{ width: "40%" }}
        >
          Search
        </Button>
        <Select>
          <Select.Option> </Select.Option>
        </Select>
      </>
    );
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current.isAfter(moment().subtract(1, "day"));
  };

  function ArrayFieldItemTemplate(props: ArrayFieldTemplateItemType) {
    const { children, className } = props;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
      <>
        {/* <Collapse defaultActiveKey={['1']}>
         <Panel header="This is panel header 1" key="1">{children}</Panel>
       </Collapse> */}
        <Button type="primary" onClick={showModal}>
          Open Modal
        </Button>
        <Modal
          width={1000}
          title="Basic Modal"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {children}
        </Modal>
      </>
    );
  }

  const CustomArrayWidget = (props: any) => {
    var valueHolder = "";
    const { value, onChange } = props;
    console.log(props);
    const handleChange = (evt) => {
      valueHolder = evt.target.value;
    };
    const manageOnClick = (e) => {
      console.log("Props", props);
    };
    return (
      <>
        <Button onClick={manageOnClick}>1</Button>
      </>
    );
  };

  function CustomFieldTemplate(props: FieldTemplateProps) {
    const {
      id,
      classNames,
      style,
      label,
      help,
      required,
      description,
      errors,
      children,
    } = props;
    return (
      <div className={classNames} style={style}>
        <label htmlFor={id}>
          {label}
          {required ? "*+++" : null}
        </label>
        {description}
        {children}
        {errors}
        {help}
      </div>
    );
  }
  function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
    return (
      <div>
        {props.items.map((element) => element.children)}
        {props.canAdd && (
          <button type="button" onClick={props.onAddClick}></button>
        )}
      </div>
    );
  }
  console.log(taskDetails == null);

  const widgets = {
    madTextWidget: MadTextWidget,
    customArrayWidget: CustomArrayWidget,
  };
  const templates = {
    ArrayFieldItemTemplate: ArrayFieldItemTemplate,
    FieldTemplate: CustomFieldTemplate,
    //ArrayFieldTemplate:ArrayFieldTemplate
  };

  const uiSchema = {
    // "eligibilityDtls": {
    eligibilityDtls: {
      items: {
        referenceId: {
          "ui:widget": "madTextWidget",
          "ui:placeholder": "Your name here",
          "ui:title": "Reference Id",
        },
      },
      // "ui:widget":"customArrayWidget"
    },
    loanID: {
      "ui:widget": "madTextWidget",
      "ui:placeholder": "Your name here",
      "ui:title": "Reference Id",
    },
    // "items": {
    //   "details": {
    //     "ui:widget": "checkbox"
    //   },
    // }
    // },
  };

  const [isJsonSchema, setIsJsonSchema] = useState(true);
  const [loading, setLoading] = useState(false);
  const [applicantTypes, setApplicantType] = useState([]);
  const [individualTypes, setIndividualType] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [selectedApplicationType, setSelectedApplicationType] =
    useState("INDIVIDUAL");
  const [leadForm] = Form.useForm() as any;
  const [extraInputFields, setExtraInputFields] = useState([] as any);
  const [isAdditionalField, setIsAdditionalField] = useState(false);
  const [extraCaseFields, setExtraCaseFields] = useState({} as any);
  const [isBureauScore, setIsBureauScore] = useState(false);
  const [isCustomerIndian, setIsCustomerIndian] = useState(false);
  const [bureauScoreStatus, setBureauScoreStatus] = useState("");
  const [sourceOfCase, setSourceOfCase] = useState("");
  const [finalAdditionalField, setFinalAdditionalField] = useState([] as any);
  const [finalSalutationField, setFinalSalutationField] = useState([] as any);
  const [propertyOwnership, setPropertyOwnership] = useState([] as any);
  const [isChangeCaseField, setIsChangeCaseField] = useState(false);
  const [isChangeMobile, setIsChangeMobile] = useState(false);
  const [employmentType, setEmploymentType] = useState([]);
  const [requiredProfession, setRequiredProfession] = useState(false);
  const [requiredProfessionCoApp, setRequiredProfessionCoApp] = useState(false);
  const [Salutation, setSalutation] = useState([]);
  const [businessProfile, setBusinessProfile] = useState([] as any);
  const [isPersonalLoan, setIsPersonalLoan] = useState(false);
  const [isCurrentEmis, setIsCurrentEmis] = useState(false);
  //
  const [isBajajLender, setIsBajajLender] = useState(false);
  const [isBajajExtraCall, setIsBajajExtraCall] = useState(false);

  const [coApplicantDetail, setCoApplicantDetails] = useState([] as any);
  const [bajajSalesUserData, setBajajSalesUserData] = useState([] as any);
  const [isICICIender, setIsICICILender] = useState(false);
  const [isCASHEender, setIsCASHELender] = useState(false);
  const [isAmbitLender, setIsAmbitLender] = useState(false);
  const [isFlexiLender, setIsFlexiLender] = useState(false);
  const [isKreditBeeLender, setKreditBeeLender] = useState(false);
  const [isABCFLLender, setIsABCFLLender] = useState(false);

  const [isMoneyWideLender, setIsMoneyWideLender] = useState(false);
  const [isConsent, setIsConsent] = useState(true);
  const [isConsentID, setIsConsentID] = useState(null);

  type PincodeOption = {
    value: string;
    label: string;
  };
    const [pincodeOptions, setPincodeOptions] = useState<PincodeOption[]>([]);
    const [pincodeCaseBajaj, setPincodeCaseBajaj] = useState({} as any);
  console.log(leadData);
  const TextAreaFields = [
    "offer.CoApplicant_Leads__r.Current_Residence_Address_Line_1__c",
    "prospect.officeAddress.ADDRESS_LINE_1",
    "prospect.permanentAddress.ADDRESS_LINE_1",
  ];

  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }
  
  const handlePincodeChange = (value) => {
    if(value.length > 3){
        setIsChangeCaseField(true);
        const params = {
          pincode: value,
        };
        API_SERVICE.checkPincodeForBajaj(params).then(({ data }) => {
          if (data) {
            if (data.payload.length == 0) {
              const pincodeExists = pincodeOptions.some(option => option.value === value);
              if (!pincodeExists) {
                // Manually set the field value if pincode is not in the list
                leadForm.setFieldsValue({
                  cityCase: "",
                  pincodeCase: value,
                });
              }
            }else {
              let showPincodeForBajaj = data.payload?.map((item) => {
                return {
                  value: item.pinCode,
                  key: item.pinCode,
                  city: item.city
                }
              })
              if (data.payload.length == 1) {
                leadForm.setFieldsValue({
                  cityCase: data.payload.at(0).city,
                });
              }
              setPincodeOptions(showPincodeForBajaj);
            }

          }
        })
        
      }
  };

  const selectPincodeForBajaj = (e) => {
    const nextCity = e.target ? e.target.value : e;
    let showcity: any = pincodeOptions.find((item: any) => item.key == nextCity)
    setPincodeCaseBajaj(showcity);
    leadForm.setFieldsValue({
      cityCase: showcity.city,
      pincodeCase: showcity.value,
    });
  }

  const handlePincodeSelectChange = (value) => {
    // Check if the value exists in the options
    const pincodeExists = pincodeOptions.some(option => option.value === value);
    if (!pincodeExists) {
      // Manually set the field value if pincode is not in the list
      leadForm.setFieldsValue({ pincodeCase: value });
    }
  };

  const checkAndModifySalutation = (resultAdditionalFieldMap) => {
    leadForm.getFieldValue("applicantType");
    leadForm.setFieldValue("Salutation__c");
    if (resultAdditionalFieldMap) {
      let index = resultAdditionalFieldMap.findIndex(
        (item) => item.apiPropertyName === "Salutation__c"
      );
      if (index > -1) {
        const tmpMasterFieldSalutation =
          resultAdditionalFieldMap[index].possibleValues;
        leadForm.getFieldValue("Salutation__c");
        if (Salutation?.length <= 0) {
          setSalutation(tmpMasterFieldSalutation);
        }
        if (resultAdditionalFieldMap) {
          let tempData = resultAdditionalFieldMap;
          if (leadForm.getFieldValue("applicantType") === "BUSINESS") {
            if (Salutation.length > 0) {
              tempData[index].possibleValues = Salutation?.filter(
                (data: any) => data.key.toUpperCase() === "MESSRS."
              );
              setFinalSalutationField([...tempData]);
            } else {
              tempData[index].possibleValues = tmpMasterFieldSalutation?.filter(
                (data: any) => data.key.toUpperCase() === "MESSRS."
              );
              setFinalSalutationField([...tempData]);
            }
          } else {
            if (Salutation.length > 0) {
              tempData[index].possibleValues = Salutation?.filter(
                (data: any) => data.key.toUpperCase() !== "MESSRS."
              );
              setFinalSalutationField([...tempData]);
            } else {
              tempData[index].possibleValues = tmpMasterFieldSalutation?.filter(
                (data: any) => data.key.toUpperCase() !== "MESSRS."
              );
              setFinalSalutationField([...tempData]);
            }
          }
        }
      }
    }
  };

  const updatedChanges = () => {
    if (isConsentID) {
      API_SERVICE.moneyWideCheckConsent(isConsentID)
        .then(({ data }) => {
          if (data) {
            setIsConsent(data?.payload);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };
  const OnChangesForRetriggerSMS = () => {
    API_SERVICE.moneyWideTriggerConsentSMS(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          console.log("data", data);
          notification.success({ message: data.payload });
          getCaseDetails();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };


  const getCaseDetails = () => {
    API_SERVICE.getLeadsById(id)
      .then(({ data }) => {
        if (data?.payload) {
          setIsConsentID(data?.payload?.lenderConsentId);
          console.log('data?.payload', data?.payload);
          setFlowableLeadData(data?.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  useEffect(() => {

    if(`${leadData.address?.pinCode}`!="null")
      {
        handlePincodeChange(`${leadData.address?.pinCode}`);
      }
    getCaseDetails();
    setIsBajajLender(selectedLander.lenderName.toLowerCase().includes("bajaj") && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance"));
    setIsICICILender(
      selectedLander?.lenderName.toLowerCase().includes("icici") ||
        selectedLander?.lenderName.toLowerCase().includes("kotak")
    );
    setIsCASHELender(
      selectedLander?.lenderName.toLowerCase().includes("cashe")
    );
    setIsFlexiLender(
      selectedLander?.lenderName.toLowerCase().includes("flexi")
    );
    setIsAmbitLender(
      selectedLander?.lenderName.toLowerCase().includes("ambit")
    );
    setKreditBeeLender(
      selectedLander?.lenderName.toLowerCase().includes("kreditbee")
    );
    setIsABCFLLender(
      selectedLander?.lenderName.toLowerCase().includes("abcfl") ||
        selectedLander?.lenderName.toLowerCase().includes("abcl")
    );
    setIsMoneyWideLender(
      selectedLander?.lenderName.toLowerCase().includes("moneywide")
    );

    const filter =
      Object.keys(additionalFields).length === 0
        ? []
        : additionalFields?.filter(
            (value) =>
              value.internalPropertyName === null &&
              value.apiPropertyName !== "loan_id"
          );
    setExtraInputFields(filter);

    const { contactDetails, address, caseDetails, entityOrEmployerName, dsaCaseAdditionalAttributes } =
      leadData;

    let enumValues = localStorage.getItem("enumValues") as any;
    let lType: any = null;
    let aType: any = null;
    let iType: any = null;
    let bType: any = null;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredLoanType = enumValues.find(
        (item: any) => item.propertyName === "LOAN_TYPE"
      );
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let businessTypes = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let businessProfileValue = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_PROFILE"
      );
      let propertyOwnershipValue = enumValues.find(
        (item: any) => item.propertyName === "PROPERTY_OWNERSHIP"
      );

      console.log("businessProfileValue", businessProfileValue);
      if (businessProfileValue) {
        setBusinessProfile(businessProfileValue?.keyAndDisplayValueList);
      }
      if (propertyOwnershipValue) {
        setPropertyOwnership(propertyOwnershipValue?.keyAndDisplayValueList);
      }
      lType = filteredLoanType?.keyAndDisplayValueList?.find(
        (item: any) => item.value === leadData.loanType
      ) as any;
      aType = filteredApplicationType?.keyAndDisplayValueList?.find(
        (item: any) => item.value === leadData.applicationType
      ) as any;
      iType = filteredIndivType?.keyAndDisplayValueList?.find(
        (item: any) => item.value === leadData.applicationSubType
      ) as any;
      bType = businessTypes?.keyAndDisplayValueList?.find(
        (item: any) => item.value === leadData.applicationSubType
      ) as any;

      let resultAdditionalFieldMap = filter.map((additionalItem) => {
        let filterdata = enumValues?.filter(
          (enumItem: any) =>
            enumItem?.propertyName ===
            additionalItem?.displayName.toUpperCase().replace(/ /g, "_")
        );
        return additionalItem;
      });
      console.log("resultAdditionalFieldMap", resultAdditionalFieldMap);
      const orgField = resultAdditionalFieldMap.filter(
        (item) => !item.apiPropertyName.toLowerCase().includes("coapplicant")
      );
      const coApplicantDetails = resultAdditionalFieldMap.filter((item) =>
        item.apiPropertyName.toLowerCase().includes("coapplicant")
      );
      const fi1 = orgField.map((field: any) => {
        if (field?.apiPropertyName === "prospect.Salutation__c") {
          if (aType?.key === "INDIVIDUAL") {
            const dataPossibleValues = field?.possibleValues?.filter(
              (item) => item?.value !== "Messrs."
            );
            field["possibleValues"] = dataPossibleValues;
          } else {
            const dataPossibleValues = field?.possibleValues?.filter(
              (item) => item?.value === "Messrs."
            );
            field["possibleValues"] = dataPossibleValues;
          }
        }
        if (field?.apiPropertyName === "offer.Sourcing_Branch__c") {
          if (formData?.cityCase) {
            const dataPossibleValues = field?.possibleValues?.filter((item) =>
              formData?.cityCase
                .toLowerCase()
                .icludes(item?.value.toLowerCase())
            );
            field["possibleValues"] = dataPossibleValues;
          }
        }
        return field;
      });

      if (
        selectedLander.lenderName.toLowerCase().includes("bajaj") &&
        leadData.applicationTypeKey === "BUSINESS"
      ) {
        const allData = [...resultAdditionalFieldMap];
        console.log("allData", allData);

        setFinalAdditionalField(allData);
        setFinalAdditionalField(allData);
      } else {
        setCoApplicantDetails(coApplicantDetails);
        setFinalSalutationField(resultAdditionalFieldMap);
        setFinalAdditionalField(orgField);
      }

      // setFinalAdditionalField(orgField);
      //setCoApplicantDetails(coApplicantDetails);
      //setFinalSalutationField(resultAdditionalFieldMap);
      leadForm.setFieldsValue({
        applicantType: aType?.key || "",
      });
      checkAndModifySalutation(resultAdditionalFieldMap);

      const tempSalaried =
        leadData?.loanType === "Personal Loan" &&
        leadData.applicationSubType === "Salaried";
      setIsPersonalLoan(tempSalaried);
    }

    setSourceOfCase(leadData?.sourceOfCase ?? "");
    setBureauScoreStatus(
      leadData?.leadAdditionalInfoDetails?.bureauScoreStatus ?? ""
    );
    setSelectedApplicationType(aType?.key);
    setExtraCaseFields({
      loanType: lType?.value ? lType.value : "",
      comment: caseDetails?.lenderActivityHolder?.comments,
      tentativePayout: caseDetails?.loanDetails?.expectedInterestRate ?? 0,
    });
    leadForm.setFieldsValue({
      applicantType: aType?.key || "",
      individualType: iType?.key || "",
      businessType: bType?.key || "",
      genderCase: contactDetails?.gender,
      pan: contactDetails?.pan === "PENDING" ? "" : contactDetails?.pan,
      businessPan: contactDetails?.pan === "PENDING" ? "" : contactDetails?.pan,
      businessName: dsaCaseAdditionalAttributes?.businessName || contactDetails?.contactName,
      businessProfile: leadData?.businessProfile || undefined,
      propertyOwnership: leadData?.caseDetails?.propertyOwnership || undefined,
      businessVintage: leadData?.businessVintage || "",
      businessTurnover: leadData?.businessTurnOver || "",
      officePinCode: leadData?.officePincode,
      monthlyEmi: leadData?.monthlyEmi,
      firstName: contactDetails?.firstName || "",
      mobileNumber: contactDetails?.mobileNumber?.startsWith("1")
        ? ""
        : contactDetails?.mobileNumber,
      email: contactDetails?.emailId || "",
      dateOfBirth:
        contactDetails?.dateOfBirth && moment(contactDetails.dateOfBirth),
      pincodeCase: address?.pinCode || "",
      address1Case: address?.address || "",
      cityCase: address?.city || "",
      stateCase: address?.state || "",
      employername: entityOrEmployerName || "",
      loanAmount: caseDetails?.loanDetails?.loanAmount || "",
      loanTenure: caseDetails?.loanDetails?.loanTenureInYears || "",
      monthlyInHandSalary: caseDetails?.loanDetails?.monthlyInHandSalary || "",
      currentEmis: caseDetails?.loanDetails?.currentEmis || "",
    });
  }, [additionalFields]);

  useEffect(() => {
    dispatch(
      setScreenHeader({
        // backScreenTitle: "View Lead",
        // backScreenPath: `/lead/details/${leadData?.caseDetails?.caseId}`,
        screenTitle: `Additional Fields For ${selectedLander?.lenderName}`,
      })
    );
    if (Object.keys(leadData?.dsaCaseAdditionalAttributes ?? {})?.length > 0) {
      leadForm.setFieldsValue({
        ...leadData.dsaCaseAdditionalAttributes,
        dateOfBirth:
          leadData.dsaCaseAdditionalAttributes?.dateOfBirth &&
          moment(leadData.dsaCaseAdditionalAttributes.dateOfBirth),
        coApplicantDob:
          leadData.dsaCaseAdditionalAttributes?.coApplicantDob &&
          moment(leadData.dsaCaseAdditionalAttributes.coApplicantDob),
        dateOfIncorporation:
          leadData.dsaCaseAdditionalAttributes?.dateOfIncorporation &&
          moment(leadData.dsaCaseAdditionalAttributes.dateOfIncorporation),
        employmentType:
          leadData?.dsaCaseAdditionalAttributes?.employerType ?? "",
        salaryMode: leadData?.dsaCaseAdditionalAttributes?.salaryMode ?? "",
        jobSector: leadData?.dsaCaseAdditionalAttributes?.jobSector ?? "",
        workStatus: leadData?.dsaCaseAdditionalAttributes?.workStatus ?? "",
        officeAddress:
          leadData?.dsaCaseAdditionalAttributes?.officeAddress ?? "",
        maritalStatus:
          leadData?.dsaCaseAdditionalAttributes?.maritalStatus ?? "",
        loanPurpose: leadData?.dsaCaseAdditionalAttributes?.loanPurpose ?? "",
        coapplicant2Dob:
          leadData.dsaCaseAdditionalAttributes?.coapplicant2Dob &&
          moment(leadData.dsaCaseAdditionalAttributes.coapplicant2Dob),
      });
    }
    const currentEMIs = leadForm.getFieldValue("currentEmis")
      ? Number(leadForm.getFieldValue("currentEmis"))
      : 0;
    handleCurrentEmis(currentEMIs);
    return () => {
      dispatch(
        setScreenHeader({
          backScreenTitle: "Lead",
          backScreenPath: "/lead",
          screenTitle: "View Lead",
        })
      );
    };
  }, []);
  const handleCurrentEmis = (value: any) => {
    setIsChangeCaseField(true);
    const val = Number(value);
    setIsCurrentEmis(val > 0);
  };

  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  useEffect(() => {
    getAllTypes();
    setEnumValues();
  }, []);

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let filteredGenderType = enumValues.find(
        (item: any) => item.propertyName === "GENDER"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      if (filteredApplicationType) {
        setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      }
      if (filteredIndivType) {
        setIndividualType(filteredIndivType.keyAndDisplayValueList);
      }
      if (filteredGenderType) {
        setGenders(filteredGenderType.keyAndDisplayValueList);
      }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
    }
  };

  //this validation for only ambit
  const checkAddressLength = (_: any, value: any) => {
    if (value === null || (value?.length ?? 0) == 0) {
      return Promise.resolve();
    }
    if (isAmbitLender && value?.length > 95) {
      return Promise.reject(
        new Error("Address Line 1 must not be greater than 95 characters.")
      );
    } else {
      return Promise.resolve();
    }
  };

  // const checkPanDetails = (e: any) => {
  //   setIsChangeCaseField(true);
  //   e.persist();
  //   const { value: nextValue } = e.target;
  //   const params = {
  //     panNumber: nextValue,
  //     consent: "Y",
  //   };
  //   API_SERVICE.checkPanDetailsForProfile(params).then(({ data }) => {
  //     setIsChangeCaseField(true);
  //     if (selectedApplicationType !== "INDIVIDUAL") {
  //       leadForm.setFieldsValue({
  //         employername: data?.payload?.result?.name,
  //       });
  //     } else {
  //       console.log("data else", data?.payload?.result);
  //       let date = moment(data?.payload?.result?.dob);
  //       console.log("date", date);
  //       leadForm.setFieldsValue({
  //         firstName: data?.payload?.result?.name,
  //         contactName: data?.payload?.data?.full_name.trim(),
  //         dateOfBirth: date,
  //       });
  //     }
  //   });
  // };

  const checkPincode = (e: any) => {
    setIsChangeCaseField(true);
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        console.log();
        leadForm.setFieldsValue({
          cityCase: data.payload.city,
          stateCase: data.payload.state,
        });
      }
    });
  };

  const checkPincodeState = (pincode: any) => {
    setIsChangeCaseField(true);
    // const { value: nextValue } = e.target;
    const params = {
      pin_code: pincode,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        console.log();
        leadForm.setFieldsValue({
          // cityCase: data.payload.city,
          stateCase: data.payload.state,
        });
      }
    });
  };
  useEffect(() => {
    if (!leadData?.address?.state) {
      if (leadData.address.pinCode) {
        checkPincodeState(leadData.address.pinCode);
      }
    }
  }, []);

  const fieldBorrowed = finalAdditionalField.find(
    (field: any) => field?.apiPropertyName === "borrowedStatus"
  );
  const fieldNotBorrowed1 = fieldBorrowed?.possibleValues?.filter(
    (field: any) => field?.key === "NOT_BORROWED"
  );
  const fieldBorrowed1 = fieldBorrowed?.possibleValues?.filter(
    (field: any) => field?.key === "BORROWED"
  );
  if (isCurrentEmis) {
    leadForm.setFieldsValue({
      borrowedStatus: fieldBorrowed1 ? fieldBorrowed1[0]?.key : "",
    });
  } else {
    leadForm.setFieldsValue({
      borrowedStatus: fieldNotBorrowed1 ? fieldNotBorrowed1[0]?.key : "",
    });
  }

  const setAmbit = () => {
    leadForm.setFieldsValue({
      office_pincode: leadData?.dsaCaseAdditionalAttributes?.pincode,
      office_address: leadData?.dsaCaseAdditionalAttributes?.addressLine1,
    });
  }

  const setLoantap = () => {
    leadForm.setFieldsValue({
      business_addr_line1: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressLine1,
      business_zipcode: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressPincode,
      business_city: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressCity,
      office_addr_line1: leadData?.dsaCaseAdditionalAttributes?.addressLine1,
      office_city: leadData?.dsaCaseAdditionalAttributes?.city,
      office_zipcode: leadData?.dsaCaseAdditionalAttributes?.pincode,
      business_name: leadData?.dsaCaseAdditionalAttributes?.businessName,
    });
  }

  const setBajaj = (formData: any) => {

    const additionalData = leadData?.dsaCaseAdditionalAttributes;

    const names = (additionalData?.coApplicantName ?? formData['offer.CoApplicant_Leads__r.First_Name__c'])?.split(" ")

    const dob = additionalData?.coApplicantDob ?? formData['offer.CoApplicant_Leads__r.Date_of_Birth__c'];
    const dateofbirth = dob && moment(dob);

    const doi = additionalData?.dateOfIncorporation;
    const dateOfIncorporation = doi && moment(doi);

    const gender = additionalData?.coapplicantGender ?? formData['offer.CoApplicant_Leads__r.Gender__c'];
    const genderVal = gender == "M" ? "Male" : "Female";

    const coPincode = additionalData?.coapplicantPincode ?? formData['offer.CoApplicant_Leads__r.Current_Residence_Pincode__c'];
    leadForm.setFieldsValue({
      "prospect.dateOfIncorporation": dateOfIncorporation,
      "prospect.officeAddress.PINCODE": additionalData?.pincode ? ('' + additionalData?.pincode) : "",
      "prospect.officeAddress.ADDRESS_LINE_1": additionalData?.addressLine1,
      "prospect.officeAddress.ADDRESS_LINE_2": additionalData?.city,
      "prospect.officeAddress.ADDRESS_LINE_3": additionalData?.state,
      "offer.CoApplicant_Leads__r.First_Name__c": names?.at(0),
      "offer.CoApplicant_Leads__r.Last_Name__c": names?.at(-1),
      "offer.CoApplicant_Leads__r.PAN__c": additionalData?.coApplicantPan,
      "offer.CoApplicant_Leads__r.Gender__c": genderVal,
      "offer.CoApplicant_Leads__r.Date_of_Birth__c": dateofbirth,
      "offer.CoApplicant_Leads__r.Mobile_Number__c": additionalData?.coapplicantMobileNumber ?? formData['offer.CoApplicant_Leads__r.Mobile_Number__c'],
      "offer.CoApplicant_Leads__r.Current_Residence_Pincode__c": coPincode ? ('' + coPincode) : "",
      "offer.CoApplicant_Leads__r.Current_Residence_Address_Line_1__c": additionalData?.coapplicantAddressLine1 ?? formData['offer.CoApplicant_Leads__r.Current_Residence_Address_Line_1__c'],
      "offer.CoApplicant_Leads__r.Current_Residence_Address_Line_2__c": additionalData?.coapplicantCity ?? formData['offer.CoApplicant_Leads__r.Current_Residence_Address_Line_2__c'],
      "offer.CoApplicant_Leads__r.Current_Residence_Address_Line_3__c": additionalData?.coapplicantState ?? formData['offer.CoApplicant_Leads__r.Current_Residence_Address_Line_3__c'],
      "offer.CoApplicant_Leads__r.Permanent_Residence_Pincode__c": additionalData?.coapplicantPermanentPincode ? ('' + additionalData?.coapplicantPermanentPincode) : "",
      "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_1__c": additionalData?.coapplicantPermanentAddressLine1 ?? "",
      "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_2__c": additionalData?.coapplicantPermanentCity ?? "",
      "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_3__c": additionalData?.coapplicantPermanentState ?? "",
    });
  }

  const setCredilioFinancialTechnologiesPrivateLimited  = () => {
    leadForm.setFieldsValue({
      net_monthly_salary: leadData?.dsaCaseAdditionalAttributes?.monthlyInHandSalary,
    });
  }

  const createPayload = (formData: any) => {
    const fi = finalAdditionalField.find(
      (field: any) =>
        field?.apiPropertyName === "loan_city" ||
        field?.apiPropertyName === "city"
    );
    const sourcingBranch = finalAdditionalField.find(
      (field: any) => field?.apiPropertyName == "offer.Sourcing_Branch__c"
    );
    // if (field?.apiPropertyName == "offer.Sourcing_Branch__c") {
    if(isAmbitLender) {
      setAmbit();
    } else if(selectedLander.lenderName.toLowerCase() == "loan tap") {
      setLoantap();
    } else if(selectedLander.lenderName == "Credilio Financial Technologies Private Limited") {
      setCredilioFinancialTechnologiesPrivateLimited();
    } else if(isBajajLender) {
      setBajaj(formData);
    }

    if (
      formData?.cityCase &&
      isBajajLender &&
      bajajSalesUserData?.length === 0
    ) {
      const naviMumbai =
        formData?.cityCase?.toLowerCase().includes("navi") &&
        formData?.cityCase?.toLowerCase().includes("mumbai");
      const dataPossibleValues = sourcingBranch?.possibleValues?.filter(
        (item) => {
          if (
            item?.value.toLowerCase() === "nagar" &&
            formData?.cityCase?.toLowerCase() === "nagar"
          ) {
            return item;
          } else if (
            item?.value.toLowerCase() !== "nagar" &&
            item?.value.split(" ").length <= 2 &&
            formData?.cityCase
              ?.toLowerCase()
              .includes(item?.value.split(" ").at(0).toLowerCase()) &&
            !naviMumbai
          ) {
            return item;
          } else if (
            item?.value.toLowerCase() !== "nagar" &&
            formData?.cityCase?.toLowerCase().includes("kutch") &&
            (formData?.cityCase
              ?.toLowerCase()
              .includes(item?.value.split(" ").at(-1).toLowerCase()) ||
              formData?.cityCase
                ?.toLowerCase()
                .includes(item?.value.split(" ").at(0).toLowerCase())) &&
            !naviMumbai
          ) {
            return item;
          } else if (
            item?.value.toLowerCase() !== "nagar" &&
            formData?.cityCase?.toLowerCase().includes("navi") &&
            formData?.cityCase?.toLowerCase().includes("mumbai") &&
            item?.value.toLowerCase().includes("navi mumbai")
          ) {
            return item;
          } else {
            return "";
          }
        }
      );
      if (dataPossibleValues?.length > 0) {
        leadForm.setFieldsValue({
          "offer.Sourcing_Branch__c": dataPossibleValues[0]?.value,
        });
      } else {
        leadForm.setFieldsValue({
          "offer.Sourcing_Branch__c": "",
        });
        notification.error({
          message:
            "City Mapping not found, please select the Sourcing City from Dropdown",
        });
      }
    }

    const fiState = finalAdditionalField.find(
      (field: any) => field?.apiPropertyName == "state"
    );
    const findCity = fi?.possibleValues?.filter(
      (field: any) =>
        field?.key == formData?.cityCase ||
        formData?.cityCase?.includes(field?.key)
    );
    const findState = fiState?.possibleValues?.filter(
      (field: any) =>
        field?.value == formData?.stateCase ||
        formData?.stateCase?.includes(field?.value)
    );

    const fiOfficePinCode = finalAdditionalField.find(
      (field: any) => field?.apiPropertyName == "officepincode"
    );
    if (fiOfficePinCode) {
      leadForm.setFieldsValue({
        officepincode: formData?.officePinCode,
      });
    }
    const fiVintageBuiness = finalAdditionalField.find(
      (field: any) => field?.apiPropertyName == "vintage"
    );
    if (fiVintageBuiness) {
      leadForm.setFieldsValue({
        vintage: leadData?.dsaCaseAdditionalAttributes?.vintage ?? formData?.businessVintage,
      });
    }
    if (findCity) {
      leadForm.setFieldsValue({
        loan_city: findCity ? findCity[0]?.key : "",
        city: findCity ? findCity[0]?.key : "",
      });
    }
    if (findState) {
      leadForm.setFieldsValue({
        state: findState ? findState[0]?.key : "",
      });
    }

    const bureauScore =
      bureauScoreStatus.length != 0
        ? bureauScoreStatus
        : isBureauScore
        ? "REQUEST_INITIATED"
        : null;

    const data = {
      sourceOfCase: sourceOfCase,
      address: {
        address: formData.address1Case,
        area: formData.cityCase,
        city: formData.cityCase,
        country: userDetails?.userDetails.address.country,
        geoCode: userDetails?.userDetails.address.geoCode,
        pinCode: formData.pincodeCase,
        state: formData.stateCase,
      },
      applicationSubType:
        formData.applicantType === "INDIVIDUAL"
          ? formData.individualType
          : formData.businessType,
      applicationType: formData.applicantType,
      officePincode: formData.officePinCode,
      comments: extraCaseFields?.comment,
      assignToUserId: leadData?.caseDetails?.assignedToUserDetails?.userId,
      commissionDetail: {
        connectorName: formData.contactName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId:
        leadData?.caseDetails?.connectorDetails?.userDetails?.userId ||
        userDetails?.userDetails.userId,
      nationality: isCustomerIndian ? "INDIAN" : null,
      bureauScoreStatus: bureauScore,
      contactDetails: {
        gender: formData.genderCase,
        firstName: formData.firstName,
        dateOfBirth: formData.dateOfBirth ? moment(formData.dateOfBirth).format("YYYY-MM-DD").toString() : "",
        emailId: formData.email,
        mobileNumber: formData.mobileNumber,
        pan:
          formData.applicantType === "INDIVIDUAL"
            ? formData.pan
            : formData.businessPan,
      },
      entityOrEmployerName: formData.employername,
      loanDetails: {
        expectedInterestRate:
          formData.tentativePayout ?? extraCaseFields?.tentativePayout,
        loanAmount: formData.loanAmount,
        loanTenureInYears: formData.loanTenure,
        monthlyInHandSalary:
        formData?.monthlyInHandSalary,
        currentEmis: formData.currentEmis,
      },
      loanType: leadData?.loanType,
      loanProductId: leadData?.loanProductId,
      businessProfile: formData?.businessProfile,
      propertyOwnership: formData?.propertyOwnership,
      businessTurnOver: formData?.businessTurnover,
      monthlyEmi: formData?.monthlyEmi,
      businessVintage: formData?.businessVintage,
    };
    return data;
  };

  const handelEditCaseResponse = (
    values: any,
    isCallAdditionalField: boolean,
    data: any
  ) => {
    if (
      (selectedLander?.lenderName?.toLowerCase()?.includes("hdfc") ||
        selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
        selectedLander?.lenderName.toLowerCase().includes("abcl") ||
        selectedLander?.lenderName.toLowerCase().includes("credit saison") ||
        selectedLander?.lenderName.toLowerCase().includes("flexi")
      ) &&
      isChangeMobile
    ) {
      setIsChangeMobile(false);
      getLenderAdditonalField(currLeadId, currLenderId);
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("hdfc") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
      selectedLander?.lenderName.toLowerCase().includes("abcl") ||
      selectedLander?.lenderName.toLowerCase().includes("nira finance") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("flexi") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("kotak") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("icici") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("cashe") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("moneywide") ||
      selectedLander?.lenderName.toLowerCase().includes("credit saison") ||
      selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("kreditbee") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("prefr") ||
      selectedLander?.lenderName?.toLowerCase()?.includes("incred") ||
      (selectedLander?.lenderName?.toLowerCase()?.includes("fibe") &&
        leadData?.loanType == "Buy Now Pay Later")
    ) {
      handleGetNextTask(currLeadId, currLenderId);
    }
    if (data?.payload) {
      setFlowableLeadData(data.payload);
    }
    if (taskDetails != null && Object.keys(additionalFields).length === 0) {
      callBack();
      setIsChangeCaseField(false);
      setLoading(false);
    } else {
      callBack();
      // callBackFuncs();
      setIsChangeCaseField(false);
      if (isCallAdditionalField) {
        updateLenderAdditonalField(values);
      } else {
        setLoading(false);
        if (Object.keys(additionalFields).length === 0) {
          setShowAdditionalFields(false);
        } else {
          setIsAdditionalField(true);
        }
      }
    }
  };

  const editCaseDetail = (values: any, isCallAdditionalField: boolean) => {
    if (leadData?.productVersion === "V2") {
      if(selectedLander?.lenderName?.toLowerCase()?.includes("flexi") || (selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance")) || (selectedLander?.lenderName.toLowerCase().includes("prefr"))) {
        validateAdditonalField();
      } else {
        handelEditCaseResponse(values, isCallAdditionalField, leadData);
      }
      return;
    }

    const payload = createPayload(values);
    setLoading(true);
    API_SERVICE.editLead(leadData.caseDetails.caseId, payload)
      .then(({ data }) => {
        if (data) {
          if(selectedLander?.lenderName?.toLowerCase()?.includes("flexi") || (selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") || (selectedLander?.lenderName.toLowerCase().includes("prefr")))) {
            setFlowableLeadData(data.payload);
            validateAdditonalField();
          } else {
            handelEditCaseResponse(values, isCallAdditionalField, data);
          }
        }
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {});
  };
  const updateLenderAdditonalField = (values) => {
    let key = Object.keys(values);
    let filterDataObject = {} as any;

    finalAdditionalField.map((data) => {
      let filterData = key.filter((item) => item === data?.apiPropertyName);
      filterDataObject[filterData[0]] = values[filterData[0]] ?? "";
    });
    console.log("filterDataObject", filterDataObject);
    function updateJSONForBajaj(dummyData) {
      let datas1 = {};
      const keys = Object.entries(dummyData);
      keys.filter((item) => {
        const splitKeys = item[0].split(".");
        let updated = false;
        splitKeys.forEach((kValue, index) => {
          if ([datas1].length > 0) {
            const objKeys = Object.keys(datas1);
            if (objKeys.length > 0) {
              objKeys.forEach((kVale) => {
                const dynamicFunct = () => {
                  if (splitKeys.length > 2) {
                    dynamicFunct();
                    let upDate = false;
                    if (datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]]) {
                      const childObj = Object.keys(
                        datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]]
                      );
                      if (childObj.length > 0) {
                        datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]][
                          splitKeys[splitKeys.length - 1]
                        ] = item[1];
                        upDate = true;
                      }
                    }
                    if (!upDate) {
                      datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]] = {
                        [splitKeys[splitKeys.length - 1]]: item[1],
                      };
                    }
                  }
                };
                if (splitKeys[0] === kVale) {
                  if (splitKeys.length > 2) {
                    let upDate = false;
                    if (datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]]) {
                      const childObj = Object.keys(
                        datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]]
                      );
                      if (childObj.length > 0) {
                        datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]][
                          splitKeys[splitKeys.length - 1]
                        ] = item[1];
                        upDate = true;
                      }
                    }
                    if (!upDate) {
                      datas1[splitKeys[0]][splitKeys[splitKeys.length - 2]] = {
                        [splitKeys[splitKeys.length - 1]]: item[1],
                      };
                    }
                  }
                  if (splitKeys.length === 2) {
                    datas1[splitKeys[0]][splitKeys[splitKeys.length - 1]] =
                      item[1];
                    updated = true;
                  }
                }
              });
            }
          }
          if (!updated) {
            if (splitKeys.length === 2)
              datas1 = {
                ...datas1,
                [splitKeys[0]]: {
                  [splitKeys[splitKeys.length - 1]]: item[1],
                },
              };
          }
        });
      });
      return datas1;
    }
    setLoading(true);
    if (selectedLander.lenderName.toLowerCase().includes("bajaj")) {
      //Bajaj sales user API call
      if (isBajajExtraCall) {
        const objData: any = {};
        const userData: any[] = [];
        bajajSalesUserData.filter((item) => {
          if (filterDataObject?.salesUsers === item?.Id) {
            userData.push({
              OwnerId: item?.OwnerId,
              Id: item?.Id,
            });
            objData.proposalSubmitId = item?.proposalSubmitId;
          }
        });
        objData.users = userData;
        // updateLenderAdditonalFieldForBajajSaleUser
        API_SERVICE.updateLenderAdditonalFieldForBajajSaleUser(
          selectedLander.lenderInfoId,
          leadData?.caseDetails?.caseId,
          leadData?.loanProductId,
          objData
        )
          .then(({ data }) => {
            setLoading(false);
            if (data) {
              let loanId = 0;
              let status = "";
              try {
                // let response = JSON.parse(data?.payload?.payload);
                loanId = data?.payload?.lenderApplicationId ?? 0;
                setFaircentStatus(data?.payload?.loanStatus ?? "");
              } catch (e) {
                loanId = 0;
                status = "";
              }
              callbackAdditionalField({
                lenderInfoId: selectedLander.lenderInfoId,
                loanId,
                status,
              });
              setShowAdditionalFields(false);
            }
          })
          .catch((e: any) => {
            setLoading(false);
            notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        //Bajaj sumbit to lender
        filterDataObject = updateJSONForBajaj(filterDataObject);
        API_SERVICE.updateLenderAdditonalFieldForBajaj(
          selectedLander.lenderInfoId,
          leadData?.caseDetails?.caseId,
          leadData?.loanProductId,
          filterDataObject
        )
          .then(({ data }) => {
            setLoading(false);
            if (data) {
              let loanId = 0;
              let status = "";
              if (data?.payload) {
                if (data?.payload?.mapping) {
                  const mapping = {
                    selectedAccountId: "0019D00000Sz982QAB",
                    modifySourcingChannel: "",
                    proposal: "LP1005674350",
                    isSalesUserMulti: "Yes",
                    proposalSubmitId: "PSD2354812230",
                    source: "Car Dekho",
                    lenderId: "BU0000000010",
                    userId: "58520fab-9622-4c12-a0bb-0aa41fc816ca",
                    productName: "ZLC",
                    users:
                      '[{"Status":true,"Designation":"ASM","OwnerId":"0050k000002D4EiAAK","Id":"a2u9D000000RLwtQAG","Name":"ASM PUNE1"},{"Status":true,"Designation":"ASM","OwnerId":"0050k000001D7chAAC","Id":"a2u9D000000UplOQAS","Name":"Shubham Manna"}]',
                    OfferId: "00Q9D000004Oc8lUAC",
                    AppStage: "Pre Sales",
                    caseId: "DC3378252758",
                  };
                  const saleUserField = JSON.parse(
                    data?.payload?.mapping?.users
                  );
                  const finalSalesUserLst: any[] = [];
                  saleUserField.filter((item) => {
                    finalSalesUserLst.push({
                      key: item?.Id,
                      value: item?.Name,
                      OwnerId: item?.OwnerId,
                      Id: item?.Id,
                      proposalSubmitId:
                        data?.payload?.mapping?.proposalSubmitId,
                    });
                  });
                  setBajajSalesUserData(finalSalesUserLst);
                  const ObjSales = {
                    apiPropertyName: "salesUsers",
                    internalPropertyName: null,
                    dataType: "String",
                    required: true,
                    displayName: "Sales Users",
                    possibleValues: finalSalesUserLst,
                  };
                  setAdditionalFields([ObjSales]);
                  setShowAdditionalFields(true);
                  setIsBajajExtraCall(true);
                } else {
                  callbackAdditionalField({
                    lenderInfoId: selectedLander.lenderInfoId,
                    loanId,
                    status,
                  });
                  setShowAdditionalFields(false);
                }
              }
            }
          })
          .catch((e: any) => {
            setLoading(false);
            notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      //non-bajaj lender
      API_SERVICE.updateLenderAdditonalField(
        selectedLander.lenderInfoId,
        leadData?.caseDetails?.caseId,
        leadData?.loanProductId,
        filterDataObject
      )
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            let loanId = 0;
            let status = "";
            try {
              // let response = JSON.parse(data?.payload?.payload);
              loanId = data?.payload?.lenderApplicationId ?? 0;
              setFaircentStatus(data?.payload?.loanStatus ?? "");
            } catch (e) {
              loanId = 0;
              status = "";
            }
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId,
              status,
              earlySalaryResponse: data?.payload?.earlySalaryResponse,
              showRequirementDocs: data?.payload?.status
                ? data?.payload?.status.toLowerCase() === "rejected"
                  ? "false"
                  : ""
                : "",
            });
            callBackRejected({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId,
              status:
                selectedLander?.lenderName
                  ?.toLowerCase()
                  ?.includes("lending") ||
                selectedLander?.lenderName?.toLowerCase()?.includes("faircent")
                  ? data?.payload?.status
                    ? data?.payload?.status
                    : "REJECTED"
                  : "",
              Message: data?.payload?.rejectedReason,
            });
            setShowAdditionalFields(false);
          }
        })
        .catch((e: any) => {
          setLoading(false);
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishForm = (values: any) => {
    if (leadData?.productVersion === "V2") {
      createPayload({
        ...values,
        firstName: values?.applicantName,
        cityCase: values?.city,
      });
    }
    onClickContinue(isAdditionalField ? "Continue" : "Next");
    if (isAdditionalField && !isChangeCaseField) {
      editCaseDetail(values, isAdditionalField);
    } else {
      editCaseDetail(values, isAdditionalField);
    }
  };

  const onChangeCoApplicantDetails = (event: any) => {
    if (event?.target?.checked) {
      const allData = [...finalAdditionalField, ...coApplicantDetail];
      setFinalAdditionalField(allData);
    } else {
      const orgField = finalAdditionalField.filter(
        (item) => !item.apiPropertyName.toLowerCase().includes("coapplicant")
      );
      setFinalAdditionalField([...orgField]);
    }
  };

  const onChangePermanantAddess = (e: any) => {
    if (e?.target?.checked) {
      leadForm.setFieldsValue({
        "prospect.permanentAddress.PINCODE":
          leadData.address.pinCode?.toString(),
        "prospect.permanentAddress.ADDRESS_LINE_1": leadData?.address?.address,
        "prospect.permanentAddress.ADDRESS_LINE_3": leadData?.address?.state,
        "prospect.permanentAddress.ADDRESS_LINE_2": leadData?.address?.city,
      });
    } else {
      leadForm.setFieldsValue({
        "prospect.permanentAddress.PINCODE": "",
        "prospect.permanentAddress.ADDRESS_LINE_1": "",
        "prospect.permanentAddress.ADDRESS_LINE_3": "",
        "prospect.permanentAddress.ADDRESS_LINE_2": "",
      });
    }
  };

  const onChangeCoApplicantPermanantAddess = (e: any) => {
    if (e?.target?.checked) {
      //console.log("testing ke")
      leadForm.setFieldsValue({
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_1__c":
          leadData.address.address,
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_2__c":
          leadData.address.city,
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_3__c":
          leadData.address.state,
        "offer.CoApplicant_Leads__r.Permanent_Residence_Pincode__c":
          leadData.address.pinCode,
      });
    } else {
      leadForm.setFieldsValue({
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_1__c": "",
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_2__c": "",
        "offer.CoApplicant_Leads__r.Permanent_Residence_Address_Line_3__c": "",
        "offer.CoApplicant_Leads__r.Permanent_Residence_Pincode__c": "",
      });
    }
  };

  const onChangeApplicationType = (e: RadioChangeEvent) => {
    setSelectedApplicationType(e.target.value);

    // setSelectedApplicationType(selectedApplicationType);
    leadForm.setFieldValue("Salutation__c");
    let index = finalSalutationField.findIndex(
      (item) => item.apiPropertyName === "Salutation__c"
    );
    if (index > -1) {
      const tmpMasterFieldSalutation =
        finalSalutationField[index].possibleValues;
      leadForm.getFieldValue("Salutation__c");
      if (Salutation?.length <= 0) {
        setSalutation(tmpMasterFieldSalutation);
      }
      if (finalSalutationField) {
        let tempData = finalSalutationField;
        if (leadForm.getFieldValue("applicantType") === "BUSINESS") {
          if (Salutation.length > 0) {
            tempData[index].possibleValues = Salutation?.filter(
              (data: any) => data.key.toUpperCase() === "MESSRS."
            );
            setFinalSalutationField([...tempData]);
            if (tempData[index].possibleValues.length === 1) {
              let fieldName = tempData[index]?.apiPropertyName;
              leadForm?.setFieldsValue({
                [fieldName]: tempData[index]?.possibleValues[0]?.value,
              });
            }
          } else {
            tempData[index].possibleValues = tmpMasterFieldSalutation?.filter(
              (data: any) => data.key.toUpperCase() === "MESSRS."
            );
            setFinalSalutationField([...tempData]);
          }
        } else {
          if (Salutation.length > 0) {
            tempData[index].possibleValues = Salutation?.filter(
              (data: any) => data.key.toUpperCase() !== "MESSRS."
            );
            setFinalSalutationField([...tempData]);
          } else {
            tempData[index].possibleValues = tmpMasterFieldSalutation?.filter(
              (data: any) => data.key.toUpperCase() !== "MESSRS."
            );
            setFinalSalutationField([...tempData]);
          }
        }
      }
    }
  };

  const disableDate = (current) => {
    let eighteenYearsAgo = new Date();
    let eightAgo = eighteenYearsAgo.setFullYear(
      eighteenYearsAgo.getFullYear() - 18
    );
    return current && current > new Date(eightAgo);
  };

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select>
        <Select.Option value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );

  const renderButtons = () => {
    return (
      <Row gutter={[24, 24]}>
        <Divider />
        <Col className="btns">
          <Form.Item>
            <Space>
              <Button
                htmlType="button"
                onClick={() => {
                  setShowAdditionalFields(false);
                }}
              >
                Cancel
              </Button>
              <Button
                loading={loading}
                onClick={() =>
                  onClickContinue(isAdditionalField ? "Continue" : "Next")
                }
                htmlType="submit"
                type="primary"
              >
                {isAdditionalField ? "Continue" : "Next"}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const onChangeIndividualType = (value) => {
    setIsPersonalLoan(
      leadData?.loanType === "Personal Loan" && value === "SALARIED"
    );

    setIsPersonalLoan(
      leadData?.loanType === "Personal Loan" && value === "SALARIED"
    );

    leadForm.setFieldValue("Employment_Type__c", undefined);
    let index = finalAdditionalField.findIndex(
      (item) => item.apiPropertyName === "Employment_Type__c"
    );
    if (index > -1) {
      const tmpMasterFieldAdditional =
        finalAdditionalField[index].possibleValues;
      if (employmentType?.length <= 0) {
        setEmploymentType(tmpMasterFieldAdditional);
      }
      if (finalAdditionalField) {
        let tempData = finalAdditionalField;
        if (value?.toUpperCase() === "SALARIED") {
          if (employmentType.length > 0) {
            tempData[index].possibleValues = employmentType?.filter(
              (data: any) => data.key.toUpperCase() === "SALARIED"
            );
            setFinalAdditionalField([...tempData]);
          } else {
            tempData[index].possibleValues = tmpMasterFieldAdditional?.filter(
              (data: any) => data.key.toUpperCase() === "SALARIED"
            );
            setFinalAdditionalField([...tempData]);
          }
        } else {
          if (employmentType.length > 0) {
            tempData[index].possibleValues = employmentType?.filter(
              (data: any) => data.key.toUpperCase() !== "SALARIED"
            );
            setFinalAdditionalField([...tempData]);
          } else {
            tempData[index].possibleValues = tmpMasterFieldAdditional?.filter(
              (data: any) => data.key.toUpperCase() !== "SALARIED"
            );
            setFinalAdditionalField([...tempData]);
          }
        }
      }
    }
  };

  const getLenderAdditonalField = (caseId: any, lenderId: any) => {
    setLoading(true);
    API_SERVICE.getLenderAdditonalField(lenderId, caseId, "")
      .then(({ data }) => {
        if (data?.payload?.workflowType === "FLOWABLE") {
          setGetNextTaskPayload(data?.payload?.taskDetail);
          setOpenFlowablePath(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetNextTask = (caseId: any, lenderId: any) => {
    setLoading(true);
    API_SERVICE.getNextTask(caseId, lenderId)
      .then((data) => {
        console.log(data.data.payload.jsonSchema);
        setIsLastTask(data.data.payload.lastStep);
        setGetNextTaskPayload(data.data.payload);
        setOpenFlowablePath(true);
      })
      .catch((e: any) => {
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("prefr")
        ) {
          if (e.response.data.payload.errorMessage) {
            if (
              e.response.data.payload.errorMessage
                .toLowerCase()
                .includes("no tasks found")
            ) {
              callbackAdditionalField({
                lenderInfoId: selectedLander.lenderInfoId,
                loanId: "",
                status: "",
                showRequirementDocs: "false",
              });
              setOpenFlowablePath(false);
              setShowAdditionalFields(false);
              skippedLoginStatus();
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClickContinue = (nextOrContinue: String) => {
    if (nextOrContinue == "Next") {
      // if (selectedLander?.lenderName?.toLowerCase()?.includes("flexi") || selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
      //     handleGetNextTask(currLeadId, currLenderId);
      // }
      let businessNameObj = finalAdditionalField?.find(
        (item) => item?.apiPropertyName === "business_name"
      );
      if (businessNameObj) {
        leadForm.setFieldValue(
          businessNameObj?.apiPropertyName,
          leadForm.getFieldValue("employername")
        );
      }
    }

    if (
      leadForm.getFieldValue("pan") &&
      leadForm.getFieldValue("dateOfBirth")
    ) {
      nextOrContinue === "Next"
        ? setRequiredProfession(false)
        : leadForm.getFieldValue("Employment_Type__c") ===
            "Self Employed Professional" ||
          leadForm.getFieldValue("Employment_Type__c") === "Salaried"
        ? setRequiredProfession(true)
        : setRequiredProfession(false);
      nextOrContinue === "Next"
        ? setRequiredProfessionCoApp(false)
        : leadForm.getFieldValue("Employment_Type__c") ===
            "Self Employed Professional" ||
          leadForm.getFieldValue("Employment_Type__c") === "Salaried"
        ? setRequiredProfessionCoApp(true)
        : setRequiredProfessionCoApp(false);
      if (nextOrContinue === "Next") {
        // if (Object.keys(additionalFields).length === 0) {
        //   setOpenFlowablePath(true);
        // }
        onChangeIndividualType(leadForm.getFieldValue("individualType"));
      }
    }
  };

  const onChangeAdditionalFields = () => {
    leadForm.getFieldValue("prospect.Employment_Type__c") ===
    "Self_Employed_Professional"
      ? setRequiredProfession(true)
      : setRequiredProfession(false);
    leadForm.getFieldValue("offer.CoApplicant_Leads__r.Employment_Type__c") ===
    "Self_Employed_Professional"
      ? setRequiredProfessionCoApp(true)
      : setRequiredProfessionCoApp(false);
  };
  const onChangeAdditionalFieldsInputs1 = (
    value: any,
    isRequired: boolean,
    isCheck: boolean
  ) => {
    if ((value == null && !isRequired) || !isCheck) {
      return Promise.resolve();
    }
    const lowRange = 16;
    const highRange = 24;
    if (lowRange > Number(value) || Number(value) > highRange) {
      return Promise.reject(
        new Error(`Requested ROI should be between 16 to 24`)
      );
    } else {
      return Promise.resolve();
    }
  };

  const validateAdditonalField = () => {
    setLoading(true);
     const param = { pincode: leadForm.getFieldValue("pincodeCase") };
    API_SERVICE.validateAdditonalField(currLenderId, currLeadId, param)
      .then(({ data }: any) => {
        if (data?.payload?.status) {
          handelEditCaseResponse(leadForm.getFieldsValue(), isAdditionalField, leadData);
        } else {
          if ((selectedLander?.lenderName.toLowerCase().includes("prefr"))) {
            const errors = data?.payload?.errorMsg?.split('Error:').at(-1);
            setShowValuesForPrefr(errors);
            setShowFlexiReason(data?.payload?.reason);
          } else {
            setShowValueForBajaj(data?.payload?.errorMsg);
            setShowFlexiReason(data?.payload?.reason);
          }
        }
      })
      .catch((e: any) => notification.error({ message: API_SERVICE.handleErrors(e) }))
      .finally(() => setLoading(false));
  };


  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnRadioTabStyles: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };


  return (
    <div className="content-box">

      
      {showFlexiReason.length > 0 && (
        <FlexiPincodeAvailibility
          showFlexiReason={showFlexiReason}
          setShowFlexiReason={setShowFlexiReason}
          currLenderId={currLenderId}
          currLeadId={currLeadId}
          showValueForBajaj={!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") ? selectedLander?.lenderName.toLowerCase().includes("prefr") ? showValuesForPrefr : "" : showValueForBajaj}
          pincode={leadForm.getFieldValue("pincodeCase")}
          message={!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") ? selectedLander?.lenderName.toLowerCase().includes("prefr") ? `${showValuesForPrefr}    Do you want to update and continue?` : `${leadForm.getFieldValue("pincodeCase")} Pincode is not serviceable by Flexiloans. Do you want to change your current address pincode?` : `${leadForm.getFieldValue("pincodeCase"), showValueForBajaj} Do you want to update?`}
          callBack={() => {
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              showRequirementDocs: "false"
            });
            setShowAdditionalFields(false);
            skippedLoginStatus();
          }}
          setLoading={setLoading}
        />
      )}
      <Form
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          applicantType: "INDIVIDUAL",
          genderCase: "M",
        }}
        form={leadForm}
        onFinish={onFinishForm}
        autoComplete="off"
        name="leadForm"
      >
        <Row gutter={[24, 24]}>
          {leadData?.productVersion === "V2" ? (
            <Col xs={24} md={16} span={16} className="col-border-right">
              <LeadEditAdditional
                leadData={leadData}
                isICICIender={isICICIender}
                isCASHEender={isCASHEender}
                isKreditBeeLender={isKreditBeeLender}
                additionalFields={additionalFields}
                isBajajLender={isBajajLender}
                isFlexiLender={isFlexiLender}
                isABCFLLender={isABCFLLender}
                selectedLander={selectedLander}
                checkAddressLength={checkAddressLength}
                setShowAdditionalFields={setShowAdditionalFields}
                leadForm={leadForm}
              />
            </Col>
          ) : (
            <>
              <Col xs={24} md={8} span={8} className="col-border-right">
                <div className={"form-box"}>
                  <div className="loan-type">
                    <Space className="item" direction="vertical">
                      <Text>Loan Type</Text>
                      <Space>
                        <img src={home} alt="" width={16} />
                        <Text>{leadData?.loanType}</Text>
                      </Space>
                      {/* <Title level={5}>{leadData?.loanType}</Title> */}
                    </Space>
                  </div>

                  {Object.keys(additionalFields).length === 0 &&
                    (leadData?.caseDetails?.loanDetails?.expectedInterestRate ??
                      0) == 0 &&
                    leadData?.caseDetails?.connectorDetails?.payoutType ==
                      "VARIABLE" && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                      <Form.Item
                        label="Tentative Payout %"
                        name="tentativePayout"
                        rules={[
                          {
                            required: true,
                            message: "Please enter tentative payout %",
                          },
                          {
                            pattern: /^\d*(\.)?(\d{0,2})?$/,
                            message: "Please enter valid payout %",
                          },
                        ]}
                      >
                        <Input
                          className="payout-input"
                          max={100}
                          min={0}
                          placeholder="Enter payout %"
                        />
                      </Form.Item>
                    )}

                  <Form.Item
                    label="Applicant Type"
                    name="applicantType"
                    rules={[
                      { required: true, message: "Please select loan type." },
                    ]}
                  >
                    <Radio.Group
                      onChange={onChangeApplicationType}
                      className="custom-radio"
                      style={btnRadioTabStyles}
                      name="applicantType"
                      disabled
                    >
                      <Space direction="horizontal">
                        {applicantTypes &&
                          applicantTypes.map((loan: any, i: number) => {
                            if (isPersonalLoan && loan.key == "BUSINESS") {
                              return null;
                            }
                            return (
                              <Radio.Button key={i} value={loan.key}>
                                {loan.value}
                              </Radio.Button>
                            );
                          })}
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <div className="application-type">
                    {selectedApplicationType === "INDIVIDUAL" ? (
                      <Form.Item
                        className="field-bg"
                        label="Individual Type"
                        name="individualType"
                        rules={[
                          {
                            required: true,
                            message: "Please select individual type",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select"
                          className="custom-select"
                          onChange={onChangeIndividualType}
                          disabled
                        >
                          {individualTypes &&
                            individualTypes.map((loan: any, i: number) => {
                              if (
                                isPersonalLoan &&
                                loan.key == "SELF_EMPLOYED"
                              ) {
                                return null;
                              }
                              return (
                                <Select.Option key={i} value={loan.key}>
                                  {loan.value}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        className="field-bg"
                        label="Business Type"
                        name="businessType"
                        rules={[
                          {
                            required: true,
                            message: "Please select business type",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select"
                          className="custom-select"
                          disabled
                        >
                          {businessTypes &&
                            businessTypes
                              .sort(function (a: any, b: any) {
                                if (a.key.toLowerCase() < b.key.toLowerCase())
                                  return -1;
                                if (a.key.toLowerCase() > b.key.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              ?.map((loan: any, i: number) => (
                                <Select.Option key={i} value={loan.key}>
                                  {loan.value}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    )}
                  </div>

                  {/* TODO - Bureau Score */}
                  {/* {Object.keys(additionalFields).length === 0 &&
                bureauScoreStatus.length == 0 &&
                selectedApplicationType === "INDIVIDUAL" && (
                  <div style={{ display: "grid" }}>
                    <Form.Item name="bureauScore">
                      <Checkbox
                        name="bureauScore"
                        className=""
                        style={{ marginTop: "10px" }}
                        onChange={(ev) => setIsBureauScore(ev.target.checked)}
                      >
                        <span className="note-text">Get Bureau Score</span>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item name="isCustomerIndian">
                      <Checkbox
                        style={{ marginLeft: "0px", marginTop: "10px" }}
                        onChange={(ev) =>
                          setIsCustomerIndian(ev.target.checked)
                        }
                      >
                        <span className="note-text">
                          Confirm if Customer is an Indian National
                        </span>
                      </Checkbox>
                    </Form.Item>

                    <span className="note-text" style={{ marginTop: "10px" }}>
                      I confirm that Customer has been informed about the credit
                      Bureau Disclaimer.
                    </span>

                    <span className="note-text">
                      “I hereby appoint Oneinfinity as my authorised
                      representation to receive my credit information from
                      Experian or such credit bureaus.”
                    </span>
                  </div>
                )}

              {bureauScoreStatus.length != 0 &&
                selectedApplicationType === "INDIVIDUAL" && (
                  <BureauScoreView
                    status={
                      leadData?.leadAdditionalInfoDetails?.bureauScoreStatus ??
                      ""
                    }
                  />
                )} */}

                  {selectedApplicationType === "INDIVIDUAL" ? (
                    <Divider orientation="left" orientationMargin="0">
                      Personal Details
                    </Divider>
                  ) : (
                    <Divider orientation="left" orientationMargin="0">
                      Contact Details
                    </Divider>
                  )}
                  <Form.Item
                    label="Gender"
                    name="genderCase"
                    rules={[
                      {
                        required: true,
                        message: "Please select individual type",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="custom-radio"
                      style={btnRadioTabStyles}
                      name="genderCase"
                      defaultValue={1}
                      onChange={() => setIsChangeCaseField(true)}
                    >
                      <Space direction="horizontal">
                        {genders &&
                          genders.map((loan: any, i: number) => (
                            <Radio.Button key={i} value={loan.key}>
                              {loan.value}
                            </Radio.Button>
                          ))}
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  {selectedApplicationType === "INDIVIDUAL" && (
                    <Form.Item
                      className="field-bg"
                      label="Pan"
                      name="pan"
                      rules={[
                        {
                          required:
                              Object.keys(additionalFields).length === 0
                                ? !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance")
                                : additionalFields?.some(
                                    (value) =>
                                      value?.internalPropertyName?.toUpperCase() ===
                                      "PAN_NUMBER"
                                  ),
                          message: "Please enter pan number",
                        },
                        {
                          pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                          message: "Please enter valid pan nuber",
                        },
                      ]}
                    >
                      <Input
                        className="custom-input"
                        // onChange={debounce(checkPanDetails, 1000)}
                        placeholder="Pan number"
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    className="field-bg"
                    label="Contact Name"
                    name="firstName"
                    rules={[
                      { required: true, message: "Please enter contact name" },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid name",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Contact name"
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Mobile Number"
                    name="mobileNumber"
                    required
                    rules={[
                      {
                        required:
                          isICICIender ||
                          isCASHEender ||
                          isKreditBeeLender ||
                          isABCFLLender || isFlexiLender
                            ? true
                            : Object.keys(additionalFields).length === 0
                            ? ["bajaj housing finance"].includes(
                              selectedLander?.lenderName?.toLowerCase()
                            )
                            : additionalFields?.some(
                                (value) =>
                                  value?.internalPropertyName?.toUpperCase() ===
                                  "PHONE_NUMBER"
                              ),
                        message: "Please enter mobile number",
                      },
                      {
                        pattern: PHONE_NUMBER_REGEX,
                        message: "Please enter valid number",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input-phone"
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                      onChange={() => {
                        setIsChangeCaseField(true);
                        setIsChangeMobile(true);
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Email"
                    name="email"
                    required
                    rules={[
                      {
                        
                        required:
                          isICICIender || isCASHEender || isKreditBeeLender || isFlexiLender
                            ? true
                            : Object.keys(additionalFields).length === 0
                            ? ["bajaj housing finance"].includes(
                              selectedLander?.lenderName?.toLowerCase()
                            )
                            : additionalFields?.some(
                                (value) =>
                                  value?.internalPropertyName?.toUpperCase() ===
                                  "EMAIL_ID"
                              ),
                        message: "Please enter email",
                      },
                      { type: "email", message: "Please enter valid email" },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Email"
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>

                  {(selectedApplicationType === "INDIVIDUAL" ||
                    additionalFields?.some(
                      (value) =>
                        value?.internalPropertyName?.toUpperCase() ===
                        "DATE_OF_BIRTH"
                    )) && (
                    // <Form.Item
                    //   className="field-bg"
                    //   //label="Date of Birth"
                    //   name="dateOfBirth"
                    //   rules={[
                    //     {
                    //       required:
                    //         Object.keys(additionalFields).length === 0
                    //           ? isBureauScore
                    //           : additionalFields?.some(
                    //               (value) =>
                    //                 value?.internalPropertyName?.toUpperCase() ===
                    //                 "DATE_OF_BIRTH"
                    //             ),
                    //       message: "Please enter date of birth",
                    //     },
                    //   ]}
                    // >
                    <FormDOB
                      form={leadForm}
                      disableDate={disableDate}
                      label="Date of Birth (YYYY-MM-DD)"
                      placeholder="Date of Birth"
                      name="dateOfBirth"
                      isRequired={
                        !(
                          selectedApplicationType === "BUSINESS" &&
                          isBajajLender
                        ) && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance")
                      }
                      onChange={() => setIsChangeCaseField(true)}
                    />
                    // </Form.Item>
                  )}

                  {!isPersonalLoan && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                    <Divider orientation="left" orientationMargin="0">
                      Business Details
                    </Divider>
                  )}
                  {selectedApplicationType !== "INDIVIDUAL" && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                    <>
                      <Form.Item
                        className="field-bg"
                        label="Business Name"
                        name="employername"
                        rules={[
                          {
                            required:
                              isFlexiLender || isBajajLender || isABCFLLender
                                ? true
                                : Object.keys(additionalFields).length === 0
                                ? ["credit saison"].includes(
                                    selectedLander?.lenderName?.toLowerCase()
                                  )
                                : additionalFields?.some(
                                    (value) =>
                                      value?.internalPropertyName?.toUpperCase() ===
                                      "BUSINESS_NAME"
                                  ),
                            message: "Please enter business name",
                          },
                        ]}
                      >
                        <Input
                          className="custom-input"
                          placeholder="Business name"
                          onChange={() => setIsChangeCaseField(true)}
                        />
                      </Form.Item>

                      <Form.Item
                        className="field-bg"
                        label="Business Pan"
                        name="businessPan"
                        rules={[
                          {
                            required:
                              Object.keys(additionalFields).length === 0
                                ? ["credit saison"].includes(
                                    selectedLander?.lenderName?.toLowerCase()
                                  )
                                : additionalFields?.some(
                                    (value) =>
                                      value?.internalPropertyName?.toUpperCase() ===
                                      "PAN_NUMBER"
                                  ),
                            message: "Please enter pan number",
                          },
                          {
                            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                            message: "Please enter valid pan number",
                          },
                        ]}
                      >
                        <Input
                          className="custom-input"
                          // onChange={debounce(checkPanDetails, 1000)}
                          onChange={() => setIsChangeCaseField(true)}
                          placeholder="Business pan number"
                        />
                      </Form.Item>
                    </>
                  )}
                  {!isPersonalLoan && !selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                    <>
                      <Form.Item
                        className="field-bg"
                        label="Business Profile"
                        name="businessProfile"
                        rules={[
                          {
                            required:
                              Object.keys(additionalFields).length === 0
                                ? (isFlexiLender ||
                                    isABCFLLender ||
                                    ["credit saison"].includes(
                                      selectedLander?.lenderName?.toLowerCase()
                                    )) ??
                                  false
                                : additionalFields?.some(
                                    (value) =>
                                      value?.internalPropertyName?.toUpperCase() ===
                                      "PROFILE"
                                  ),
                            message: "Please Select Business Profile",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Business Profile"
                          className="custom-select"
                          onChange={() => setIsChangeCaseField(true)}
                        >
                          {businessProfile &&
                            businessProfile
                              ?.sort(function (a: any, b: any) {
                                if (
                                  a?.key?.toLowerCase() < b?.key?.toLowerCase()
                                )
                                  return -1;
                                if (
                                  a?.key?.toLowerCase() > b?.key?.toLowerCase()
                                )
                                  return 1;
                                return 0;
                              })
                              ?.map((loan: any, i: number) => (
                                <Select.Option key={i} value={loan.key}>
                                  {loan.value}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="field-bg"
                        label="No of Years In Business"
                        name="businessVintage"
                        rules={[
                          {
                            required:
                              Object.keys(additionalFields).length === 0
                                ? isFlexiLender ?? false
                                : additionalFields?.some(
                                    (value) =>
                                      value?.internalPropertyName?.toUpperCase() ===
                                      "VERTICAL"
                                  ),
                            message: "Please enter no of years in business",
                          },
                          // {
                          //   pattern: /^[A-Za-z\\s ]+$/,
                          //   message: "Please enter valid name",
                          // },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          className="custom-input"
                          placeholder="No of Years In Business"
                          onChange={() => setIsChangeCaseField(true)}
                        />
                      </Form.Item>

                      <Form.Item
                        className="field-bg"
                        label="Business Turnover (Yearly)"
                        name="businessTurnover"
                        rules={[
                          {
                            required:
                              Object.keys(additionalFields).length === 0
                                ? ["hdfc bank", "credit saison"].includes(
                                    selectedLander?.lenderName?.toLowerCase()
                                  )
                                : additionalFields?.some((value) =>
                                    [
                                      "REVENUE_RANGE",
                                      "MONTHLY_INCOME",
                                      "LOAN_DETAILS.MONTHLY_IN_HAND_SALARY",
                                    ].includes(
                                      value?.internalPropertyName?.toUpperCase()
                                    )
                                  ),
                            message: "Please enter business turnover",
                          },
                        ]}
                      >
                        <InputNumber
                          className="custom-input-number"
                          placeholder="Business turnover"
                          formatter={(value) => numberWithCommas(value)}
                          min={1}
                          onChange={() => setIsChangeCaseField(true)}
                        />
                      </Form.Item>
                      <Form.Item
                        className="field-bg"
                        label="Monthly Obligations (EMIs)"
                        name="monthlyEmi"
                        rules={[
                          {
                            required: false,
                            message: "Please enter monthly obligations (EMIs)",
                          },
                        ]}
                      >
                        <InputNumber
                          className="custom-input-number"
                          placeholder="Monthly Obligations (EMIs)"
                          formatter={(value) => numberWithCommas(value)}
                          min={0}
                          onChange={() => setIsChangeCaseField(true)}
                        />
                      </Form.Item>
                      <Form.Item
                        className="field-bg"
                        label="Ownership"
                        name="propertyOwnership"
                        rules={[
                          {
                            required: isABCFLLender,
                            message: "Please enter Ownership",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select"
                          className="custom-select"
                          onChange={() => setIsChangeCaseField(true)}
                        >
                          {propertyOwnership &&
                            propertyOwnership
                              ?.sort(function (a: any, b: any) {
                                if (a.key.toLowerCase() < b.key.toLowerCase())
                                  return -1;
                                if (a.key.toLowerCase() > b.key.toLowerCase())
                                  return 1;
                                return 0;
                              })
                              ?.map((loan: any, i: number) => (
                                <Select.Option key={i} value={loan.key}>
                                  {loan.value}
                                </Select.Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={24} md={8} span={8} className="col-border-right">
                <div className={"form-box"}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ marginTop: "0px" }}
                  >
                    Current Address
                  </Divider>
                  {!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                  <Form.Item
                    className="field-bg"
                    label="Pin Code"
                    name="pincodeCase"
                    rules={[
                      {
                        required:
                          isCASHEender ||
                          isICICIender ||
                          isFlexiLender ||
                          isKreditBeeLender ||
                          isABCFLLender
                            ? true
                            : Object.keys(additionalFields).length === 0 ||
                              (selectedApplicationType === "BUSINESS" &&
                                isBajajLender)
                            ? ["hdfc bank", "nira finance"].includes(
                                selectedLander?.lenderName?.toLowerCase()
                              )
                            : additionalFields?.some((value) =>
                                [
                                  "PINCODE",
                                  "CURRENT_RESIDENCE_PINCODE",
                                ].includes(
                                  value?.internalPropertyName?.toUpperCase()
                                )
                              ),
                        message: "Please enter pin code",
                      },
                      {
                        pattern: /^[1-9][0-9]{5}$/,
                        message: "Please enter valid pincode",
                      },
                    ]}
                  >

                  
                    <Input
                      className="custom-input"
                      onChange={debounce(checkPincode, 1000)}
                      placeholder="Please enter pin code"
                    />
                  

                  </Form.Item>
                )}
                {selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                <Form.Item
                  label={
                    <span>
                      Pin Code&nbsp;
                      <Tooltip title="Please type more than 3 characters to access serviceable pincodes" >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  className="field-bg"

                  name="pincodeCase"
                  rules={[
                    {
                      required: true,
                      message: "Please enter pin code",
                    },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: "Please enter valid pincode",
                    },
                  ]}
                >
                  <Select
                    className="custom-select"
                    showSearch
                    placeholder="Select or enter pin code"
                    onSelect={selectPincodeForBajaj}
                    onSearch={debounce(handlePincodeChange, 1000)}
                    onChange={handlePincodeSelectChange}
                    filterOption={false}
                    options={pincodeOptions}
                  />
                </Form.Item>
                )}

                  {isBajajLender ? (
                    <Form.Item
                      className="field-bg"
                      label="Address Line 1"
                      name="address1Case"
                      rules={[
                        {
                          required:
                            isICICIender ||
                            isCASHEender ||
                            isFlexiLender ||
                            isABCFLLender
                              ? true
                              : Object.keys(additionalFields).length === 0 ||
                                (selectedApplicationType === "BUSINESS" &&
                                  isBajajLender)
                              ? ["hdfc bank", "nira finance"].includes(
                                  selectedLander?.lenderName?.toLowerCase()
                                )
                              : additionalFields?.some((value) =>
                                  [
                                    "ADDRESS",
                                    "ADDRESS_DETAILS",
                                    "CURRENT_RESIDENCE_ADDRESS_LINE_1",
                                  ].includes(
                                    value?.internalPropertyName?.toUpperCase()
                                  )
                                ),
                          message: "Please enter address",
                        },
                        { validator: checkAddressLength },
                      ]}
                    >
                      <TextArea
                        className="custom-input"
                        maxLength={100}
                        placeholder="Please enter address"
                        onChange={() => setIsChangeCaseField(true)}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      className="field-bg"
                      label="Address Line 1"
                      name="address1Case"
                      rules={[
                        {
                          required:
                            isICICIender ||
                            isCASHEender ||
                            isFlexiLender ||
                            isABCFLLender
                              ? true
                              : Object.keys(additionalFields).length === 0 ||
                                (selectedApplicationType === "BUSINESS" &&
                                  isBajajLender)
                              ? ["hdfc bank", "nira finance"].includes(
                                  selectedLander?.lenderName?.toLowerCase()
                                )
                              : additionalFields?.some((value) =>
                                  [
                                    "ADDRESS",
                                    "ADDRESS_DETAILS",
                                    "CURRENT_RESIDENCE_ADDRESS_LINE_1",
                                  ].includes(
                                    value?.internalPropertyName?.toUpperCase()
                                  )
                                ),
                          message: "Please enter address",
                        },
                        { validator: checkAddressLength },
                      ]}
                    >
                      <Input
                        className="custom-input"
                        placeholder="Please enter address"
                        onChange={() => setIsChangeCaseField(true)}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    className="field-bg"
                    label="City"
                    name="cityCase"
                    rules={[
                      {
                        required:
                          isICICIender || isCASHEender || isABCFLLender
                            ? true
                            : Object.keys(additionalFields).length === 0 ||
                              (selectedApplicationType === "BUSINESS" &&
                                isBajajLender)
                            ? ["nira finance"].includes(
                                selectedLander?.lenderName?.toLowerCase()
                              )
                            : additionalFields?.some(
                                (value) =>
                                  value?.internalPropertyName?.toUpperCase() ===
                                  "CITY"
                              ),
                        message: "Please enter city",
                      },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid city",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Please enter city"
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="State"
                    name="stateCase"
                    rules={[
                      {
                        required:
                          isICICIender || isCASHEender || isABCFLLender
                            ? true
                            : Object.keys(additionalFields).length === 0 ||
                              (selectedApplicationType === "BUSINESS" &&
                                isBajajLender)
                            ? ["nira finance"].includes(
                                selectedLander?.lenderName?.toLowerCase()
                              )
                            : additionalFields?.some(
                                (value) =>
                                  value?.internalPropertyName?.toUpperCase() ===
                                  "STATE"
                              ),
                        message: "Please enter state",
                      },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid state",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Please enter state"
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>

                  {!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                  <Divider orientation="left" orientationMargin="0">
                    Employer Details
                  </Divider>
                  )}
                  {!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                  <Form.Item
                    className="field-bg"
                    label="Employer / entity name"
                    name="employername"
                    rules={[
                      {
                        required:
                          isCASHEender || isABCFLLender
                            ? true
                            : Object.keys(additionalFields).length === 0
                            ? ["nira finance"].includes(
                                selectedLander?.lenderName?.toLowerCase()
                              )
                            : additionalFields?.some(
                                (value) =>
                                  value?.internalPropertyName?.toUpperCase() ===
                                  "EMPLOYEE_OR_ENTITY_NAME"
                              ),
                        message: "Enter employer / entity name",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Employer / entity name"
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>
                  )}

                  {isPersonalLoan &&
                    selectedApplicationType === "INDIVIDUAL" &&
                    leadData.loanType === "Personal Loan" && (
                      <>
                        <Form.Item
                          className="field-bg"
                          label="Monthly In-hand Salary(INR)"
                          name="monthlyInHandSalary"
                          rules={[
                            {
                              required: true,
                              // Object.keys(additionalFields).length === 0
                              //   ? false
                              //   : additionalFields?.some(
                              //     (value) =>
                              //       value?.internalPropertyName?.toUpperCase() ===
                              //       "REVENUE_RANGE" || value?.internalPropertyName?.toUpperCase() ===
                              //       "MONTHLY_INCOME" || value?.internalPropertyName?.toUpperCase() ===
                              //       "MONTHLY_INCOME"
                              //   ),
                              message: "Please enter Monthly In-hand Salary",
                            },
                          ]}
                        >
                          <InputNumber
                            className="custom-input-number"
                            placeholder="Monthly In-hand Salary"
                            formatter={(value) => numberWithCommas(value)}
                            min={1}
                            onChange={() => setIsChangeCaseField(true)}
                          />
                        </Form.Item>
                        <Form.Item
                          className="field-bg"
                          label="Office Pin Code"
                          name="officePinCode"
                          rules={[
                            {
                              required:
                                Object.keys(additionalFields).length === 0
                                  ? ["hdfc bank"].includes(
                                      selectedLander?.lenderName?.toLowerCase()
                                    )
                                  : additionalFields?.some(
                                      (value) =>
                                        value?.internalPropertyName?.toUpperCase() ===
                                          "OFFICEPINCODE" ||
                                        value?.apiPropertyName?.toUpperCase() ===
                                          "OFFICEPINCODE"
                                    ),
                              message: "Please enter office pin code",
                            },
                            {
                              pattern: /^[1-9][0-9]{5}$/,
                              message: "Please enter valid pincode",
                            },
                          ]}
                        >
                          <Input
                            className="custom-input"
                            // onChange={debounce(checkPincode, 1000)}
                            placeholder="Please enter office pin code"
                            onChange={() => setIsChangeCaseField(true)}
                          />
                        </Form.Item>
                        <Form.Item
                          className="field-bg"
                          label="Current EMIs (INR)"
                          name="currentEmis"
                          rules={[
                            {
                              // required: false,
                              required:
                                Object.keys(additionalFields).length === 0
                                  ? ["nira finance"].includes(
                                      selectedLander?.lenderName?.toLowerCase()
                                    )
                                  : additionalFields?.some(
                                      (value) =>
                                        value?.internalPropertyName ===
                                        "MONTHLY_EMI"
                                    ),
                              message: "Please enter Current EMIs (INR)",
                            },
                          ]}
                        >
                          <InputNumber
                            className="custom-input-number"
                            placeholder="Current EMIs (INR)"
                            formatter={(value) => numberWithCommas(value)}
                            onChange={handleCurrentEmis}
                            min={0}
                          />
                        </Form.Item>
                      </>
                    )}

                  {leadData?.loanType !== "Credit Card" ? (
                    <Divider orientation="left" orientationMargin="0">
                      Loan Details
                    </Divider>
                  ) : (
                    ""
                  )}

                  <Form.Item
                    className="field-bg"
                    label="Loan Amount"
                    name="loanAmount"
                    hidden={leadData?.loanType === "Credit Card"}
                    rules={[
                      { required: true, message: "Please enter loan amount" },
                    ]}
                  >
                    <InputNumber
                      className="custom-input-number"
                      placeholder="Please enter loan amount"
                      formatter={(value) => numberWithCommas(value)}
                      min={1}
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>
                  {!selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                  <Form.Item
                    className="field-bg"
                    label="Loan Tenure(in Years)"
                    hidden={leadData?.loanType === "Credit Card"}
                    name="loanTenure"
                    rules={[
                      { required: true, message: "Please enter Loan Tenure" },
                    ]}
                  >
                    <InputNumber
                      className="custom-input-number"
                      placeholder="Please enter Loan Tenure"
                      min={1}
                      onChange={() => setIsChangeCaseField(true)}
                    />
                  </Form.Item>
                  )}
                </div>
                {/* {!isAdditionalField && (
              <Row gutter={[24, 24]}>
                <Divider />
                {isMoneyWideLender && (
                  <Col className="btns">
                    <Form.Item>
                      <Space>
                        <div
                          className="consentSection"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Button
                            htmlType="button"
                            // htmlType="submit"
                            type="primary"
                            name="btnSumbit"
                            onClick={() => {
                              updatedChanges();
                            }}
                          >
                            Check Consent
                          </Button>
                          <label
                            className="consentLink"
                            onClick={OnChangesForRetriggerSMS}
                          >
                            Resend Consent SMS
                          </label>
                        </div>
                      </Space>
                    </Form.Item>
                  </Col>
                )}
                <Col className="btns">
                  <Form.Item>
                    <Space>
                      <Button
                        htmlType="button"
                        onClick={() => {
                          setShowAdditionalFields(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={loading}
                        disabled={isMoneyWideLender && !isConsent}
                        onClick={() => {
                          if (!isICICIender) {
                            onClickContinue(
                              isAdditionalField ? "Continue" : "Next"
                            );
                          }
                        }}
                        value={isAdditionalField ? "Continue" : "Next"}
                        htmlType="submit"
                        type="primary"
                        name="btnSumbit"
                      >
                        {isAdditionalField ? "Continue" : "Next"}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            )} */}
              </Col>
            </>
          )}

                {/* {selectedLander?.lenderName.toLowerCase().includes("bajaj housing finance") && (
                  <>
                  <Col xs={24} md={8} span={8}>
                  <Divider orientation="left" orientationMargin="0">
                  Loan Details: Current Address
                  </Divider>

                <Form.Item
                  label={
                    <span>
                      Pin Code&nbsp;
                      <Tooltip title="Please type more than 3 characters to access serviceable pincodes" >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </span>
                  }
                  className="field-bg"

                  name="pincodeCaseBajaj"
                  rules={[
                    {
                      required: true,
                      message: "Please enter pin code",
                    },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: "Please enter valid pincode",
                    },
                  ]}
                >
                  <Select
                    className="custom-select"
                    showSearch
                    placeholder="Select or enter pin code"
                    onSelect={selectPincodeForBajaj}
                    onSearch={debounce(handlePincodeChange, 1000)}
                    onChange={handlePincodeSelectChange}
                    filterOption={false}
                    options={pincodeOptions}
                  />
                </Form.Item>

                <Form.Item
                  className="field-bg"
                  label="City"
                  name="cityCaseBajaj"
                  rules={[
                    {
                      // required: true,
                      message: "Please enter city",
                    },
                    {
                      pattern: STRING_ONLY,
                      message: "Please enter valid city",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Please enter city"
                    onChange={() => setIsChangeCaseField(true)}
                  />
                </Form.Item>

                    </Col>
                    </>
                  )} */}

          {Object.keys(additionalFields).length !== 0 && (
            <Col xs={24} md={8} span={8}>
              <div className={"form-box"}>
                <Divider
                  orientation="left"
                  orientationMargin="0"
                  style={{ marginTop: "0px" }}
                >
                  Additional Fields
                </Divider>

                {finalAdditionalField?.map((field) => {
                  const hidden =
                    (field?.apiPropertyName === "noOfLoans" &&
                      !isCurrentEmis) ||
                    (isBajajLender &&
                      field?.apiPropertyName === "prospect.Profession__c" &&
                      !requiredProfession) ||
                    (isBajajLender &&
                      field?.apiPropertyName ===
                        "offer.CoApplicant_Leads__r.Profession__c" &&
                      !requiredProfessionCoApp);
                  return (
                    <>
                      {field?.dataType?.toLowerCase() === "date" &&
                      isBajajLender ? (
                        <>
                          {field.apiPropertyName ===
                          "prospect.dateOfIncorporation" ? (
                            <Form.Item
                              label={field?.displayName}
                              name={field?.apiPropertyName}
                              className="field-bg"
                              rules={[
                                {
                                  required:
                                    isAdditionalField &&
                                    !hidden &&
                                    field?.required,
                                  message: `Please enter ${field?.displayName}`,
                                },
                              ]}
                            >
                              <DatePicker
                                format="YYYY-MM-DD"
                                // format="YYYY-MM-DD"
                                className="custom-input"
                                placeholder={field?.displayName}
                                disabledDate={disabledDate}
                              />
                            </Form.Item>
                          ) : (
                            <FormDOB
                              form={leadForm}
                              disableDate={disableDate}
                              label={field?.displayName}
                              placeholder="Date of Birth"
                              name={field?.apiPropertyName}
                              isRequired={
                                isAdditionalField && !hidden && field?.required
                              }
                            />
                          )}
                        </>
                      ) : TextAreaFields.includes(field.apiPropertyName) ? (
                        <Form.Item
                          className="field-bg"
                          label={field?.displayName}
                          key={field?.apiPropertyName}
                          name={field?.apiPropertyName}
                          hidden={hidden}
                          initialValue={
                            null !== field?.defaultValue
                              ? field?.defaultValue
                              : field?.possibleValues &&
                                field?.possibleValues?.length === 1
                              ? field?.possibleValues[0].key
                              : null
                          }
                          rules={[
                            {
                              required:
                                isAdditionalField && !hidden && field?.required,
                              message: `Please enter ${field?.displayName}`,
                            },
                          ]}
                        >
                          <TextArea
                            id={field?.apiPropertyName}
                            className="custom-input"
                            hidden={hidden}
                            maxLength={100}
                            style={{ marginTop: "10px" }}
                            onWheel={(e) => e.currentTarget.blur()}
                            // value={item[field.apiPropertyName]}
                            // onChange={(ev) => rmInputHandler(ev, i)}
                            placeholder={
                              isBajajLender &&
                              field?.apiPropertyName ===
                                "offer.Requested_ROI__c"
                                ? `${field?.displayName}(16% to 24%)`
                                : field?.displayName
                            }
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          className="field-bg"
                          label={field?.displayName}
                          key={field?.apiPropertyName}
                          name={field?.apiPropertyName}
                          hidden={hidden}
                          initialValue={
                            null !== field?.defaultValue
                              ? field?.defaultValue
                              : field?.possibleValues &&
                                field?.possibleValues?.length === 1
                              ? field?.possibleValues[0].key
                              : null
                          }
                          rules={[
                            field?.dataType === "Number"
                              ? {
                                  pattern: POSITIVE_VALUE_REGEX,
                                  message: `Please eter ${field?.displayName} grater then 0`,
                                }
                              : { required: false },
                            field?.apiPropertyName ===
                              "prospect.Profession__c" ||
                            field?.apiPropertyName ===
                              "offer.CoApplicant_Leads__r.Profession__c"
                              ? {
                                  required:
                                    isAdditionalField &&
                                    !hidden &&
                                    leadForm.getFieldValue(
                                      "prospect.Employment_Type__c" ||
                                        "offer.CoApplicant_Leads__r.Employment_Type__c"
                                    ) !== ""
                                      ? requiredProfession ||
                                        requiredProfessionCoApp
                                      : false,
                                  message: `Please enter ${field?.displayName}`,
                                }
                              : {
                                  required:
                                    isAdditionalField &&
                                    !hidden &&
                                    field?.required,
                                  message: `Please enter ${field?.displayName}`,
                                },
                            {
                              validator: (_, e) =>
                                onChangeAdditionalFieldsInputs1(
                                  e,
                                  isAdditionalField &&
                                    !hidden &&
                                    field?.required,
                                  field?.apiPropertyName ===
                                    "offer.Requested_ROI__c"
                                ),
                            },
                          ]}
                        >
                          {field?.possibleValues ? (
                            <Select
                              key={field?.apiPropertyName}
                              id={field?.apiPropertyName}
                              className="custom-multiple-select hide-selected-item"
                              style={{ marginTop: "10px" }}
                              showSearch
                              placeholder={field?.displayName}
                              optionFilterProp="children"
                              onChange={() => onChangeAdditionalFields()}
                            >
                              {field?.possibleValues?.map((perpose: any) => (
                                <Select.Option
                                  {...perpose}
                                  key={perpose?.key}
                                  value={perpose?.key}
                                >
                                  {perpose?.value}
                                </Select.Option>
                              ))}
                            </Select>
                          ) : (
                            <Input
                              id={field?.apiPropertyName}
                              className="custom-input"
                              hidden={hidden}
                              style={{ marginTop: "10px" }}
                              type={
                                field?.dataType === "Number" ? "number" : "text"
                              }
                              onWheel={(e) => e.currentTarget.blur()}
                              // value={item[field.apiPropertyName]}
                              // onChange={(ev) => rmInputHandler(ev, i)}
                              placeholder={
                                isBajajLender &&
                                field?.apiPropertyName ===
                                  "offer.Requested_ROI__c"
                                  ? `${field?.displayName}(16% to 24%)`
                                  : field?.displayName
                              }
                            />
                          )}
                        </Form.Item>
                      )}
                    </>
                  );
                })}
                {isBajajLender && !isBajajExtraCall && (
                  <>
                    {isBajajLender &&
                      selectedApplicationType !== "BUSINESS" && (
                        <Checkbox onChange={onChangeCoApplicantDetails}>
                          Add Co Applicant Details
                        </Checkbox>
                      )}
                    <br />
                    {isBajajLender &&
                      selectedApplicationType !== "BUSINESS" && (
                        <Checkbox onChange={onChangePermanantAddess}>
                          Permanant Address Same As Current Address
                        </Checkbox>
                      )}
                    <br />

                    {isBajajLender && selectedApplicationType == "BUSINESS" && (
                      <Checkbox onChange={onChangeCoApplicantPermanantAddess}>
                        Co Applicant Permanent Address Same As Current Address
                      </Checkbox>
                    )}
                  </>
                )}
                {!isAdditionalField && (
                  <div
                    style={{
                      position: "absolute",
                      inset: "0",
                      backgroundColor: "#FFFFFF88",
                      zIndex: "9",
                    }}
                  />
                )}
              </div>
            </Col>
          )}
        </Row>

        {/* <Row gutter={[24, 24]}>
          <Divider />
          <Col offset={21}>
            <Form.Item>
              <Space>
                <Button
                  htmlType="button"
                  onClick={() => setShowAdditionalFields(false)}
                >
                  Cancel
                </Button>
                <Button loading={loading} type="primary" htmlType="submit">
                  {isAdditionalField ? "Continue" : "Next"}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row> */}

        {/* {isAdditionalField && renderButtons()} */}
        {/* {!isAdditionalField && ( */}
        <div>
          <Row>
            <Divider />
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Col className="btns">
                {isMoneyWideLender && !isConsent ? (
                  <Form.Item>
                    <Space>
                      <div className="consentSection">
                        <Button
                          htmlType="button"
                          // htmlType="submit"
                          className="getConsent"
                          type="primary"
                          name="btnSumbit"
                          onClick={() => {
                            updatedChanges();
                          }}
                        >
                          Get Consent
                        </Button>
                        <label
                          className="consentLink"
                          onClick={OnChangesForRetriggerSMS}
                        >
                          Resend Consent SMS
                        </label>
                      </div>
                    </Space>
                  </Form.Item>
                ) : (
                  isMoneyWideLender &&
                  isConsent && (
                    <label className="consentSuccessLink">
                      <img src={iconVerified} />
                      Consent Taken
                    </label>
                  )
                )}
              </Col>
              <Col className="btns">
                <Form.Item>
                  <Space>
                    <Button
                      className="dynamic-btn-default"
                      style={btnDefaultBorderStyle}
                      htmlType="button"
                      onClick={() => {
                        setShowAdditionalFields(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="dynamic-btn-primary"
                      style={btnPrimaryStyles}
                      loading={loading}
                      disabled={isMoneyWideLender && !isConsent}
                      // onClick={  async () => {
                      //   console.log('Forms >>>1', await leadForm.validateFields());
                      //   console.log('Forms', leadForm.getFieldsValue());
                      //   // onClickContinue(isAdditionalField ? "Continue" : "Next")
                      // }
                      // }
                      htmlType="submit"
                      type="primary"
                    >
                      {isAdditionalField ? "Continue" : "Next"}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </div>
          </Row>
        </div>
        {/* )} */}
      </Form>
      
      {/* {console.log("flowableLeadData", flowableLeadData)} */}
      {openFlowablePath && (
        <AddtionalFieldsFlowable
          getNextTaskPayload={getNextTaskPayload}
          callBack={callBack}
          setShowAdditionalFields={setShowAdditionalFields}
          setCustomUI={setCustomUI}
          setTaskDetails={setTaskDetails}
          taskDetails={taskDetails}
          customUI={customUI}
          leadData={leadData}
          currLenderId={currLenderId}
          currLoanProductId={currLoanProductId}
          currLeadId={currLeadId}
          setOpenFlowablePath={setOpenFlowablePath}
          openFlowablePath={openFlowablePath}
          jsonSchema={formData}
          setCallbackReqDoc={setCallbackReqDoc}
          callbackAdditionalField={callbackAdditionalField}
          selectedLander={selectedLander}
          getLeadById={getLeadById}
          lenderLoginHandler={lenderLoginHandler}
          skippedLoginStatus={skippedLoginStatus}
          setIsLastTask={setIsLastTask}
          isLastTask={isLastTask}
        />
      )}
      {loading && <AppLoading />}
    </div>
  );
};

export default AdditionalField;
