import "./index.scss";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import {
  Input,
  Space,
  Button,
  Row,
  Col,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Form,
  notification,
  AutoComplete,
  Select,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { FaSearch } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import debounce from "lodash/debounce";
import { getUser, hasUserRole } from "shared/services/Utility";
import type { ColumnsType } from "antd/es/table";
import { ReactComponent as IconShare } from "../../../assets/images/shareIcon.svg";
import { ReactComponent as IconPlayNewBorder } from "../../../assets/images/play_new_border.svg";
import { ReactComponent as IconEditBorder } from "../../../assets/images/edit_border.svg";
import whatsappIcon from "../../../assets/images/whatsappIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import {
  setActiveKey,
  setIsShowFilter,
  setLeadPageNo,
  setLeadPageSize,
  setLeadSearchParam,
  setPostLenderCase,
  setPreLenderCase,
  setRestFilterData,
} from "shared/redux/lead-reducer";
import LeadFilter from "./LeadFilter/LeadFilter";
import blackFilterIcon from "../../../assets/images/blackFilter.svg";
import blackRefereshIcon from "../../../assets/images/blackReferesh.svg";
import { setScreenHeader } from "shared/redux/common-reducer";
import { DownOutlined } from "@ant-design/icons";

const { CheckableTag } = Tag;
const { TabPane } = Tabs;

type Props = {};

const LeadPage: React.FunctionComponent<Props> = () => {
  const userRolePermission = hasUserRole();
  const history = useHistory();
  const location = useLocation() as any;
  const [allLeadsData, setAllLeadsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [counts, setCounts] = useState({
    totalCases: 0,
    totalCasesInLeadState: 0,
    totalCasesInProspectState: 0,
    totalCasesInVisitState: 0,
    totalCasesInDocumentState: 0,
    totalLenderAssignedCases: 0,
    totalLenderLoggedInCases: 0,
    totalLenderSanctionedCases: 0,
    totalLenderRejectedCases: 0,
    totalLenderWithdrawnCases: 0,
    totalLenderPendencyCases: 0,
    totalLenderDisbursedCases: 0,
  });
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [prospectOnboardingStatus, setProspectOnboardingStatus] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const lead: any = useSelector((state: RootState) => state.lead);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [errorCaseIds, setErrorCaseIds] = useState([] as any);
  const [selectedCaseIds, setSelectedCaseIds] = useState([] as any);
  const [isModalWhatsappVisible, setIsModalWhatsappVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isWhatsappEnable, setIsWhatsappEnable] = useState(false);
  const { appColors }: any = useSelector((state: RootState) => state.common);

  const columns: ColumnsType<any> = [
    {
      title: "Lead",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      width: 50,
      ellipsis: true,
    },
    /*{
      title: "First Name",
      dataIndex: ["contactDetails", "firstName"],
      key: "name",
      ellipsis: true,
    },
    {
      title: "Last Name",
      dataIndex: ["contactDetails", "lastName"],
      key: "name",
      ellipsis: true,
    },*/
    {
      title: "Product Type",
      dataIndex: ["loanType"],
      key: "productType",
      ellipsis: true,
    },
    {
      title: "Lender Name",
      dataIndex: ["caseDetails", "allLenderNames"],
      key: "allLenderNames",
      ellipsis: true,
      render: (_: any, record: any) => {
        const lenderNames = record?.caseDetails?.allLenderNames;
        if (lead.activeKey == "PRE_LENDER" && (lenderNames?.length ?? 0) > 0) {
          if ((lenderNames?.length ?? 0) > 0) {
            return (
              <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
                <text>{lenderNames}</text>
              </div>
            );
          }
        } else {
          if (
            record.caseDetails.lenderActivityHolder.lenderActivityDetails
              .length > 0
          ) {
            return (
              <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
                <text>
                  {
                    record.caseDetails.lenderActivityHolder
                      .lenderActivityDetails[0].lenderName
                  }
                </text>
              </div>
            );
          }
        }
        return "NA";
      },
    },
    {
      title: "Lender Current Stage",
      dataIndex: ["caseDetails", "allLenderStages"],
      key: "allLenderStages",
      ellipsis: true,
      render: (_: any, record: any) => {
        let lenderStage = record?.caseDetails?.allLenderStages;
        const lenderActivityDetails =
          record?.caseDetails?.lenderActivityHolder?.lenderActivityDetails;
        if (
          (lenderStage?.length ?? 0) === 0 &&
          (lenderActivityDetails?.length ?? 0) > 0
        ) {
          lenderStage =
            record.caseDetails.lenderActivityHolder.lenderActivityDetails[0]
              .status;
        }
        return (
          <div style={{ width: "200px", whiteSpace: "pre-wrap" }}>
            <text>{lenderStage}</text>
          </div>
        );
      },
    },
    {
      title: "Drop Off Stage",
      dataIndex: "currDropOffStage",
      key: "currDropOffStage",
      width: 50,
      ellipsis: true,
      render: (_: any, record: any) => {
        let value = {} as any;
        value = prospectOnboardingStatus?.find((item: any) => {
          return item?.key === record?.currDropOffStage;
        });
        return value?.value;
      },
    },
    {
      title: "Version",
      width: 50,
      dataIndex: "productVersion",
      key: "version",
      align: "center",
    },
    {
      title: "Case Stage",
      dataIndex: ["caseDetails", "caseStatus"],
      key: "stage",
    },
    {
      title: "Lead Originator",
      dataIndex: ["caseDetails", "connectorDetails", "companyName"],
      key: "sourceOfCase",
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "mobileNumber",
      render: (_: any, record: any) =>
        record?.contactDetails?.mobileNumber.startsWith("1")
          ? ""
          : record?.contactDetails?.mobileNumber,
    },
    {
      title: "Case Created Date",
      dataIndex: "caseCreatedDate",
      key: "caseCreatedDate",
      width: 180,
      render: (_: any, record: any) =>
        moment(record.caseCreatedDate)
          .locale("en")
          .format("MMM, DD, YYYY HH:mm"),
    },
    {
      title: "Days Open",
      dataIndex: "daysOpen",
      key: "daysOpen",
      width: 30,
    },
    {
      title: "Assign to Sales User",
      dataIndex: ["caseDetails", "assignedToUserDetails", "firstName"],
      key: "assignedSalesUserName",
      width: 30,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: "center",
      width: 60,
      render: (_: any, record: any) => {
        const isAction =
          ("LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey) ||
          ("PRE_LENDER" === lead.activeKey &&
            "ALL" === lead.preLenderCase &&
            record.caseDetails.caseStatus === "Lead");
        let isLoanProduct = (record?.loanProductId?.length ?? 0) == 0;
        return (
          <Space className="action-button" size="small">
            <Button
              className="custome-btn-icon-secondary"
              style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
              size="small"
              // type="primary"
              // shape="circle"
              hidden={
                "LEAD" === lead.preLenderCase && "PRE_LENDER" === lead.activeKey
              }
              onClick={() => getShareLinkId(record)}
              icon={<IconShare />}
            />
            {isWhatsappEnable && (
              <Button
                size="small"
                hidden={!record?.isWhatsAppEnabled}
                onClick={() => {
                  setSelectedLead(record);
                  handleClick(record.caseDetails.caseId);
                }}
                icon={
                  <img
                    src={whatsappIcon}
                    alt=""
                    height={24}
                    style={{ marginTop: "-4px" }}
                  />
                }
              />
            )}

            {isAction || isLoanProduct ? (
              <div style={{ width: "24px" }}></div>
            ) : (
              <Button
                className="custome-btn-icon-play"
                style={{ "--bgColor": appColors?.appPrimaryColor ?? "" } as any}
                size="small"
                type="text"
                onClick={() => goToLeadDetails(record)}
                icon={<IconPlayNewBorder />}
              />
            )}
            <Button
              className="custome-btn-icon-secondary"
              style={{ "--bgColor": appColors?.appSecondaryColor ?? "" } as any}
              size="small"
              type="text"
              disabled={userRolePermission["ReadOnly"]}
              hidden={
                !(
                  userRolePermission["UpdateLead"] ||
                  userRolePermission["Admin"]
                )
              }
              onClick={() => goToLeadEdit(record)}
              icon={<IconEditBorder />}
            />
            {/* <Button
            className="pink"
            size="small"
            type="primary"
            shape="circle"
            icon={<AiFillDelete />}
          /> */}
          </Space>
        );
      },
    },
  ];

  const preLenderCases = [
    {
      key: "ALL",
      value: "All (" + counts.totalCases + ")",
    },
    {
      key: "LEAD",
      value: "Lead (" + counts.totalCasesInLeadState + ")",
    },
    {
      key: "PROSPECT",
      value: "Prospect (" + counts.totalCasesInProspectState + ")",
    },
    {
      key: "VISIT",
      value: "Visit (" + counts.totalCasesInVisitState + ")",
    },
    {
      key: "DOCUMENTATION",
      value: "Documentation (" + counts.totalCasesInDocumentState + ")",
    },
  ];

  const postLenderCases = [
    {
      value: "Lender Selection (" + counts.totalLenderAssignedCases + ")",
      key: "ASSIGNED",
    },
    {
      value: "Log in (" + counts.totalLenderLoggedInCases + ")",
      key: "LOGGED",
    },
    {
      key: "SANCTIONED",
      value: "Sanctioned (" + counts.totalLenderSanctionedCases + ")",
    },
    {
      key: "REJECTED",
      value: "Rejected (" + counts.totalLenderRejectedCases + ")",
    },
    {
      key: "WITHDRAWN",
      value: "Withdrawn (" + counts.totalLenderWithdrawnCases + ")",
    },
    {
      key: "PENDENCY",
      value: "Pendency (" + counts.totalLenderPendencyCases + ")",
    },
    {
      key: "DISBURSED",
      value: "Disbursed (" + counts.totalLenderDisbursedCases + ")",
    },
  ];

  if (location?.state) {
    /*if (location?.state?.activeTab) {
      dispatch(setActiveKey(location?.state?.activeTab));
    }

    if (location?.state?.name && "POST_LENDER" === location?.state?.activeTab)
      dispatch(setPostLenderCase(location?.state?.name));
    else if (location?.state?.name)
      dispatch(setPreLenderCase(location?.state?.name));*/

    location.state = null;
  }

  /* useEffect functions */
  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Manage Leads" }));

    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredProspectOnboardingStatus = enumValues.find(
        (item: any) => item.propertyName === "PROSPECT_ONBOARDING_STATUS"
      );

      if (filteredProspectOnboardingStatus)
        setProspectOnboardingStatus(
          filteredProspectOnboardingStatus?.keyAndDisplayValueList
        );
    }
  }, []);

  useEffect(() => {
    if (
      userRolePermission["CreatAdmineOperations"] === true ||
      userRolePermission["CreateOperations"] === true ||
      userRolePermission["CreateLead"] === true
    ) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
    // dispatch(setLeadPageNo(1));
    form.setFieldsValue({
      searchBox: lead.searchParam,
    });

    getAllLeadsData(
      lead.pageNo,
      lead.pageSize,
      lead.searchParam,
      lead.filterData
    );
  }, [
    lead.activeKey,
    lead.searchParam,
    lead.preLenderCase,
    lead.postLenderCase,
    lead.filterData,
  ]);

  /* other functions */
  const refreshLead = () => {
    //dispatch(setFilterData({}));
    dispatch(setRestFilterData());
    dispatch(setActiveKey("PRE_LENDER"));
    dispatch(setPreLenderCase("ALL"));
    dispatch(setPostLenderCase("ASSIGNED"));
    //setSelectedFilterData({});
    dispatch(setIsShowFilter(false));
    form.setFieldsValue({
      searchBox: "",
    });
    dispatch(setLeadSearchParam(""));
    dispatch(setLeadPageNo(1));
    getAllLeadsData(1, lead.pageSize, "", {});
  };

  const goToLeadEdit = (item: any) => {
    if (
      (item?.loanProductId?.length ?? 0) == 0 ||
      item?.productVersion != "V2"
    ) {
      history.push(`/lead/edit/${item.caseDetails.caseId}`);
    } else {
      goToLeadDetails(item);
    }
  };

  const goToLeadDetails = (item: any) => {
    history.push(`/lead/details/${item.caseDetails.caseId}`);
  };

  const handleClick = (caseId) => {
    API_SERVICE.getWhatsAppDocumentValidate(caseId)
      .then(({ data }) => {
        if (data.payload.validToSend) {
          setIsModalWhatsappVisible(true);
        } else {
          setIsModalWhatsappVisible(false);
          setSuccessMessage(data.payload.message);
          setIsSuccessModalVisible(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getShareLinkId = (item: any) => {
    API_SERVICE.getShareLinkId(item?.caseDetails?.caseId)
      .then(({ data }) => {
        navigator.clipboard.writeText(data?.payload);
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const resetCounts = () => {
    setCounts((counts) => ({
      ...counts,
      totalCases: 0,
      totalCasesInLeadState: 0,
      totalCasesInProspectState: 0,
      totalCasesInVisitState: 0,
      totalCasesInDocumentState: 0,
      totalLenderAssignedCases: 0,
      totalLenderLoggedInCases: 0,
      totalLenderSanctionedCases: 0,
      totalLenderRejectedCases: 0,
      totalLenderWithdrawnCases: 0,
      totalLenderPendencyCases: 0,
      totalLenderDisbursedCases: 0,
    }));
  };

  const getAllLeadsData = (
    pageNo: number = 1,
    pageSize: number = 10,
    searchValue: any = null,
    //caseStatus: string = lead.preLenderCase,
    filter: any = lead.filterData
  ) => {
    let params = {};
    if (
      lead.activeKey === "PRE_LENDER" &&
      (lead.preLenderCase === "VISIT" ||
        lead.preLenderCase === "LEAD" ||
        lead.preLenderCase === "DOCUMENTATION" ||
        lead.preLenderCase === "PROSPECT")
    ) {
      params = {
        ...filter,
        searchParam: searchValue,
        status: lead.preLenderCase,
      };
    } else {
      params = {
        ...filter,
        searchParam: searchValue,
      };
    }

    if (lead.activeKey === "PRE_LENDER")
      getPreLenderData(pageNo, pageSize, params);
    else if (lead.activeKey === "POST_LENDER")
      getPostLenderData(lead.postLenderCase, pageNo, pageSize, params);
  };

  const getPreLenderData = (pageNo, pageSize, params) => {
    setLoading(true);
    setAllLeadsData([]);
    API_SERVICE.getLeadsByFilter(pageNo, pageSize, params)
      .then(({ data }) => {
        if (data?.payload?.content?.length > 0) {
          mapLeadsData(data);
        } else {
          mapLeadsData(data);
          notification.error({ message: "No data found." });
        }
      })
      .catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => setLoading(false));
  };

  const getPostLenderData = (caseStatus, pageNo, pageSize, params) => {
    setLoading(true);
    setAllLeadsData([]);
    API_SERVICE.getLeadsByFilterPostCase(caseStatus, pageNo, pageSize, params)
      .then(({ data }) => {
        if (data?.payload?.content) {
          mapLeadsData(data);
        }
      })
      .catch((error) => {
        mapLeadsData([]);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      })
      .finally(() => setLoading(false));
  };

  const mapLeadsData = (data: any) => {
    //setCheckedListFilter(data?.payload);
    resetCounts();
    setCounts((counts) => ({
      ...counts,
      totalCases: data?.payload?.totalCases ?? 0,
      totalCasesInLeadState: data?.payload?.totalCasesInLeadState ?? 0,
      totalCasesInProspectState: data?.payload?.totalCasesInProspectState ?? 0,
      totalCasesInVisitState: data?.payload?.totalCasesInVisitState ?? 0,
      totalCasesInDocumentState: data?.payload?.totalCasesInDocumentState ?? 0,
      totalLenderAssignedCases: data?.payload?.totalLenderAssignedCases ?? 0,
      totalLenderLoggedInCases: data?.payload?.totalLenderLoggedInCases ?? 0,
      totalLenderSanctionedCases:
        data?.payload?.totalLenderSanctionedCases ?? 0,
      totalLenderRejectedCases: data?.payload?.totalLenderRejectedCases ?? 0,
      totalLenderWithdrawnCases: data?.payload?.totalLenderWithdrawnCases ?? 0,
      totalLenderPendencyCases: data?.payload?.totalLenderPendencyCases ?? 0,
      totalLenderDisbursedCases: data?.payload?.totalLenderDisbursedCases ?? 0,
    }));
    setTotalRecords(data?.payload?.totalElements);
    setAllLeadsData(data?.payload?.content);
    setIsWhatsappEnable(data?.payload?.whatsappEnabled);
  };

  const handlePaginationChange = (pagination) => {
    let page = pagination.pageSize !== lead.pageSize ? 1 : pagination.current;
    dispatch(setLeadPageNo(page));
    dispatch(setLeadPageSize(pagination.pageSize));
    getAllLeadsData(page, pagination.pageSize, lead.searchParam);
  };

  const handlePreLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPreLenderCase(value));
  };

  const handlePostLenderTagChange = (i: number, value: string) => {
    dispatch(setLeadPageNo(1));
    dispatch(setPostLenderCase(value));
  };

  const activeTabChange = (key) => {
    setAllLeadsData([]);
    dispatch(setLeadPageNo(1));
    dispatch(setActiveKey(key));
  };

  const getColumns = () => {
    const co = columns.filter(
      (col) =>
        col.key !==
        ("VISIT" !== lead.preLenderCase && lead.activeKey === "PRE_LENDER"
          ? ""
          : "currDropOffStage")
    );
    const co1 = co.filter(
      (col) =>
        col.key !==
        (lead.preLenderCase == "ALL" && lead.activeKey === "PRE_LENDER"
          ? ""
          : "allLenderStages")
    );
    return co1;
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({}),
  };

  const searchAssignTo = (ev: any) => {
    if (ev.length != 0) {
      const params = {
        name: ev,
      };
      API_SERVICE.getUsersWithRole(params).then(({ data }) => {
        if (data) {
          setAssignToData(data.payload);
        }
      });
    }
  };

  const selectAssignTo = (item, opt) => {
    setSelectedAssignTo(opt);
  };

  const assignSalesUserToLead = () => {
    if (undefined === selectedRowKeys || selectedRowKeys.length === 0) {
      notification.error({ message: "Please select a lead to approve." });
      return;
    }
    if (
      undefined === selectedAssignTo ||
      null === selectedAssignTo ||
      undefined === selectedAssignTo?.userId ||
      null === selectedAssignTo.userId
    ) {
      notification.error({ message: "Please assignee to assign a lead." });
      return;
    }
    if (
      selectedRowKeys &&
      selectedRowKeys.length > 0 &&
      selectedAssignTo &&
      selectedAssignTo.userId
    ) {
      //const tempRowKey = selectedRowKeys.map(id=>{return `${id}1`})
      const params = {
        dsaCaseIds: selectedRowKeys,
        salesUserId: selectedAssignTo.userId,
      };
      setSelectedCaseIds(selectedRowKeys);
      API_SERVICE.assignSalesUserToLead(params)
        .then(({ data }) => {
          if (data) {
            let tmp = [""];
            tmp.push("salesUserId");
            setSelectedRowKeys([]);
            //findErrorCaseIds(params)
            if (data.payload.errorCases) {
              notification.error({ message: data.payload.message });
            } else {
              notification.success({ message: data.payload.message });
            }
            form.resetFields(tmp);
            console.log(errorCaseIds, selectedCaseIds);
            getAllLeadsData(lead.pageNo, lead.pageSize, lead.searchParam);
          }
          if (data.payload.errorCases) {
            setErrorDisplay(true);
            const tmpErrorCaseId = data.payload.errorCases;
            const errorCaseId = data.payload.errorCases; //tmpErrorCaseId.map(caseId=>{return caseId.substr(0,(caseId.length)-1)})
            setErrorCaseIds(errorCaseId);
            console.log(errorCaseIds, selectedCaseIds);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
    }
  };

  const handleModalOk = () => {
    API_SERVICE.sendWhatsAppMessage(selectedLead.caseDetails.caseId)
      .then(({ data }) => {
        setIsModalWhatsappVisible(false);
        if (data.payload) {
          notification.success({ message: data.payload });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const handleModalCancel = () => {
    setIsModalWhatsappVisible(false);
  };
  const handleModalmessage = () => {
    setIsSuccessModalVisible(false);
  };

  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
  };

  const whatsapp = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isModalWhatsappVisible}
        footer={null}
        closable={false}
      >
        <div
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              marginBottom: "35px",
              marginLeft: "5px",
              fontSize: "medium",
            }}
          >
            Is {selectedLead?.contactDetails?.mobileNumber} the valid contact
            number? <br />
            if not, please update lead details.
          </p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              className="dynamic-btn-primary"
              style={
                {
                  "--btnColor": appColors?.appPrimaryColor ?? "",
                  width: "155px",
                  height: "40px",
                  borderRadius: "8px",
                } as any
              }
              type="primary"
              onClick={handleModalOk}
            >
              Confirm
            </Button>
            <Button
              className="dynamic-btn-default"
              type="default"
              style={
                {
                  "--borderColor": appColors?.appSecondaryColor ?? "",
                  width: "155px",
                  height: "40px",
                  borderRadius: "8px",
                } as any
              }
              onClick={handleModalCancel}
            >
              Back
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  const message = () => {
    return (
      <Modal
        centered
        width={450}
        visible={isSuccessModalVisible}
        footer={null}
        closable={false}
      >
        {" "}
        <p
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "15px",
            marginLeft: "5px",
            fontSize: "medium",
          }}
        >
          {successMessage}
        </p>
        <Button
          className="dynamic-btn-default"
          style={
            {
              "--borderColor": appColors?.appSecondaryColor ?? "",
              width: "155px",
              height: "40px",
              borderRadius: "8px",
              margin: "20px",
              marginLeft: "110px",
            } as any
          }
          type="default"
          onClick={handleModalmessage}
        >
          OK
        </Button>
      </Modal>
    );
  };

  const bulkAssignError = () => {
    return (
      <Modal
        centered
        //style={{top:20}}
        width={350}
        footer={null}
        closable={false}
        visible={errorDisplay}
      >
        <div
          style={{ display: "grid", alignItems: "center", marginTop: "5px" }}
        >
          {/**/}
          <p>
            There was an error in assigning some cases. Please retry assignment
            for the cases highlighted on screen
          </p>
          <Button
            style={{
              bottom: "15px",
              justifyContent: "center",
              left: "70px",
            }}
            size="large"
            className="button-submit"
            htmlType="submit"
            onClick={() => setErrorDisplay(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  };

  const menuList = (
    <Menu
      selectable
      className="menu-list"
      style={
        {
          "--bgColor": appColors?.appPrimaryLightColor ?? "",
          "--color": appColors?.appPrimaryColor ?? "",
        } as any
      }
      onClick={(item: any) => {
        if (item?.key == "BulkUpload") {
          history.push("/lead/bulkUpload");
        } else {
          history.push("/lead/bulkUpdate");
        }
      }}
      items={[
        {
          key: "BulkUpload",
          label: "Bulk Upload",
        },
        {
          key: "BulkUpdate",
          label: "Bulk Update",
          style: {
            display:
              userRolePermission["ApprovePayout"] ||
              userRolePermission["Admin"] ||
              getUser()?.userDetails?.department?.toLowerCase() == "back office"
                ? ""
                : "none",
          },
        },
      ]}
    />
  );

  return (
    <>
      {bulkAssignError()}
      {whatsapp()}
      {message()}
      <div className="content-box">
        <Form form={form}>
          <Row className="lead-filter-row">
            <Col className="filter-bar left" span={13}>
              <Form.Item name="searchBox">
                <Input
                  size="large"
                  className="search-box"
                  placeholder="Search"
                  onChange={debounce((event) => {
                    dispatch(setLeadPageNo(1));
                    dispatch(setLeadSearchParam(event.target.value));
                  }, 600)}
                  allowClear
                  prefix={<FaSearch />}
                />
              </Form.Item>
            </Col>
            <Col
              span={11}
              className="filter-bar right"
              style={{ marginLeft: "-62px", marginTop: "-7px" }}
            >
              <Space>
                <Button
                  type="primary"
                  className="filter-btn"
                  onClick={() => dispatch(setIsShowFilter(!lead.isShowFilter))}
                >
                  <img
                    src={blackFilterIcon}
                    alt=""
                    style={{ marginLeft: "-7px" }}
                  />
                  <div
                    className="badge-green"
                    hidden={
                      !(
                        lead.filterData?.connectorId ||
                        lead.filterData?.loanType ||
                        lead.filterData?.location ||
                        lead.filterData?.managerHierarchy
                      )
                    }
                  />
                </Button>

                <Tooltip title="Refresh">
                  <Button
                    type="primary"
                    className="refresh-button"
                    onClick={() => refreshLead()}
                  >
                    {" "}
                    <img
                      src={blackRefereshIcon}
                      alt=""
                      style={{ marginLeft: "-10px" }}
                    />
                  </Button>
                </Tooltip>

                <Button
                  type="primary"
                  className="add-button dynamic-btn-primary"
                  style={btnPrimaryStyles}
                  hidden={
                    !(
                      userRolePermission["CreateLead"] ||
                      userRolePermission["Admin"]
                    )
                  }
                  onClick={() => history.push("/lead/add-lead")}
                >
                  Add Lead
                </Button>

                <Dropdown
                  overlay={menuList}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: false }}
                >
                  <Button
                    type="primary"
                    className="add-button dynamic-btn-primary"
                    style={btnPrimaryStyles}
                    hidden={
                      !(
                        userRolePermission["CreateLead"] ||
                        userRolePermission["Admin"]
                      )
                    }
                  >
                    Bulk Operations
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Form>

        {lead.isShowFilter && (
          <LeadFilter
            key="NEW_LEAD_FILTER"
            //actionApplyFilter={actionApplyFilter}
            //setIsFilterVisible={setIsFilterVisible}
            //setSelectedFilterData={setSelectedFilterData}
            //selectedFilterData={selectedFilterData}
            //setFilterListData={setFilterListData}
            //filterListData={filterListData}
          />
        )}

        <Row>
          <Col className="btn-box dynamic-tabs" span={24} style={customStyles}>
            <Tabs
              activeKey={lead.activeKey}
              onChange={(key) => activeTabChange(key)}
            >
              <TabPane tab="Pre Lender" key="PRE_LENDER">
                {preLenderCases.map((item: any, i: number) => (
                  <CheckableTag
                    className="custom-checkable"
                    style={customStyles}
                    key={item.key}
                    checked={item.key === lead.preLenderCase}
                    // onChange={() => dispatch(setPreLenderCase(item.key))}
                    onChange={(checked) =>
                      handlePreLenderTagChange(i, item.key)
                    }
                  >
                    {item.value}
                  </CheckableTag>
                ))}
              </TabPane>
              <TabPane tab="Post Lender" key="POST_LENDER">
                {postLenderCases.map((item: any, i: number) => (
                  <CheckableTag
                    className="custom-checkable"
                    style={customStyles}
                    key={item.key}
                    checked={item.key === lead.postLenderCase}
                    // onChange={() => dispatch(setPostLenderCase(item.key))}
                    onChange={(checked) =>
                      handlePostLenderTagChange(i, item.key)
                    }
                  >
                    {item.value}
                  </CheckableTag>
                ))}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="dynamic-pagination-checkbox"
              style={customStyles}
              size="small"
              loading={loading}
              scroll={{ x: "max-content" }}
              dataSource={allLeadsData}
              rowKey={(record) => record.caseDetails.caseId}
              rowClassName={(record: any) =>
                errorCaseIds.includes(record?.caseDetails?.caseId)
                  ? "selected-row"
                  : ""
              }
              rowSelection={rowSelection}
              columns={getColumns()}
              pagination={{
                current: lead.pageNo,
                pageSize: lead.pageSize,
                total: totalRecords,
              }}
              onChange={handlePaginationChange}
            />
          </Col>
        </Row>

        <Row
          gutter={[24, 24]}
          style={{ display: "flex", justifyContent: "left", marginTop: "20px" }}
        >
          <Col span={8}>
            <Form form={form}>
              <Form.Item label="Assign To:" name="salesUserId">
                {/* <input /> */}
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="Search with mobile number or name"
                  dropdownClassName="custom-option-list"
                  onSelect={selectAssignTo}
                >
                  {assignToData.map((item: any, i: any) => (
                    <Select.Option
                      key={item.userId}
                      {...item}
                      value={item.fullName}
                    >
                      <Row gutter={[16, 16]} className="assign-list">
                        <Col className="left" span={16}>
                          <span className="name">{item.fullName}</span>
                          <br />
                          <span className="city">
                            {item?.address?.city && item?.address?.city}
                          </span>
                        </Col>
                        <Col className="right" span={8}>
                          <span className="mob">
                            {item?.phoneNumber && item?.phoneNumber}
                          </span>
                        </Col>
                      </Row>
                    </Select.Option>
                  ))}
                </AutoComplete>
                &nbsp;&nbsp;&nbsp;
              </Form.Item>
            </Form>
          </Col>
          <Col span={3}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="upload-btn dynamic-btn-primary"
                style={btnPrimaryStyles}
                onClick={assignSalesUserToLead}
              >
                Assign
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LeadPage;
