import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/zh-cn";
import { AADHAR_CARD_NUMBER_REGEX, NUMBER_REGEX, PAN_REGAX, PHONE_NUMBER_REGEX } from "shared/constants/AppConstants";
import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  Space,
  Tabs,
  DatePicker,
  Select,
  notification,
  UploadProps,
  message,
  InputNumber,
  Checkbox,
  List,
  AutoComplete,
} from "antd";
import debounce from "lodash/debounce";
import API_SERVICE from "shared/services/api-service";
import "./index.scss";
import { Link, useHistory } from "react-router-dom";
import type { RangePickerProps } from "antd/es/date-picker";
import FormItem from "antd/es/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
const { Title } = Typography;

type Props = {};

const AddEmployeeForm: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [location, setLocation] = useState([]);
  const [location1, setLocation1] = useState([]);
  const [isTelecaller, setIsTelecaller] = useState(false);
  const [genders, setGenders] = useState([]);
  const [addConnectorForm] = Form.useForm() as any;
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Employee",
        backScreenPath: "/employees",
        screenTitle: "Add New Employee",
      })
    );
    getAllTypes();
    getDesignation();
    getLocation();
    // getManager();
    getAllTypes12();
  }, []);

  // const checkPanDetails = (e: any) => {
  //   e.persist();
  //   const { value: nextValue } = e.target;
  //   const params = {
  //     pan_number: nextValue,
  //   };
  //   API_SERVICE.checkPanDetails(params).then(({ data }) => {
  //     addConnectorForm.setFieldsValue({
  //       contactName: data?.payload?.data?.full_name.trim(),
  //     });
  //   });
  // };

  const getAllTypes12 = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredGenderType = data.find(
          (item: any) => item.propertyName == "GENDER"
        );
        setGenders(filteredGenderType.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllTypes = () => {
    API_SERVICE.getDepartments()
      .then(({ data }) => {
        setDepartment(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getDesignation = () => {
    API_SERVICE.getDesignations()
      .then(({ data }) => {
        setDesignation(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getLocation = () => {
    API_SERVICE.getLocations()
      .then(({ data }) => {
        setLocation(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getManager = (value?: any) => {
    console.log("value: ", value)
    API_SERVICE.getUsersWithRole({name: value})
      .then(({ data }) => {
        setLocation1(data.payload);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const buildPayload = (formData: any) => {
    let userData = localStorage.getItem("user") as any;
    if (userData) {
      userData = JSON.parse(userData);
    }

    let tempSelected = location1.filter((loan: any) => loan.fullName === formData.lineManager) as any;

    const payload = {
      gender: formData.gender,
      aadhar: formData.aadhar,
      branch: formData.branch,
      department: formData.department,
      designation: formData.designation,
      dob: moment(formData.dob).format("YYYY-MM-DD"),
      email: formData.email,
      lineManager: tempSelected[0].userId,
      name: formData.name.trim(),
      pan: formData?.pan?.toUpperCase() ?? "",
      panUploadUrl: "",
      phoneNumber: formData.phoneNumber,
      role: formData.role,
      profileUploadUrl: "",
      bureauCredits: formData.bureauCredits,
      isTelecaller: isTelecaller,
      telecallerId: (isTelecaller ? formData.telecallerId : "")
    };
    return payload;
  };

  const onFinishForm = (values: any) => {
    if (values.bureauScoreLimit > 99) {
      notification.error({
        message:
          "You can not enter Bureau Score Limit grater then 99",
      });
      return;
    }

    const payload = buildPayload(values);
    console.log("payload", payload)
    API_SERVICE.addEmployee(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push("/employees");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  const onTelecaller = (e) => {
    setIsTelecaller(e.target.checked);
  }

  const bureauRequestsSummary = [
    {
      title: "Current Month Consumed:",
    },
    {
      title: "Current Month Remaining:",
    },
    {
      title: "Lifetime Consumed:",
    },
  ];

  const btnRadioStyles: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const customStyles: any = {'--bgColor': appColors?.appPrimaryColor ?? "", alignItems: "flex-start" };

  return (
    <div className="add-emp-wrapper content-box">
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        onFinish={onFinishForm}
      >

        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }} >User Details</label>
            <div className="form-box">
              <Form.Item
                className="field-bg"
                label="Full Name"
                name="name"
                rules={[{ required: true, message: "Please enter full name." }]}
              >
                <Input className="custom-input" placeholder="Enter name" />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Mobile Number"
                name="phoneNumber"
                rules={[
                  { required: true, message: 'Please enter mobile number' }, 
                  { pattern: PHONE_NUMBER_REGEX, message: 'Please enter valid mobile number' }
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Email ID"
                name="email"
                rules={[
                  { required: true, message: "Please enter email" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input className="custom-input" placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Date of Birth(YYYY-MM-DD)"
                name="dob"
                rules={[
                  { required: true, message: "Please enter date of birth" },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  className="custom-input"
                  placeholder="Date of birth"
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item
                className="field-bg"
                label="PAN"
                name="pan"
                rules={[
                  { required: true, message: "Please enter pan number" },
                  {
                    pattern: PAN_REGAX,
                    message: "Please enter valid pan nuber",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  onInput={toInputUppercase}
                  // onChange={debounce(checkPanDetails, 1000)}
                  placeholder="Enter PAN number"
                />
              </Form.Item>
              <Form.Item
                className="field-bg"
                label="Aadhar"
                name="aadhar"
                rules={[
                  {
                    required: true,
                    message: "Please enter aadhar details.",
                  },
                  {
                    pattern: AADHAR_CARD_NUMBER_REGEX,
                    message: "Please enter valid number",
                  },
                ]}
              >
                <InputNumber
                  className="custom-input"
                  style={{ borderRadius: "11px", padding: "4px", border: "solid 1px #f1f1f1" }}
                  formatter={value => `${value}`.replace(/\d{4}(?=.)/g, '$& ')}
                  maxLength={14}
                  // minLength={14}
                  // className="custom-input"
                  placeholder="Enter aadhar details"
                />
              </Form.Item>
              <Form.Item
                label="Select Gender"
                name="gender"
                rules={[{ required: true, message: "Please select gender." }]}
              >
                <Radio.Group
                  className="custom-radio"
                  style={btnRadioStyles}
                  name="gender"
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((gender: any, i: number) => (
                        <Radio.Button key={i} value={gender.key}>
                          {gender.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }} >Select Roles</label>
            <div className="form-box">
              <Form.Item
                className="field-bg"
                label="Department"
                name="department"
                rules={[
                  {
                    required: true,
                    message: "Please enter the department.",
                  },
                ]}
              >
                <Select placeholder="Please select" className="custom-select">
                  {department?.sort(function (a: any, b: any) {
                    if (a.toLowerCase() < b.toLowerCase()) return -1;
                    if (a.toLowerCase() > b.toLowerCase()) return 1;
                    return 0;
                  })?.map((province) => (
                    <Select.Option key={province} value={province}>
                      {province}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="field-bg"
                label="Location"
                name="branch"
                rules={[
                  { required: true, message: "Please select location type" },
                ]}
              >
                <Select placeholder="Please select" className="custom-select">
                  {location &&
                    location?.sort(function (a: any, b: any) {
                      if (a.officeName.toLowerCase() < b.officeName.toLowerCase()) return -1;
                      if (a.officeName.toLowerCase() > b.officeName.toLowerCase()) return 1;
                      return 0;
                    })?.map((loan: any, i: number) => (
                      <Select.Option key={i} value={loan.officeName}>
                        {loan.officeName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="field-bg"
                label="Line Manager"
                name="lineManager"
                rules={[
                  { required: true, message: "Please select Line Manager" },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat selectIcon"
                  placeholder="Line Manager"               
                  onSearch={debounce(getManager, 1000)}
                >
                  {location1.map((loan: any) => (
                    <Select.Option key={loan.userId} value={loan.fullName}>
                    {loan.fullName}
                  </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
              <Form.Item
                className="field-bg"
                label="Designation"
                name="designation"
                // designation
                rules={[
                  {
                    required: true,
                    message: "Please enter the designation.",
                  },
                ]}
              >
                <Select placeholder="Please select" className="custom-select">
                  {designation &&
                    designation?.sort(function (a: any, b: any) {
                      if (a.designation.toLowerCase() < b.designation.toLowerCase()) return -1;
                      if (a.designation.toLowerCase() > b.designation.toLowerCase()) return 1;
                      return 0;
                    })?.map((loan: any, i: number) => (
                      <Select.Option key={i} value={loan.designation}>
                        {loan.designation}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <div style={{ display: "flex" }} >
                <Form.Item name="isTelecaller" style={{ marginTop: "27px" }}>
                  <Checkbox name="isTelecaller" checked={isTelecaller} onChange={onTelecaller} className="dynamic-pagination-checkbox" style={customStyles} >
                    Telecaller
                  </Checkbox>
                </Form.Item>
                {isTelecaller ?
                  <Form.Item name="telecallerId" rules={[{ required: isTelecaller, message: "Please enter tele caller id." }]} style={{ marginTop: "23px", width: "67%" }}>
                    <InputNumber className="custom-input" placeholder="Enter tele caller id." 
                      style={{padding: "4px"}} />
                  </Form.Item>
                  :
                  null
                }
              </div>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <div className="form-box">
              <label style={{ marginBottom: "15px" }} >Bureau Requests Tracking</label>
              <Form.Item
                className="field-bg"
                label="Current Month Limit"
                name="bureauCredits"
                initialValue={0}
                rules={[{ required: true, message: 'Bureau Score Limit' }, { pattern: NUMBER_REGEX, message: 'Please enter valid number' }]}
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Bureau Score Limit"
                />
              </Form.Item>

              <label style={{ marginBottom: "15px" }} >Bureau Requests Summary</label>
              <List
                className="field-bg"
                itemLayout="horizontal"
                dataSource={bureauRequestsSummary}
                renderItem={(item) => {
                  return (
                    <>
                      <List.Item style={{ color: "rgba(0, 0, 0, 0.65)" }}><Space>{item.title}<a>0</a></Space></List.Item>
                    </>
                  );
                }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col className="continue-emp-btn">
            <FormItem>
              <Button
                size="large"
                className="button-submit dynamic-btn-primary"
                style={btnPrimaryStyles}
                htmlType="submit"
                type="primary">
                Continue
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddEmployeeForm;
