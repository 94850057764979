import { Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import TermsNConditions from "./termsNConditions";
import PrivacyPolicy from "./privacyPolicy";
import FAQ from "./faq";
import About from "./about";
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import "./help.scss";
import { RootState } from "shared/redux/store";

const { TabPane } = Tabs;
type Props = {};

const Help: React.FunctionComponent<Props> = (props) => {
  const [activeKey, setActiveKey] = useState("1");
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({}));
}, []);

  const onTabChange = (key: any) => {
    setActiveKey(key);
  };

  const tabBtnStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? ""};
  
  return (
    <div className="content-box">
      <Row>
        <Col className="btn-box" span={24} style={tabBtnStyles}>
          <div className="help-tab-box">
          <Tabs  
            activeKey={activeKey}
            onChange={onTabChange}
            type="card"
            className="custom-app-tab"        
          >
            <TabPane tab="Terms & Conditions" key="1">
              <TermsNConditions />
            </TabPane>
            <TabPane tab="Privacy Policy" key="2">
              <PrivacyPolicy />
            </TabPane>
            <TabPane tab="FAQ" key="3">
              <FAQ />
            </TabPane>
            <TabPane tab="About" key="4">
              <About />
            </TabPane>
          </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Help;
