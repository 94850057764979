import { Modal, Upload, UploadProps, notification, Row, Col } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {ReactComponent as AddFileGray} from "../../../../assets/images/upload-file-gray.svg";
import { addDocumentCommon } from "../../../services/Utility";
import { fileUploadCommon } from "../../../services/Utility";
import imgDocument from "../../../../assets/images/document_download.svg";
import imgTickRight from "../../../../assets/images/tickRight.svg";
import imgLoading from "../../../../assets/images/loading.svg";
import wrong from "../../../../assets/images/wrong.svg";


type fileType =
  | "image"
  | "pdf"
  | "xls"
  | "xlsx"
  | "svg"
  | "zip"
  | "jpg"
  | "jpeg"
  | "png"
  | "jpeg/jpg;";

interface IAppUpload {
  acceptType?: fileType[];
  documentSetId?: string; // If available then upload document API calling here
  callBack: any;
  className?: string;
  multiple?: boolean;
  showUploadList?: boolean;
  children?: React.ReactNode;
  maxSize?: number;
  setFileSize: any;
  setUploadedFile: any;
  setIsSelectedLenderDoc: any;
  checkTwoExtension?: boolean;
  disabled?: boolean;
  setPopupVisible?: any;
  popupVisible?: any;
  uploadDocumentList?: any;
  setUploadDocumentList?: any;
}

const AppUpload: React.FC<IAppUpload> = (props: IAppUpload) => {
  const {
    acceptType,
    documentSetId,
    callBack,
    className,
    multiple,
    showUploadList,
    children,
    maxSize,
    setFileSize,
    setUploadedFile,
    setIsSelectedLenderDoc,
    checkTwoExtension,
    setPopupVisible,
    popupVisible,
    uploadDocumentList,
    setUploadDocumentList,
    disabled
  } = props;

  const [fileList, setFileList] = useState([] as any);
  // const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  // const [popupVisible, setPopupVisible] = useState(false);

  const getAccept = () => {
    console.log("acceptType: ", acceptType);
    let fileFormat: string[] = [];
    if (acceptType?.includes("pdf")) {
      fileFormat.push("application/pdf");
    }
    if (acceptType?.includes("xls") || acceptType?.includes("xlsx")) {
      fileFormat.push("application/vnd.ms-excel");
      fileFormat.push(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
    if (acceptType?.includes("svg")) {
      fileFormat.push("image/svg+xml");
    }
    if (acceptType?.includes("zip")) {
      fileFormat.push("application/x-zip-compressed");
      fileFormat.push("application/zip");
    }
    if (
      acceptType?.includes("jpeg") ||
      acceptType?.includes("jpg") ||
      acceptType?.includes("jpeg/jpg;")
    ) {
      fileFormat.push("image/jpg");
      fileFormat.push("image/jpeg");
    }
    if (acceptType?.includes("png")) {
      fileFormat.push("image/png");
    }
    if ((acceptType?.length ?? 0) == 0 || acceptType?.includes("image")) {
      fileFormat.push("image/jpg");
      fileFormat.push("image/jpeg");
      fileFormat.push("image/png");
    }
    return fileFormat;
  };

  const uploadProps: UploadProps = {
    accept: getAccept().join(", "),
    listType: "text",
    showUploadList: showUploadList ?? false,
    multiple: multiple,
    maxCount: multiple ? 5 : 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log("::", newFileList);
      setFileList(newFileList);
      setUploadedFile(null);
      setIsSelectedLenderDoc(false);
    },
    beforeUpload: (file, fileLists) => {
      if (multiple && fileLists?.length >= 5 && uploadDocumentList) {
        if (fileLists.findIndex((f) => f.uid === file?.uid) + 1 > 5) {
          uploadDocumentList.failed.push(file);
          if (fileLists[fileLists.length -1]?.uid === file?.uid) {
            notification.error({
              message: `Please upload only 5 Files`,
            });
          }
          return Upload.LIST_IGNORE;
        }
         else {
          fileList.push(file);
          return false;  
         }
      } else {
        fileList.push(file);
        return false;  
      }
    },
    fileList,
  };

  const onUpload = (acceptedFile: any) => {
    const files = acceptedFile.fileList;
    const index = files.indexOf(acceptedFile?.file);
    console.log('index', index);
    if (acceptedFile?.file?.status !== "removed" && fileList.length > 0) {
      console.log("::status", acceptedFile?.file?.status);
      const fileSize = acceptedFile?.file?.size
        ? (acceptedFile?.file?.size / 1000 / 1000).toFixed(2)
        : null;
      setFileSize(Number(fileSize));
      // if (multiple && acceptedFile?.fileList?.length > 5) {
      //   notification.error({
      //     message: `Please Upload Only 5 Files`,
      //   });
      //   return;
      // }
      if (acceptedFile?.file?.size > (maxSize ?? 10) * 1000 * 1000) {
        notification.error({
          message: `Please attach less than ${maxSize ?? 10}MB file`,
        });
        fileList.filter((item) => item?.uid !== acceptedFile?.file?.uid);
        if (multiple && uploadDocumentList) {
          uploadDocumentList.failed.push(acceptedFile.file);
          setFileList(fileList);
        } else {
          setFileList([]);
          return;
        }
      } else {
        const ext = acceptedFile?.file?.name.match(/\.(.+)$/)[1];

        if (ext.indexOf(".") !== -1 && checkTwoExtension) {
          notification.error({
            message: `Filename with two extensions is not supported due to security restrictions`,
          });
          if (multiple && uploadDocumentList) {
          fileList.filter((item) => item?.uid !== acceptedFile?.file?.uid);
          uploadDocumentList.failed.push(acceptedFile.file);
          setFileList(fileList);
          } else {
            setFileList(fileList);
            return;
          }
        }

        const allowedAttechmentTypes = getAccept();
        console.log("allowedAttechmentTypes: ", allowedAttechmentTypes);
        if (allowedAttechmentTypes.indexOf(acceptedFile?.file?.type) === -1) {
          const msgType = acceptType?.join(", ");
          notification.error({
            message: `Please attach only ${msgType ?? "image"} file.`,
          });
          if (multiple && uploadDocumentList) {
            fileList.filter((item) => item?.uid !== acceptedFile?.file?.uid);
            uploadDocumentList.failed.push(acceptedFile.file);
            setFileList(fileList);
          } else {
            setFileList([]);
            return;
          }
        }
        if (multiple && uploadDocumentList) {
          uploadDocumentList.sucess.push(acceptedFile.file);
        }
        UploadDocumentHandler(acceptedFile?.file);
      }
    }
  };

  const UploadDocumentHandler = async (file: any) => {
    const response = await fileUploadCommon(file);
    if (response?.data && response?.file) {
      if (multiple && uploadDocumentList) {
        if (uploadDocumentList.sucess.length > 0 ) {
          uploadDocumentList.sucess.map((item: any, index: any) => {
            if (item.uid === response.file.uid) {
              response.index = uploadDocumentList.sucess.length > 1 ? index : null;
              item.status = 'Done'
              return item;
            }
            return item;
          })
        }
      }
      if ((documentSetId?.length ?? 0) > 0) {
        uploadDocumtnt(response?.file, response?.data);
      } else {
        if (multiple && setPopupVisible) {
          setPopupVisible(true);
        }
        callBack(response, uploadDocumentList);
      }
    }
  };

  const uploadDocumtnt = async (file, data) => {
    const response = await addDocumentCommon(
      documentSetId ?? "",
      data,
      file.name,
      "",
      ""
    );
    if (response?.payload) {
      callBack(response?.payload);
    }
  };

  return (
    <>
      <Upload
        className={className ?? "upload-wrapper"}
        disabled={disabled}
        onChange={onUpload}
        {...uploadProps}
      >
        {children ? (
          children
        ) : (
          <>
            <AddFileGray />
            Upload New Document
          </>
        )}
      </Upload>
    </>
  );
};

export default AppUpload;
