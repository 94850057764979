import { Button, Space, Tabs, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import giftIcon from "../../../../../assets/images/gift.svg";
import info from "../../../../../assets/images/info.svg";
import Login from "./Login";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

interface ILoginTabs {
    setIsLoading: any;
    leadData: any;
    updateCase: any;
    getRedirectURLLink: any;
    getCashETriggerSMSLink: any;
    callbackReqDoc: any;
    callbackAdditionalField: any;
    setShowRequirementDocs: any;
    setSelectedLander: any;
    setAdditionalFields: any;
    setCurrLenderId: any;
    setCurrLeadId: any;
    setShowAdditionalFields: any;
    setIsLoginListDecisioning: any;
    isLoginListDecisioning: any;
    submitTask: any;
    getTaskData: any;
    getLenderAdditonalField: any;
    lenderNextHandler: any;
    loading: any;
    getNextTask: any;
    setCallbackReqDoc: any;
}

const LoginTabs: React.FC<ILoginTabs> = (props: ILoginTabs) => {
    const {
        setIsLoading,
        leadData,
        updateCase,
        getRedirectURLLink,
        getCashETriggerSMSLink,
        callbackReqDoc,
        callbackAdditionalField,
        setShowRequirementDocs,
        setSelectedLander,
        setAdditionalFields,
        setCurrLenderId,
        setCurrLeadId,
        setShowAdditionalFields,
        setIsLoginListDecisioning,
        isLoginListDecisioning,
        submitTask,
        getTaskData,
        getLenderAdditonalField,
        lenderNextHandler,
        loading,
        getNextTask,
        setCallbackReqDoc,
    } = props;
    const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
    const [isLoginTabsListDecisioning, setIsLoginTabsListDecisioning] = useState(false);
    const [lendersData, setLendersData] = useState([] as any);
    const [loginTabkey, setLoginTabkey] = useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);
    const { appColors }: any = useSelector((state: RootState) => state.common);

    useEffect(() => {
        if (isLoginListDecisioning) {
            listDecisioning();
            setIsLoginListDecisioning(false);
        }
    }, [isLoginListDecisioning])

    const listDecisioning = () => {
        setIsLoading(true);
        let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
            return item?.journeyId
        })
        API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false, '', tmpId)
            .then(({ data }) => {
                setLoginTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId);
                setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
                getLenders();
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE?.handleErrors(e) });
            }).finally(() => setIsLoading(false))
    };

    const getLenders = () => {
        setIsLoading(true);
        API_SERVICE.getLenders({
            status: "Active",
            loanProductId: leadData?.loanProductId,
            pageNo: 1,
            pageSize: 200,
        })
            .then(({ data }) => {
                if (data?.payload?.content) {
                    const datas = data?.payload?.content;
                    setLendersData(datas)
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE?.handleErrors(e) });
            })
            .finally(() => setIsLoading(false));
    };

    const listData = (decisionData: any, tmpLendersData: any = lendersData) => {
        const tmp = [...decisionData];
        for (let i = tmpLendersData?.length - 1; i >= 0; i--) {
            for (let j = 0; j < tmp?.length; j++) {
                if (
                    tmpLendersData[i] &&
                    tmpLendersData[i]?.lenderInfoId === tmp[j]?.lenderInfoId
                ) {
                    tmpLendersData?.splice(i, 1);
                    const findLender =
                        leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.find(
                            (item) =>
                                item?.lenderInfoId == decisionData[j]?.lenderInfoId
                        );
                    tmp[j] = { ...tmp[j], ...findLender };
                }
            }
        }

        const allLenders = [...tmp, ...tmpLendersData];

        allLenders.sort((a: any, b: any) =>
            b.maxLoanAmount - a.maxLoanAmount
        );

        allLenders.sort((a: any, b: any) => {
            const isDocUploadedA =
                a?.submissionType != "API" ||
                (a?.submissionType == "API" && a?.allDocumentUploaded) ||
                ["REJECTED", "PENDENCY", "WITHDRAWN"].includes(a?.status);
            const isLoggedA =
                isDocUploadedA &&
                [
                    "LOGGED",
                    "SANCTIONED",
                    "DISBURSED",
                    "REJECTED",
                    "PENDENCY",
                    "WITHDRAWN",
                ].includes(a?.status);

            const isDocUploadedB =
                b?.submissionType != "API" ||
                (b?.submissionType == "API" && b?.allDocumentUploaded) ||
                ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(b?.status);
            const isLoggedB =
                isDocUploadedB &&
                [
                    "LOGGED",
                    "SANCTIONED",
                    "DISBURSED",
                    "REJECTED",
                    "PENDENCY",
                    "WITHDRAWN",
                ].includes(b?.status);

            return isLoggedA === isLoggedB ? 0 : isLoggedA ? -1 : 1;
        });
        return allLenders;
    }

    const onPublishCW = (journeyId) => {
        setIsLoading(true);
        API_SERVICE.updateCaseCWOfferDetails(leadData?.caseDetails?.caseId, journeyId)
            .then(({ data }) => {
                notification.success({ message: data?.payload })
            }).catch((e: any) => {
                notification.error({ message: API_SERVICE?.handleErrors(e) })
            })
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <Tabs
                className="lender-sub-tabs"
                style={{'--borderColor': appColors?.appPrimaryColor ?? ""}as any}
                size="small"
                activeKey={loginTabkey}
                onChange={(key) => { setLoginTabkey(key); setIsLoginTabsListDecisioning(true) }}
            >
                {leadData?.journeyConfigResponseList?.map((data: any) => {
                    let tmpFindData = descisioningDetail?.find((item: any) =>
                        item?.journeyId == data?.journeyId
                    );
                    return (
                        <Tabs.TabPane
                            key={data?.journeyId}
                            tab={data?.journeyName}
                        >

                            <div className="tab-Userdetails">
                                <Space className="userdetails">
                                    <p style={{ color: `${tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning ? "red" : ""}` }}>Date/Time: {tmpFindData?.executionTime}</p>
                                    {tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning && (
                                        <Tooltip
                                            title="Something has been changed please edit the case"
                                            placement="topLeft"
                                            color="#1A3175"
                                        >
                                            <img src={info} style={{ marginBottom: "8px" }} />
                                        </Tooltip>
                                    )}
                                    <p>Executed By: {tmpFindData?.executedBy}</p>
                                </Space>
                                {tmpFindData?.workflowType == "BORROWER_DIRECT" && (
                                    <Button className="publish-btn" onClick={() => onPublishCW(tmpFindData?.journeyId)}><img src={giftIcon} />Publish to CW</Button>
                                )}
                            </div>
                            <Login
                                setIsLoading={setIsLoading}
                                leadData={leadData}
                                updateCase={updateCase}
                                getRedirectURLLink={getRedirectURLLink}
                                getCashETriggerSMSLink={getCashETriggerSMSLink}
                                callbackReqDoc={callbackReqDoc}
                                callbackAdditionalField={callbackAdditionalField}
                                setShowRequirementDocs={setShowRequirementDocs}
                                setSelectedLander={setSelectedLander}
                                setAdditionalFields={setAdditionalFields}
                                setCurrLenderId={setCurrLenderId}
                                setCurrLeadId={setCurrLeadId}
                                setShowAdditionalFields={setShowAdditionalFields}
                                submitTask={submitTask}
                                getTaskData={getTaskData}
                                getLenderAdditonalField={getLenderAdditonalField}
                                lenderNextHandler={lenderNextHandler}
                                loading={loading}
                                getNextTask={getNextTask}
                                setCallbackReqDoc={setCallbackReqDoc}
                                decisionData={listData(tmpFindData?.decisioningDataListResponseList ?? [], lendersData)} />
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        </>
    )
}

export default LoginTabs;