import React, { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Select,
  Typography,
  notification,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import {
  GREATERTHEN_ZERO_REGEX,
  PHONE_NUMBER_REGEX,
  STRING_ONLY,
} from "shared/constants/AppConstants";
import { numberWithCommas } from "shared/services/Utility";
import FormCheckbox from "components/FormCheckbox/FormCheckbox";
import API_SERVICE from "shared/services/api-service";
import { ReactComponent as IconEdit } from "../../../../../assets/images/edit_border.svg";
import "../../LeadDetail/LeadEditAdditional/LeadEditAdditional.scss";
import { setLenderRequiredField } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
const { Text } = Typography;

interface ILeadEditAdditional {
  leadData: any;
  isICICIender: boolean;
  isCASHEender: boolean;
  isKreditBeeLender: boolean;
  isBajajLender: boolean;
  isFlexiLender: boolean;
  isABCFLLender: boolean;
  selectedLander: any;
  additionalFields: any;
  checkAddressLength: any;
  setShowAdditionalFields: any;
  leadForm: any;
}

const LeadEditAdditional: React.FC<ILeadEditAdditional> = (
  props: ILeadEditAdditional
) => {
  const {
    leadData,
    isICICIender,
    isCASHEender,
    isKreditBeeLender,
    isBajajLender,
    isFlexiLender,
    isABCFLLender,
    selectedLander,
    additionalFields,
    checkAddressLength,
    setShowAdditionalFields,
    leadForm,
  } = props;
  const [isShowCurrentAddress, setIsShowCurrentAddress] = useState(true);
  const [isShowCorresPondenceAddress, setIsShowCorresPondenceAddress] =
    useState(true);
  const [educationQualificationList, setEducationaQualificationList] = useState(
    []
  );
  const [genderList, setGenderList] = useState([]);
  const [businessProfileList, setBusinessProfileList] = useState([]);
  const [coApplicantList, setCoApplicantList] = useState([]);
  const [propertyOwnertList, setPropertyOwnertList] = useState([]);
  const [isEditDetils, setIsEditDetails] = useState(false);

  const selectedApplicationType = leadData?.applicationTypeKey;
  const selectedApplicationSubType = leadData?.applicationSubTypeKey;

  const { Panel } = Collapse;
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const isSBL = leadData?.loanType == "Small Business Loan";
  const isHL = leadData?.loanType == "Home loan";
  const isLAP = leadData?.loanType == "Loan Against Property";
  const isCC = leadData?.loanType == "Credit Card";

  const isCoApplicant1IncomeConsidered =
    leadData?.dsaCaseAdditionalAttributes
      ?.coapplicantIncomeToBeConsideredOrNot ?? false;
  const isCoApplicant2IncomeConsidered =
    leadData?.dsaCaseAdditionalAttributes
      ?.coapplicant2IncomeToBeConsideredOrNot ?? false;

  useEffect(() => {
    getAllTypes();
  }, []);

  const fetchMandatoryFields = async () => {
    let arrayOfLead = [] as any;

    try {
      await leadForm.validateFields();
    } catch (error: any) {
      console.log("error: ", error);
      let tmpError = error?.errorFields?.map((item: any) => {
        return item?.name;
      });

      var tmpMandatoryFieldsName = tmpError?.map((item: any) => {
        return item[0];
      });
    }

    let additionalObj: any = {
      loanAmount: isCC ? "Expected Credit Limit (Monthly)" : "Loan Amount",
      tenure: "Loan Tenure(in Years)",
      approximateValueOfProperty: "Approximate Value of Property",
      applicantType: "Applicant Type",
      applicantSubType:
        selectedApplicationType == "BUSINESS"
          ? "Business Type"
          : "Individual Type",
      applicantName: "Applicant Name as per PAN",
      fatherOrSpouseName: "Father's/Spouse's Name",
      applicantPan: "Pan",
      dateOfBirth: "Date of Birth",
      dateOfIncorporation: "Date Of Incorporation",
      vintage: "Vintage/ No of years in business",
      businessProfile: "Business Profile",
      mobileNumber: "Mobile Number",
      gender: "Gender",
      email: "Email",
      pincode: "Pincode",
      addressLine1: "Address Line 1",
      city: "City",
      coApplicantName: "Name",
      coApplicantIndividualType: "Co-Applicant Type",
      state: "State",
      latestYearTurnover: "Latest Year Turnover",
      businessName: "Business Name",
      employerName: "Employer Name",
      monthlyInHandSalary:
        isHL || isLAP ? "Monthly Net Salary" : isCC ? "Monthly In Hand Salary" : "Monthly Salary",
      officePincode: "Office Pincode",
      officeCity: "Office City",
      currentEmis: "Existing Total EMI Amount",
      monthlyEmi: "Existing Total EMI Amount",
      designation: "Designation",
      totalExperienceInYears: "Total experience in years",
      currentWorkExperience: "Current Work experience",
      workStatus: "Work Status",
      officeAddress: "Office Address",
      officePhoneNumber: "Office Phone Number",
      educationalQualification: "Education Qualification",
      maritalStatus: "Marital Status",
      residenceType: "Residence Type",
      hometown: "Hometown",
      durationOfStayInCurrentCity: "Duration of stay in Current city",
      durationOfStayInCurrentHomeAddress:
        "Duration of stay in Current home address",
      addressLine2: "Address Line2",
    };

    let tmpLeadFinalfields = tmpMandatoryFieldsName.map((item: any) => {
      return additionalObj[`${item}`];
    });
    arrayOfLead.push(tmpLeadFinalfields);
    if (arrayOfLead?.length > 0) {
      console.log("arrayOfError fields: ", arrayOfLead);
      dispatch(setLenderRequiredField(arrayOfLead));
    }
  };

  const editDetails = () => {
    setIsEditDetails(true);
  };

  const purgeAllCaseDecisioningData = () => {
    API_SERVICE.purgeAllCaseDecisioningData(leadData?.caseDetails?.caseId)
      .then((data) => {
        getNextTask({
          processInstanceId: leadData?.processInstanceId,
          gotoStep: "loanDetailsStep",
        });
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const getNextTask = (payload: any) => {
    // setIsLoading(true);
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        fetchMandatoryFields();
        setIsEditDetails(false);
        setShowAdditionalFields(false);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      });
    // .finally(() => setIsLoading(false));
  };

  const getAllTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredEducationQualification = data.find(
          (item: any) => item.propertyName == "EDUCATIONAL_QUALIFICATION"
        );
        setEducationaQualificationList(
          filteredEducationQualification.keyAndDisplayValueList
        );

        let filteredGender = data.find(
          (item: any) => item.propertyName == "GENDER"
        );
        setGenderList(filteredGender.keyAndDisplayValueList);

        let filteredApplicantType = data.find(
          (item: any) => item.propertyName == "APPLICATION_TYPE"
        );
        setCoApplicantList(filteredApplicantType.keyAndDisplayValueList);
        let filteredBusinessProfile = data.find(
          (item: any) => item.propertyName == "BUSINESS_PROFILE"
        );
        setBusinessProfileList(filteredBusinessProfile.keyAndDisplayValueList);

        let filteredpropertOwner = data.find(
          (item: any) => item.propertyName == "PROPERTY_OWNERSHIP"
        );
        setPropertyOwnertList(filteredpropertOwner.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderLoanDetails = () => {
    const column = [
      <Form.Item
        className="custom-select"
        label={isCC ? "Expected Credit Limit (Monthly)" : "Loan Amount"}
        name="loanAmount"
        rules={[
          {
            required: true,
            message: "Please enter " + (isCC ? "Expected Credit Limit (Monthly)" : "Loan Amount"),
          },
        ]}
      >
        <InputNumber
          disabled
          className="custom-input-number1"
          placeholder={"Please enter " + (isCC ? "Expected Credit Limit (Monthly)" : "Loan Amount")}
          formatter={(value) => numberWithCommas(value)}
          min={1}
        />
      </Form.Item>,
      !isCC && (
      <Form.Item
        className="custom-select"
        label="Loan Tenure(in Years)"
        name="tenure"
        rules={[
          {
            required: true,
            message: "Please enter Loan Tenure",
          },
          {
            pattern: GREATERTHEN_ZERO_REGEX,
            message: "Please enter valid Loan Tenure",
          },
        ]}
      >
        <InputNumber
          disabled
          className="custom-input-number1"
          placeholder="Please enter Loan Tenure"
        />
      </Form.Item>
      ),
      !isHL && (
        <Form.Item
          label="Loan Purpose"
          name="loanPurpose"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isCC && (
        <FormCheckbox
        className={"dynamic-pagination-checkbox"}
        style={customStyles}
        isDisable={true}
        name="isPropertyIdentified"
        textElement={<div>Is it backed by Fixed Deposits?</div>}
      />),      
      (isHL || isLAP || isCC) &&
        leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified == true && (
          <Form.Item
            className="custom-select"
            label="Approximate Value of Property"
            name="approximateValueOfProperty"
            rules={[
              {
                required:
                  (isHL || isLAP) &&
                  leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified ==
                    true,
                message: "Please enter Approximate Value of Property",
              },
              {
                pattern: GREATERTHEN_ZERO_REGEX,
                message: "Please enter valid Approximate Value of Property",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              placeholder="Please enter Approximate Value of Property"
            />
          </Form.Item>
        ),

      (isHL || isLAP) &&
        leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified == true && (
          <Form.Item
            label="Ownership"
            name="ownership"
            className="custom-select"
            rules={[
              {
                required:
                  (isHL || isLAP) &&
                  leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified ==
                    true,
                message: "Please select Ownership",
              },
            ]}
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      isLAP &&
        leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified == true && (
          <Form.Item
            label="Status of Property"
            name="statusOfProperty"
            className="custom-select"
            rules={[
              {
                required:
                  isLAP &&
                  leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified ==
                    true,
                message: "Please select Status of Property",
              },
            ]}
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),
    ];

    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={column.filter((item) => item)}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </>
    );
  };

  const renderApplicantDetails = () => {
    const column = [
      <Form.Item
        className="custom-select"
        label="Applicant Type"
        name="applicantType"
        rules={[
          {
            required: true,
            message: "Please enter Applicant Type",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="Applicant Type" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label={
          selectedApplicationType == "BUSINESS"
            ? "Business Type"
            : "Individual Type"
        }
        name="applicantSubType"
        rules={[
          {
            required: true,
            message: `Please enter ${
              selectedApplicationType == "BUSINESS"
                ? "Business Type"
                : "Individual Type"
            }`,
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder={
            selectedApplicationType == "BUSINESS"
              ? "Business Type"
              : "Individual Type"
          }
        />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Applicant Name as per PAN"
        name="applicantName"
        rules={[
          {
            required: true,
            message: "Please enter Applicant Name as per PAN",
          },
          {
            pattern: STRING_ONLY,
            message: "Please enter valid name",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Applicant Name as per PAN"
        />
      </Form.Item>,
      (isHL || isLAP) && selectedApplicationSubType == "SALARIED" && (
        <Form.Item
          className="custom-select"
          label="Father's/Spouse's Name"
          name="fatherOrSpouseName"
          rules={[
            {
              required: true,
              message: "Please enter Father's/Spouse's Name",
            },
            {
              pattern: STRING_ONLY,
              message: "Please enter valid name",
            },
          ]}
        >
          <Input
            disabled
            className="custom-input"
            placeholder="Father's/Spouse's Name"
          />
        </Form.Item>
      ),
      <Form.Item
        className="custom-select"
        label="Mobile Number"
        name="mobileNumber"
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: PHONE_NUMBER_REGEX,
            message: "Please enter valid number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="PAN"
        name="applicantPan"
        rules={[
          {
            required:
              isHL || isLAP
                ? false
                : isKreditBeeLender || isBajajLender || ["incred finance"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                )
                ? true
                : selectedApplicationType === "INDIVIDUAL"
                ? true
                : Object.keys(additionalFields).length === 0
                ? false
                : additionalFields?.some(
                    (value) =>
                      value?.internalPropertyName?.toUpperCase() ===
                      "PAN_NUMBER"
                  ),
            message: "Please enter pan number",
          },
          {
            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            message: "Please enter valid pan number",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          // onChange={debounce(checkPanDetails, 1000)}
          placeholder="Pan number"
        />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Email"
        name="email"
        rules={[
          {
            required:
              isHL || isLAP
                ? false
                : isICICIender ||
                  isCASHEender ||
                  isKreditBeeLender ||
                  isABCFLLender
                ? true
                : Object.keys(additionalFields).length === 0
                ? ["moneywide", "nira finance"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : additionalFields?.some(
                    (value) =>
                      value?.internalPropertyName?.toUpperCase() === "EMAIL_ID"
                  ),
            message: "Please enter email",
          },
          { type: "email", message: "Plesae enter valid email" },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Please enter email ID"
        />
      </Form.Item>,
      (!(
        selectedApplicationType == "BUSINESS" &&
        selectedApplicationSubType !== "SOLE_PROPRIETOR"
      ) ||
        ((isHL || isLAP) && selectedApplicationType == "INDIVIDUAL")) && (
        <Form.Item
          className="custom-select"
          label={"Date of Birth"}
          name={"dateOfBirth"}
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : !(selectedApplicationType === "BUSINESS" && isBajajLender),
              message: "Please enter date of birth",
            },
          ]}
        >
          <DatePicker
            disabled
            format="DD-MM-YYYY"
            className="custom-input"
            placeholder="DD-MM-YYYY"
          />
        </Form.Item>
      ),
      ((selectedApplicationType == "BUSINESS" &&
        selectedApplicationSubType !== "SOLE_PROPRIETOR") ||
        isSBL ||
        ((isHL || isLAP) &&
          (selectedApplicationSubType == "SELF_EMPLOYED" ||
            selectedApplicationType == "BUSINESS"))) && (
        <Form.Item
          className="custom-select"
          label={"Date Of Incorporation"}
          name={"dateOfIncorporation"}
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : !(selectedApplicationType === "BUSINESS" && isBajajLender),
              message: "Please enter date of incorporation",
            },
          ]}
        >
          <DatePicker
            disabled
            format="DD-MM-YYYY"
            className="custom-input"
            placeholder="DD-MM-YYYY"
          />
        </Form.Item>
      ),

      (selectedApplicationType == "INDIVIDUAL" || isSBL || isCC) && (
        <Form.Item
          label="Gender"
          name="gender"
          className="custom-select"
          rules={[
            {
              required: isHL || isLAP ? false : ["incred finance"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ) ? true : !isSBL,
              message: "Please Select gender",
            },
          ]}
        >
          <Select placeholder="Please select" disabled>
            {genderList.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC) && (
        <Form.Item
          label="Education Qualification"
          name="educationalQualification"
          className="custom-select"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Education Qualification",
            },
          ]}
        >
          <Select placeholder="Please select" disabled>
            {educationQualificationList.map((educationData: any, i: number) => (
              <Select.Option value={educationData.key} key={i}>
                {educationData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC) && (
        <Form.Item
          className="custom-select"
          label="Profession"
          name="profession"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item
          className="custom-select"
          label="No of Dependent"
          name="numberOfDependents"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      (isHL || isLAP) && selectedApplicationSubType == "SALARIED" && (
        <Form.Item
          className="custom-select"
          label="No. of working family members"
          name="noOfWorkingFamilyMembers"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item
          label="Marital Status"
          name="maritalStatus"
          className="custom-select"
          rules={[
            {
              required: ["loan tap"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Marital Status",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item className="custom-select" label="Religion" name="religion">
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      ((selectedApplicationType == "INDIVIDUAL" && !isSBL && !isHL && !isLAP) || isCC ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item
          className="custom-select"
          label="Nationality"
          name="nationality"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      ((selectedApplicationType == "BUSINESS" ||
      selectedApplicationSubType == "SELF_EMPLOYED") && !isCC) ? (
        <Form.Item
          label="Vintage/ No of years in business"
          name="vintage"
          className="custom-select"
          rules={[
            {
              required:
                Object.keys(additionalFields).length === 0
                  ? isFlexiLender ?? false
                  : additionalFields?.some(
                      (value) =>
                        value?.internalPropertyName?.toUpperCase() ===
                        "VERTICAL"
                    ),
              message: "Please enter vintage/ no of years in business",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ) : (
        ""
      ),
      ((selectedApplicationType == "BUSINESS" ||
      selectedApplicationSubType == "SELF_EMPLOYED") && !isCC) ? (
        <Form.Item
          label="Business Profile"
          name="businessProfile"
          className="custom-select"
          rules={[
            {
              required:
                Object.keys(additionalFields).length === 0
                  ? (isFlexiLender || isABCFLLender) ?? false
                  : additionalFields?.some(
                      (value) =>
                        value?.internalPropertyName?.toUpperCase() === "PROFILE"
                    ),
              message: "Please Select Business Profile",
            },
          ]}
        >
          <Select placeholder="Please select" disabled>
            {businessProfileList?.map((businessData: any, i: number) => (
              <Select.Option value={businessData.key} key={i}>
                {businessData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        ""
      ),
      !isHL && !isLAP && !isCC && (
        <Form.Item
          label="Property Ownership"
          name="propertyOwnership"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {propertyOwnertList.map((propertyData: any, i: number) => (
              <Select.Option value={propertyData.key} key={i}>
                {propertyData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      (selectedApplicationSubType == "SELF_EMPLOYED" ||
        selectedApplicationSubType == "SOLE_PROPRIETOR") && !isCC && (
        <Form.Item
          label="Business Name"
          name="businessName"
          // className="custom-select"
          rules={[
            {
              required: isFlexiLender || isBajajLender || isABCFLLender,
              message: "Please enter business name",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isSBL && (
        <Form.Item label="GSTIN" name="gstin" className="custom-select">
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];
    const currentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="pincode"
        rules={[
          {
            required:
              isHL || isLAP
                ? ["bajaj housing finance"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : isCASHEender ||
                  isICICIender ||
                  isFlexiLender ||
                  isKreditBeeLender
                ? true
                : Object.keys(additionalFields).length === 0 ||
                  (selectedApplicationType === "BUSINESS" && isBajajLender)
                ? [
                    "hdfc bank",
                    "moneywide",
                    "nira finance",
                    "loan tap",
                    "prefr",
                    "incred finance",
                  ].includes(selectedLander?.lenderName?.toLowerCase())
                : additionalFields?.some((value) =>
                    ["PINCODE", "CURRENT_RESIDENCE_PINCODE"].includes(
                      value?.internalPropertyName?.toUpperCase()
                    )
                  ),
            message: "Please enter pin code",
          },
          {
            pattern: /^[1-9][0-9]{5}$/,
            message: "Please enter valid pincode",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="addressLine1"
        rules={[
          {
            required:
              isHL || isLAP
                ? false
                : isICICIender || isCASHEender || isFlexiLender
                ? true
                : Object.keys(additionalFields).length === 0 ||
                  (selectedApplicationType === "BUSINESS" && isBajajLender)
                ? ["hdfc bank", "nira finance"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : additionalFields?.some((value) =>
                    [
                      "ADDRESS",
                      "ADDRESS_DETAILS",
                      "CURRENT_RESIDENCE_ADDRESS_LINE_1",
                    ].includes(value?.internalPropertyName?.toUpperCase())
                  ),
            message: "Please enter address",
          },
          { validator: checkAddressLength },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      leadData?.loanType == "Personal Loan" ||
        ((isHL || isLAP || isCC) && (
          <Form.Item
            className="custom-select"
            label="Address Line 2"
            name="addressLine2"
            rules={[
              {
                required:
                  isHL || isLAP
                    ? false
                    : isCASHEender || isKreditBeeLender
                    ? true
                    : ["nira finance", "loan tap"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      ),
                message: "Please enter Address Line2",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        )),
      <Form.Item
        className="custom-select"
        label="City"
        name="city"
        rules={[
          {
            required:
              isHL || isLAP
                ? false
                : isICICIender || isCASHEender || isBajajLender
                ? true
                : Object.keys(additionalFields).length === 0 ||
                  (selectedApplicationType === "BUSINESS" && isBajajLender)
                ? ["nira finance", "moneywide"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : additionalFields?.some(
                    (value) =>
                      value?.internalPropertyName?.toUpperCase() === "CITY"
                  ),
            message: "Please enter city",
          },
          {
            pattern: STRING_ONLY,
            message: "Please enter valid city",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="state"
        rules={[
          {
            required:
              isHL || isLAP
                ? false
                : isICICIender || isCASHEender
                ? true
                : Object.keys(additionalFields).length === 0 ||
                  (selectedApplicationType === "BUSINESS" && isBajajLender)
                ? ["nira finance"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : additionalFields?.some(
                    (value) =>
                      value?.internalPropertyName?.toUpperCase() === "STATE"
                  ),
            message: "Please enter state",
          },
          {
            pattern: STRING_ONLY,
            message: "Please enter valid state",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item className="custom-select" label="Country" name="country">
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      (leadData?.loanType == "Personal Loan" || isHL || isLAP || isCC) && (
        <Form.Item
          label="Residence Type"
          name="residenceType"
          className="custom-select"
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : ["loan tap"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    ),
              message: "Residence Type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      leadData?.loanType == "Personal Loan" && (
        <Form.Item
          className="custom-select"
          label="Hometown"
          name="hometown"
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : ["nira finance", "loan tap"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    ),
              message: "Hometown",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      (leadData?.loanType == "Personal Loan" ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current city"
          name="durationOfStayInCurrentCity"
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : ["hdfc bank"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    ),
              message: "Duration of stay in Current city",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      (leadData?.loanType == "Personal Loan" ||
        ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")) && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current home address"
          name="durationOfStayInCurrentHomeAddress"
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : ["hdfc bank"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    ),
              message: "Duration of stay in Current home address",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];

    const correspondenceAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="correspondanceAddressLine1"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="correspondanceAddressCity"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="correspondanceAddressPincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="correspondanceAddressState"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="correspondanceAddressCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      isCC && (
        <Form.Item
          label="Residence Type"
          name="permanentResidenceType"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={column.filter((item) => item)}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ padding: "10px" }}>
                {isSBL ||
                ((isHL || isLAP) && selectedApplicationSubType != "SALARIED")
                  ? "Current Office Address"
                  : `Current Address Details`}
              </Text>
              <div style={{ float: "right" }}>
                <Button
                  type="text"
                  size="small"
                  className="details-icons"
                  onClick={() => setIsShowCurrentAddress(!isShowCurrentAddress)}
                >
                  {isShowCurrentAddress ? <DownOutlined /> : <UpOutlined />}
                </Button>
              </div>
            </div>
            {isShowCurrentAddress && (
              <List
                grid={{ gutter: 15, column: 2, xs: 1 }}
                dataSource={currentAddressColumns}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            )}
          </div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ padding: "10px" }}>
                {isSBL
                  ? "Registered Office Address"
                  : isHL || isLAP
                  ? "Permanent Address Details"
                  : `Correspondence Address Details`}
              </Text>
              <div style={{ float: "right" }}>
                <Button
                  type="text"
                  size="small"
                  className="details-icons"
                  onClick={() =>
                    setIsShowCorresPondenceAddress(!isShowCorresPondenceAddress)
                  }
                >
                  {isShowCorresPondenceAddress ? (
                    <DownOutlined />
                  ) : (
                    <UpOutlined />
                  )}
                </Button>
              </div>
              <div className="" style={{ display: "flex" }}>
                <FormCheckbox
                  isDisable={true}
                  className={"dynamic-pagination-checkbox"}
                  style={customStyles}
                  name="sameAsCurrentAddress"
                  textElement={<div>Same as Current Address</div>}
                />
              </div>
            </div>
            <div className="">
              {isShowCorresPondenceAddress && (
                <List
                  grid={{ gutter: 15, column: 2, xs: 1 }}
                  dataSource={correspondenceAddressColumns}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderIncomeDetails = () => {
    const columnIncome = [
      isHL || isLAP ? (
        selectedApplicationSubType !== "SALARIED" && (
          <Form.Item
            className="custom-select"
            label={
              isSBL
                ? "Last Year Turnover"
                : isHL || isLAP
                ? "Latest Year GrossTurnover"
                : "Latest Year Turnover"
            }
            name="latestYearTurnover"
            rules={[
              {
                required:
                  leadData?.loanType == "Personal Loan" || isHL || isLAP
                    ? false
                    : Object.keys(additionalFields).length === 0
                    ? ["hdfc bank"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      )
                    : additionalFields?.some((value) =>
                        [
                          "REVENUE_RANGE",
                          "MONTHLY_INCOME",
                          "LOAN_DETAILS.MONTHLY_IN_HAND_SALARY",
                        ].includes(value?.internalPropertyName?.toUpperCase())
                      ),
                message: `Please enter ${
                  isSBL
                    ? "Last Year Turnover"
                    : isHL || isLAP
                    ? "Latest Year GrossTurnover"
                    : "Latest Year Turnover"
                }`,
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder={`Please enter ${
                isSBL
                  ? "Last Year Turnover"
                  : isHL || isLAP
                  ? "Latest Year GrossTurnover"
                  : "Latest Year Turnover"
              }`}
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label={
            isSBL
              ? "Last Year Turnover"
              : isHL || isLAP
              ? "Latest Year GrossTurnover"
              : "Latest Year Turnover"
          }
          name="latestYearTurnover"
          rules={[
            {
              required:
                leadData?.loanType == "Personal Loan" || isHL || isLAP
                  ? false
                  : Object.keys(additionalFields).length === 0
                  ? ["hdfc bank"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    )
                  : additionalFields?.some((value) =>
                      [
                        "REVENUE_RANGE",
                        "MONTHLY_INCOME",
                        "LOAN_DETAILS.MONTHLY_IN_HAND_SALARY",
                      ].includes(value?.internalPropertyName?.toUpperCase())
                    ),
              message: `Please enter ${
                isSBL
                  ? "Last Year Turnover"
                  : isHL || isLAP
                  ? "Latest Year GrossTurnover"
                  : "Latest Year Turnover"
              }`,
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder={`Please enter ${
              isSBL
                ? "Last Year Turnover"
                : isHL || isLAP
                ? "Latest Year GrossTurnover"
                : "Latest Year Turnover"
            }`}
          />
        </Form.Item>
      ),
      !isSBL &&
        (isHL || isLAP ? (
          selectedApplicationSubType != "SALARIED" && (
            <Form.Item
              className="custom-select"
              label={
                isHL || isLAP
                  ? "Previous Year GrossTurnover"
                  : "Previous Year Turnover"
              }
              name="previousYearTurnover"
              rules={[
                {
                  required: false,
                  message: "Please enter previous year turnover",
                },
              ]}
            >
              <InputNumber
                disabled
                className="custom-input-number1"
                formatter={(value) => numberWithCommas(value)}
                placeholder="Please enter previous year turnover"
              />
            </Form.Item>
          )
        ) : (
          <Form.Item
            className="custom-select"
            label={
              isHL || isLAP
                ? "Previous Year GrossTurnover"
                : "Previous Year Turnover"
            }
            name="previousYearTurnover"
            rules={[
              {
                required: false,
                message: "Please enter previous year turnover",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder="Please enter previous year turnover"
            />
          </Form.Item>
        )),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label={
            isSBL
              ? "Total Current Monthly EMI"
              : isHL || isLAP
              ? "Current EMI"
              : "EMI"
          }
          name="monthlyEmi"
          rules={[
            {
              required: false,
              message: `Please enter ${
                isSBL ? "Total Current Monthly" : isHL || isLAP ? "Current" : ""
              } EMI`,
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder={`Please enter ${
              isSBL ? "Total Current Monthly" : isHL || isLAP ? "Current" : ""
            } EMI`}
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label={
            isSBL
              ? "Total Current Loan/s Outstanding"
              : isHL || isLAP
              ? "Existing Loan amount"
              : "Current Loan Outstanding"
          }
          name="currentLoanOutstanding"
          rules={[
            {
              required: false,
              message: `Please enter ${
                isSBL
                  ? "Total Current Loan/s Outstanding"
                  : isHL || isLAP
                  ? "Existing Loan amount"
                  : "Current Loan Outstanding"
              }`,
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder={`Please enter ${
              isSBL
                ? "Total Current Loans/Outstanding"
                : isHL || isLAP
                ? "Existing Loan amount"
                : "Current Loan Outstanding"
            }`}
          />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType !== "SALARIED" && (
          <Form.Item
            className="custom-select"
            label={
              isSBL
                ? "Last Year Profit"
                : isHL || isLAP
                ? "Latest Year Net Profit"
                : "Latest Year Profit"
            }
            name="latestYearProfit"
            rules={[
              {
                required: false,
                message: `Please enter ${
                  isSBL
                    ? "Last Year Profit"
                    : isHL || isLAP
                    ? "Latest Year Net Profit"
                    : "Latest Year Profit"
                }`,
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder={`Please enter ${
                isSBL
                  ? "Last Year Profit"
                  : isHL || isLAP
                  ? "Latest Year Net Profit"
                  : "Latest Year Profit"
              }`}
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label={
            isSBL
              ? "Last Year Profit"
              : isHL || isLAP
              ? "Latest Year Net Profit"
              : "Latest Year Profit"
          }
          name="latestYearProfit"
          rules={[
            {
              required: false,
              message: `Please enter ${
                isSBL
                  ? "Last Year Profit"
                  : isHL || isLAP
                  ? "Latest Year Net Profit"
                  : "Latest Year Profit"
              }`,
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder={`Please enter ${
              isSBL
                ? "Last Year Profit"
                : isHL || isLAP
                ? "Latest Year Net Profit"
                : "Latest Year Profit"
            }`}
          />
        </Form.Item>
      ),
      !isSBL &&
        (isHL || isLAP ? (
          selectedApplicationSubType !== "SALARIED" && (
            <Form.Item
              className="custom-select"
              label={
                isHL || isLAP
                  ? "Previous Year Net Profit"
                  : "Previous Year Profit"
              }
              name="previousYearProfit"
              rules={[
                {
                  required: false,
                  message: `Please enter previous year ${
                    (isHL || isLAP) && "net"
                  } profit`,
                },
              ]}
            >
              <InputNumber
                disabled
                className="custom-input-number1"
                formatter={(value) => numberWithCommas(value)}
                placeholder={`Please enter previous year ${
                  (isHL || isLAP) && "net"
                } profit`}
              />
            </Form.Item>
          )
        ) : (
          <Form.Item
            className="custom-select"
            label={
              isHL || isLAP
                ? "Previous Year Net Profit"
                : "Previous Year Profit"
            }
            name="previousYearProfit"
            rules={[
              {
                required: false,
                message: `Please enter previous year ${
                  (isHL || isLAP) && "net"
                } profit`,
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder={`Please enter previous year ${
                (isHL || isLAP) && "net"
              } profit`}
            />
          </Form.Item>
        )),
      !isSBL && !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Latest Year Gross Profit"
          name="latestYearGrossProfit"
          rules={[
            {
              required: false,
              message: "Please enter latest year profit",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Please enter latest year profit"
          />
        </Form.Item>
      ),
      ((!isSBL && !isHL && !isLAP) ||
        (isSBL &&
          !["SOLE_PROPRIETOR", "SELF_EMPLOYED"].includes(
            selectedApplicationSubType
          ))) && (
        <Form.Item
          className="custom-select"
          label="Partners Directors Remuneration"
          name="partnersDirectorsRemuneration"
          rules={[
            {
              required: false,
              message: "Please enter partners directors remuneration",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="partners directors remuneration"
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label={isSBL ? "Last Year Depreciation" : "Latest Year Depriciation"}
          name="latestYearDepreciation"
          rules={[
            {
              required: false,
              message: `Please enter ${
                isSBL ? "last" : "latest"
              } year depriciation`,
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder={`Please enter ${
              isSBL ? "last" : "latest"
            } year depriciation`}
          />
        </Form.Item>
      ),
      !isSBL && !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Previous Year Depriciation"
          name="previousYearDepreciation"
          rules={[
            {
              required: false,
              message: "Please enter previous year depriciation",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Please enter previous year depriciation"
          />
        </Form.Item>
      ),
      !isSBL && !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Share Capital"
          name="shareCapital"
          rules={[
            {
              required: false,
              message: "Please enter share capital",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Please enter share capital"
          />
        </Form.Item>
      ),
      ((isSBL && ["LLP", "PARTNERSHIP"].includes(selectedApplicationSubType)) ||
        (!isHL && !isLAP)) && (
        <Form.Item
          className="custom-select"
          label="Partners Capital"
          name="partnersCapital"
          rules={[
            {
              required: false,
              message: "Please enter partners capital",
            },
          ]}
        >
          <Input
            disabled
            className="custom-input-number1"
            placeholder="Please enter partners capital"
          />
        </Form.Item>
      ),
      ((!isSBL && !isHL && !isLAP) ||
        (isSBL && ["PVT", "LTD"].includes(selectedApplicationSubType))) && (
        <Form.Item
          className="custom-select"
          label="Reserves and Surplus"
          name="reservesAndSurplus"
          rules={[
            {
              required: false,
              message: "Please enter reserves and surplus",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Please enter reserves and surplus"
          />
        </Form.Item>
      ),
      !isSBL && !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Loans from Partners or Directors"
          name="loansFromPartners"
          rules={[
            {
              required: false,
              message: "Please enter loans from partners or directors",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Please enter  loans from partners or directors"
          />
        </Form.Item>
      ),
      (isHL || isLAP) && (
        <Form.Item
          className="custom-select"
          label="Current EMI"
          name="monthlyEmi"
          rules={[
            {
              // required: false,
              required:
                Object.keys(additionalFields).length === 0
                  ? ["nira finance"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    )
                  : additionalFields?.some(
                      (value) => value?.internalPropertyName === "MONTHLY_EMI"
                    ),
              message: "Please enter Current EMIs (INR)",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Current EMI"
          />
        </Form.Item>
      ),
      (isHL || isLAP) && (
        <Form.Item
          className="custom-select"
          label="Existing Loan amount"
          name="existingLoanAmount"
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder="Existing Loan amount"
          />
        </Form.Item>
      ),
      <FormCheckbox
        className={"dynamic-pagination-checkbox"}
        style={customStyles}
        isDisable={true}
        name="isCoApplicantEnabled"
        textElement={<div>Do you wish to add a Co-applicant?</div>}
      />,
    ];

    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnIncome.filter((item) => item)}
          renderItem={(item1) => <List.Item>{item1}</List.Item>}
        />
      </>
    );
  };

  const relationships = [
    {
      value: "Proprietor",
      key: "PROPRIETOR",
    },
    {
      value: "Partner",
      key: "PARTNER",
    },
    {
      value: "Shareholder",
      key: "SHAREOLDER",
    },
    {
      value: "Spouse",
      key: "SPOUSE",
    },
    {
      value: "Mother",
      key: "MOTHER",
    },
    {
      value: "Father",
      key: "FATHER",
    },
    {
      value: "Brother",
      key: "BROTHER",
    },
    {
      value: "Sister",
      key: "SISTER",
    },
    {
      value: "Director",
      key: "DIRECTOR",
    },
    {
      value: "Others",
      key: "OTHERS",
    },
  ];

  const renderCoApplicantDetails1 = () => {
    const columnCoApplicant = [
      !isSBL && (
        <Form.Item
          label="Co-Applicant Type"
          name="coApplicantIndividualType"
          className="custom-select"
          rules={[
            {
              required: !isSBL,
              message: "Please enter Co-applicant type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled>
            {coApplicantList.map((coApplicantData: any, i: number) => (
              <Select.Option value={coApplicantData.key} key={i}>
                {coApplicantData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      <Form.Item
        className="custom-select"
        label="Name"
        name="coApplicantName"
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Please enter name"
        />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="PAN"
        name="coApplicantPan"
        rules={[
          {
            required: false,
            message: "Please enter pan number",
          },
          {
            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            message: "Please enter valid pan number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="Pan number" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Date of Birth"
        name="coApplicantDob"
        rules={[
          {
            required: false,
            message: "Please enter date of birth",
          },
        ]}
      >
        <DatePicker
          disabled
          format="DD-MM-YYYY"
          className="custom-input"
          placeholder="DD-MM-YYYY"
        />
      </Form.Item>,
      isSBL && (
        <Form.Item
          className="custom-select"
          label="Mobile Number"
          name="coapplicantMobileNumber"
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid number",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isSBL && (
        <Form.Item
          label="Gender"
          name="coapplicantGender"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {genderList.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      isSBL && (
        <Form.Item
          label="Relationship"
          name="coapplicantRelationship"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {relationships.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      !isSBL && (
        <Form.Item
          className="custom-select"
          label="Age"
          name="coApplicantAge"
          rules={[
            {
              required: false,
              message: "Please enter age",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input"
            placeholder="Please enter age"
          />
        </Form.Item>
      ),
    ];

    const currentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicantPincode"
        rules={[
          {
            pattern: /^[1-9][0-9]{5}$/,
            message: "Please enter valid pincode",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicantAddressLine1"
        rules={[{ validator: checkAddressLength }]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicantCity"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid city",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicantState"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid state",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicantCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
    ];

    const parmentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicantPermanentPincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicantPermanentAddressLine1"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicantPermanentCity"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicantPermanentState"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicantPermanentCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnCoApplicant.filter((item) => item)}
          renderItem={(item3) => <List.Item>{item3}</List.Item>}
        />
        {!isCC &&
        <div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Current Residence Address"
                  : "Current Address Details"}
              </Text>
            </div>
            <List
              grid={{ gutter: 15, column: 2, xs: 1 }}
              dataSource={currentAddressColumns}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Permanent Residence Address"
                  : "Permanent Address Details"}
              </Text>
            </div>
            <div className="" style={{ display: "flex" }}>
              <FormCheckbox
                className={"dynamic-pagination-checkbox"}
                style={customStyles}
                isDisable={true}
                name="sameAsCoapplicantCurrentAddress"
                textElement={<div>Same as Current Address</div>}
              />
            </div>
            <div className="">
              <List
                grid={{ gutter: 15, column: 2, xs: 1 }}
                dataSource={parmentAddressColumns}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          </div>
        </div>
        }
      </>
    );
  };

  const renderCoApplicantDetails2 = () => {
    const columnCoApplicant2 = [
      !isSBL && (
        <Form.Item
          label="Co-Applicant Type"
          name="coapplicant2Type"
          className="custom-select"
          rules={[
            {
              required: false,
              message: "Please enter Co-applicant type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled>
            {coApplicantList.map((coApplicantData: any, i: number) => (
              <Select.Option value={coApplicantData.key} key={i}>
                {coApplicantData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      <Form.Item
        className="custom-select"
        label="Name"
        name="coapplicant2Name"
        rules={[
          {
            required: false,
            message: "Please enter name",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Please enter name"
        />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="PAN"
        name="coapplicant2Pan"
        rules={[
          {
            required: false,
            message: "Please enter pan number",
          },
          {
            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            message: "Please enter valid pan number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="Pan number" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Date of Birth"
        name="coapplicant2Dob"
        rules={[
          {
            required: false,
            message: "Please enter date of birth",
          },
        ]}
      >
        <DatePicker
          disabled
          format="DD-MM-YYYY"
          className="custom-input"
          placeholder="DD-MM-YYYY"
        />
      </Form.Item>,
      isSBL && (
        <Form.Item
          className="custom-select"
          label="Mobile Number"
          name="coapplicant2MobileNumber"
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid number",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isSBL && (
        <Form.Item
          label="Gender"
          name="coapplicant2Gender"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {genderList.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      isSBL && (
        <Form.Item
          label="Relationship"
          name="coapplicant2Relationship"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {relationships.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      !isSBL && (
        <Form.Item
          className="custom-select"
          label="Age"
          name="coapplicant2Age"
          rules={[
            {
              required: false,
              message: "Please enter age",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input"
            placeholder="Please enter age"
          />
        </Form.Item>
      ),
    ];

    const currentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicant2Pincode"
        rules={[
          {
            pattern: /^[1-9][0-9]{5}$/,
            message: "Please enter valid pincode",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicant2AddressLine1"
        rules={[{ validator: checkAddressLength }]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicant2City"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid city",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicant2State"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid state",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicant2Country"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
    ];

    const parmentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicant2PermanentPincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicant2PermanentAddressLine1"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicant2PermanentCity"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicant2PermanentState"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicant2PermanentCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnCoApplicant2.filter((item) => item)}
          renderItem={(item4) => <List.Item>{item4}</List.Item>}
        />
        {!isCC &&
        <div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Current Residence Address"
                  : "Current Address Details"}
              </Text>
            </div>
            <List
              grid={{ gutter: 15, column: 2, xs: 1 }}
              dataSource={currentAddressColumns}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Permanent Residence Address"
                  : "Permanent Address Details"}
              </Text>
            </div>
            <div className="" style={{ display: "flex" }}>
              <FormCheckbox
                className={"dynamic-pagination-checkbox"}
                style={customStyles}
                isDisable={true}
                name="sameAsCoapplicant2CurrentAddress"
                textElement={<div>Same as Current Address</div>}
              />
            </div>
            <div className="">
              <List
                grid={{ gutter: 15, column: 2, xs: 1 }}
                dataSource={parmentAddressColumns}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          </div>
        </div>
        }
      </>
    );
  };
  const renderCoApplicantDetails1HL = () => {
    const columnCoApplicant = [
      <Form.Item
        label="Co-Applicant Type"
        name="coApplicantIndividualType"
        className="custom-select"
        rules={[
          {
            required: !isSBL,

            message: "Please enter Co-applicant type",
          },
        ]}
      >
        <Select placeholder="Please select" disabled>
          {coApplicantList.map((coApplicantData: any, i: number) => (
            <Select.Option value={coApplicantData.key} key={i}>
              {coApplicantData.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>,

      <Form.Item
        className="custom-select"
        label="Name"
        name="coApplicantName"
        rules={[
          {
            required: true,

            message: "Please enter name",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Please enter name"
        />
      </Form.Item>,

      <Form.Item
        className="custom-select"
        label="PAN"
        name="coApplicantPan"
        rules={[
          {
            required: false,

            message: "Please enter pan number",
          },

          {
            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,

            message: "Please enter valid pan number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="Pan number" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Mobile Number"
        name="coapplicantMobileNumber"
        rules={[
          {
            pattern: PHONE_NUMBER_REGEX,

            message: "Please enter valid number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" && (
        <Form.Item
          className="custom-select"
          label="Date of Birth"
          name="coApplicantDob"
          rules={[
            {
              required: false,

              message: "Please enter date of birth",
            },
          ]}
        >
          <DatePicker
            disabled
            format="DD-MM-YYYY"
            className="custom-input"
            placeholder="DD-MM-YYYY"
          />
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" && (
        <Form.Item
          label="Gender"
          name="coapplicantGender"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {genderList.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" && (
        <Form.Item
          label="Relationship"
          name="coapplicantRelationship"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {relationships.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),

      <FormCheckbox
        className={"dynamic-pagination-checkbox"}
        style={customStyles}
        isDisable={true}
        name="coapplicantIncomeToBeConsideredOrNot"
        textElement={<div>Income to be considered or not?</div>}
      />,

      // leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
      //   "SALARIED" && (
      //   <Form.Item
      //     className="custom-select"
      //     label="Age"
      //     name="coApplicantAge"
      //     rules={[
      //       {
      //         required: false,

      //         message: "Please enter age",
      //       },
      //     ]}
      //   >
      //     <InputNumber
      //       disabled
      //       className="custom-input"
      //       placeholder="Please enter age"
      //     />
      //   </Form.Item>
      // ),

      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Father's/Spouse's Name"
          name="coapplicantFatherOrSpouseName"
          rules={[
            {
              pattern: STRING_ONLY,

              message: "Please enter valid name",
            },
          ]}
        >
          <Input
            disabled
            className="custom-input"
            placeholder="Father's/Spouse's Name"
          />
        </Form.Item>
      ),

      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Coapplicant Email"
          name="coapplicantEmail"
        >
          <Input
            disabled
            className="custom-input"
            placeholder="Coapplicant Email"
          />
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="No of Dependent"
            name="coapplicantNoOfDependent"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="No. of working family members"
            name="coapplicantNoOfWorkingFamilyMembers"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            label="Marital Status"
            name="coapplicantMaritalstatus"
            className="custom-select"
            rules={[
              {
                required: ["loan tap"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),

                message: "Marital Status",
              },
            ]}
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Religion"
            name="coapplicantReligion"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Nationality"
            name="coapplicantNationality"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Employer Name"
            name="coapplicantEmployerName"
            rules={[
              {
                required:
                  isHL || isLAP
                    ? false
                    : isCASHEender || isABCFLLender || isBajajLender
                    ? true
                    : Object.keys(additionalFields).length === 0
                    ? ["nira finance", "hdfc bank", "moneywide"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      )
                    : additionalFields?.some(
                        (value) =>
                          value?.internalPropertyName?.toUpperCase() ===
                          "EMPLOYEE_OR_ENTITY_NAME"
                      ),

                message: "Please enter employer name",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            label="Employer Type"
            name="coapplicant1EmployerType"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Monthly Net Salary"
            name="coapplicantMonthlyInHandSalary"
            rules={[
              {
                required: false,

                message: "Please enter Monthly Net Salary",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Monthly Gross Salary"
            name="coapplicantMonthlyGrossSalary"
            rules={[
              {
                required: false,

                message: "Please enter Monthly Gross Salary",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Designation"
            name="coapplicantDesignation"
            rules={[
              {
                required:
                  isHL || isLAP
                    ? false
                    : isBajajLender
                    ? true
                    : Object.keys(additionalFields).length === 0
                    ? [
                        "nira finance",

                        "hdfc bank",

                        "moneywide",

                        "bajaj finance",
                      ].includes(selectedLander?.lenderName?.toLowerCase())
                    : false,

                message: "Please enter Designation",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            label="Job Sector"
            name="coapplicantJobSector"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Total experience in years"
            name="coapplicantTotalExperienceInYears"
            rules={[
              {
                required: ["moneywide"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),

                message: "Please enter Total experience in years",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Current Work experience"
            name="coapplicantCurrentExperienceInYears"
            rules={[
              {
                required: ["nira finance", "moneywide"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),

                message: "Please enter Current Work experience",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Current EMI"
            name="coapplicantCurrentEmi"
            rules={[
              {
                // required: false,

                required:
                  Object.keys(additionalFields).length === 0
                    ? ["nira finance"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      )
                    : additionalFields?.some(
                        (value) => value?.internalPropertyName === "MONTHLY_EMI"
                      ),

                message: "Please enter Current EMIs (INR)",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType ==
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Existing Loan amount"
            name="coapplicantExistingLoanAmount"
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType !=
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            label="Business Name"
            name="coapplicantBusinessName"
            // className="custom-select"

            rules={[
              {
                required: isFlexiLender || isBajajLender || isABCFLLender,

                message: "Please enter business name",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coApplicantIndividualType !=
        "SALARIED" &&
        isCoApplicant1IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label={"Latest Year Turnover"}
            name="coapplicantLatestYearTurnover"
            rules={[
              {
                required: false,

                message: `Please enter Latest Year Turnover`,
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder={`Please enter Latest Year Turnover`}
            />
          </Form.Item>
        ),
    ];

    const currentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicantPincode"
        rules={[
          {
            pattern: /^[1-9][0-9]{5}$/,
            message: "Please enter valid pincode",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicantAddressLine1"
        rules={[{ validator: checkAddressLength }]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,

      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicantCity"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid city",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicantState"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid state",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicantCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          label="Coapplicant Residence Type"
          name="coapplicantResidenceType"
          className="custom-select"
          rules={[
            {
              required: ["loan tap"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Coapplicant Residence Type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current city"
          name="coapplicantDurationOfStayInCurrentCityInMonth"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Duration of stay in Current city",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current home address"
          name="coapplicantDurationOfStayInCurrentHomeAddressInMonth"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Duration of stay in Current home address",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];

    const parmentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicantPermanentPincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicantPermanentAddressLine1"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicantPermanentCity"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicantPermanentState"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicantPermanentCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          label="Coapplicant Residence Type"
          name="coapplicantPermanentResidenceType"
          className="custom-select"
          rules={[
            {
              required: ["loan tap"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Coapplicant Residence Type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current city"
          name="coapplicantPermanentDurationOfStayInCurrentCityInMonth"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Duration of stay in Current city",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isCoApplicant1IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current home address"
          name="coapplicantPermanentDurationOfStayInCurrentHomeAddressInMonth"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Duration of stay in Current home address",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnCoApplicant.filter((item) => item)}
          renderItem={(item3) => <List.Item>{item3}</List.Item>}
        />
        <div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Current Residence Address"
                  : (isHL || isLAP) &&
                    leadData?.dsaCaseAdditionalAttributes
                      ?.coApplicantIndividualType != "SALARIED"
                  ? "Current Office Address"
                  : "Current Address Details"}
              </Text>
            </div>
            <List
              grid={{ gutter: 15, column: 2, xs: 1 }}
              dataSource={currentAddressColumns}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Permanent Residence Address"
                  : "Permanent Address Details"}
              </Text>
            </div>
            <div className="" style={{ display: "flex" }}>
              <FormCheckbox
                className={"dynamic-pagination-checkbox"}
                style={customStyles}
                isDisable={true}
                name="sameAsCoapplicantCurrentAddress"
                textElement={<div>Same as Current Address</div>}
              />
            </div>
            <div className="">
              <List
                grid={{ gutter: 15, column: 2, xs: 1 }}
                dataSource={parmentAddressColumns}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCoApplicantDetails2HL = () => {
    const columnCoApplicant2 = [
      <Form.Item
        label="Co-Applicant Type"
        name="coapplicant2Type"
        className="custom-select"
        rules={[
          {
            required: false,

            message: "Please enter Co-applicant type",
          },
        ]}
      >
        <Select placeholder="Please select" disabled>
          {coApplicantList.map((coApplicantData: any, i: number) => (
            <Select.Option value={coApplicantData.key} key={i}>
              {coApplicantData.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Name"
        name="coapplicant2Name"
        rules={[
          {
            required: false,

            message: "Please enter name",
          },
        ]}
      >
        <Input
          disabled
          className="custom-input"
          placeholder="Please enter name"
        />
      </Form.Item>,

      <Form.Item
        className="custom-select"
        label="PAN"
        name="coapplicant2Pan"
        rules={[
          {
            required: false,

            message: "Please enter pan number",
          },

          {
            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,

            message: "Please enter valid pan number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="Pan number" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Mobile Number"
        name="coapplicant2MobileNumber"
        rules={[
          {
            pattern: PHONE_NUMBER_REGEX,

            message: "Please enter valid number",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" && (
        <Form.Item
          className="custom-select"
          label="Date of Birth"
          name="coapplicant2Dob"
          rules={[
            {
              required: false,

              message: "Please enter date of birth",
            },
          ]}
        >
          <DatePicker
            disabled
            format="DD-MM-YYYY"
            className="custom-input"
            placeholder="DD-MM-YYYY"
          />
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" && (
        <Form.Item
          label="Gender"
          name="coapplicant2Gender"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {genderList.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" && (
        <Form.Item
          label="Relationship"
          name="coapplicant2Relationship"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled>
            {relationships.map((genderData: any, i: number) => (
              <Select.Option value={genderData.key} key={i}>
                {genderData.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),

      <FormCheckbox
        className={"dynamic-pagination-checkbox"}
        style={customStyles}
        isDisable={true}
        name="coapplicant2IncomeToBeConsideredOrNot"
        textElement={<div>Income to be considered or not?</div>}
      />,

      // leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type ==
      //   "SALARIED" && (
      //   <Form.Item
      //     className="custom-select"
      //     label="Age"
      //     name="coApplicant2Age"
      //     rules={[
      //       {
      //         required: false,

      //         message: "Please enter age",
      //       },
      //     ]}
      //   >
      //     <InputNumber
      //       disabled
      //       className="custom-input"
      //       placeholder="Please enter age"
      //     />
      //   </Form.Item>
      // ),

      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Father's/Spouse's Name"
          name="coapplicant2FatherOrSpouseName"
          rules={[
            {
              pattern: STRING_ONLY,

              message: "Please enter valid name",
            },
          ]}
        >
          <Input
            disabled
            className="custom-input"
            placeholder="Father's/Spouse's Name"
          />
        </Form.Item>
      ),

      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Coapplicant Email"
          name="coapplicant2Email"
        >
          <Input
            disabled
            className="custom-input"
            placeholder="Coapplicant Email"
          />
        </Form.Item>
      ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="No of Dependent"
            name="coapplicant2NoOfDependent"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="No. of working family members"
            name="coapplicant2NoOfWorkingFamilyMembers"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            label="Marital Status"
            name="coapplicant2MaritalStatus"
            className="custom-select"
            rules={[
              {
                required: false,

                message: "Marital Status",
              },
            ]}
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Religion"
            name="coapplicant2Religion"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Nationality"
            name="coapplicant2Nationality"
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Employer Name"
            name="coapplicant2EmployerName"
            rules={[
              {
                required: false,
                message: "Please enter employer name",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            label="Employer Type"
            name="coapplicant2EmployerType"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Monthly Net Salary"
            name="coapplicant2MonthlyInHandSalary"
            rules={[
              {
                required: false,

                message: "Please enter Monthly Net Salary",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Monthly Gross Salary"
            name="coapplicant2MonthlyGrossSalary"
            rules={[
              {
                required: false,

                message: "Please enter Monthly Gross Salary",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Designation"
            name="coapplicant2Designation"
            rules={[
              {
                required: false,
                message: "Please enter Designation",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            label="Job Sector"
            name="coapplicant2JobSector"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Total experience in years"
            name="coapplicant2TotalExperienceInYears"
            rules={[
              {
                required: false,

                message: "Please enter Total experience in years",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Current Work experience"
            name="coapplicant2CurrentExperienceInYears"
            rules={[
              {
                required: false,

                message: "Please enter Current Work experience",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Current EMI"
            name="coapplicant2CurrentEmi"
            rules={[
              {
                // required: false,

                required: false,
                message: "Please enter Current EMIs (INR)",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type == "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label="Existing Loan amount"
            name="coapplicant2ExistingLoanAmount"
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type != "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            label="Business Name"
            name="coapplicant2BusinessName"
            // className="custom-select"

            rules={[
              {
                required: false,
                message: "Please enter business name",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        ),

      leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type != "SALARIED" &&
        isCoApplicant2IncomeConsidered && (
          <Form.Item
            className="custom-select"
            label={"Latest Year Turnover"}
            name="coapplicant2LatestYearTurnover"
            rules={[
              {
                required: false,

                message: `Please enter Latest Year Turnover`,
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder={`Please enter Latest Year Turnover`}
            />
          </Form.Item>
        ),
    ];

    const currentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicant2Pincode"
        rules={[
          {
            pattern: /^[1-9][0-9]{5}$/,
            message: "Please enter valid pincode",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicant2AddressLine1"
        rules={[{ validator: checkAddressLength }]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,

      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicant2City"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid city",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicant2State"
        rules={[
          {
            pattern: STRING_ONLY,
            message: "Please enter valid state",
          },
        ]}
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicant2Country"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          label="Coapplicant Residence Type"
          name="coapplicant2ResidenceType"
          className="custom-select"
          rules={[
            {
              required: false,
              message: "Coapplicant Residence Type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current city"
          name="coapplicant2DurationOfStayInCurrentCityInMonth"
          rules={[
            {
              required: false,
              message: "Duration of stay in Current city",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current home address"
          name="coapplicant2DurationOfStayInCurrentHomeAddressInMonth"
          rules={[
            {
              required: false,
              message: "Duration of stay in Current home address",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];

    const parmentAddressColumns = [
      <Form.Item
        className="custom-select"
        label="Pincode"
        name="coapplicant2PermanentPincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Address Line 1"
        name="coapplicant2PermanentAddressLine1"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="City"
        name="coapplicant2PermanentCity"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="State"
        name="coapplicant2PermanentState"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Country"
        name="coapplicant2PermanentCountry"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          label="Coapplicant Residence Type"
          name="coapplicant2PermanentResidenceType"
          className="custom-select"
          rules={[
            {
              required: false,
              message: "Coapplicant Residence Type",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current city"
          name="coapplicant2PermanentDurationOfStayInCurrentCityInMonth"
          rules={[
            {
              required: false,
              message: "Duration of stay in Current city",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isCoApplicant2IncomeConsidered && (
        <Form.Item
          className="custom-select"
          label="Duration of stay in Current home address"
          name="coapplicant2PermanentDurationOfStayInCurrentHomeAddressInMonth"
          rules={[
            {
              required: false,
              message: "Duration of stay in Current home address",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];

    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnCoApplicant2.filter((item) => item)}
          renderItem={(item4) => <List.Item>{item4}</List.Item>}
        />
        <div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Current Residence Address"
                  : (isHL || isLAP) &&
                    leadData?.dsaCaseAdditionalAttributes?.coapplicant2Type !=
                      "SALARIED"
                  ? "Current Office Address"
                  : "Current Address Details"}
              </Text>
            </div>
            <List
              grid={{ gutter: 15, column: 2, xs: 1 }}
              dataSource={currentAddressColumns}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </div>
          <div className="">
            <div className="col-border-bottom-3">
              <Text className="" style={{ paddingBlock: "10px" }}>
                {isSBL
                  ? "Permanent Residence Address"
                  : "Permanent Address Details"}
              </Text>
            </div>
            <div className="" style={{ display: "flex" }}>
              <FormCheckbox
                className={"dynamic-pagination-checkbox"}
                style={customStyles}
                isDisable={true}
                name="sameAsCoapplicant2CurrentAddress"
                textElement={<div>Same as Current Address</div>}
              />
            </div>
            <div className="">
              <List
                grid={{ gutter: 15, column: 2, xs: 1 }}
                dataSource={parmentAddressColumns}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderCoApplicantDetails = () => {
    return (
      <>
        <div style={{}}>
          <Collapse
            style={{ borderRadius: "6px" }}
            expandIconPosition="end"
            defaultActiveKey={"6"}
            className="co-applicant-details"
          >
            <Panel
              header={isSBL ? "Co Applicant 1" : "Co-Applicant Details1"}
              key="6"
            >
              {isHL || isLAP
                ? renderCoApplicantDetails1HL()
                : renderCoApplicantDetails1()}
            </Panel>
            <Panel
              header={isSBL ? "Co Applicant 2" : "Co-Applicant Details2"}
              key="7"
            >
              {isHL || isLAP
                ? renderCoApplicantDetails2HL()
                : renderCoApplicantDetails2()}
            </Panel>
          </Collapse>
        </div>
      </>
    );
  };

  const btnDefaultBorderStyle: any = {
    "--borderColor": appColors?.appSecondaryColor ?? "",
    width: "130px",
  };
  const btnPrimaryStyles: any = {
    "--btnColor": appColors?.appPrimaryColor ?? "",
    width: "130px",
  };
  const customStyles: any = { "--bgColor": appColors?.appPrimaryColor ?? "" };

  const renderIncomeDetailsPl = () => {
    const columnImcomePL = [
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Employer Name"
            name="employerName"
            rules={[
              {
                required:
                  isHL || isLAP
                    ? false
                    : isCASHEender || isABCFLLender || isBajajLender
                    ? true
                    : Object.keys(additionalFields).length === 0
                    ? ["nira finance", "hdfc bank", "moneywide"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      )
                    : additionalFields?.some(
                        (value) =>
                          value?.internalPropertyName?.toUpperCase() ===
                          "EMPLOYEE_OR_ENTITY_NAME"
                      ),
                message: "Please enter employer name",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Employer Name"
          name="employerName"
          rules={[
            {
              required:
                isCASHEender || isABCFLLender || isBajajLender
                  ? true
                  : Object.keys(additionalFields).length === 0
                  ? ["nira finance", "hdfc bank", "moneywide"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    )
                  : additionalFields?.some(
                      (value) =>
                        value?.internalPropertyName?.toUpperCase() ===
                        "EMPLOYEE_OR_ENTITY_NAME"
                    ),
              message: "Please enter employer name",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            label="Employer Type"
            name="employerType"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        )
      ) : (
        <Form.Item
          label="Employer Type"
          name="employmentType"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Monthly Net Salary"
            name="monthlyInHandSalary"
            rules={[
              {
                required: ["bajaj housing finance"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),
                message: "Please enter Monthly Net Salary",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Monthly Salary"
          name="monthlyInHandSalary"
          rules={[
            {
              required: true,
              message: "Please enter Monthly In-hand Salary",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
          />
        </Form.Item>
      ),
      (isHL || isLAP) && selectedApplicationSubType == "SALARIED" && (
        <Form.Item
          className="custom-select"
          label="Monthly Gross Salary"
          name="monthlyGrossSalary"
          rules={[
            {
              required: false,
              message: "Please enter Monthly Gross Salary",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          label="Salary Mode"
          name="salaryMode"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Designation"
            name="designation"
            rules={[
              {
                required:
                  isHL || isLAP
                    ? false
                    : isBajajLender
                    ? true
                    : Object.keys(additionalFields).length === 0
                    ? [
                        "nira finance",
                        "hdfc bank",
                        "moneywide",
                        "bajaj finance",
                      ].includes(selectedLander?.lenderName?.toLowerCase())
                    : false,
                message: "Please enter Designation",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Designation"
          name="designation"
          rules={[
            {
              required:
                isHL || isLAP
                  ? false
                  : isBajajLender
                  ? true
                  : Object.keys(additionalFields).length === 0
                  ? [
                      "nira finance",
                      "hdfc bank",
                      "moneywide",
                      "bajaj finance",
                    ].includes(selectedLander?.lenderName?.toLowerCase())
                  : false,
              message: "Please enter Designation",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            label="Job Sector"
            name="jobSector"
            className="custom-select"
          >
            <Select placeholder="Please select" disabled></Select>
          </Form.Item>
        )
      ) : (
        <Form.Item
          label="Job Sector"
          name="jobSector"
          className="custom-select"
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Total experience in years"
            name="totalExperienceInYears"
            rules={[
              {
                required: ["moneywide"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),
                message: "Please enter Total experience in years",
              },
            ]}
          >
            <Input disabled className="custom-input" placeholder="" />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Total experience in years"
          name="totalExperienceInYears"
          rules={[
            {
              required: ["moneywide"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Total experience in years",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Current Work experience"
            name="currentWorkExperience"
            rules={[
              {
                required: ["nira finance", "moneywide"].includes(
                  selectedLander?.lenderName?.toLowerCase()
                ),
                message: "Please enter Current Work experience",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              placeholder=""
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Current Work experience"
          name="currentWorkExperience"
          rules={[
            {
              required: ["nira finance", "moneywide"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Current Work experience",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            placeholder=""
          />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Current EMI"
            name="monthlyEmi"
            rules={[
              {
                // required: false,
                required:
                  Object.keys(additionalFields).length === 0
                    ? ["nira finance"].includes(
                        selectedLander?.lenderName?.toLowerCase()
                      )
                    : additionalFields?.some(
                        (value) => value?.internalPropertyName === "MONTHLY_EMI"
                      ),
                message: "Please enter Current EMIs (INR)",
              },
            ]}
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Existing Total EMI Amount"
          name={leadData?.loanType == "Personal Loan" ? "monthlyEmi" : "currentEmis"}
          rules={[
            {
              // required: false,
              required:
                Object.keys(additionalFields).length === 0
                  ? ["nira finance"].includes(
                      selectedLander?.lenderName?.toLowerCase()
                    )
                  : additionalFields?.some(
                      (value) => value?.internalPropertyName === "MONTHLY_EMI"
                    ),
              message: "Please enter Current EMIs (INR)",
            },
          ]}
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder=""
          />
        </Form.Item>
      ),
      isHL || isLAP ? (
        selectedApplicationSubType == "SALARIED" && (
          <Form.Item
            className="custom-select"
            label="Existing Loan amount"
            name="existingLoanAmount"
          >
            <InputNumber
              disabled
              className="custom-input-number1"
              formatter={(value) => numberWithCommas(value)}
              placeholder=""
            />
          </Form.Item>
        )
      ) : (
        <Form.Item
          className="custom-select"
          label="Existing Loan amount"
          name="existingLoanAmount"
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder=""
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Exsiting Loan Bank"
          name="exisitingLoanBank"
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder=""
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Monthly Rent"
          name="monthlyRent"
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder=""
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Household Income"
          name="householdIncome"
        >
          <InputNumber
            disabled
            className="custom-input-number1"
            formatter={(value) => numberWithCommas(value)}
            placeholder=""
          />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          label="Work Status"
          name="workStatus"
          className="custom-select"
          rules={[
            {
              required: ["nira finance"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Work Status",
            },
          ]}
        >
          <Select placeholder="Please select" disabled></Select>
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office Address"
          name="officeAddress"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Work Status",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office Pincode"
          name="officePincode"
          rules={[
            {
              required: isCASHEender
                ? true
                : Object.keys(additionalFields).length === 0
                ? ["hdfc bank", "moneywide"].includes(
                    selectedLander?.lenderName?.toLowerCase()
                  )
                : additionalFields?.some(
                    (value) =>
                      value?.internalPropertyName?.toUpperCase() ===
                        "OFFICEPINCODE" ||
                      value?.apiPropertyName?.toUpperCase() === "OFFICEPINCODE"
                  ),
              message: "Please enter office pin code",
            },
            {
              pattern: /^[1-9][0-9]{5}$/,
              message: "Please enter valid pincode",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office City"
          name="officeCity"
          rules={[
            {
              required: ["moneywide"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Office City",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office State"
          name="officeState"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office email"
          name="officeEmail"
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
      !isHL && !isLAP && (
        <Form.Item
          className="custom-select"
          label="Office Phone Number"
          name="officePhoneNumber"
          rules={[
            {
              required: ["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ),
              message: "Please enter Office Phone Number",
            },
          ]}
        >
          <Input disabled className="custom-input" placeholder="" />
        </Form.Item>
      ),
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnImcomePL.filter((item) => item)}
          renderItem={(item2) => <List.Item>{item2}</List.Item>}
        />
      </>
    );
  };

  const renderIncomeDetailsCC = () => {
    const columnImcomePL = [
      <Form.Item
        className="custom-select"
        label="Employer Name"
        name="employerName"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        label="Employer Type"
        name="employmentType"
        className="custom-select"
      >
        <Select placeholder="Please select" disabled></Select>
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Monthly In-hand Salary"
        name="monthlyInHandSalary"
        rules={[
          {
            required: true,
            message: "Please enter Monthly In-hand Salary",
          },
        ]}
      >
        <InputNumber
          disabled
          className="custom-input-number1"
          formatter={(value) => numberWithCommas(value)}
        />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Designation"
        name="designation"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Office Pincode"
        name="officePincode"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <Form.Item
        className="custom-select"
        label="Office email"
        name="officeEmail"
      >
        <Input disabled className="custom-input" placeholder="" />
      </Form.Item>,
      <FormCheckbox
        className={"dynamic-pagination-checkbox"}
        style={customStyles}
        isDisable={true}
        name="isCoApplicantEnabled"
        textElement={<div>Do you wish to add a Co-applicant?</div>}
      />,
    ];
    return (
      <>
        <List
          grid={{ gutter: 15, column: 2, xs: 1 }}
          dataSource={columnImcomePL.filter((item) => item)}
          renderItem={(item2) => <List.Item>{item2}</List.Item>}
        />
      </>
    );
  };

  const renderEditModal = () => (
    <Modal
      width={450}
      visible={isEditDetils}
      closable={false}
      maskClosable={false}
      footer={false}
      centered
    >
      <div style={{ padding: "30px" }}>
        <p style={{ textAlign: "center", fontSize: "18px" }}>
          Editing of values will cause override of previous decisioning. Please
          re-run the process for updated decisioning.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Button
            className="dynamic-btn-default"
            style={btnDefaultBorderStyle}
            size="large"
            onClick={() => setIsEditDetails(false)}
          >
            Cancel
          </Button>
          <Button
            className="dynamic-btn-primary"
            style={btnPrimaryStyles}
            size="large"
            type="primary"
            onClick={() => purgeAllCaseDecisioningData()}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {renderEditModal()}
      <div style={{ padding: "10px" }}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "5px",
              marginBottom: "15px",
            }}
          >
            <p className="custome-btn-icon">
              <IconEdit
                onClick={editDetails}
                style={
                  {
                    "--bgColor": appColors?.appPrimaryColor ?? "",
                    "--width": "24px",
                  } as any
                }
              />
            </p>
          </div>
          <div>
            <Collapse
              style={{ borderRadius: "6px" }}
              expandIconPosition="end"
              defaultActiveKey={"1"}
            >
              <Panel header="Loan Details" key="1">
                {renderLoanDetails()}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Collapse
              style={{ borderRadius: "6px" }}
              expandIconPosition="end"
              defaultActiveKey={"2"}
            >
              <Panel
                header={isSBL ? "Business Details" : "Applicant Details"}
                key="2"
              >
                {renderApplicantDetails()}
              </Panel>
            </Collapse>
          </div>
          <div style={{ marginTop: "15px" }}>
            <Collapse
              style={{ borderRadius: "6px" }}
              expandIconPosition="end"
              defaultActiveKey={"3"}
            >
              <Panel header="Income Details" key="3">
                {leadData?.loanType == "Personal Loan" ||
                ((isHL || isLAP) && selectedApplicationSubType == "SALARIED")
                  ? renderIncomeDetailsPl()
                  : isCC ? renderIncomeDetailsCC() : renderIncomeDetails()}
              </Panel>
            </Collapse>
          </div>
          {leadData?.dsaCaseAdditionalAttributes?.isCoApplicantEnabled && (
            <div style={{ marginTop: "15px" }}>
              <Collapse
                style={{ borderRadius: "6px" }}
                expandIconPosition="end"
                defaultActiveKey={"4"}
              >
                <Panel header="Co-Applicant Details" key="4">
                  {renderCoApplicantDetails()}
                </Panel>
              </Collapse>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default LeadEditAdditional;
