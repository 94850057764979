import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const leadSlice = createSlice({
  name: "LeadReducer",
  initialState: {
    pageNo: 1, pageSize: 10, searchParam: null, activeKey: "PRE_LENDER", preLenderCase: "ALL", postLenderCase: "ASSIGNED",
    isShowFilter: false,
    isAssignToMe: false,
    connectorId: undefined,
    loanType: undefined,
    location: undefined,
    managerHierarchy: undefined,
    isLenderDecisionErr: false,
    filterData: { "connectorId": undefined, "loanType": undefined, "location": undefined, managerHierarchy: undefined },
    filterListDetails: {},//{ "SUB_CONNECTOR": [], "CONNECTOR": [], "SALES_EXECUTIVE": [], "TEAM_LEADER": [], "SALES_MANAGER": [], "UNIT_MANAGER": [], "REGIONAL_HEAD": [] },
    selectedFilterListDetails: {}//{ "SUB_CONNECTOR": "", "CONNECTOR": "", "SALES_EXECUTIVE": "", "TEAM_LEADER": "", "SALES_MANAGER": "", "UNIT_MANAGER": "", "REGIONAL_HEAD": "" }
  },
  reducers: {
    setLeadPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setLeadPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setLeadSearchParam: (state, action: PayloadAction<any>) => {
      state.searchParam = action.payload;
    },
    setPreLenderCase: (state, action: PayloadAction<string>) => {
      state.preLenderCase = action.payload;
    },
    setPostLenderCase: (state, action: PayloadAction<string>) => {
      state.postLenderCase = action.payload;
    },
    setActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    setIsShowFilter: (state, action: PayloadAction<boolean>) => {
      state.isShowFilter = action.payload;
    },
    setIsAssignToMe: (state, action: PayloadAction<boolean>) => {
      state.isAssignToMe = action.payload;
    },
    setConnectorId: (state, action: PayloadAction<any>) => {
      state.connectorId = (action.payload ? action.payload : undefined);
    },
    setLoanType: (state, action: PayloadAction<any>) => {
      state.loanType = (action.payload ? action.payload : undefined);
    },
    setLocation: (state, action: PayloadAction<any>) => {
      state.location = (action.payload ? action.payload : undefined);
    },
    setManagerHierarchy: (state, action: PayloadAction<any>) => {
      state.managerHierarchy = (action.payload ? action.payload : undefined);
    },
    setIsLenderDecisionErr: (state, action: PayloadAction<any>) => {
      state.isLenderDecisionErr = action.payload;
    },
    setRestFilterData: (state) => {
      state.filterListDetails = {};
      state.selectedFilterListDetails = {};
      state.connectorId = undefined;
      state.loanType = undefined;
      state.location = undefined;
      state.managerHierarchy = undefined;
      state.filterData = { "connectorId": state.connectorId, "loanType": state.loanType, "location": state.location, managerHierarchy: state.managerHierarchy }
    },
    setFilterData: (state, action: PayloadAction<any>) => {
      state.filterData = action.payload
    },
    setFilterListDetails: (state, action: PayloadAction<any>) => {
      state.filterListDetails[action.payload.key] = action.payload.value
    },
    setSelectedFilterListDetails: (state, action: PayloadAction<any>) => {
      state.selectedFilterListDetails[action.payload.key] = action.payload.value
    }
  }
});

// Action creators are generated for each case reducer function
export const { setLeadPageNo, setLeadPageSize, setLeadSearchParam, setPreLenderCase, setPostLenderCase, setActiveKey,
  setIsShowFilter, setIsAssignToMe, setConnectorId, setLoanType, setLocation, setManagerHierarchy, setIsLenderDecisionErr, setRestFilterData, setFilterData, setFilterListDetails, setSelectedFilterListDetails } = leadSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default leadSlice.reducer;