import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { notification } from "antd";
import parameters from "shared/parameters";
import ProductSteps from "./ProductSteps";
import AppLoading from "shared/components/AppLoading/AppLoading";
import { useDispatch } from "react-redux";
import { setAppColors } from "shared/redux/common-reducer";

const MobileRunTime = () => {
  const [openFlowablePath, setOpenFlowablePath] = useState(false);
  const [leadData, setLeadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitCalling, setSubmitCalling] = useState(false);
  const [isStepReadOnly, setIsStepReadOnly] = useState(false);
  const [submitPayload, setSubmitPayload] = useState({} as any);
  const [getTaskData, setGetTaskData] = useState({} as any);
  const [json, setJson] = useState({} as any);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const caseId = params.get("caseId");
  const stepKey = params.get("stepKey");
  const clientType = params.get("clientType");
  const dispatch = useDispatch();

  useEffect(() => {
    getTenantLogo();
    getCaseDetails(caseId);
    const detectEndOfScroll = () => {
      const scrollElement = document.documentElement;
      // debugger
      const scrollThreshold =
        scrollElement.scrollHeight - scrollElement.clientHeight - 15;
      if (
        scrollElement.scrollHeight - scrollElement.scrollTop ===
        scrollElement.clientHeight ||
        scrollThreshold <= scrollElement.scrollTop
      ) {
        callbackForMobile("SCROLL_END");
      } else if (scrollElement.scrollTop == 0) {
        callbackForMobile("SCROLL_TOP");
      }
    };
    window.addEventListener("scroll", detectEndOfScroll);
  }, []);

  const getTenantLogo = () => {
    API_SERVICE.getTenantDocumentDetails()
      .then(({ data }) => {
        let obj = {} as any;
        data?.payload?.tenantColorConfig?.map((item: any) => {
          obj[item.element] = item.hexCode;
        })
        const tmpObj = {
          appPrimaryColor: obj?.primary_color,
          appSecondaryColor: obj?.secondary_color,
          appSecondaryLightColor: obj?.secondary_color ? `${obj?.secondary_color}33` : "",
          appPrimaryLightColor: obj?.secondary_color ? `${obj?.secondary_color}33` : "",
        }
        dispatch(setAppColors(tmpObj));
      })
  };

  const getCaseDetails = (caseId: any) => {
    setLoading(true);
    callbackForMobile("LOADING_TRUE");
    API_SERVICE.getLeadsById(caseId)
      .then(({ data }) => {
        if (data?.payload) {
          setLeadData(data?.payload);
          handleGetTask(
            {
              processInstanceId: data?.payload?.processInstanceId,
              clientType: clientType,
            },
            data?.payload
          );
        }
      })
      .catch((e: any) => {
        setLoading(false);
        callbackForMobile("LOADING_FALSE");
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleGetTask = (payload: any, leadDataTmp: any = leadData) => {
    API_SERVICE.productGetNextTask(payload)
      .then(({ data }) => {
        setGetTaskData(data?.payload ?? {});
        fetchProductWorkflowById(leadDataTmp);
        const isRead =
          data?.payload?.productFormMetadata?.stepKey != stepKey ||
          data?.payload?.taskDefinitionKey != "case_form_task";
        setIsStepReadOnly(isRead);
        callbackForMobile(isRead ? "IS_EDIT_MODE_FALSE" : "IS_EDIT_MODE_TRUE");
      })
      .catch((e: any) => { });
  };

  const fetchProductWorkflowById = (leadData: any) => {
    API_SERVICE.fetchProductWorkflowById(leadData?.masterProductId)
      .then(({ data }) => {
        const stage = data?.payload?.productWorkflowConfig?.stages?.find(
          (stage: any) => stage?.key == "prospectStage"
        );
        const step = stage?.steps?.find((step: any) => step?.key == stepKey);
        setJson(step?.form?.definition ?? {});
        setOpenFlowablePath(true);
      })
      .catch((e: any) => {
        setLoading(false);
        callbackForMobile("LOADING_FALSE");
        notification.error({ message: API_SERVICE?.handleErrors(e) });
      })
      .finally(() => {
        callbackForMobile("LOADING_FALSE");
        setLoading(false);
      });
  };

  const callbackForMobile = (actionType: string) => {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ actionType: actionType })
      );
  };

  useEffect(() => {
    console.log("submitCalling: ", submitCalling);
    if (submitCalling) {
      console.log("loading: ", loading);
      setLoading(true);
      callbackForMobile("LOADING_TRUE");
      API_SERVICE.productSubmitTask(submitPayload)
        .then(() => {
          callbackForMobile("SUBMIT_TASK");
        })
        .catch((e: any) => {
          if (e?.response?.status == 409) {
            callbackForMobile("REFRESH_PAGE");
          } else {
            notification.error({ message: API_SERVICE?.handleErrors(e) });
          }
        })
        .finally(() => {
          callbackForMobile("LOADING_FALSE");
          setLoading(false);
        });
    }
  }, [submitCalling]);

  const submitTask = (payload: any) => {
    setSubmitPayload(payload);
    setSubmitCalling(true);
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    try {
      str = str?.replaceAll(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replaceAll(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replaceAll(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    } catch (error) {
      str = str?.replace(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replace(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replace(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    }
    return JSON.parse(str);
  };

  return (
    <div style={{ padding: "15px", minHeight: "150px" }}>
      {openFlowablePath && (
        <ProductSteps
          getTaskData={getTaskData}
          submitTask={submitTask}
          leadData={leadData}
          getJSONSchema={getJSONSchema}
          formJsonSchema={JSON.stringify(json)}
          isStepReadOnly={isStepReadOnly}
          currentSteps={stepKey}
          getNextTask={handleGetTask}
          setGetTaskData={setGetTaskData}
        />
      )}
      {loading && <AppLoading />}
    </div>
  );
};
export default MobileRunTime;
