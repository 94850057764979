import {
  Row,
  Select,
  Typography,
  Input,
  Col,
  Form,
  Collapse,
  InputNumber,
  DatePicker,
  Button,
  notification,
  Tooltip,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { DOUBLE_REGEX } from "shared/constants/AppConstants";
import { numberWithCommas } from "shared/services/Utility";
import action from "../../../../../assets/images/action.svg";
import shareIcon from "../../../../../assets/images/shareIcon.svg";
import {ReactComponent as IconApprove} from "../../../../../assets/images/apporveIcon.svg";
import "./LenderDecision.scss";
import LenderDisbursedMain from "../LenderDisbursed/LenderDisbursedMain";
import moment from "moment";
import type { RangePickerProps } from "antd/es/date-picker";
import DocumentStatus from "shared/components/Lead/RequiredDocuments/DocumentStatus";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { setIsLenderDecisionErr } from "shared/redux/lead-reducer";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Title, Text } = Typography;

const LenderDecision: React.FunctionComponent<any> = ({
  setIsLoading,
  selectedLenderList,
  lenderDecisionHandler,
  leadData,
  faircentStatus,
  getRedirectURLLink,
  loadingUpdate,
  getDownloadLetterForICICI,
  lenderDisbursedHandler,
  isShowDisbursed,
  setIsShowDisbursed,
}) => {
  const [selectedLenderData, setSelectedLenderData] = useState([] as any);
  const [rejectReasons, setRejectReasons] = useState([] as any);
  const [pendencyReasons, setPendencyReasons] = useState([] as any);
  const [withdrawnReasons, setWithdrawnReasons] = useState([] as any);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [selectedLenderName, setSelectedLenderName] = useState("");
  const [selectedLender, setSelectedLender] = useState({} as any);
  const [showDocumentStatus, setShowDocumentStatus] = useState(false);
  const [errorList, setErrorList] = useState([] as any);
  const [dicisionForm] = Form.useForm();
  const leadReducer:any = useSelector((state: RootState) => state.lead);
  const {appColors, isMobile}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const rejectedReasonList = enumValues.find(
        (item) => item.propertyName === "REJECTION_REASON"
      );
      const rejectedReasonListDisplay =
        rejectedReasonList.keyAndDisplayValueList?.filter((item: any) => {
          return (
            item?.key == "FRAUD_CASE" ||
            item?.key == "LOAN_SIZE_SMALL" ||
            item?.key == "LIMIT_EXHAUSTED" ||
            item?.key == "INCOMPLETE_DOCUMENTS" ||
            item?.key == "VERTICAL_NOT_SUPPORTED" ||
            item?.key == "CREDIT_SCORE_INSUFFICIENT" ||
            item?.key == "OUTSIDE_GEOGRAPHIC_JURISDICTION" ||
            item?.key == "DUPLICATE"
          );
        });
      setRejectReasons(rejectedReasonListDisplay);
      const pendencyReasonList = enumValues?.find(
        (item) => item?.propertyName === "PENDENCY_REASON"
      );
      setPendencyReasons(pendencyReasonList?.keyAndDisplayValueList);
      const withdrawnReasonList = enumValues?.find(
        (item) => item?.propertyName === "WITHDRAWN_REASON"
      );
      setWithdrawnReasons(withdrawnReasonList?.keyAndDisplayValueList);
    }
    let sanctionedAmount = 0;
    let lenderDecisionType = [] as any;
    const tmpAssignedLenders =
      leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails?.filter(
        (item) =>
          (item.status === "LOGGED" && item?.submissionType != "API") ||
          (item?.submissionType == "API" && item?.allDocumentUploaded) ||
          item.status == "PENDENCY" ||
          item.status === "SANCTIONED" ||
          item.status === "DISBURSED" ||
          item.status === "REJECTED" ||
          item.status === "WITHDRAWN"
      );
    tmpAssignedLenders?.map(
      (item, index) => (
        (item.leanderDecision = {
          selectedType: getSelectedType(item),
          sanctionedData: {
            interestRate: item.interestRate,
            loanAmount: item.sanctionedAmount,
            sanctionedDate: item?.sanctionedDate,
          },
          rejectedData: { rejectReason: item.rejectedReason },
          pendencyData: { [`comment${index}`]: item?.comments },
          withdrawnData: { [`comment${index}`]: item?.comments },
          interestRate: item.interestRate,
        }),
        lenderDecisionType.push({
          selectedType: getSelectedType(item),
        }),
        (sanctionedAmount += item.sanctionedAmount ?? 0)
      )
    );
    setSelectedLenderData(tmpAssignedLenders);

    const setData = {} as any;
    tmpAssignedLenders.map((lender: any, index: number) => {
      const selectedType =
        lender?.status == "DISBURSED"
          ? "Disbursed"
          : lender?.leanderDecision?.selectedType ?? undefined;
      setData[`selectedType${index}`] = selectedType;
      if (selectedType == 1) {
        const sanctionedData = {
          ...lender?.leanderDecision?.sanctionedData,
          sanctionedDate: lender?.leanderDecision?.sanctionedData
            ?.sanctionedDate
            ? moment(lender?.leanderDecision?.sanctionedData?.sanctionedDate)
            : "",
        };
        setData[`${index}`] = sanctionedData;
      } else if (selectedType == 2) {
        setData[`rejectReason${index}`] =
          lender?.leanderDecision?.rejectedData?.rejectReason ?? undefined;
      } else if (selectedType == 3) {
        setData[`comment${index}`] =
          lender?.leanderDecision?.withdrawnData[`comment${index}`] ??
          undefined;
      } else if (selectedType == 4) {
        setData[`comment${index}`] =
          lender?.leanderDecision?.pendencyData[`comment${index}`] ?? undefined;
      } else {
        const sanctionedData = {
          ...lender?.leanderDecision?.sanctionedData,
          disbursedAmount: calcTotalDisbursedAmount(
            lender?.disbursalLenderInfoDetails
          ),
        };
        setData[`${index}`] = sanctionedData;
      }
    });

    const loanAmount = leadData.caseDetails?.loanDetails?.loanAmount || 0;
    dicisionForm.setFieldsValue({
      ...setData,
      approvedAmount: sanctionedAmount,
      requestedAmount: loanAmount,
    });
    dispatch(setIsLenderDecisionErr(false));
  }, [leadReducer?.isLenderDecisionErr ||leadData]);
  
  const getSelectedType = (item) => {
    if (
      faircentStatus?.toUpperCase() === "SANCTIONED" ||
      item.status == "SANCTIONED"
    ) {
      return 1;
    } else if (faircentStatus === "Rejected" || item.status == "REJECTED") {
      return 2;
    } else if (item.status == "WITHDRAWN") {
      return 3;
    } else if (item.status == "PENDENCY") {
      return 4;
    }
    return null;
  };

  const onChangeHandler = (ev: any, i: number) => {
    let lenders = [...selectedLenderData];
    lenders[i].leanderDecision["selectedType"] = ev;
    setSelectedLenderData(lenders);
    if (ev == 3 || ev == 4) {
      dicisionForm.setFieldsValue({
        [`comment${i}`]: "",
      });
    }
    const setData = {} as any;
    if (ev == 1) {
      const sanctionedData = {
        ...lenders[i]?.leanderDecision?.sanctionedData,
        sanctionedDate: lenders[i]?.leanderDecision?.sanctionedData
          ?.sanctionedDate
          ? moment(lenders[i]?.leanderDecision?.sanctionedData?.sanctionedDate)
          : "",
      };
      setData[`${i}`] = sanctionedData;
    }else if(ev == 2){
      setData[`rejectReason${i}`] = lenders[i]?.leanderDecision?.rejectedData?.rejectReason ?? ""
    }
    dicisionForm.setFieldsValue({ ...setData });

    let field = dicisionForm.getFieldValue(`${i}`);
    if (ev == 2) {
      let total = dicisionForm.getFieldValue("approvedAmount");
      if (field) {
        total = total - field.loanAmount;
      }else{
        total = total - lenders[i]?.sanctionedAmount
      }
      // if (ev != 2) {
      //   total = total + lenders[i]?.leanderDecision?.sanctionedData?.
      // }
      // field.interestRate = "";
      // field.loanAmount = "";
      // field.sanctionedDate = "";
      dicisionForm.setFieldsValue({ names: field, approvedAmount: total });
    }
    if (ev !== 2) {
      let total = 0;
      lenders?.forEach(item => {
        if (item?.leanderDecision?.selectedType != 2) {
          total += item?.sanctionedAmount
        }
      });
      dicisionForm.setFieldsValue({ names: field, approvedAmount: total });
    }
  };

  const lenderSanctionedHandler = (lenderID: string, i, lenderName = selectedLenderName) => {
    const selectedlenders = [...selectedLenderData];
    const lenders = selectedlenders.filter(
      (item: any) => item?.lenderInfoId == lenderID
    );
    lenders?.map((item: any) => {
      const tmpObj = {
        selectedType: item?.leanderDecision?.selectedType,
        sanctionedData: {
          interestRate: item?.leanderDecision?.sanctionedData?.interestRate,
          loanAmount: item?.leanderDecision?.sanctionedData?.loanAmount,
          sanctionedDate: item?.leanderDecision?.sanctionedData?.sanctionedDate,
        },
        rejectedData: {
          rejectReason0:
            item?.leanderDecision?.rejectedData?.[`rejectReason${i}`],
        },
        pendencyData: {
          comment0: item?.leanderDecision?.pendencyData?.[`comment${i}`],
        },
        withdrawnData: {
          comment0: item?.leanderDecision?.withdrawnData?.[`comment${i}`],
        },
        interestRate: item?.leanderDecision?.interestRate,
      };
      item.leanderDecision = tmpObj;
    });
    const data = {
      lenders,
      comment: "",
      requestedAmount: dicisionForm.getFieldValue("requestedAmount") ?? 0,
      approvedAmount: dicisionForm.getFieldValue("approvedAmount") ?? 0,
    };
    const checkDisbursedLender = lenders.find(
      (item: any) => item.lenderName == lenderName
    );
    if (checkDisbursedLender?.status !== "DISBURSED") {
      lenderDecisionHandler(data, (res) => {
        if(res?.success) {
          setIsShowDisbursed(true);
        }
      });
    } else {
      setIsShowDisbursed(true);
    }
  };

  const decisionHandler = (event: any, i: number, customName?: string) => {
    const lenders = [...selectedLenderData];
    let fieldName = event?.target?.name ? event.target.name : customName;
    let fieldValue = event?.target ? event.target.value : event;
    if (lenders[i].leanderDecision.selectedType === 1) {
      setErrorList(errorList.filter((item) => item != `${fieldName}${i}`));
      lenders[i].leanderDecision.sanctionedData[fieldName] = fieldValue;
      let amount = lenders.map((item) =>
        Number(item.leanderDecision.sanctionedData.loanAmount)
      );
      // if(leadData?.caseDetails?.loanDetails?.loanAmount > fieldValue){
      const total = amount.reduce((a, b) => a + (isNaN(b) ? 0 : b), 0);
      dicisionForm.setFieldsValue({ approvedAmount: total });
      //} else {
      //     notification.error({ message: "Total Sanction Amount cannot be greater than Loan Amount" });
      // }
    }
    if (lenders[i].leanderDecision.selectedType === 2) {
      lenders[i].leanderDecision.rejectedData[fieldName] = fieldValue;
    }
    if (lenders[i].leanderDecision.selectedType === 3) {
      lenders[i].leanderDecision.withdrawnData[fieldName] = fieldValue;
    }
    if (lenders[i].leanderDecision.selectedType === 4) {
      lenders[i].leanderDecision.pendencyData[fieldName] = fieldValue;
    }
    setSelectedLenderData(lenders);
  };

  const renderDisbursed = () => (
    <LenderDisbursedMain
      setIsLoading={setIsLoading}
      leadData={leadData}
      selectedLenderList={selectedLenderList}
      lenderDisbursedHandler={lenderDisbursedHandler}
      getRedirectURLLink={getRedirectURLLink}
      getDownloadLetterForICICI={getDownloadLetterForICICI}
      loadingUpdate={loadingUpdate}
      selectedLenderName={selectedLenderName}
    />
  );

  const calcTotalDisbursedAmount = (disbursalLenderInfoDetails) => {
    let amountDisbursed = disbursalLenderInfoDetails?.map((item) =>
      Number(item.lenderAmount.disbursedAmount)
    );
    // let amountInsurance = disbursalLenderInfoDetails?.map((item) =>
    //   Number(item.lenderAmount.insuranceAmount)
    // );
    const totalDisbursed = amountDisbursed?.reduce((a, b) => a + b, 0);
    // const totalInsurance = amountInsurance?.reduce((a, b) => a + b, 0);
    const total = totalDisbursed;
    return isNaN(total) ? 0 : total;
  };

  const renderRejectedView = (item: any, i: any) => {
    const isError = errorList.includes(`rejectReason${i}`);
    return (
      <Col span={10}>
        <span className="field-lable"><span className="star">*</span> Rejection Reason</span>
        <div className={`rejected-wrapper ${isError?"field-error":""}`}>
          <Form.Item
            name={`rejectReason${i}`}
            // initialValue={item.rejectedReason}
          >
            <Select
              className="custom-select"
              showSearch
              placeholder="Select Reason"
              optionFilterProp="children"
              onChange={(ev) => {
                setErrorList(errorList.filter((item) => item != `rejectReason${i}`));
                decisionHandler(ev, i, `rejectReason${i}`);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {rejectReasons?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Col>
    );
  };

  const renderWithdraw = (item: any, i: any) => {
    const isError = errorList.includes(`withdrawnReason${i}`);
    return (
      <Col span={10}>
        <span className="field-lable"><span className="star">*</span> Withdrawn Reason</span>
        <div className={`rejected-wrapper ${isError?"field-error":""}`}>
          <Form.Item
            name={`comment${i}`}
            // initialValue={item.rejectedReason}
          >
            <Select
              className="custom-select"
              showSearch
              placeholder="Select Reason"
              optionFilterProp="children"
              onChange={(ev) => {
                setErrorList(errorList.filter((item) => item != `withdrawnReason${i}`));
                decisionHandler(ev, i, `comment${i}`);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {withdrawnReasons?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Col>
    );
  };

  const renderPendency = (item: any, i: any) => {
    const isError = errorList.includes(`pendencyReason${i}`);
    return (
      <Col span={10}>
        <span className="field-lable"><span className="star">*</span> Pendency Reason</span>
        <div className={`rejected-wrapper ${isError?"field-error":""}`}>
          <Form.Item
            name={`comment${i}`}
            // initialValue={item.rejectedReason}
          >
            <Select
              className="custom-select"
              showSearch
              placeholder="Select Reason"
              optionFilterProp="children"
              onChange={(ev) => {
                setErrorList(errorList.filter((item) => item != `pendencyReason${i}`));
                decisionHandler(ev, i, `comment${i}`);
              }}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {pendencyReasons?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Col>
    );
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return  moment().add(-100, 'years') >= current || current && current > moment().endOf("day");
  };

  const renderSanctionedView = (item: any, i: any) => {
    const isErrorRate = errorList.includes(`interestRate${i}`);
    const isErrorAmount = errorList.includes(`loanAmount${i}`);
    return (
      <Form.List name={i}>
        {(fields) => (
          <>
            <Col span={5} xs={10} xl={5}>
              <Form.Item
                label={<span className="field-lable"><span className="star">*</span> Interest rate %</span>}
                name="interestRate"
                className={`decision-item ${isErrorRate?"field-error":""}`}
                rules={[
                  {
                    pattern: DOUBLE_REGEX,
                    message: "Please enter valid interest rate %",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  name="interestRate"
                  // defaultValue={item?.interestRate}
                  disabled={
                    item.status == "DISBURSED" &&
                    (item?.leanderDecision?.interestRate ?? 0) > 0
                  }
                  onChange={(ev) => decisionHandler(ev, i)}
                  placeholder="Interest rate %"
                />
              </Form.Item>
            </Col>
            {isMobile ? <Col span={4}></Col> : '' }
            <Col span={5} xs={10} xl={5} style={{ fontSize: "12px" }}>
              <Form.Item
                name="loanAmount"
                className={`decision-item ${isErrorAmount?"field-error":""}`}
                label={
                  item?.status == "DISBURSED" ? "Sanctioned Amount" : <span className="field-lable"><span className="star">*</span> Amount</span>
                }
                rules={[
                  () => ({
                    validator(_, value) {
                      // if (value == 0 || isNaN(value)) {
                      //   return Promise.reject("Please enter valid amount");
                      // }
                      const availbleAmount =
                        (leadData?.caseDetails?.loanDetails?.loanAmount ?? 0) -
                        (dicisionForm.getFieldValue("approvedAmount") - value);
                      if (value > availbleAmount) {
                        return Promise.reject(
                          `Please enter less amount than ${availbleAmount}`
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  // name="loanAmount"
                  disabled={
                    item.status == "DISBURSED" &&
                    (item?.sanctionedAmount ?? 0) > 0
                  }
                  formatter={(value) => numberWithCommas(value)}
                  onChange={(ev) => decisionHandler(ev, i, "loanAmount")}
                  placeholder="Amount"
                />
              </Form.Item>
            </Col>
            <Col span={5} xs={10} xl={5}>
              {item?.status == "DISBURSED" ? (
                <Form.Item
                  className="decision-item"
                  name="disbursedAmount"
                  label="Disbursed Amount"
                >
                  <InputNumber
                    className="custom-input-number"
                    // name="loanAmount"
                    disabled={item.status == "DISBURSED"}
                    formatter={(value) => numberWithCommas(value)}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="sanctionedDate"
                  className="decision-item"
                  label="Sanctioned Date"
                >
                  <DatePicker
                    // format="YYYY-MM-DD"
                    className="custom-input"
                    placeholder="Select Date "
                    disabledDate={disabledDate}
                    onBlur={(ev) =>
                      decisionHandler(ev?.target?.value, i, "sanctionedDate")
                    }
                  />
                </Form.Item>
              )}
            </Col>
          </>
        )}
      </Form.List>
    );
  };

  const onActionClick = (item: any, i: number) => {
    setSelectedLenderName(item?.lenderName);
    const leanderDecision = item?.leanderDecision;
    let msgError = "";
    if (leanderDecision.selectedType === 1) {
      const sanctionedData = leanderDecision?.sanctionedData;
      const msg = `Please enter ${item?.lenderName}`;
      const loanAmount = sanctionedData?.loanAmount;
      if (sanctionedData?.interestRate == "" && loanAmount == 0) {
        msgError = `${msg} Interest rate and Amount`;
        setErrorList([...errorList, `interestRate${i}`, `loanAmount${i}`]);
      } else if (sanctionedData?.interestRate == "") {
        msgError = `${msg} Interest rate`;
        setErrorList([...errorList, `interestRate${i}`]);
      } else if (loanAmount == 0 || loanAmount == null) {
        msgError = `${msg} Amount`;
        setErrorList([...errorList, `loanAmount${i}`]);
      } else if (loanAmount > 0) {
        const availbleAmount =
          (leadData?.caseDetails?.loanDetails?.loanAmount ?? 0) -
          (dicisionForm.getFieldValue("approvedAmount") - loanAmount);
        if (loanAmount > availbleAmount) {
          msgError = `Please enter less amount than ${availbleAmount} for ${item?.lenderName}`;
          setErrorList([...errorList, `loanAmount${i}`]);
        }
      }
    } else if (leanderDecision.selectedType === 2) {
      const rejectedData = leanderDecision?.rejectedData;
      if (
        (rejectedData[`rejectReason${i}`] ?? "") == "" &&
        (rejectedData?.rejectReason ?? "") == ""
      ) {
        msgError = `Please select ${item?.lenderName} Rejection Reason`;
        setErrorList([...errorList, `rejectReason${i}`]);
      }
    } else if (leanderDecision.selectedType === 3) {
      if ((leanderDecision?.withdrawnData[`comment${i}`] ?? "") == "") {
        msgError = `Please enter ${item?.lenderName} Withdrawn Reason`;
        setErrorList([...errorList, `withdrawnReason${i}`]);
      }
    } else if (leanderDecision.selectedType === 4) {
      if ((leanderDecision?.pendencyData[`comment${i}`] ?? "") == "") {
        msgError = `Please enter ${item?.lenderName} Pendency Reason`;
        setErrorList([...errorList, `pendencyReason${i}`]);
      }
    }
    if (msgError == "") {
      lenderSanctionedHandler(item?.lenderInfoId, i, item?.lenderName);
    } else {
      notification.error({ message: msgError });
    }
  };
  const isLenderLogged = (findLender) => {
    const isDocUploaded =
    findLender?.submissionType != "API" ||
    (findLender?.submissionType == "API" &&
      findLender?.allDocumentUploaded) ||
    ["REJECTED", "WITHDRAWN", "PENDENCY"].includes(findLender?.status);
  const isLogged =
    isDocUploaded &&
    [
      "LOGGED",
      "SANCTIONED",
      "DISBURSED"
    ].includes(findLender?.status)
   return isLogged;
  }

  const renderRedirect = (item: any) => (
    <>
      {item?.submissionType?.includes("API") &&
        (item?.lenderApplicationId?.length ?? 0) > 0 && (
          <span className="flex" style={{ display: "flex" }}>
            {(["BU9709497975", "BU3620294738"].includes(item.lenderInfoId) ||
              item?.lenderName?.toLowerCase()?.includes("fibe") ||
              item?.lenderName?.toLowerCase()?.includes("moneywide") ||
              item?.lenderName?.toLowerCase()?.includes("cashe") ||
              item?.lenderName?.toLowerCase()?.includes("kreditbee") ||
              item?.lenderName.toLowerCase().includes("nira finance") ||
              item?.lenderName?.toLowerCase()?.includes("abcfl") ||
              item?.lenderName?.toLowerCase()?.includes("abcl") ||
              item?.lenderName?.toLowerCase()?.includes("prefr") ||
              item?.lenderName?.toLowerCase()?.includes("incred") ||
              item?.lenderName?.toLowerCase()?.includes("credilio")) && isLenderLogged(item) && (
              <>
                <a
                  onClick={() =>
                    getRedirectURLLink(
                      item?.lenderInfoId,
                      leadData?.loanProductId,
                      item?.lenderApplicationId
                    )
                  }
                  className="small"
                  style={{ fontSize: "12px" }}
                >
                  {`Lender Link`}
                </a>
                <Button
                  size="small"
                  style={{ margin: "2px", border: "none" }}
                  onClick={() =>
                    getRedirectURLLink(
                      item?.lenderInfoId,
                      leadData?.loanProductId,
                      item?.lenderApplicationId,
                      true
                    )
                  }
                  icon={
                    <img
                      src={shareIcon}
                      alt=""
                      width={18}
                      height={18}
                      style={{ marginTop: "-4px" }}
                    />
                  }
                />
              </>
            )}
          </span>
        )}
    </>
  );

  const renderDocumentStatus = (lender) => {
    const total = lender?.documentProgressDetails?.totalNumberOfDocs ?? 0;
    const submitted =
      lender?.documentProgressDetails?.documentsSuccessfullySubmitted ?? 0;
    const failed = lender?.documentProgressDetails?.failure ?? 0;
    return (
      <>
        {total > 0 && (submitted > 0 || failed > 0) && (
          <span
            style={{ cursor: "pointer", fontSize: "11px" }}
            onClick={() => {
              setDocumentDetails(
                lender?.documentProgressDetails?.submissionDetails
              );
              setSelectedLender(lender);
              setShowDocumentStatus(true);
            }}
          >
            {`Document Status: ${submitted}/${total}`}
          </span>
        )}
      </>
    );
  };

  const renderLenderDecision = () => (
    <div>
      {showDocumentStatus && (
        <DocumentStatus
          showDocumentStatus={showDocumentStatus}
          setShowDocumentStatus={setShowDocumentStatus}
          lenderInfoId={selectedLender?.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          documentDetails={documentDetails}
        />
      )}
      <Form
        form={dicisionForm}
        // onFinish={lenderSanctionedHandler}
        layout="vertical"
      >
        <div className="disbursal-main-div">
          <div className="" style={{ padding: "10px", rowGap: "0px" }}>
            <Row gutter={[8, 8]}>
              <Col span={5}>
                <span style={{ fontSize: "14px", marginTop: "7px" }}>
                  Request details:
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="decision-item"
                  label="Total Tentative Loan Amount"
                >
                  <InputNumber
                    readOnly
                    className="custom-input-number"
                    name="tentativeLoanAmount"
                    value={leadData?.caseDetails?.loanDetails?.loanAmount || 0}
                    formatter={(value) => numberWithCommas(value)}
                    placeholder="Total Tentative Loan Amount"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="approvedAmount"
                  className="decision-item"
                  label="Total Sanctioned Loan Amount"
                >
                  <InputNumber
                    className="custom-input-number"
                    name="approvedAmount"
                    readOnly
                    formatter={(value) => numberWithCommas(value)}
                    //placeholder="Approved Amount"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="leadDisburse-Detail-scroll"
          style={{ maxHeight: "350px" }}
        >
          <ul style={{ listStyle: "none", padding: "0px" }}>
            {selectedLenderData?.map((item: any, i: any) => {
              let consentDetails: any = {};
              if ((item?.consentDetails?.length ?? 0) > 0) {
                consentDetails = JSON.parse(item?.consentDetails ?? {});
              }
              return (
                <li key={i}>
                  <div className="lender-detail1-main-div">
                    <Row gutter={[6, 6]} style={{ marginLeft: " ", flex: "1" }}>
                      <Col span={4}>
                        <span style={{ fontSize: "12px", marginTop: "7px" }}>
                          {item.lenderName}
                        </span>
                        <br />
                        <Space>
                          {Object.keys(consentDetails).length > 0 && (
                            <Tooltip
                              title={`Time stemp: ${consentDetails?.timeStamp}`}
                            >
                              <span  className="lender-additional-details" style={{"--appSecondary": appColors?.appSecondaryColor ?? ""} as any}>
                                <IconApprove /> {"Consent"}
                              </span>
                            </Tooltip>
                          )}
                          {item?.submissionType?.includes("API") && (
                            <span  className="lender-additional-details" style={{"--appSecondary": appColors?.appSecondaryColor ?? ""} as any}>
                              <IconApprove /> {"API"}
                            </span>
                          )}
                        </Space>
                        <div>
                          {item?.submissionType?.includes("API") &&
                            (item?.lenderApplicationId?.length ?? 0) > 0 && (
                              <div className="lenderId-div">
                                <span
                                  style={{ fontSize: "9px" }}
                                >{`Loan ID: ${item.lenderApplicationId}`}</span>
                              </div>
                            )}
                            {item?.submissionType?.includes("API") && item?.lenderName?.toLowerCase()?.includes("incred") &&
                            (item?.additionalFields?.length ?? 0) > 0 && (
                              <div className="lenderId-div">
                                <span
                                  style={{ fontSize: "9px" }}
                                >{`Customer ID: ${JSON.parse(item.additionalFields)?.customerId}`}</span>
                              </div>
                            )}
                          {renderRedirect(item)}
                          {renderDocumentStatus(item)}
                        </div>
                      </Col>
                      <Col span={5} xs={10} xl={5}>
                        <div>
                          <Form.Item
                            className="decision-item"
                            label="Loan Status"
                            name={`selectedType${i}`}
                          >
                            <Select
                              className="custom-select"
                              onSelect={(ev: any) => {
                                onChangeHandler(ev, i);
                              }}
                              disabled={item?.status == "DISBURSED"}
                            >
                              <Select.Option value={1}>
                                Sanctioned
                              </Select.Option>
                              <Select.Option value={2}>Rejected</Select.Option>
                              <Select.Option value={3}>WithDrawn</Select.Option>
                              <Select.Option value={4}>Pendency</Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      {item.leanderDecision.selectedType === 1 ||
                      item?.status == "DISBURSED"
                        ? renderSanctionedView(item, i)
                        : ""}
                      {item.leanderDecision.selectedType === 2 &&
                        renderRejectedView(item, i)}
                      {item.leanderDecision.selectedType === 3 &&
                        renderWithdraw(item, i)}
                      {item.leanderDecision.selectedType === 4 &&
                        renderPendency(item, i)}
                    </Row>
                    {(item?.leanderDecision?.selectedType ?? 0) > 0 ||
                    item?.status == "DISBURSED" ? (
                      <>
                        <div>
                          <Text className="fields-text">Action</Text>
                          <Form.Item name={`action${i}`}>
                            <Button
                              type="text"
                              htmlType="submit"
                              style={{ color: "blue", border: "none" }}
                              icon={<img src={action} alt="" />}
                              onClick={() => onActionClick(item, i)}
                            />
                          </Form.Item>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Form>
    </div>
  );

  return isShowDisbursed ? renderDisbursed() : renderLenderDecision();
};

export default LenderDecision;
