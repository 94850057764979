import React, { useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @ts-ignore
import { Form } from "react-formio";
import API_SERVICE from "shared/services/api-service";
import { Button, Card, Modal, notification, Spin } from "antd";
import RequiredDocumentFlowable from "../RequiredDocuments/RequiredDocumentFlowable";
import "./AdditionalFieldsFlowable.scss";
import OtpScreen from "./FlowableScreens/ICICIOtpFlowableScreen";
import OfferAcceptanceScreen from "./FlowableScreens/ICICIOfferAcceptance";
// import ShareButton from "react-share/lib/ShareButton";
import { useHistory } from "react-router-dom";
import jsonFile from "./user_applicant_task.json";
import moment from "moment";
import parameters from "shared/parameters";
//aditya birla screen
import ABCLBreResults from "./FlowableScreens/ABCLScreens/ABCLBreResult";
import ABCLDocuments from "./FlowableScreens/ABCLScreens/ABCLDocument";
import ABCLOfferDetails from "./FlowableScreens/ABCLScreens/ABCLOfferDetails";
import ABCLBreNoOffer from "./FlowableScreens/ABCLScreens/ABCLBreNoOffer";
import CreditSaisonRejected from "./FlowableScreens/CreditSaisoncreens/CreditSaisonRejected";
import FlexiPincodeAvailibility from "./FlexiPincodeAvailibility";
import InCredLoadingScreen from "./FlowableScreens/InCredScreens/InCredLoadingScreen";
import InCredRetryScreen from "./FlowableScreens/InCredScreens/InCredRetryScreen";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const AddtionalFieldsFlowable: React.FunctionComponent<any> = ({
  jsonSchema,
  customUI,
  taskDetails,
  currLenderId,
  currLoanProductId,
  currLeadId,
  setOpenFlowablePath,
  openFlowablePath,
  leadData,
  setTaskDetails,
  setCustomUI,
  setShowAdditionalFields,
  callBack,
  setCallbackReqDoc,
  callbackAdditionalField,
  selectedLander,
  lenderLoginHandler,
  getNextTaskPayload,
  getLeadById,
  skippedLoginStatus,
  setIsLastTask,
  isLastTask,
}) => {
  const [formData, setFormData] = useState({} as any);
  const [loading, setloading] = useState(false);
  const [showFlexiReason, setShowFlexiReason] = useState("" as any);
  const [isCustomUI, setIsCustomUI] = useState(customUI);
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [isOfferAcceptanceScreen, setIsOfferAcceptanceScreen] = useState(false);
  const [isDocumentUploadResponse, setIsDocumentUploadResponse] =
    useState(null);
  const [messageICICI, setMessageICICI] = useState("");
  // const [isLastTask, setIsLastTask] = useState(false);
  // const [isCustomUI, setIsCustomUI] = useState(true);
  const [failedCount, setFailedCount] = useState(0);
  //Aditya birla
  const [ABCLBreResultScreen, setABCLBreResultScreen] = useState(false);
  const [ABCLBreOfferDetailsScreen, setABCLBreOfferDetailsScreen] =
    useState(false);
  const [ABCLBreDocumentScreen, setABCLBreDocumentScreen] = useState(false);
  const [ABCLBreNoOfferScreen, setABCLBreNoOfferScreen] = useState(false);
  const [docIframe, setDocIframe] = useState(false);
  const [CreditSaisonScreen, setCreditSaisonScreen] = useState(false);
  const [CreditSaisonScreenMessage, setCreditSaisonScreenMessage] = useState();
  const [displayInCredRetryScreen, setDisplayInCredRetryScreen] = useState(false);
  const [displayInCredLoadingScreen, setDisplayInCredLoadingScreen] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const [closeConfirmation, setCloseConfirmation] = useState(false);

  const history = useHistory();
  const applicantTypeData = () => {
    let applicantType = "";
    if (
      leadData?.applicationTypeKey == "INDIVIDUAL" &&
      leadData?.applicationSubTypeKey == "SALARIED"
    ) {
      applicantType = "SAL";
    } else if (
      leadData?.applicationTypeKey == "INDIVIDUAL" &&
      leadData?.applicationSubTypeKey == "SELF_EMPLOYED"
    ) {
      applicantType = "SEP";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "PARTNERSHIP"
    ) {
      applicantType = "P1";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "PVT"
    ) {
      applicantType = "PVT";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "LLP"
    ) {
      applicantType = "LLP";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "HUF"
    ) {
      applicantType = "HUF";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "SOLE_PROPRIETOR"
    ) {
      applicantType = "SP";
    } else if (
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey == "LTD"
    ) {
      applicantType = "PUB";
    } else {
      applicantType = "";
    }
    return applicantType;
  };

  const iciciLoanProduct = () => {
    let loanProduct = "";
    if (leadData.loanType === "Home loan") {
      loanProduct = "HL";
    } else if (leadData.loanType === "Loan Against Property") {
      loanProduct = "LAP";
    } else {
      loanProduct = "";
    }
    return loanProduct;
  };
  const genderMap = {
    M: "Male",
    F: "Female",
    O: "Other",
  };

  const natureOfBusiness = {
    SERVICE_PROVIDER: "Service",
    MANUFACTURER: "Manufacturer",
    TRADER: "Retailer" || "Wholesaler",
  };
  const natureOfBusinessABCL = {
    SERVICE_PROVIDER: "Services",
    MANUFACTURER: "Manufacturing",
    TRADER: "Trading",
  };
  const lead_status = {
    LTD: "Public Limited",
    PVT: "Private Limited",
    PARTNERSHIP: "Partnership",
    LLP: "Limited Liability Partnership (LLP)",
    SOLE_PROPRIETOR: "Proprietorship",
  };

  useEffect(() => {
    console.log("Lead data", leadData);
    if (selectedLander?.lenderName?.toLowerCase()?.includes("icici")) {
      setIcici();
      if (Object.keys(getNextTaskPayload).length !== 0) {
        if (
          getNextTaskPayload?.taskDefinitionKey ===
          "user_task_customer_otp_verification"
        ) {
          console.log("OTP Task");
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
          setIsOtpScreen(true);
        } else if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_offer_acceptance"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(true);
          setFinalJSONSchema(
            getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
          );
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setFinalJSONSchema(
            getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
          );
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        }
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
        ) {
          const data = getJSONSchema(getNextTaskPayload?.jsonSchema);
          setIsCustomUI(getNextTaskPayload?.customUI);
          setIsLastTask(getNextTaskPayload?.lastStep);
          setDocumentList(data);
          setFormData({});
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        }
      }
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("flexi")) {
      setFlexi();
      if (Object.keys(getNextTaskPayload).length !== 0) {
        setFinalJSONSchema(
          getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
        );
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
        ) {
          const data = getJSONSchema(getNextTaskPayload?.jsonSchema);
          setIsCustomUI(!getNextTaskPayload?.customUI);
          setIsLastTask(getNextTaskPayload?.lastStep);
          setDocumentList(data);
          setFormData({});
          }
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
      }
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
      setKotak();
      console.log("getNextTaskPayload", getNextTaskPayload);
      if (Object.keys(getNextTaskPayload).length !== 0) {
        setFinalJSONSchema(
          getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
        );
        setTaskDetails({
          taskName: getNextTaskPayload?.taskName,
          taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
          taskId: getNextTaskPayload?.taskId,
        });
        setIsOfferJSON(false);
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
        ) {
          setIsCustomUI(!getNextTaskPayload?.customUI);
          setIsLastTask(getNextTaskPayload?.lastStep);
          const uploadDocument = getJSONSchema(getNextTaskPayload?.jsonSchema);
          if (leadData?.loanType.toLowerCase().includes("home")) {
            setDocumentList(uploadDocument.HL);
          } else if (leadData?.loanType.toLowerCase().includes("business")) {
            setDocumentList(uploadDocument.BL);
          } else {
            setDocumentList(uploadDocument.LAP);
          }

          console.log(getNextTaskPayload?.customUI);
          setFormData({});
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        }
      }
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("fibe") &&
      leadData?.loanType == "Buy Now Pay Later"
    ) {
      setBNPL();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("moneywide")
    ) {
      setMoneyWide();
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("cashe")) {
      setCashE();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("kreditbee")
    ) {
      setKreditBee();
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("hdfc")) {
      setHDFC();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
      selectedLander?.lenderName.toLowerCase().includes("abcl")
    ) {
      setABCL();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("nira finance")
    ) {
      setNiraFinance();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("credit saison")
    ) {
      setCreditSaison();
    } else if (
      selectedLander?.lenderName?.toLowerCase()?.includes("bajaj housing finance")
    ) {
      setBajajHousingFinance();
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("prefr")) {
      setPrefr();
    } else if (selectedLander?.lenderName?.toLowerCase()?.includes("incred")) {
      setInCred();
    }
  }, [leadData, getNextTaskPayload]);

  const [documentList, setDocumentList] = useState([]);

  const [finalJSONSchema, setFinalJSONSchema] = useState(jsonSchema);
  const [responseJSONSchema, setResponseJSONSchema] = useState([
    {
      referenceId: "",
      prptyId: "",
      exstngLoanSeq: "",
      expectedLoanAmt: "350000",
      expectedTenure: "147",
      msg: "Eligible for Product",
      status: "Y",
      maxLoanAmt: "3238000",
      minLoanAmt: "300000.0",
      maxTenure: "180",
      minTenure: "12.0",
      emi: "3958",
      rate: "9.1",
      feesEdited: "",
      processingFee: "8260",
      editedProcessingFee: "",
      initialFee: "2183.0",
      balanceFee: "0.0",
      editedInitialFee: "",
      editedBalanceFee: "",
      pfcgst: "630",
      pfsgst: "630",
      pfgst: "0.0",
      adminCharges: "1032.5",
      admcgst: "78.75",
      admsgst: "875.0",
      admgst: "78.75",
      cersaiCharges: "59.0",
      cersaicgst: "4.5",
      cersaisgst: "4.5",
      cersaigst: "50.0",
      cibilCharges: "59.0",
      cibilcgst: "4.5",
      cibilsgst: "4.5",
      cibilgst: "50.0",
      loginpfsgst: "158",
      loginpfcgst: "158",
      balancepfsgst: "0",
      balancepfcgst: "0",
      topuploginpf: "",
      topupbalancepf: "",
      mclr: "6.5",
      ltv: "90%",
      schemeCode: null,
      schemeDesc: null,
      spread: "2.6",
      prePayment: "2%",
      loanType: null,
      adminChargesGst: null,
      cersaiCharGst: null,
      loanTypeId: null,
      letterType: "R",
      cibilExcpMsg: null,
      overAllStatus: "Approve",
      promotioncode: "",
      promotionDesc: "",
      addlnTopup: "N",
      addlnTopupAmt: "0",
    },
  ]) as any;
  const [isOfferJSON, setIsOfferJSON] = useState(false);
  const [documentDetails, setDocumentDetails] = useState([{}]);

  console.log("Final JSON Schema : ", finalJSONSchema);
  const setIcici = () => {
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
    let panFirstName = leadData?.contactDetails?.firstName
      ?.trim()
      .split(" ")
      ?.at(0);
    const iciciPreField = {
      uniqueRefNo: leadData?.caseDetails?.caseId,
      applicantReq: {
        source: "",
        loanId: "",
        status: "",
        userId: "",
        channel: "",
        loanVer: "10",
        tmpltVer: "01",
        applicants: [
          {
            applicantType: "A",
            // emplymntType:"OTHER" ? leadData?.applicationSubType=="Salaried" : "SAL" ? leadData?.applicationType=="Individual" && leadData?.applicationSubType=="Salaried" : "SEP" ? leadData?.applicationType=="Individual" && leadData?.applicationSubType=="Self-Employed" : "" ,
            emplymntType:
              leadData?.applicationTypeKey == "INDIVIDUAL" &&
              leadData?.applicationSubTypeKey == "SALARIED"
                ? "SAL"
                : leadData?.applicationTypeKey == "INDIVIDUAL" &&
                  leadData?.applicationSubTypeKey == "SELF_EMPLOYED"
                ? "SENP"
                : leadData?.applicationTypeKey == "BUSINESS"
                ? "OTHER"
                : "",
            vkyc: "NA",
            isPOA: "N",
            noPanSelcted: false,
            pan_NA: "Y",
            panFirstName: panFirstName,
            panLastName: panLastName?.at(-1).toString(),
            workDtls: [
              {
                netSalary:
                  leadData?.applicationTypeKey == "INDIVIDUAL" &&
                  leadData?.applicationSubTypeKey == "SALARIED"
                    ? leadData?.caseDetails?.loanDetails?.monthlyInHandSalary
                    : "",
              },
            ],
            custInfo: {
              srcOfValidation: "C360",
              gender:
                leadData?.contactDetails?.gender === "O"
                  ? "TG"
                  : leadData?.contactDetails?.gender,
              last_name: panLastName?.at(-1).toString(),
              first_name: panFirstName,
              panNo: leadData?.contactDetails?.pan,
              date_of_birth: leadData?.contactDetails?.dateOfBirth
                ? new Date(leadData?.contactDetails?.dateOfBirth.slice(0, 10))
                : "",
              contact_info: {
                primary_phone: leadData?.contactDetails?.mobileNumber,
                personal_email: leadData?.contactDetails?.emailId,
                offcMailPresent: false,
              },
            },
            current_residence: {
              zip: leadData?.address?.pinCode,
              address_line1: leadData?.address?.address,
              city: leadData?.address?.city,
              state: leadData?.address?.state,
            },
            // mailing_address: {
            //   zip: leadData?.address?.pinCode,
            //   address_line1: leadData?.address?.address,
            //   city: leadData?.address?.city,
            //   state: leadData?.address?.state,
            // },
            // business_address: {
            //   zip: leadData?.address?.pinCode,
            //   address_line1: leadData?.address?.address,
            //   city: leadData?.address?.city,
            //   state: leadData?.address?.state,
            // },
            // permanent_residence: {
            //   zip: leadData?.address?.pinCode,
            //   address_line1: leadData?.address?.address,
            //   city: leadData?.address?.city,
            //   state: leadData?.address?.state,
            // },

            vkycrefno: "",
            applicantId: "",

            panNsdlStatus: "Not linked with UID",
            searchCriteria: "",
            identification_info: null,
            stFinancialAplcntType: "Y",
          },
        ],
      },
      productReq: {
        productDtls: [
          {
            mortgagePrdtDtls: [
              {
                productTypeInternal: iciciLoanProduct(),
                propertyDtls: [
                  {
                    rqstedTenure:
                      leadData?.caseDetails?.loanDetails?.loanTenureInMonths /
                      12,
                    rqstedAmt: leadData?.caseDetails?.loanDetails?.loanAmount,
                  },
                ],
              },
            ],
          },
        ],
      },
    };
    // setloading(true);
    // setFormData(iciciPreField);
    setTimeout(() => {
      setFormData(iciciPreField);
      // setloading(false);
    }, 50);
  };
  console.log("::leadData", leadData);
  const setFlexi = () => {
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
    let panFirstName = leadData?.contactDetails?.firstName
      ?.trim()
      .split(" ")
      ?.at(0);
    const DOI = leadData?.dsaCaseAdditionalAttributes?.dateOfIncorporation;
    const propertyOwnership = leadData?.dsaCaseAdditionalAttributes?.propertyOwnership ?? leadData?.caseDetails?.propertyOwnership;
    setFormData({
      first_name: panFirstName,
      last_name: panLastName?.at(-1).toString(),
      mobile_no: leadData?.contactDetails?.mobileNumber,
      email: leadData?.contactDetails?.emailId,
      loanApplication: {
        amount: leadData?.caseDetails?.loanDetails?.loanAmount,
        loanApplicant: {
          dob: leadData?.contactDetails?.dateOfBirth,
          gender: genderMap[leadData?.contactDetails?.gender],
          pan_no:
            leadData?.contactDetails?.pan === "PENDING"
              ? ""
              : leadData?.applicationType === "Individual"
              ? leadData?.contactDetails?.pan
              : "",
          pincode: leadData?.address?.pinCode?.toString(),
          address_line_1: leadData?.address?.address,
          ownership_status:
              ["HOUSE_OFFICE", "ONLY_HOUSE"].includes(propertyOwnership)
              ? "Owned"
              : ["ONLY_OFFICE", "NO_OWNERSHIP"].includes(propertyOwnership) 
              ? "Rented"
              : "",
        },
        loanBusiness: {
          business_name: leadData?.entityOrEmployerName,
          business_pan_no:
            leadData?.contactDetails?.pan === "PENDING"
              ? ""
              : leadData?.applicationType === "Business"
              ? leadData?.contactDetails?.pan
              : "",
          nature_of_business: natureOfBusiness[leadData?.businessProfile],
          legal_status: lead_status[leadData?.applicationSubTypeKey],
          business_vintage: leadData?.businessVintage
            ? Number(leadData?.businessVintage) === 0
              ? "Less than 1 year"
              : Number(leadData?.businessVintage) === 1
              ? "1 to 2 years"
              : [2, 3, 4, 5].includes(Number(leadData?.businessVintage))
              ? "2 to 5 years"
              : Number(leadData?.businessVintage) > 5
              ? "More than 5 years"
              : ""
            : "",
          gst_no: leadData?.dsaCaseAdditionalAttributes?.gstin,
          date_of_incorporation: DOI ? moment(DOI).locale("en").format("YYYY-MM-DD") : "",
        },
        loanFinance: {
          monthly_emi: leadData?.monthlyEmi,
        },
        loanMerchant: {
          partner_application_id: currLeadId,
        },
      },
    });
  };

  const setKotakCoApplicant = () => {
    const data = leadData?.dsaCaseAdditionalAttributes;
    let names = data?.coApplicantName?.trim().split(" ")
    const dob = data?.coApplicantDob? new Date(data?.coApplicantDob).toISOString(): ""

    const relation = data?.coapplicantRelationship;
    let relationship = "";
    if(relation == "PROPRIETOR") {
      relationship = "3";
    } else if(relation == "PARTNER") {
      relationship = "4";
    } else if(relation == "DIRECTOR") {
      relationship = "5";
    }

    const marital = data?.coapplicantMaritalstatus;
    let maritalstatus = "";
    if(marital == "SINGLE") {
      maritalstatus = "Single";
    } else if(marital == "MARRIED") {
      maritalstatus = "Married";
    } else if((marital?.length ?? 0) > 0) {
      maritalstatus = "Others";
    }

    setFormData({
      CreateLead: {
        "co-applicantDetails": {
          ObjectData: {
            FirstName: names?.at(0),
            MiddleName: "",
            LastName: names?.at(-1),
            PAN: data?.coApplicantPan,
            Gender: data?.coapplicantGender ? genderMap[data?.coapplicantGender] : "",
            Email: data?.coapplicantEmail ?? "",
            Mobile: data?.coapplicantMobileNumber,
            DOBorDOI: dob,
            Zipcode: data?.coapplicantPincode,
            AddressLine1: data?.coapplicantAddressLine1,
            State: data?.coapplicantState,
            Country: data?.coapplicantCountry,
            Same_Address_Co_App1: data?.sameAsCoapplicantCurrentAddress ? 1 : 2,
            Pincode1: data?.coapplicantPermanentPincode,
            Address1: data?.coapplicantPermanentAddressLine1,
            State1: data?.coapplicantPermanentState,
            Country1: data?.coapplicantPermanentCountry,
            Relation_with_Applicant: relationship,
            coapp_Marital_Status: maritalstatus,
            coapp_Income: "",
            CompanyOREmployer_Name: data?.coapplicantEmployerName ?? "",
          },
        },
      },
    });
  };

  const setKotak = () => {
    let names = leadData?.contactDetails?.firstName?.trim().split(" ");
    const propertyOwnership = leadData?.dsaCaseAdditionalAttributes?.propertyOwnership ?? leadData?.caseDetails?.propertyOwnership;
    const loanType = leadData?.loanType.toLowerCase();
    setFormData({
      productTypeInternal: loanType.includes("home") ? "HL" : loanType.includes("business") ? "BL" : "LAP",
      cityName: leadData?.address?.city,
      CreateLead: {
        ProductKey: loanType.includes("home loan") ? "121" : loanType == "loan against property" ? "129" : "",
        LeadAmount: leadData?.caseDetails?.loanDetails?.loanAmount,
        DateofBirth: leadData?.contactDetails?.dateOfBirth
          ? new Date(leadData?.contactDetails?.dateOfBirth).toISOString()
          : "",
        GenderID: leadData?.contactDetails?.gender === "M" ? 1 : 2,
        PanNumber:
          leadData?.contactDetails?.pan.toLowerCase() === "pending"
            ? ""
            : leadData?.contactDetails?.pan,
        Email: leadData?.contactDetails?.emailId,
        MobilePhone: leadData?.contactDetails?.mobileNumber,
        State: leadData?.address?.state,
        Address_Line_1_Le: leadData?.address?.address,
        CorrespondenceAddLine_2: leadData?.dsaCaseAdditionalAttributes?.addressLine2,
        ZipCode: leadData?.address?.pinCode,
        FirstName: names[0],
        LastName: names[names.length - 1],
        Custom: {
          Individual: leadData?.applicationTypeKey == "INDIVIDUAL" ? "I" : "O",
          Applicant_Type: applicantTypeData(),
          LoanTnurInMnth:
            leadData?.caseDetails?.loanDetails?.loanTenureInMonths,
          Cmpny_Emp_Name: leadData?.entityOrEmployerName,
          Business_Le:
            leadData?.businessProfile === "MANUFACTURER"
              ? "Manufacturing"
              : leadData?.businessProfile === "SERVICE_PROVIDER"
              ? "Service"
              : leadData?.businessProfile === "TRADER"
              ? "Trading"
              : "",
          Vintage_Le: leadData?.businessVintage
            ? leadData?.businessVintage < 3
              ? "Less than 3 years"
              : "More than 3 years"
            : "",
          EMI_Le: leadData?.monthlyEmi,
          Pincode1_LE: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressPincode,
          Address_Line_P1_Le: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressLine1,
          State1_LE: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressState,
          property_Le2:
            propertyOwnership === "HOUSE_OFFICE"
              ? "Both"
              : propertyOwnership === "ONLY_HOUSE"
              ? "Residential"
              : propertyOwnership === "ONLY_OFFICE"
              ? "Shop or Factory"
              : propertyOwnership === "NO_OWNERSHIP"
              ? "None"
              : "",
          CoApplicant_Required: leadData?.dsaCaseAdditionalAttributes
            ?.isCoApplicantEnabled
            ? "Y"
            : "N",
          Outstanding_Loan_Amnt: leadData?.dsaCaseAdditionalAttributes?.existingLoanAmount,
          Propert_Identified: leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified ? "Yes" : "No"
        },
      },
    });

    if (getNextTaskPayload?.taskDefinitionKey === "user_task_new_co_applicant") {
      setKotakCoApplicant();
    }
  };

  const setBNPL = () => {
    setOldFlowData();
    const bnplDetails = leadData?.dsaCaseAdditionalFileds?.bnplLoanDetails;

    setFormData({
      fullName: leadData?.contactDetails?.firstName,
      pan:
        leadData?.contactDetails?.pan.toLowerCase() === "pending"
          ? ""
          : leadData?.contactDetails?.pan,
      gender:
        leadData?.contactDetails?.gender === "M"
          ? "M"
          : leadData?.contactDetails?.gender === "F"
          ? "F"
          : "M",
      dob: leadData?.contactDetails?.dateOfBirth
        ? leadData?.contactDetails?.dateOfBirth
        : "",
      email: leadData?.contactDetails?.emailId,
      mobile: leadData?.contactDetails?.mobileNumber,
      city: leadData?.address?.city,
      pincode: leadData?.address?.pinCode,
      company: leadData?.entityOrEmployerName,
      monthlyInHandSalary:
        leadData?.caseDetails?.loanDetails?.monthlyInHandSalary,
      officePincode: leadData?.officePincode,
      entityOrEmployerName: leadData?.entityOrEmployerName,
      applicationSubType: leadData?.applicationSubType,
      studentFullName: bnplDetails?.studentFullName ?? "",
      studentDOB: bnplDetails?.studentDOB ?? "",
      instituteName: bnplDetails?.instituteName ?? null,
      branchName: bnplDetails?.branchName ?? null,
      courseName: bnplDetails?.courseName ?? null,
      bnplApplicantType: bnplDetails?.bnplApplicantType ?? null,
      orderAmount: leadData?.caseDetails?.loanDetails?.loanAmount,
    });
  };

  const setMoneyWide = () => {
    setOldFlowData();
    setFormData({
      pan:
        leadData?.contactDetails?.pan.toLowerCase() === "pending"
          ? ""
          : leadData?.contactDetails?.pan,
      full_name: leadData?.contactDetails?.firstName,
      gender:
        leadData?.contactDetails?.gender === "M"
          ? 1
          : leadData?.contactDetails?.gender === "F"
          ? 2
          : 3,
      dob: leadData?.contactDetails?.dateOfBirth
        ? leadData?.contactDetails?.dateOfBirth
        : "",
      email: leadData?.contactDetails?.emailId,
      phone_no: leadData?.contactDetails?.mobileNumber,
      pincode: leadData?.address?.pinCode,
      loan_amount: leadData?.caseDetails?.loanDetails?.loanAmount,
      net_monthly_incm: leadData?.caseDetails?.loanDetails?.monthlyInHandSalary,
      city_name: leadData?.address?.city,
      // city_name: leadData?.address?.city.toLowerCase().includes('mumbai') ? 'Mumbai' : leadData?.address?.city.toLowerCase().includes('delhi') ? 'Delhi' : leadData?.address?.city.toLowerCase().includes('ahmedabad') ? 'Ahmedabad' : leadData?.address?.city.toLowerCase().includes('bangalore') ? 'Bangalore' : leadData?.address?.city.toLowerCase().includes('pune') ? 'Pune' : '',
      employer_name: leadData?.entityOrEmployerName,
      office_pincode: leadData?.officePincode,
      employer_type:
        leadData?.caseDetails?.loanDetails?.additionalDetails?.employerType,
      office_city_name:
        leadData?.caseDetails?.loanDetails?.additionalDetails?.officeCity,
      current_work_exp:
        leadData?.caseDetails?.loanDetails?.additionalDetails
          ?.currentWorkExperience,
      total_work_exp:
        leadData?.caseDetails?.loanDetails?.additionalDetails
          ?.totalWorkExperience,
      industry_id:
        leadData?.caseDetails?.loanDetails?.additionalDetails?.industryType,
    });
  };

  const setCashE = () => {
    setOldFlowData();
    setFormData({
      Pan:
        leadData?.contactDetails?.pan.toLowerCase() === "pending"
          ? ""
          : leadData?.contactDetails?.pan,
      Name: leadData?.contactDetails?.firstName,
      Gender: leadData?.contactDetails?.gender === "M" ? "M" : "F",
      Dob: leadData?.contactDetails?.dateOfBirth
        ? leadData?.contactDetails?.dateOfBirth
        : "",
      emailId: leadData?.contactDetails?.emailId,
      mobileNo: leadData?.contactDetails?.mobileNumber,
      pinCode: leadData?.address?.pinCode,
      loanAmount: leadData?.caseDetails?.loanDetails?.loanAmount,
      Salary: leadData?.caseDetails?.loanDetails?.monthlyInHandSalary,
      City: leadData?.address?.city,
      companyName: leadData?.entityOrEmployerName,
      Locality: leadData?.address?.city,
      address_Line_1: leadData?.address?.address,
      State: leadData?.address?.state.toUpperCase(),
      create_Customer_Api: {
        office_pincode: leadData?.officePincode,
      },
    });
  };

  const setOldFlowData = () => {
    if (Object.keys(getNextTaskPayload).length !== 0) {
      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      if (
        getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
      ) {
        const data = getJSONSchema(getNextTaskPayload?.jsonSchema);

        setIsLastTask(getNextTaskPayload?.lastStep);
        setDocumentList(data);
        setFormData({});
      }
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
      setIsCustomUI(getNextTaskPayload?.customUI);
    }
  };

  const setKreditBee = () => {
    setOldFlowData();
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
    let panFirstName = leadData?.contactDetails?.firstName
      ?.trim()
      .split(" ")
      ?.at(0);

    setFormData({
      pan:
        leadData?.contactDetails?.pan.toLowerCase() === "pending"
          ? ""
          : leadData?.contactDetails?.pan,
      firstname: panFirstName,
      lastname: panLastName?.at(-1).toString(),
      gender:
        leadData?.contactDetails?.gender === "M"
          ? "M"
          : leadData?.contactDetails?.gender === "F"
          ? "F"
          : "M",
      dob: leadData?.contactDetails?.dateOfBirth
        ? leadData?.contactDetails?.dateOfBirth
        : "",
      email: leadData?.contactDetails?.emailId,
      mobile: leadData?.contactDetails?.mobileNumber,
      pincode: leadData?.address?.pinCode,
      company: leadData?.entityOrEmployerName,
      salary: leadData?.caseDetails?.loanDetails?.monthlyInHandSalary,
    });
  };

  const setNiraFinance = () => {
    setOldFlowData();
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
    let panFirstName = leadData?.contactDetails?.firstName
      ?.trim()
      .split(" ")
      ?.at(0);
    const dateOfBirth = leadData?.contactDetails?.dateOfBirth
      ? moment(leadData?.contactDetails.dateOfBirth)
          .locale("en")
          .format("YYYY-MM-DD")
      : "";

    setFormData({
      firstName: panFirstName,
      lastName: panLastName?.at(-1).toString(),
      gender:
        leadData?.contactDetails?.gender === "M"
          ? "Male"
          : leadData?.contactDetails?.gender === "F"
          ? "Female"
          : "Other",
      dateOfBirth: dateOfBirth,
      personalEmailID: leadData?.contactDetails?.emailId,
      mobileNo: leadData?.contactDetails?.mobileNumber,
      pinCode: leadData?.address?.pinCode,
      city: leadData?.address?.city,
      state: leadData?.address?.state,
      addressLine1: leadData?.address?.address,
      addressLine2: leadData?.dsaCaseAdditionalAttributes?.addressLine2,
      monthlySalary: leadData?.loanDetails.monthlyInHandSalary,
      organizationName: leadData?.entityOrEmployerName,
      existingTotalEMIAmount: leadData?.loanDetails.currentEmis,
      maritalStatus:
        leadData?.dsaCaseAdditionalAttributes?.maritalStatus?.toLowerCase(),
      highestEducationDegree:
        leadData?.dsaCaseAdditionalAttributes?.educationalQualification,
      // institutionName: leadData?.contactDetails?.institutionName,
      hometown: leadData?.dsaCaseAdditionalAttributes?.hometown,
      employmentType: leadData?.applicationSubTypeKey?.toLowerCase(),
      experienceInMonths:
        leadData?.dsaCaseAdditionalAttributes?.totalExperienceInMonths,
      designation: leadData?.dsaCaseAdditionalAttributes?.designation,
      jobSector: leadData?.dsaCaseAdditionalAttributes?.jobSector,
      workStatus: leadData?.dsaCaseAdditionalAttributes?.workStatus,
      salaryMode: leadData?.dsaCaseAdditionalAttributes?.salaryMode,
    });
  };


  const setBajajHousingFinance = () => {
    setOldFlowData();
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ")?.at(1);
    let panFirstName = leadData?.contactDetails?.firstName?.trim().split(" ")?.at(0);

    if (!panLastName) {
      panLastName = panFirstName;
    }

    const dateOfBirth = leadData?.contactDetails?.dateOfBirth
      ? moment(leadData?.contactDetails.dateOfBirth)
          .locale("en")
          .format("YYYY-MM-DD")
      : "";

    setFormData({
      loanDetails: {
        contactName: leadData?.contactDetails?.firstName,
      firstName: panFirstName,
      lastName: panLastName,
      mobileNumber: leadData?.contactDetails?.mobileNumber,
      emailId: leadData?.contactDetails?.emailId,
      dateOfBirth: dateOfBirth,
      gender:
        leadData?.contactDetails?.gender,
      },
      employerDetails:{
        employmentType: leadData?.applicationSubTypeKey === "SALARIED"
        ? "SALARIED"
        : leadData?.applicationSubTypeKey === "SELF_EMPLOYED"
        ? "SEP"
        : "",
        netSalary: leadData?.loanDetails?.monthlyInHandSalary === 0 
        ? ""
        : leadData?.loanDetails?.monthlyInHandSalary,
        employerName: leadData?.entityOrEmployerName,
        monthlyObligations: leadData?.monthlyEmi,
      },
      addressDetails:{
        pinCode: leadData?.address?.pinCode,
        city: leadData?.address?.city,
      },
      productDetails:{
        requiredLoanAmount: leadData?.loanDetails?.loanAmount,
        loanType: leadData?.loanType === "Home loan"
        ? "HHL"
        : leadData?.loanType === "Loan Against Property"
        ? "HLP"
        : leadData?.loanType,
        vouchers: leadData?.address?.city,
        propertyIdentified: leadData?.dsaCaseAdditionalAttributes?.isPropertyIdentified ? "yes" : "no",
      } 
    });
  };

  const setCreditSaison = () => {
    setOldFlowData();

    let businessName = leadData?.entityOrEmployerName;

    let names = leadData?.contactDetails?.firstName?.trim().split(" ");

    const additionalData = leadData?.dsaCaseAdditionalAttributes;

    setFormData({
      business: {
        pan:
          leadData?.contactDetails?.pan === "PENDING"
            ? ""
            : leadData?.contactDetails?.pan,
        name: businessName,
        proprietorName: leadData?.contactDetails?.firstName,
        turnover: leadData?.businessTurnOver,

        //entityType: leadData?.applicationSubTypeKey,

        entityType:
          leadData?.applicationSubTypeKey === "SOLE_PROPRIETOR"
            ? "PROPRIETORSHIP"
            : "",

        nature:
          leadData?.businessProfile === "RETAILER"
            ? "TRADER_RETAIL"
            : leadData?.businessProfile === "TRADER"
            ? "TRADER_RETAIL"
            : leadData?.businessProfile === "MANUFACTURER"
            ? "MANUFACTURING"
            : leadData?.businessProfile === "SERVICE_PROVIDER"
            ? "SERVICE"
            : leadData?.businessProfile === "WHOLESALER_DISTRIBUTOR"
            ? "TRADER_WHOLESALE"
            : leadData?.businessProfile,
        requestedLoanAmount: leadData?.loanDetails?.loanAmount,
        requestedLoanTenure: leadData?.loanDetails?.loanTenureInYears,
        doi: additionalData?.dateOfIncorporation,
        gstin: additionalData?.gstin,
        email: additionalData?.email,
        phone: additionalData?.mobileNumber,
        corporateBusinessAddress: {
          pincode: additionalData?.pincode,
          lineOne: additionalData?.addressLine1,
          city: additionalData?.city,
          state: additionalData?.state,
        },
        registeredBusinessAddress: {
          pincode: additionalData?.correspondanceAddressPincode,
          lineOne: additionalData?.correspondanceAddressLine1,
          city: additionalData?.correspondanceAddressCity,
          state: additionalData?.correspondanceAddressState,
        },
      },
      primaryCoapplicant: {
        pan: additionalData?.coApplicantPan,
        firstName: names?.at(0),
        lastName: names?.at(-1),
        dob: moment(leadData.contactDetails.dateOfBirth).format("YYYY-MM-DD"),
        gender:
          leadData?.contactDetails?.gender === "M"
            ? "MALE"
            : leadData?.contactDetails?.gender === "F"
            ? "FEMALE"
            : "Other",
        email: leadData?.contactDetails.emailId,
        phone: leadData?.contactDetails.mobileNumber,
        currentAddress: {
          pincode: additionalData?.coapplicantPincode,
          lineOne: additionalData?.coapplicantAddressLine1,
          city: additionalData?.coapplicantCity,
          state: additionalData?.coapplicantState,
        },
        permanentAddress: {
          pincode: additionalData?.coapplicantPermanentPincode,
          lineOne: additionalData?.coapplicantPermanentAddressLine1,
          city: additionalData?.coapplicantPermanentCity,
          state: additionalData?.coapplicantPermanentPincode,
        },
      },
    });
    if (Object.keys(getNextTaskPayload).length !== 0) {
      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      setIsOfferJSON(false);
      if (getNextTaskPayload?.taskDefinitionKey != "user_task_lead_push") {
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
        ) {
          // setIsCustomUI(getNextTaskPayload?.customUI);
          // setIsLastTask(getNextTaskPayload?.lastStep);
          //const data = getJSONSchema(getNextTaskPayload?.jsonSchema);
          setIsCustomUI(getNextTaskPayload?.customUI);
          //setIsLastTask(false);
          setDocumentList(getJSONSchema(getNextTaskPayload?.jsonSchema));
          setFormData({});
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_retry_bre_result"
        ) {
          setIsOtpScreen(false);
          //setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey ===
          "user_task_retry_bre_for_bank_statement"
        ) {
          setIsOtpScreen(false);
          //setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_display_offer"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey ===
          "user_task_offer_not_available"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(false);
          setABCLBreNoOfferScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        }
        // else if (getNextTaskPayload?.taskDefinitionKey ===
        //   "user_task_case_rejected") {

        //   setCreditSaisonScreen(true);

        //   // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
        //   setTaskDetails({
        //     taskName: getNextTaskPayload?.taskName,
        //     taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        //     taskId: getNextTaskPayload?.taskId,
        //   });
        // }
        else {
          setFinalJSONSchema(
            getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
          );
          setIsCustomUI(getNextTaskPayload?.customUI);
        }
      }
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
    }
  };

  const setHDFC = () => {
    let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
    let panFirstName = leadData?.contactDetails?.firstName
      ?.trim()
      .split(" ")
      ?.at(0);

    const DOB = leadData?.contactDetails?.dateOfBirth
      ? moment(leadData?.contactDetails.dateOfBirth)
          .locale("en")
          .format("YYYY-MM-DD")
      : "";

    const salary =
      leadData?.loanType == "Personal Loan"
        ? leadData?.caseDetails?.loanDetails?.monthlyInHandSalary
        : Math.round((leadData?.businessTurnOver ?? 0) / 12);

    const pan =
      leadData?.contactDetails?.pan.toLowerCase() === "pending"
        ? ""
        : leadData?.contactDetails?.pan;
    const panAcNo =
      leadData?.loanType == "Personal Loan" ||
      leadData?.applicationSubTypeKey == "SELF_EMPLOYED" ||
      leadData?.applicationSubTypeKey == "SOLE_PROPRIETOR"
        ? pan
        : "";

    setFormData({
      applyLoan: {
        loanTypeInternal: leadData?.loanType,
        PAN_AC_No: panAcNo,
        First_Name: panFirstName,
        Last_Name: panLastName?.at(-1).toString(),
        Gender:
          leadData?.contactDetails?.gender === "M"
            ? "M"
            : leadData?.contactDetails?.gender === "F"
            ? "F"
            : "M",
        Date_Of_Birth: DOB,
        Email_Resi: leadData?.contactDetails?.emailId,
        Mobile1_Resi: leadData?.contactDetails?.mobileNumber,
        Phone1_Resi: leadData?.contactDetails?.mobileNumber,
        Pin_Code_Resi: leadData?.address?.pinCode,
        Address1_Resi: leadData?.address?.address,
        // City_Resi: leadData?.address?.city,
        // State_Resi: leadData?.address?.state,
        Loan_Amount: leadData?.caseDetails?.loanDetails?.loanAmount,
        Tenure_in_Months: leadData?.caseDetails?.loanDetails?.loanTenureInYears,
        Monthly_take_home_Salary: salary == 0 ? null : salary,
        Pin_Code_Work: leadData?.officePincode,
      },
    });

    if (Object.keys(getNextTaskPayload).length !== 0) {
      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      setIsCustomUI(getNextTaskPayload?.customUI);
      if (getNextTaskPayload?.taskDefinitionKey != "user_task_apply_for_loan") {
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_documents"
        ) {
          const data = getJSONSchema(getNextTaskPayload?.jsonSchema);
          setIsCustomUI(getNextTaskPayload?.customUI);
          setIsLastTask(getNextTaskPayload?.lastStep);
          setDocumentList(data);
          setFormData({});
        } else {
          setFormData({
            loanTypeInternal: leadData?.loanType,
          });
        }
      }
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
    }
  };

  const setABCL = () => {
    const isDob = !(
      leadData?.applicationTypeKey == "BUSINESS" &&
      leadData?.applicationSubTypeKey !== "SOLE_PROPRIETOR"
    );

    const DOB = leadData?.contactDetails?.dateOfBirth
      ? moment(leadData?.contactDetails.dateOfBirth)
          .locale("en")
          .format("YYYY-MM-DD")
      : "";

    const pan =
      leadData?.contactDetails?.pan.toLowerCase() === "pending"
        ? ""
        : leadData?.contactDetails?.pan;
    const panAcNo =
      leadData?.loanType == "Personal Loan" ||
      leadData?.applicationSubTypeKey == "SELF_EMPLOYED" ||
      leadData?.applicationSubTypeKey == "SOLE_PROPRIETOR"
        ? pan
        : "";

    const DOI = leadData?.dsaCaseAdditionalAttributes?.dateOfIncorporation;
    const propertyOwnership = leadData?.dsaCaseAdditionalAttributes?.propertyOwnership ?? leadData?.caseDetails?.propertyOwnership;

    setFormData({
      mobile: leadData?.contactDetails?.mobileNumber,
      basicDetails: {
        personalDetails: {
          pan: panAcNo,
          email: leadData?.contactDetails?.emailId,
          name: leadData?.contactDetails?.firstName,
          dob: isDob ? DOB : "",
          gender:
            leadData?.contactDetails?.gender === "M"
              ? "MALE"
              : leadData?.contactDetails?.gender === "F"
              ? "FEMALE"
              : "OTHER",
          mobileNo: leadData?.contactDetails?.mobileNumber,
          currentAddress: {
            pincode: leadData?.address?.pinCode,
            currentAddressLine1: leadData?.address?.address,
            currentAddressCity: leadData?.address?.city,
            currentAddressState: leadData?.address?.state,
          },
        },
        loanDetails: {
          loanAmount: leadData?.caseDetails?.loanDetails?.loanAmount,
        },
        businessDetails: {
          gstDetails: {
            gstin: leadData?.dsaCaseAdditionalAttributes?.gstin,
          },
          firmName: leadData?.entityOrEmployerName,
          nature: natureOfBusinessABCL[leadData?.businessProfile],
          industry: "",
          subIndustry: "",
          dateOfIncorporation: isDob ? DOI : DOB,
          businessAddress: {
            pincode: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressPincode,
            line1: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressLine1,
            line2: "",
            businessAddressType:
              ["HOUSE_OFFICE", "ONLY_OFFICE"].includes(propertyOwnership)
                ? "Owned"
                : ["ONLY_HOUSE", "NO_OWNERSHIP"].includes(propertyOwnership)
                ? "Rented"
                : "",
            city: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressCity,
            state: leadData?.dsaCaseAdditionalAttributes?.correspondanceAddressState,
          },
        },
      },
      // applyLoan: {
      //   loanTypeInternal: leadData?.loanType,
      //   PAN_AC_No: panAcNo,
      //   First_Name: panFirstName,
      //   Last_Name: panLastName?.at(-1).toString(),
      //   Gender: leadData?.contactDetails?.gender === "M" ? 'M' : leadData?.contactDetails?.gender === "F" ? 'F' : 'M',
      //   Date_Of_Birth: DOB,
      //   Email_Resi: leadData?.contactDetails?.emailId,
      //   Mobile1_Resi: leadData?.contactDetails?.mobileNumber,
      //   Phone1_Resi: leadData?.contactDetails?.mobileNumber,
      //   Pin_Code_Resi: leadData?.address?.pinCode,
      //   Address1_Resi: leadData?.address?.address,
      //   // City_Resi: leadData?.address?.city,
      //   // State_Resi: leadData?.address?.state,
      //   Loan_Amount: leadData?.caseDetails?.loanDetails?.loanAmount,
      //   Tenure_in_Months: leadData?.caseDetails?.loanDetails?.loanTenureInYears,
      //   Monthly_take_home_Salary: salary == 0 ? null : salary,
      //   Pin_Code_Work: leadData?.officePincode,
      // }
    });

    if (Object.keys(getNextTaskPayload).length !== 0) {
      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      setIsOfferJSON(false);
      if (getNextTaskPayload?.taskDefinitionKey != "user_task_apply_for_loan") {
        if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_upload_document"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_retry_bre_result"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey === "user_task_display_offer"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else if (
          getNextTaskPayload?.taskDefinitionKey ===
          "user_task_offer_not_available"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(false);
          setABCLBreNoOfferScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: getNextTaskPayload?.taskName,
            taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
            taskId: getNextTaskPayload?.taskId,
          });
        } else {
          setFinalJSONSchema(
            getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
          );
          setIsCustomUI(getNextTaskPayload?.customUI);
        }
      }
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
    }
  };
  //Lender Prefr:
  const setPrefr = () => {
    const DOB = leadData?.contactDetails?.dateOfBirth
      ?  new Date(leadData?.contactDetails.dateOfBirth).toISOString()
      : "";

    const pan =
      leadData?.contactDetails?.pan.toLowerCase() === "pending"
        ? ""
        : leadData?.contactDetails?.pan;

      let panLastName = leadData?.contactDetails?.firstName?.trim().split(" ");
      let panFirstName = leadData?.contactDetails?.firstName
        ?.trim()
        .split(" ")
        ?.at(0);
        console.log('selectedLander?.additionalFields', selectedLander);

    setFormData({
        firstName: panFirstName,
        lastName: panLastName?.at(-1).toString(),
        dob: DOB,
        desiredLoanAmount_text: leadData?.caseDetails?.loanDetails?.loanAmount.toString(),
        employmentType: leadData?.applicationSubTypeKey === 'SOLE_PROPRIETOR' || leadData?.applicationSubTypeKey === 'SELF_EMPLOYED' ? 'self employed' : leadData?.applicationSubTypeKey === 'SALARIED' ? 'salaried' : '',
        netMonthlyIncome_text: leadData?.loanDetails.monthlyInHandSalary.toString(),
        gender: leadData?.contactDetails?.gender === "M"
        ? "Male"
        : leadData?.contactDetails?.gender === "F"
        ? "Female" : 'Male',
        panNumber: pan,
        personalEmailId: leadData?.contactDetails?.emailId,
        // motherName: "Mothername",
        currentResidenceType: leadData?.dsaCaseAdditionalAttributes?.propertyOwnership === 'ONLY_HOUSE' ? "self-owned" : '',
        currentAddress: leadData?.address?.address,
        // permanentAddressSameAsCurrAddress: true,
        // permanentAddress: "addres",
        companyName: leadData?.entityOrEmployerName,
        workEmailId: leadData?.dsaCaseAdditionalAttributes?.officeEmail,
        officeAddress: leadData?.dsaCaseAdditionalAttributes?.officeAddress,
        // campaignName: "tet",
        // campaignMedium: "dasd",
        // campaignSource: "dasdas",
        // desiredLoanAmount: leadData?.caseDetails?.loanDetails?.loanAmount,
        // netMonthlyIncome: 312323,
        // permanentAddressPincode: 400101,
        // loanPurpose: "",
        currentAddressPincode: leadData?.address?.pinCode,
        officePincode: leadData?.officePincode,
        // organizationType: "",
        // isDisable: false
      }
    );
    if (Object.keys(getNextTaskPayload).length !== 0) {
      if (getNextTaskPayload?.taskDefinitionKey === "user_task_submit_customer_details") {
        const allLender = leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails.find((item) => item?.lenderInfoId === selectedLander?.lenderInfoId);
        // console.log('allLender', JSON.parse(JSON.parse(allLender?.additionalFields)?.payload));
        const oldData = allLender?.additionalFields ? JSON.parse(JSON.parse(allLender?.additionalFields)?.payload) : ''
        setFormData({...oldData})
      }

      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      setIsOfferJSON(false);
      setFinalJSONSchema(
        getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
      );
      setIsCustomUI(getNextTaskPayload?.customUI);
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
    }
  }

  //Lender incred:
  const setInCred = () => {
    const DOB = leadData?.dsaCaseAdditionalAttributes?.dateOfBirth ? leadData?.dsaCaseAdditionalAttributes?.dateOfBirth
    ? moment(leadData?.dsaCaseAdditionalAttributes?.dateOfBirth)
        .locale("en")
        .format("YYYY-MM-DD")
    : "" :  leadData?.contactDetails?.dateOfBirth
      ? moment(leadData?.contactDetails.dateOfBirth)
          .locale("en")
          .format("YYYY-MM-DD")
      : "";

    const pan =
    leadData?.dsaCaseAdditionalAttributes?.applicantPan ? leadData?.dsaCaseAdditionalAttributes?.applicantPan.toLowerCase() === "pending"
    ? ""
    : leadData?.dsaCaseAdditionalAttributes?.applicantPan : 
      leadData?.contactDetails?.pan.toLowerCase() === "pending"
        ? ""
        : leadData?.contactDetails?.pan;
      let panLastName = leadData?.dsaCaseAdditionalAttributes?.applicantName?.trim().split(" ") ?? leadData?.contactDetails?.firstName?.trim().split(" ");
      let panFirstName = leadData?.dsaCaseAdditionalAttributes?.applicantName?.trim()
      .split(" ")
      ?.at(0) ?? leadData?.contactDetails?.firstName
        ?.trim()
        .split(" ")
        ?.at(0);
    setFormData({
      FNAME: panFirstName,
      LNAME: panLastName?.at(-1).toString(),
      MOBILE:  leadData?.dsaCaseAdditionalAttributes?.mobileNumber ?? leadData?.contactDetails?.mobileNumber,
      DOB: DOB,
      GENDER: leadData?.dsaCaseAdditionalAttributes?.gender ?? leadData?.contactDetails?.gender === "M"
      ? "M"
      : leadData?.dsaCaseAdditionalAttributes?.gender ?? leadData?.contactDetails?.gender === "F"
      ? "F" : 'M',
      PAN: pan,
      PARTNER_REFERENCE: currLeadId,
      EMPLOYMENT_TYPE: (leadData?.dsaCaseAdditionalAttributes?.applicantSubType ?? leadData?.applicationSubTypeKey) === 'SOLE_PROPRIETOR' ||  ( leadData?.dsaCaseAdditionalAttributes?.applicantSubType ??  leadData?.applicationSubTypeKey ) === 'SELF_EMPLOYED' ? 'SELFEMP' :  (leadData?.dsaCaseAdditionalAttributes?.applicantSubType ?? leadData?.applicationSubTypeKey ) === 'SALARIED' ? 'SALARIED' : '',
      EMPLOYMENT: {
        SALARY:  leadData?.dsaCaseAdditionalAttributes?.monthlyInHandSalary ?? leadData?.loanDetails.monthlyInHandSalary.toString()
      },
      ADDRESS: {
        PINCODE:  leadData?.dsaCaseAdditionalAttributes?.pincode ?? leadData?.address?.pinCode,
      },
    })
    if (Object.keys(getNextTaskPayload).length !== 0) {
      if(getNextTaskPayload?.taskDefinitionKey === "user_task_retry_offer_status" || getNextTaskPayload?.taskDefinitionKey === "user_task_retry_trigger_offer") {
        setIsOtpScreen(false);
        setIsOfferAcceptanceScreen(false);
        setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema));
        setTaskDetails({
          taskName: getNextTaskPayload?.taskName,
          taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
          taskId: getNextTaskPayload?.taskId,
        });
        setCustomUI(getNextTaskPayload?.customUI);
        setDisplayInCredRetryScreen(true);
      }
      setFinalJSONSchema(getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}"));
      setIsOfferJSON(false);
      setFinalJSONSchema(
        getJSONSchema(getNextTaskPayload?.jsonSchema ?? "{}")
      );
      setIsCustomUI(getNextTaskPayload?.customUI);
      setTaskDetails({
        taskName: getNextTaskPayload?.taskName,
        taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
        taskId: getNextTaskPayload?.taskId,
      });
    }
  }

  const handleGetNextTask = (caseId: any, lenderId: any) => {
    setloading(true);
    if (selectedLander?.lenderName?.toLowerCase()?.includes("incred")) {
      setDisplayInCredLoadingScreen(true);
    }
    API_SERVICE.getNextTask(caseId, lenderId)
      .then((data) => {
        if (
          window["ReactNativeWebView"] &&
          data?.data?.payload?.taskDefinitionKey ===
            "user_task_upload_documents"
        ) {
          callbackForMobile("DOCUMENT");
          return;
        }
        console.log("dataLaststep",data?.data?.payload?.lastStep)
        setIsLastTask(data?.data?.payload?.lastStep);
        setIsDocumentUploadResponse(
          JSON.parse(data?.data?.payload?.documentUploadResponse)
        );
        setloading(false);
        setDisplayInCredLoadingScreen(false);
        // if (selectedLander?.lenderName?.toLowerCase()?.includes("incred")) {
          if(data?.data?.payload?.taskDefinitionKey === "user_task_retry_offer_status" || data?.data?.payload?.taskDefinitionKey === "user_task_retry_trigger_offer") {
            setIsOtpScreen(false);
            setIsOfferAcceptanceScreen(false);
            setFinalJSONSchema({});
            setIsOfferJSON(false);
            setTaskDetails({
              taskName: data?.data?.payload?.taskName,
              taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
              taskId: data?.data?.payload?.taskId,
            });
            setIsCustomUI(data?.data?.payload?.customUI);
            console.log('InCredCalled', data);
            setDisplayInCredRetryScreen(true);
          }
        // }
        // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
        // setTaskDetails({
        //   taskName: data?.data?.payload?.taskName,
        //   taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
        //   taskId: data?.data?.payload?.taskId,
        // });
        // if (selectedLander?.lenderName?.toLowerCase()?.includes("icici")){
        if (
          data?.data?.payload?.taskDefinitionKey ===
          "user_task_customer_otp_verification"
        ) {
          console.log("OTP Task");
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
          setIsOtpScreen(true);
        } else if (
          data?.data?.payload?.taskDefinitionKey ===
          "user_task_offer_acceptance"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(true);
          setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        } else if (
          data?.data?.payload?.taskDefinitionKey ===
          "user_task_retry_bre_result"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(true);
          setIsCustomUI(!data.data.payload.customUI);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        } else if (
          data?.data?.payload?.taskDefinitionKey ===
            "user_task_upload_document" &&
          (selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
            selectedLander?.lenderName.toLowerCase().includes("abcl"))
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(true);
          setIsCustomUI(data.data.payload.customUI);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        } else if (
          data?.data?.payload?.taskDefinitionKey ===
          "user_task_retry_bre_for_bank_statement"
        ) {
          setIsOtpScreen(false);
          //setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(true);
          setIsCustomUI(false);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        } else if (
          data?.data?.payload?.taskDefinitionKey === "user_task_display_offer"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(true);
          setIsCustomUI(!data.data.payload.customUI);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        } else if (
          data?.data?.payload?.taskDefinitionKey ===
          "user_task_offer_not_available"
        ) {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreNoOfferScreen(true);
          setABCLBreDocumentScreen(false);
          setIsCustomUI(!data.data.payload.customUI);
          setIsOfferJSON(false);
          // setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        }
        // else if (selectedLander?.lenderName?.toLowerCase()?.includes("incred")) {
        //   if(data?.data?.payload?.taskDefinitionKey === "user_task_retry_offer_status" || data?.data?.payload?.taskDefinitionKey === "user_task_retry_trigger_offer") {
        //     setIsOtpScreen(false);
        //     setIsOfferAcceptanceScreen(false);
        //     setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
        //     setIsOfferJSON(false);
        //     setTaskDetails({
        //       taskName: data?.data?.payload?.taskName,
        //       taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
        //       taskId: data?.data?.payload?.taskId,
        //     });
        //     setCustomUI(data.data.payload.customUI);
        //     console.log('InCredCalled', data);
        //     setDisplayInCredRetryScreen(true);
        //     return;
        //   }
        // }
         else {
          setIsOtpScreen(false);
          setIsOfferAcceptanceScreen(false);
          setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        }
        // }
        if (selectedLander?.lenderName?.toLowerCase()?.includes("hdfc")) {
          setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          if (
            data?.data?.payload?.taskDefinitionKey !==
            "user_task_apply_for_loan"
          ) {
            setIsOfferJSON(false);
            // setFormData({});
            setTaskDetails({
              taskName: data?.data?.payload?.taskName,
              taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
              taskId: data?.data?.payload?.taskId,
            });
            setFormData({
              loanTypeInternal: leadData?.loanType,
            });
          }
        }
        if (
          (selectedLander?.lenderName?.toLowerCase()?.includes("kotak") ||
            selectedLander?.lenderName?.toLowerCase()?.includes("flexi")) &&
          data?.data?.payload?.taskDefinitionKey !==
            "user_task_submit_application" &&
          data?.data?.payload?.taskDefinitionKey !==
            "user_task_upload_documents"
        ) {
          setIsCustomUI(data.data.payload.customUI);
          setIsOfferJSON(false);
          setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        }
        if (
          data?.data?.statusCode === 200 &&
          data?.data?.payload?.taskDefinitionKey ===
            "user_task_upload_documents"
        ) {
          setIsOfferAcceptanceScreen(false);
          setABCLBreResultScreen(false);
          setABCLBreDocumentScreen(false);
          setABCLBreOfferDetailsScreen(false);
          setABCLBreNoOfferScreen(false);
          setCreditSaisonScreen(false);
          setIsCustomUI(data.data.payload.customUI); //don't make false if need to make false add lender condition

          if(selectedLander?.lenderName?.toLowerCase()?.includes("flexi"))
            {
              const data = getJSONSchema(getNextTaskPayload?.jsonSchema);
              setIsCustomUI(!getNextTaskPayload?.customUI);
              setIsLastTask(getNextTaskPayload?.lastStep);
              setDocumentList(data);
              setFormData({});
              setTaskDetails({
                taskName: getNextTaskPayload?.taskName,
                taskDefinitionKey: getNextTaskPayload?.taskDefinitionKey,
                taskId: getNextTaskPayload?.taskId,
              });
            }
            
          if (selectedLander?.lenderName?.toLowerCase()?.includes("kotak")) {
            setIsCustomUI(!data.data.payload.customUI); //don't remove bcz in kotak getting false
            const uploadDocument = getJSONSchema(
              data?.data?.payload?.jsonSchema
            );
            if (leadData?.loanType.toLowerCase().includes("home")) {
              setDocumentList(uploadDocument.HL);
            } else if (leadData?.loanType.toLowerCase().includes("business")) {
              setDocumentList(uploadDocument.BL);
            } else {
              setDocumentList(uploadDocument.LAP);
            }
          } else if (
            selectedLander?.lenderName?.toLowerCase()?.includes("hdfc")
          ) {
            setIsCustomUI(data.data.payload.customUI);
          } else {
            const uploadDocument = getJSONSchema(
              data?.data?.payload?.jsonSchema
            );
            setDocumentList(uploadDocument);
          }

          setFormData({});
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        }

        if (
          data?.data?.statusCode === 200 &&
          data?.data?.payload?.taskDefinitionKey ===
            "user_task_submit_application"
        ) {
          console.log("Finish------");
          setloading(false);
          setIsCustomUI(data.data.payload.customUI);
          setFinalJSONSchema(getJSONSchema(data.data.payload.jsonSchema));
          setTaskDetails({
            taskName: data?.data?.payload?.taskName,
            taskDefinitionKey: data?.data?.payload?.taskDefinitionKey,
            taskId: data?.data?.payload?.taskId,
          });
        }

        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("flexi") &&
          data?.data?.payload?.taskDefinitionKey === "user_task_submit_lead"
        ) {
          setFlexi();
        } else if (
          selectedLander?.lenderName?.toLowerCase()?.includes("kotak") &&
          data?.data?.payload?.taskDefinitionKey === "user_task_new_co_applicant"
        ) {
          setKotakCoApplicant();
        }
      })
      .catch((e: any) => {
        setDisplayInCredLoadingScreen(false);
        console.log('e', e);
        if (
          messageICICI &&
          selectedLander?.lenderName?.toLowerCase()?.includes("icici")
        ) {
          if (
            messageICICI.toLowerCase().includes("rejected") ||
            messageICICI.toLowerCase().includes("not eligible")
          ) {
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId: "",
              status: "",
              showRequirementDocs: "false",
            });
            setOpenFlowablePath(false);
            setShowAdditionalFields(false);
            skippedLoginStatus();
          }
        }
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
          selectedLander?.lenderName.toLowerCase().includes("abcl") ||
          selectedLander?.lenderName.toLowerCase().includes("prefr") || 
          selectedLander?.lenderName.toLowerCase().includes("incred")
        ) {
          if (e.response.data.payload.errorMessage) {
            if (
              e.response.data.payload.errorMessage
                .toLowerCase()
                .includes("no tasks found")
            ) {
              if (window["ReactNativeWebView"]) {
                callbackForMobile("END_FLOWABLE");
                return;
              }
              callbackAdditionalField({
                lenderInfoId: selectedLander.lenderInfoId,
                loanId: "",
                status: "",
                showRequirementDocs: "false",
              });
              setOpenFlowablePath(false);
              setShowAdditionalFields(false);
              skippedLoginStatus();
            }
          }
        }
        setloading(false);
      });
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    try {
      str = str?.replaceAll(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replaceAll(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replaceAll(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    } catch (error) {
      str = str?.replace(
        /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
        parameters.ApiUrlOne
      );
      str = str.replace(
        /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
        parameters.ApiUrlTwo
      );
      str = str?.replace(
        /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
        parameters.ApiUrlThree
      );
    }
    return JSON.parse(str);
  };

  let tempArray = [{}];
  let tempObj = {};
  const handleFormSubmit = (
    form,
    getdocList: any = null,
    isConsent: boolean = false
  ) => {
    setloading(true);
    if (selectedLander?.lenderName?.toLowerCase()?.includes("incred")) {
      setDisplayInCredLoadingScreen(true);
      setDisplayInCredRetryScreen(false);
    }
    console.log("handleFormSubmit form: ", form.data);
    setFormData(form.data);

    if (typeof form.data == "string") {
      console.log(JSON.parse(form.data));
      JSON.parse(form.data).map((item) => {
        if (
          item?.documentDetails?.documentName !== undefined &&
          item?.documentDetails?.documentType !== undefined
        ) {
          tempObj = {
            ...item,
            isCoApplicantRequired: true,
            userId: "11142",
            // docType: item?.documentDetails?.documentType,
            // docName: item?.documentDetails?.documentName,
          };
          tempArray.push(tempObj);
        }
      });
      setDocumentDetails(tempArray);
      console.log(tempArray);
    }

    let formData =
      typeof form.data == "string"
        ? JSON.stringify(tempArray)
        : JSON.stringify(form.data);

    if (
      selectedLander?.lenderName?.toLowerCase()?.includes("flexi") &&
      taskDetails.taskDefinitionKey === "user_task_submit_opt"
    ) {
      const tmpForm = {
        otp: `${form?.data?.otp ?? ""}`,
        regenerateOTP: form?.data?.regenerateOTP ?? false,
      };
      formData = JSON.stringify(tmpForm);
    }

    let responseToRender = true;
    if (
      isLastTask &&
      ((selectedLander?.lenderName?.toLowerCase()?.includes("fibe") &&
        leadData?.loanType == "Buy Now Pay Later") ||
        selectedLander?.lenderName?.toLowerCase()?.includes("credit saison") || 
        selectedLander?.lenderName?.toLowerCase()?.includes("bajaj housing finance") ||
        selectedLander?.lenderName?.toLowerCase()?.includes("prefr")
      )
    ) {
      responseToRender = taskDetails?.responseToRender ?? false;
    }
    console.log("isLAsttask",isLastTask)
    console.log("responseToRender",responseToRender
      
    )
    //return

    let payload = {
      formData: formData,
      responseToRender: responseToRender,
      subTaskName: "",
      taskDefinitionKey: taskDetails.taskDefinitionKey,
      taskId: taskDetails.taskId,
    };
    API_SERVICE.submitTask(
      currLenderId,
      currLeadId,
      isConsent,
      JSON.stringify(payload)
    )
      .then(({ data }) => {
        setloading(false);
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("flexi") &&
          !isLastTask
        ) {
          handleGetNextTask(currLeadId, currLenderId);
        }
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("incred")
        ) {
          setDisplayInCredLoadingScreen(false);
          handleGetNextTask(currLeadId, currLenderId);
          return;
        }
        if (data?.payload?.taskDefinitionKey === "user_task_offer_acceptance") {
          if (
            data?.payload?.message.toLowerCase().includes("rejected") ||
            data?.payload?.message.toLowerCase().includes("not eligible")
          ) {
            setMessageICICI(data?.payload?.message);
          }
        }
        if (data?.payload?.responseToRender) {
          setResponseJSONSchema(JSON.parse(data?.payload?.responseJson));
          setIsOfferJSON(true);
          console.log(JSON.parse(data?.payload?.responseJson));
          setTaskDetails({
            taskDefinitionKey: data?.payload?.taskDefinitionKey,
            taskId: data?.payload?.taskId,
          });
          if (
            selectedLander?.lenderName
              ?.toLowerCase()
              ?.includes("nira finance") ||
            ((selectedLander?.lenderName
              ?.toLowerCase()
              ?.includes("moneywide") ||
              selectedLander?.lenderName?.toLowerCase()?.includes("cashe") ||
              selectedLander?.lenderName
                ?.toLowerCase()
                ?.includes("kreditbee")) &&
              data?.payload?.taskDefinitionKey !==
                "user_task_upload_documents" &&
              !selectedLander?.lenderName?.toLowerCase()?.includes("icici"))
          ) {
            console.log("Finish------");
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId: data?.data?.payload?.taskId,
              status: "",
              showRequirementDocs: "false",
            });
            setOpenFlowablePath(false);
            setShowAdditionalFields(false);
            skippedLoginStatus();
            notification.success({
              message: "Loan process completed successfully.",
            });
          }
          if (
            data?.statusCode === 200 &&
            data?.payload?.taskDefinitionKey === "user_task_upload_documents"
          ) {
            console.log("handleGetNextTask1");
            if (selectedLander?.lenderName?.toLowerCase()?.includes("icici")) {
              const loanData = JSON.parse(data?.payload?.responseJson);
              if (
                selectedLander?.lenderName?.toLowerCase()?.includes("icici") &&
                loanData?.submitApplicationResponse?.ilensId
              ) {
                callbackAdditionalField({
                  lenderInfoId: selectedLander.lenderInfoId,
                  loanId: loanData?.submitApplicationResponse?.ilensId,
                  status: "",
                  showRequirementDocs: "false",
                });
                setOpenFlowablePath(false);
                setShowAdditionalFields(false);
                notification.success({
                  message: "Loan process completed successfully.",
                });
              } else {
                if (
                  selectedLander?.lenderName?.toLowerCase()?.includes("icici")
                ) {
                  handleGetNextTask(currLeadId, currLenderId);
                } else {
                  notification.error({
                    message: loanData?.submitApplicationResponse?.resDesc,
                  });
                }
              }
            } else if (
              selectedLander?.lenderName
                ?.toLowerCase()
                ?.includes("credit saison") || selectedLander?.lenderName
                ?.toLowerCase()
                ?.includes("prefr")
            ) {
              let isLastTaskVal = undefined;
              try {
                const responseJSON = JSON.parse(
                  data?.payload?.responseJson ?? "{}"
                );
                isLastTaskVal = responseJSON?.lastStep;
              } catch (error) {}
              if (isLastTaskVal) {
                setOpenFlowablePath(false);
                setShowAdditionalFields(false);
                skippedLoginStatus();
                notification.success({
                  message: "Loan process completed successfully.",
                });
              }
            } else {
              if (
                selectedLander?.lenderName?.toLowerCase()?.includes("kotak") ||
                selectedLander?.lenderName?.toLowerCase()?.includes("flexi") ||
                selectedLander?.lenderName?.toLowerCase()?.includes("hdfc")
              ) {
                setOpenFlowablePath(false);
                setShowAdditionalFields(false);
                skippedLoginStatus();
                if(setCallbackReqDoc) {
                  setCallbackReqDoc({success: true});
                }
                notification.success({
                  message: "Loan process completed successfully.",
                });
              } else {
                setOpenFlowablePath(false);
                setShowAdditionalFields(false);
                lenderLoginHandler({
                  comment: null,
                  assignedLenderFiltered: [selectedLander],
                });
                notification.success({
                  message: "Loan process completed successfully.",
                });
              }
            }
          }
          if (
            data?.statusCode === 200 &&
            data?.payload?.taskDefinitionKey === "user_task_submit_application"
          ) {
            if (data?.payload?.responseJson) {
              const responseJSON = JSON.parse(data?.payload?.responseJson);
              if (responseJSON?.ilensId) {
                callbackAdditionalField({
                  lenderInfoId: selectedLander.lenderInfoId,
                  loanId: responseJSON?.ilensId,
                  status: "",
                  showRequirementDocs: "false",
                });
                setOpenFlowablePath(false);
                setShowAdditionalFields(false);
                skippedLoginStatus();
                notification.success({
                  message: "Loan process completed successfully.",
                });
              }
            }
          }
          if (
            data?.statusCode === 200 &&
            data?.payload?.taskDefinitionKey === "user_task_display_offer"
          ) {
            if (data?.payload?.status === "COMPLETED") {
              if (window["ReactNativeWebView"]) {
                callbackForMobile("END_FLOWABLE");
                return;
              }
              setOpenFlowablePath(false);
              setShowAdditionalFields(false);
              skippedLoginStatus();
              notification.success({
                message: "Loan process completed successfully.",
              });
            }
          }
          setFormData({});
          if (
            !isLastTask &&
            !selectedLander?.lenderName?.toLowerCase()?.includes("cashe")
          ) {
            handleGetNextTask(currLeadId, currLenderId);
          }
        } else {
          if(selectedLander?.lenderName?.toLowerCase()?.includes("bajaj housing finance") || selectedLander?.lenderName?.toLowerCase()?.includes("prefr"))
            {
              if (window["ReactNativeWebView"]) {
                callbackForMobile("END_FLOWABLE");
                return;
              }
              callbackAdditionalField({
                lenderInfoId: selectedLander.lenderInfoId,
                loanId: "",
                status: "",
                showRequirementDocs: "false",
              });
              setOpenFlowablePath(false);
              setShowAdditionalFields(false);
              skippedLoginStatus();
              notification.success({
                message: "Loan process completed successfully.",
              });
            }
          
        }
        if (
          isLastTask &&
          selectedLander?.lenderName?.toLowerCase()?.includes("fibe") &&
          leadData?.loanType == "Buy Now Pay Later"
        ) {
          let loanId = undefined;
          try {
            const responseJSON = JSON.parse(
              data?.payload?.responseJson ?? "{}"
            );
            loanId = responseJSON?.lenderApplicationId;
          } catch (error) {}
          callbackAdditionalField({
            lenderInfoId: selectedLander.lenderInfoId,
            loanId: loanId ?? data?.data?.payload?.taskId,
            status: "",
            showRequirementDocs: "false",
          });
          setOpenFlowablePath(false);
          setShowAdditionalFields(false);
          skippedLoginStatus();
          notification.success({
            message: "Loan process completed successfully.",
          });
        } else if (
          selectedLander?.lenderName
            ?.toLowerCase()
            ?.includes("credit saison") &&
          ["user_task_retry_bre_for_bank_statement"].includes(
            taskDetails.taskDefinitionKey
          )
        ) {
          let isLastTaskVal = undefined;
          try {
            const responseJSON = JSON.parse(
              data?.payload?.responseJson ?? "{}"
            );
            isLastTaskVal = responseJSON?.lastStep;
          } catch (error) {}
          if (isLastTaskVal) {
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId: "",
              status: "",
              showRequirementDocs: "false",
            });
            setOpenFlowablePath(false);
            setShowAdditionalFields(false);
            skippedLoginStatus();
            notification.success({
              message: "Loan process completed successfully.",
            });
          }
        }
      })
      .catch((e: any) => {
        setloading(false);
        setDisplayInCredLoadingScreen(false);
        if (
          selectedLander?.lenderName.toLowerCase().includes("credit saison") &&
          [
            "user_task_lead_push",
            "user_task_retry_bre_result",
            "user_task_retry_bre_for_bank_statement",
          ].includes(taskDetails.taskDefinitionKey)
        ) {
          const errorResponse = API_SERVICE.handleErrors(e);
          let data;
          try {
            data = JSON.parse(errorResponse);
          } catch (jsonError) {
            data = { errorMessage: errorResponse };
          }
          const errorMessage = data.errorMessage || "";
          const match = errorMessage.match(/user task get status rejected/);

          if (match) {
            setCreditSaisonScreenMessage(errorMessage);
            setCreditSaisonScreen(true);
          } else {
            notification.error({
              message: "Error",
              description: errorMessage,
              style: {
                width: 600,
                maxHeight: "100vh",
                overflow: "auto",
              },
            });
          }
        }
        if (taskDetails.taskDefinitionKey === "user_task_upload_documents") {
          getLenderDocumentUploadDetails();
          getdocList();
          // handleGetNextTask(currLeadId, currLenderId);
        }
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("abcfl") ||
          selectedLander?.lenderName.toLowerCase().includes("abcl")
        ) {
          handleGetNextTask(currLeadId, currLenderId);
        }
        notification.error({
          message: API_SERVICE.handleErrors(e),
          style: {
            width: 600,
            maxHeight: "100vh",
            overflow: "auto",
          },
        });

        if (taskDetails.taskDefinitionKey === "user_task_take_consent") {
          onCancelPopup();
        }
        if (
          selectedLander?.lenderName?.toLowerCase()?.includes("incred")
        ) {
          setDisplayInCredLoadingScreen(false);
          handleGetNextTask(currLeadId, currLenderId);
          return;
        }
      });
  };

  const getLenderDocumentUploadDetails = () => {
    API_SERVICE.getLenderDocumentUploadDetails(
      selectedLander.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          setFailedCount(data?.payload?.docFailedCount ?? 0);
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const validateAdditonalField = (form) => {
    if(form?.data?.loanApplication?.loanBusiness?.is_current_account_available == 0) {
      setShowFlexiReason("bankaccount");
      return;
    }

    setloading(true);
    const param = {pincode: form?.data?.loanApplication?.loanBusiness?.pincode};
    API_SERVICE.validateAdditonalField(currLenderId, currLeadId, param)
      .then(({ data }: any) => {
        if(data?.payload?.status) {
          handleFormSubmit(form);
        } else {
          setloading(false);
          setShowFlexiReason(data?.payload?.reason);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) })
        setloading(false);
      })
  };

  const handleJsonFormChange = (e) => {};
  const handleOnNextPage = (e) => {
    setFormData({ ...formData, ...e?.submission?.data });
    //setFormData({ ...formData});
  };

  const handleOnPrevPage = (e) => {
    setFormData({ ...formData, ...e?.submission?.data });
    //setFormData({ ...formData});
  };

  const onClickNext = () => {
    handleGetNextTask(currLeadId, currLenderId);
    setIsOfferJSON(false);
  };

  const beautifyKey = (val) => {
    return (
      val[0].toUpperCase() +
      val
        .slice(1)
        .match(/([A-Z]?[^A-Z]*)/g)
        ?.join(" ")
    );
  };

  const getParseObjectOrString = (obj, val) => {
    if (obj && val) {
      if (typeof obj[`${val}`] === "object") {
        return getObjectOrTag(obj[val]);
      } else {
        return (
          <>
            <Card hoverable={false} style={{ float: "left", width: "50%" }}>
              <Card.Grid hoverable={false} style={{ width: "100%" }}>
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                  <span style={{ fontWeight: "350", color: "black" }}>
                    {beautifyKey(val)}:
                  </span>
                  {obj[val]}
                </p>
              </Card.Grid>
            </Card>
          </>
        );
      }
    }
  };

  const getObjectOrTag = (obj) => {
    if (obj) {
      return Object.keys(obj).map((item) => {
        return getParseObjectOrString(obj, item);
      });
    } else {
      return "";
    }
  };

  const handleCustomEvent = (customEvent: any) => {
    if (customEvent?.type === "reGenerateOtp") {
      handleFormSubmit({ data: { regenerateOTP: true } });
    }
  };

  const callbackForMobile = (actionType: string) => {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ actionType: actionType })
      );
  };

  const onCancelPopup = () => {
    setCloseConfirmation(false);
    setOpenFlowablePath(false);
    callbackForMobile("CLOSE");
  };

  const renderModelConfirmMobile = () => {
    return (
      <Modal
        title={null}
        centered
        width={350}
        closable={false}
        footer={null}
        visible={true}
      >
        <div style={{ margin: "20px", gap: "20px", display: "grid" }}>
          <span style={{ textAlign: "center", marginTop: "15px" }}>
            {`Please confirm if ${leadData?.contactDetails?.mobileNumber} is the borrower contact number.`}
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "6px",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="dynamic-btn-primary"
              style={btnPrimaryStylesWidth}
              onClick={() => {
                handleFormSubmit({});
              }}
            >
              Confirm
            </Button>
            <Button 
              className="dynamic-btn-default"
              style={btnDefaultBorderStyleWidth}
              onClick={() => onCancelPopup()}
            >
              Back
            </Button>
          </div>
        </div>
        {loading && (
          <div className="loader-view-flowable">
            <Spin
              tip="We are processing your request, please wait"
              size="large"
            />
          </div>
        )}
      </Modal>
    );
  };

  const renderModelResendMessage = () => {
    return (
      <Modal
        title={null}
        centered
        width={550}
        closable={false}
        footer={null}
        visible={true}
      >
        <div style={{ margin: "20px", gap: "20px", display: "grid" }}>
          <p style={{ textAlign: "center", marginTop: "15px" }}>
            {`A message for customer consent has been sent to ${leadData?.contactDetails?.mobileNumber}. Please request the borrower to click the link and give consent.`}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "6px",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="dynamic-btn-primary"
              style={btnPrimaryStylesWidth}
              onClick={() => {
                handleFormSubmit({ data: { resendMessage: true } }, null, true);
              }}
            >
              Resend Message
            </Button>
            <Button 
              className="dynamic-btn-default"
              style={btnDefaultBorderStyleWidth}
              onClick={() => onCancelPopup()}
            >
              Close
            </Button>
          </div>
        </div>
        {loading && (
          <div className="loader-view-flowable">
            <Spin
              tip="We are processing your request, please wait"
              size="large"
            />
          </div>
        )}
      </Modal>
    );
  };

  const hideCloseIcon = () => {
    let hideIcon;
    hideIcon = ABCLBreNoOfferScreen || ABCLBreResultScreen;
    return hideIcon;
  };
  
  const hideHeaders = () => {
    let hideHeader;
    hideHeader =
      ABCLBreNoOfferScreen ||
      ABCLBreResultScreen ||
      displayInCredLoadingScreen ||
      displayInCredRetryScreen ||
      (docIframe && ABCLDocuments);
    return hideHeader;
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",paddingInline: "30px"};
  const btnDefaultBorderStyleWidth: any = {'--borderColor': appColors?.appSecondaryColor ?? "", minWidth: "100px"};
  const btnPrimaryStylesWidth : any = {'--btnColor' :appColors?.appPrimaryColor ?? "", minWidth: "100px"};

  return (
    <>
      {
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "auto",
          }}
        >
          <Modal
            width={600}
            visible={closeConfirmation}
            // onCancel={() => onCancelPopup()}
            closable={false}
            footer={null}
            title=""
            maskClosable={true}
          >
            {/* <Row> */}
            <div style={{ margin: "10px", textAlign: "center" }}>
              Are you sure, you want to close the form?
            </div>
            {/* </Row> */}
            {/* <Row> */}
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: "10px" }}>
                <Button                 
                  className="dynamic-btn-default"
                  style={btnDefaultBorderStyle}
                  htmlType="button"
                  onClick={() => {
                    // setShowAdditionalFields(false);
                    setCloseConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
              </div>

              <div style={{ padding: "10px" }}>
                <Button
                  className="dynamic-btn-primary"
                  size="middle"
                  style={btnPrimaryStyles}
                  type="primary"
                  htmlType="button"
                  onClick={() => onCancelPopup()}
                >
                  Ok
                </Button>
              </div>
            </div>
            {/* </Row> */}
          </Modal>
        </div>
      }
      {showFlexiReason.length > 0 && (
        <FlexiPincodeAvailibility
          showFlexiReason={showFlexiReason}
          setShowFlexiReason={setShowFlexiReason}
          currLenderId={currLenderId}
          currLeadId={currLeadId}
          pincode={formData?.loanApplication?.loanBusiness?.pincode}
          message={showFlexiReason == "pincode" 
            ? `${formData?.loanApplication?.loanBusiness?.pincode} Pincode is not serviceable by Flexiloans. Do you want to change your loan business pincode?`
            : `Current Account is mandatory in flexiloans, If Current Bank account is not available, case will get rejected, Do you wish to continue?`
          }
          callBack={() => {
            if (
              window["ReactNativeWebView"] && 
              taskDetails?.taskDefinitionKey === "user_task_upload_documents"
            ) {
              callbackForMobile("CLOSE");
              return;
            }
            callbackAdditionalField({
              lenderInfoId: selectedLander.lenderInfoId,
              loanId: "",
              status: "",
              showRequirementDocs: "false",
            });
            setOpenFlowablePath(false);
            setShowAdditionalFields(false);
            skippedLoginStatus();
          }}
          setLoading={setloading}
        />
      )}
      {taskDetails.taskDefinitionKey === "user_task_confirm_number" || 
      taskDetails.taskDefinitionKey === "user_task_confirm_mobile" ? (
        renderModelConfirmMobile()
      ) : taskDetails.taskDefinitionKey === "user_task_take_consent" ? (
        renderModelResendMessage()
      ) : (
        <Modal
          width={
            ABCLBreOfferDetailsScreen || ABCLBreDocumentScreen
              ? 610
              : ABCLBreNoOfferScreen ||
                ABCLBreResultScreen ||
                CreditSaisonScreen || displayInCredRetryScreen || displayInCredLoadingScreen
              ? 440
              : 1200
          }
          visible={openFlowablePath}
          onCancel={() => setCloseConfirmation(true)}
          closable={!hideCloseIcon()}
          footer={null}
          className={hideHeaders() ? "hide-header" : ""}
          title={
            <div style={{ display: "grid" }}>
              <span style={{ fontSize: "18px" }}>
                {isCustomUI
                  ? `Documents For ${selectedLander?.lenderName}`
                  : taskDetails?.taskDefinitionKey ==
                    "user_task_add_co_applicant"
                  ? "Co Applocant Infomation"
                  : isOfferAcceptanceScreen
                  ? "Customize your loan"
                  : ABCLBreOfferDetailsScreen
                  ? "Loan Offer"
                  : selectedLander?.lenderName
                      ?.toLowerCase()
                      ?.includes("abcfl") ||
                    selectedLander?.lenderName.toLowerCase().includes("abcl")
                  ? ABCLBreResultScreen
                    ? "BRE Screen"
                    : ABCLBreDocumentScreen
                    ? docIframe
                      ? ""
                      : "Upload Documents"
                    : ABCLBreNoOfferScreen
                    ? ""
                    : "Applicant Details"
                  : "Customer Infomation"}
              </span>
              {["hdfc bank"].includes(
                selectedLander?.lenderName?.toLowerCase()
              ) &&
                isCustomUI && (
                  <span style={{ fontSize: "14px" }}>
                    Only one document allowed...
                  </span>
                )}
            </div>
          }
          maskClosable={false}
        >
          {!isCustomUI ? (
            <>
            {console.log('isCustomUI', isCustomUI)}
              {!isOfferJSON ? (
                <>
                  {isOtpScreen ? (
                    <OtpScreen
                      data={213}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      handleFormSubmit={handleFormSubmit}
                    />
                  ) : isOfferAcceptanceScreen ? (
                    <OfferAcceptanceScreen
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      handleFormSubmit={handleFormSubmit}
                    />
                  ) : ABCLBreResultScreen ? (
                    <ABCLBreResults
                      data={1}
                      handleFormSubmit={handleFormSubmit}
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      setCloseConfirmation={setCloseConfirmation}
                      loading={loading}
                    />
                  ) : ABCLBreDocumentScreen ? (
                    <ABCLDocuments
                      data={1}
                      handleFormSubmit={handleFormSubmit}
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      setCloseConfirmation={setCloseConfirmation}
                      setloading={setloading}
                      setDocIframe={setDocIframe}
                    />
                  ) : ABCLBreOfferDetailsScreen ? (
                    <ABCLOfferDetails
                      data={1}
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      handleFormSubmit={handleFormSubmit}
                      setCloseConfirmation={setCloseConfirmation}
                    />
                  ) : ABCLBreNoOfferScreen ? (
                    <ABCLBreNoOffer
                      data={1}
                      handleFormSubmit={handleFormSubmit}
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      setCloseConfirmation={setCloseConfirmation}
                    />
                  ) : CreditSaisonScreen ? (
                    <CreditSaisonRejected
                      data={1}
                      handleFormSubmit={handleFormSubmit}
                      responseJSONSchema={responseJSONSchema}
                      leadData={leadData}
                      selectedLander={selectedLander}
                      setCloseConfirmation={setCloseConfirmation}
                      errorMessage={CreditSaisonScreenMessage}
                      redirectpage={() => {
                        callbackAdditionalField({
                          lenderInfoId: selectedLander.lenderInfoId,
                          loanId: "",
                          status: "",
                          showRequirementDocs: "false",
                        });
                        setOpenFlowablePath(false);
                        setShowAdditionalFields(false);
                        skippedLoginStatus();
                      }}
                    />
                  ) : displayInCredLoadingScreen ? ( 
                    <InCredLoadingScreen loading={displayInCredLoadingScreen} />
                  ) : (
                    <Form
                      // options={{readOnly:true}}
                      // readOnly={true}
                      // renderMode='html'
                      submission={{ data: formData }}
                      onChange={handleJsonFormChange}
                      key={"jsonForm"}
                      onSubmit={(form) => {
                        if(selectedLander?.lenderName?.toLowerCase()?.includes("flexi") && 
                          taskDetails?.taskDefinitionKey === "user_task_submit_lead") {
                          setFormData(form.data);
                          validateAdditonalField(form);
                        } else {
                          handleFormSubmit(form)
                        }
                      }}
                      onNextPage={handleOnNextPage}
                      onPrevPage={handleOnPrevPage}
                      form={finalJSONSchema}
                      jsonSchema={finalJSONSchema}
                      // form={jsonFile.JSONSchema}
                      // jsonSchema={jsonFile.JSONSchema}
                      onCustomEvent={handleCustomEvent}
                    />
                  )}
                </>
              ) : (
                <>
                  {responseJSONSchema &&
                  taskDetails.taskDefinitionKey !==
                    "user_task_display_offer" ? (
                    <>{getObjectOrTag(responseJSONSchema)}</>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingBlock: "20px",
                    }}
                  ></div>
                  <Button
                    style={{
                      float: "right",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    onClick={onClickNext}
                  >
                    Next
                  </Button>
                </>
              )}
            </>
          ) : (
            displayInCredRetryScreen ? (
              <>
              {console.log('isCustomUI 2', isCustomUI)}
              <InCredRetryScreen
                data={1}
                handleFormSubmit={handleFormSubmit}
                responseJSONSchema={responseJSONSchema}
                leadData={leadData}
                selectedLander={selectedLander}
                setCloseConfirmation={setCloseConfirmation}
                loading={loading}
              />
              </>
            ) : displayInCredLoadingScreen ? ( 
              <InCredLoadingScreen loading={displayInCredLoadingScreen} />
            ) : (
              <RequiredDocumentFlowable
                setDocumentList={setDocumentList}
                documentList={documentList}
                handleFormSubmit={handleFormSubmit}
                finalJSONSchema={finalJSONSchema}
                leadData={leadData}
                setFinalJSONSchema={setFinalJSONSchema}
                selectedLander={selectedLander}
                loadingDisplay={loading}
                isDocumentUploadResponse={isDocumentUploadResponse}
                setIsDocumentUploadResponse={setIsDocumentUploadResponse}
                setShowAdditionalFields={setShowAdditionalFields}
                skippedLoginStatus={skippedLoginStatus}
                failedCount={failedCount}
                getLenderDocumentUploadDetails={getLenderDocumentUploadDetails}
              />
            )
          )}
        </Modal>
      )}
      <>
      {loading && !ABCLBreResultScreen && !displayInCredLoadingScreen &&  (
            <div className="loader-view-flowable">
              <Spin
                tip="We are processing your request, please wait"
                size="large"
              ></Spin>
            </div>
          )}
      </>
    </>
  );
};

export default AddtionalFieldsFlowable;
