import { Button, Checkbox, Col, Modal, notification, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import UploadDocument from "../UploadDocument/UploadDocument";
import "./index.scss";
import DocCategory from "./DocCategory";
import ReplaceDocument from "./ReplaceDocument";
import RenameDocument from "./RenameDocument";
import AppLoading from "shared/components/AppLoading/AppLoading";
import SkipDocument from "./SkipDocument";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import imgDocument from "../../../../assets/images/document_download.svg";
import imgTickRight from "../../../../assets/images/tickRight.svg";
import imgLoading from "../../../../assets/images/loading.svg";
import wrong from "../../../../assets/images/wrong.svg";
import '../../LeadNew/LeadDetail/Document/Document.scss';

const RequiredDocuments: React.FunctionComponent<any> = ({
  leadData,
  selectedLander,
  setShowRequirementDocs,
  callBackDoc,
}) => {
  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [checkedDocuments, setCheckedDocuments] = useState<any>([]);
  const [isAllDocChecked, setIsAllDocChecked] = useState<any>(false);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [showReplace, setShowReplace] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [documentList, setDocumentList] = useState([] as any);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [isConflictClick, setIsConflictClick] = useState(false);
  const [failedCount, setFailedCount] = useState(0);
  const [showSkipDoc, setShowSkipDoc] = useState(false);
  const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    getDocumentList();
    getLenderDocumentUploadDetails();
    getDocumentBankList();
  }, []);

  const getDocumentBankList = () => {

    API_SERVICE.getLenderShortCode(selectedLander.lenderInfoId).then(({ data }) => {
      if (data?.payload) {
        const masterKey = 'BANK_NAME';
        const lenderShortCode = data?.payload?.lenderShortCode;
        API_SERVICE.getDocumentBankListFoLender(masterKey, lenderShortCode).then(({ data }) => {
          if (data?.payload) {
            if (data?.payload?.content?.length > 0) {
              const updtedData: any = [];
                  data?.payload?.content?.filter((item: any) => {
                    updtedData.push({
                      label: item.value,
                      value: item.key
                    });
                  });
              setBankList(updtedData);
            } else {
              API_SERVICE.getDocumentBankList(masterKey).then(({ data }) => {
                if (data?.payload) {
                  const updtedData: any = [];
                  data?.payload?.filter((item: any) => {
                    updtedData.push({
                      label: item.value,
                      value: item.id
                    });
                  });
                  setBankList(updtedData);
                }
              }).catch((e) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
              })
            }
          }
        }).catch((e) =>{
          notification.error({ message: API_SERVICE.handleErrors(e) });
        });
      }
    }).catch((e) => {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    });
  }

  const getDocumentList = (isLoading = true) => {
    if (isLoading) {
      setLoading(true);
    }
    API_SERVICE.getLenderDocumentlist(
      selectedLander.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          generateCategoryList(data.payload);
          const sortedType = data.payload?.sort((a, b) =>
            a.documentType < b.documentType ? 1 : -1
          );
          const sortedMandatory = sortedType?.sort((a, b) =>
            a.require < b.require ? 1 : -1
          );
          const updatedSortedArray = sortedMandatory.map((item) => {
            return {
              ...item,
              documentOrgType: item?.documentType,
              isUpdatable: true,
              isRemovable: false,
              format:
                item?.format?.length === 1 && item?.format?.includes("jpg")
                  ? [...item?.format, "jpeg"]
                  : item?.format,
            };
          });
          setDocumentList(
            updatedSortedArray.filter((col) => "ZIP" !== col.documentType)
          );
        }
        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateBankDetails = (documentID, reqData, callBack) => {
    const { bankName, accountNumber, filePassword, doc } = reqData?.additionalInfo;
    const payload = {
      documentAdditionalInfo: {  
        documentApplicantType: doc?.documentDetails?.documentApplicantType,
        docOrder: doc?.documentDetails?.docOrder,
        fileSize: doc?.documentDetails?.fileSize,
        documentTypeId: doc?.documentTypeId,
        bankId: bankName,
        accountNumber: accountNumber,
        filePassword: filePassword,
      },
    };
    const businessID = doc?.documentDetails?.businessDocumentId;
    API_SERVICE.updateDocumentBankDetail(businessID, payload)
      .then(({ data }) => {
        if (data) {
          if (data?.payload) {
            callBack(data?.payload?.documentAdditionalInfoDto);
          }
        }
      })
      .catch((error) => {});
  };

  const getUpdatedList = () => {
    getDocumentList(false);
  };

  const getLenderDocumentUploadDetails = () => {
    API_SERVICE.getLenderDocumentUploadDetails(
      selectedLander.lenderInfoId,
      leadData?.caseDetails?.caseId
    )
      .then(({ data }) => {
        if (data) {
          setFailedCount(data?.payload?.docFailedCount ?? 0);
        }
      })
      .catch((error) => {
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const generateCategoryList = (docs: any) => {
    const tmpLendersData = Array.from(
      new Set(docs.map((item) => item.documentCategory))
    );

    const tmp = tmpLendersData?.map((category) => {
      const filter = docs.filter((item) => item.documentCategory == category);

      const f1 = filter?.map((item) => {
        let docType = "";
        if (item?.documentDetails != null) {
          docType = item?.documentType + "~" + item?.documentDetails?.docPage;
        }
        let lenderValue = "";
        if (
          item?.documentDetails != null &&
          item?.documentDetails?.docPage > 0
        ) {
          lenderValue = item?.identifier + "~" + item?.documentDetails?.docPage;
        }
        return {
          ...item,
          isUpdatable: true,
          isRemovable: (item?.documentDetails?.docPage ?? 0) > 0,
          documentOrgType: item?.documentType,
          documentType:
            (docType?.length ?? 0) > 1 ? docType : item?.documentType,
          lenderDocumentValuesOrg: item?.lenderDocumentValues,
          // lenderDocumentValues:
          //   (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.lenderDocumentValues,
          identifier:
            (lenderValue?.length ?? 0) > 1 ? lenderValue : item?.identifier,
          identifierOrg: item?.identifier,
        };
      });

      const categoryRequire = f1?.some((data) => data.require);
      const requiredYes = f1?.some((item) => item.require);
      const requiredNO = f1?.every((item) => !item.require);
      const uploadedDoc = f1?.filter((items) => items.documentDetails);
      const mandatoryDocs = f1?.filter(
        (items) => items.require && items.documentDetails
      );
      const mandatoryTotalDocs = f1?.filter((items) => items.require);
      return {
        category: category,
        categoryRequire: categoryRequire,
        docs: f1,
        mandatory: requiredYes ? 1 : requiredNO ? 0 : 2,
        mandatoryCount: mandatoryDocs?.length,
        totalDocs: mandatoryTotalDocs?.length,
        uploadedDocs: uploadedDoc.length,
      };
    });

    console.log("tmp: ", tmp);

    setDocumentListCat(tmp);
  };

  const docReplaceDocs = (docID) => {
    const tmp = documentListCat.map((documents) => {
      const updatedDocs = documents.docs.filter((item) => {
        if (item?.documentDetails) {
          if (item?.documentDetails?.businessDocumentId === docID) {
            item.documentDetails = null;
            item.conflict = true;
            return { ...item };
          } else {
            return item;
          }
        } else {
          return item;
        }
      });
      const uploadedDoc = updatedDocs?.filter((items) => items.documentDetails);
      const mandatoryDocs = updatedDocs?.filter(
        (items) => items.require && items.documentDetails
      );
      const mandatoryTotalDocs = updatedDocs?.filter((items) => items.require);
      return {
        category: documents.category,
        docs: updatedDocs,
        totalDocs: mandatoryTotalDocs?.length,
        uploadedDocs: uploadedDoc?.length,
        mandatoryCount: mandatoryDocs?.length,
      };
    });
    setDocumentListCat(tmp);
  };

  const updateDocumentToLender = () => {
    const tmpCheckedDocuments: any = [];
    const tmpDocumentSubmitDetails: any = [];
    documentListCat?.map((document) => {
      document?.docs?.map((item) => {
        const isDocUploaded = item?.lenderDocumentStatus == "UPLOADED";
        if (
          (checkedDocuments?.includes(item?.identifier) || item.require) &&
          !isDocUploaded
        ) {
          tmpCheckedDocuments.push(item?.documentDetails?.businessDocumentId);
          tmpDocumentSubmitDetails.push({
            businessDocumentId: item?.documentDetails?.businessDocumentId,
            lenderDocumentValue: item?.lenderDocumentValues.split("~").at(0),
            documentCategory: item?.documentCategory,
          });
        }
        //  else if (
        //   checkedDocuments?.includes(item?.identifier) && !isDocUploaded
        // ) {
        //   tmpCheckedDocuments.push(
        //     item?.documentDetails?.businessDocumentId
        //   );
        //   tmpDocumentSubmitDetails.push({
        //     businessDocumentId: item?.documentDetails?.businessDocumentId,
        //     lenderDocumentValue: item?.lenderDocumentValues.split('~').at(0),
        //     documentCategory: item?.documentCategory
        //   });
        // }
      });
    });

    // return ;
    if (tmpDocumentSubmitDetails?.length > 0 && checkedDocuments.length > 0) {
      setLoading(true);
      API_SERVICE.updateDocumentToLender(
        selectedLander.lenderInfoId,
        leadData?.loanProductId,
        leadData?.caseDetails?.caseId,
        {
          businessDocumentIds: [...tmpCheckedDocuments],
          documentSubmitDetails: tmpDocumentSubmitDetails,
        }
      )
        .then(({ data }) => {
          setCheckedDocuments([]);
          setIsAllDocChecked(false);
          setLoading(false);
          if (data?.payload?.failure > 0) {
            getDocumentList();
          } else {
            callBackDoc({
              success: true,
              lenderInfoId: selectedLander.lenderInfoId,
            });
          }
          setShowRequirementDocs(false);
        })
        .catch((e: any) => {
          setCheckedDocuments([]);
          setIsAllDocChecked(false);
          getDocumentList();
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
          getLenderDocumentUploadDetails();
        });
    } else {
      if (documentListCat.length > 0) {
        notification.error({ message: "Please select Document before upload" });
      } else {
        callBackDoc({
          success: true,
          lenderInfoId: selectedLander.lenderInfoId,
        });
        setShowRequirementDocs(false);
      }
    }
  };

  const updateDocumentMapping = () => {
    updateDocumentToLender();
    return;
  };

  const actionOnDelete = (
    doc: any,
    isRemove: any = false,
    isChangeDoc: any = true
  ) => {
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        API_SERVICE.deleteDocReference(
          leadData?.caseDetails?.caseId,
          doc.documentDetails.businessDocumentId
        ).then((data1) => {
          if (data1) {
            if (isRemove) {
              actionRemove(doc);
            }
            if (isChangeDoc) {
              changeDocumentDetail(doc, null);
            }
          }
        });
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const changeDocumentDetail = (doc, docDetail, type = "") => {
    getUpdatedList();
  };

  const actionAdd = (doc, index) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        const tmplDoclist = document?.docs.map((items) => {
          if (items?.identifier === doc?.identifier) {
            items.isUpdatable = false;
            return items;
          }
          return items;
        });
        const tmoDocLists = tmplDoclist.filter(
          (val) => val?.identifierOrg === doc?.identifierOrg
        );
        const indexDocumnet = tmplDoclist.findIndex(
          (lstItem) => lstItem?.identifier === doc?.identifier,
          index
        );
        if (indexDocumnet !== -1) {
          tmplDoclist.splice(index + 1, 0, {
            ...doc,
            isUpdatable: true,
            documentDetails: null,
            isRemovable: true,
            lenderDocumentStatus: "",
            documentType: doc?.documentOrgType,
            lenderDocumentValues: `${doc?.lenderDocumentValuesOrg}~${tmoDocLists?.length}`,
            identifierOrg: doc?.identifier,
            identifier: `${doc?.identifier}~${tmoDocLists?.length}`,
          });
        }

        const uploadedDoc = tmplDoclist?.filter(
          (items) => items.documentDetails
        );
        const mandatoryDocs = tmplDoclist?.filter(
          (items) => items.require && items.documentDetails
        );
        const mandatoryTotalDocs = tmplDoclist?.filter(
          (items) => items.require
        );
        return {
          ...document,
          docs: tmplDoclist,
          totalDocs: mandatoryTotalDocs?.length,
          uploadedDocs: uploadedDoc?.length,
          mandatoryCount: mandatoryDocs?.length,
        };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };

  const actionRemove = (doc) => {
    const tmp1 = documentListCat?.map((document) => {
      if (document.category == doc.documentCategory) {
        let updateTmpLst = document?.docs?.filter(
          (items) => items.identifier !== doc.identifier
        );
        const lstName: any[] = [];
        updateTmpLst.filter((value) => {
          if (value.identifierOrg === doc.identifierOrg) {
            lstName.push(value.identifier);
          }
        });
        if (lstName?.length > 0) {
          updateTmpLst = updateTmpLst.map((value) => {
            if (value.identifier === lstName[lstName.length - 1]) {
              return {
                ...value,
                isUpdatable: true,
                isRemovable: lstName?.length !== 1,
              };
            }
            return value;
          });
        }

        const uploadedDoc = updateTmpLst?.filter(
          (items) => items.documentDetails
        );
        const mandatoryDocs = updateTmpLst?.filter(
          (items) => items.require && items.documentDetails
        );
        const mandatoryTotalDocs = updateTmpLst?.filter(
          (items) => items.require
        );
        return {
          ...document,
          docs: updateTmpLst,
          totalDocs: mandatoryTotalDocs?.length,
          uploadedDocs: uploadedDoc?.length,
          mandatoryCount: mandatoryDocs?.length,
        };
      }

      return document;
    });

    setDocumentListCat(tmp1);
  };

  const getDisableBtn = () => {
    let isDisable = false;
    documentListCat?.map((document) => {
      if (!isDisable) {
        isDisable = document?.docs?.some(
          (item) =>
            (item?.require && item.documentDetails == null) ||
            (item?.documentDetails &&
              (item?.maxSize < item?.documentDetails?.fileSize ||
                !item?.format?.includes(item?.documentDetails?.fileExtn))) ||
            (item?.require &&
              !checkedDocuments.includes(item?.identifier) &&
              item?.lenderDocumentStatus !== "UPLOADED")
        );
      }
    });
    return isDisable;
  };

  const actionReplaceDoc = (doc) => {
    setSelectedDocument(doc);
    setShowReplace(true);
  };

  const actionRenameDoc = (doc) => {
    setSelectedDocument(doc);
    setShowRename(true);
  };

  const onChangeCheckbox = (value, doc, isDelete = false) => {
    const id = doc?.identifier;
    let tmp = [...checkedDocuments];
    if (tmp.includes(id)) {
      setIsAllDocChecked(false);
      const filtered = tmp.filter((lstItem) => lstItem !== id);
      tmp = filtered;
    } else {
      if (!isDelete) {
        tmp.push(id);
      }
    }
    setCheckedDocuments(tmp);
  };

  const onChangeCheckboxAll = (value) => {
    const checked = value?.target?.checked;
    setIsAllDocChecked(checked);
    let tmp = [...checkedDocuments];
    if (checked) {
      documentListCat?.map((document) => {
        document?.docs?.map((item) => {
          if (
            item?.documentDetails &&
            item?.lenderDocumentStatus !== "UPLOADED" &&
            item?.documentDetails
          ) {
            tmp.push(item?.identifier);
          }
        });
      });
    } else {
      tmp = [];
    }
    setCheckedDocuments(tmp);
  };

  const getDocPage = () => {
    const docPageValues = selectedDocument?.identifier?.split("~");
    const docPage = docPageValues.length > 1 ? docPageValues?.at(-1) : 0;
    return (docPage?.length ?? 0) == 0 ? 0 : docPage;
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? "",paddingInline: "30px"};
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? "",paddingInline: "30px"};
  const btnCheckboxStyles: any = { '--bgColor': appColors?.appPrimaryColor ?? "" };

  const downloadPopup = () => {
    console.log('::data');
    return (
      <Modal className="model-document-upload" visible={popupVisible} footer={null} onCancel={() => {
        setPopupVisible(false)
        setUploadDocumentList({sucess: [], failed: []});
        }} mask={false}>
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of {Number(uploadDocumentList.sucess.length) + Number(uploadDocumentList.failed.length)} Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
            {items.status === 'Done' ? (
              <img src={imgTickRight} height={30} width={30} />
            ) : (
              <img className="loading-img" src={imgLoading} height={30} width={30} />
            )}
            </Col>
          </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
          <Row className="document-content" key={`doc_${index}`}>
            <Col span={2} className="doc-cols">
              <img src={imgDocument} />
            </Col>
            <Col span={13} className="doc-cols">
              <span className="document-name">{items?.name}</span>
            </Col>
            <Col span={6} className="doc-cols">
              <div className="document-size">{items?.size}KB</div>
            </Col>
            <Col span={3} className="doc-cols">
              <img src={wrong} height={30} width={30} />
            </Col>
          </Row>
          );
        })}
      </Modal>
    );
  };

  return (
    <>
      <div className="document-upload">{downloadPopup()}</div>
      {showReplace && (
        <ReplaceDocument
          documentSetId={selectedLander?.documentSetId}
          showReplaceDoc={showReplace}
          setShowReplaceDoc={setShowReplace}
          selectedDocument={selectedDocument}
          docReplaceDocs={docReplaceDocs}
          leadData={leadData}
          getDocPage={getDocPage}
          getAllDocumentList={getUpdatedList}
          selectedLander={selectedLander}
          isConflict={isConflictClick}
          actionAdd={() => {
            setShowReplace(false);
            setShowUploadDocument(true);
          }}
          callbackSuccess={(docDetail) => {
            changeDocumentDetail(selectedDocument, docDetail);
            setShowReplace(false);
          }}
          callbackDocUploaded={(isUpload, tmpDoc) => {
            if (isUpload) {
              selectedDocument.documentDetails = tmpDoc;
              changeDocumentDetail(selectedDocument, tmpDoc);
            }
            setShowUploadDocument(false);
            setShowReplace(false);
          }}
          actionOnDelete={actionOnDelete}
        />
      )}
      {showRename && (
        <RenameDocument
          showRenameDoc={showRename}
          setShowRenameDoc={setShowRename}
          selectedDocument={selectedDocument}
          callbackSuccess={(docDetail) => {
            changeDocumentDetail(selectedDocument, docDetail);
            setShowRename(false);
          }}
        />
      )}
      {showSkipDoc && (
        <SkipDocument
          showSkipDoc={showSkipDoc}
          setShowSkipDoc={setShowSkipDoc}
          documentListCat={documentListCat}
          lenderId={selectedLander.lenderInfoId}
          caseId={leadData?.caseDetails?.caseId}
          callbackSuccess={() => {
            setShowSkipDoc(false);
            callBackDoc({
              success: true,
              lenderInfoId: selectedLander.lenderInfoId,
            });
            setShowRequirementDocs(false);
          }}
        />
      )}
      {showUploadDocument && (
        <Modal
          title="Upload New Document:"
          centered
          width={400}
          onCancel={() => {
            setShowUploadDocument(false);
          }}
          footer={null}
          visible={showUploadDocument}
        >
          <UploadDocument
            // selectedType={"SIX_MONTH_SALARY_SLIP"}
            isLenderDocs={true}
            multiple={true}
            selectedLander={selectedLander}
            formatDocument={selectedDocument?.format ?? ""}
            selectedType={selectedDocument?.documentType ?? ""}
            docOrder={
              selectedDocument?.documentDetails?.docOrder ??
              selectedDocument?.documentOrgType?.split("~")?.at(-1) ??
              0
            }
            docPage={getDocPage()}
            maxSize={selectedDocument?.maxSize ?? 5}
            documentSetId={selectedLander?.documentSetId}
            individualType={leadData.applicationSubType}
            callBack={(isUpload, tmpDoc) => {
              setShowUploadDocument(false);
              if (isUpload) {
                const tmpDocumentList = documentList?.map((doc) => {
                  if (doc?.identifier === tmpDoc.identifier) {
                    return { ...doc, documentDetails: tmpDoc };
                  }
                  return doc;
                });
                setDocumentList(tmpDocumentList);

                changeDocumentDetail(selectedDocument, tmpDoc, "add");
              }
            }}
            setPopupVisible={setPopupVisible}
            popupVisible={popupVisible}
            uploadDocumentList={uploadDocumentList}
            setUploadDocumentList={setUploadDocumentList}
          />
        </Modal>
      )}
      <div className="required-document-box">
        <div className="doc-title">
          {"Documents for " + selectedLander.lenderName}
          <span>
            {
              "Click on the checkbox and upload selected documents to be shared with the lender"
            }
          </span>
        </div>
        <div style={{ paddingInline: "25px" }}>
          <Row style={{ paddingInline: "16px" }}>
            <Col span={2}>
              <Checkbox
                checked={isAllDocChecked}
                onChange={onChangeCheckboxAll}
                className="dynamic-pagination-checkbox"
                style={btnCheckboxStyles}
              />
            </Col>
            <Col span={6}>{"File Name"}</Col>
            <Col span={5}>{"Lender Doc Name"}</Col>
            <Col span={3} style={{ display: "flex", justifyContent: "center" }}>
              {"Format"}
            </Col>
            <Col span={2}>{"Max Size"}</Col>
            <Col span={6} style={{ textAlign: "center" }}>
              {"Actions"}
            </Col>
          </Row>

          {documentListCat?.map((item) => {
            return (
              <DocCategory
                isFlowable={false}
                documentCategoryList={item}
                actionOnDelete={actionOnDelete}
                actionOnUpload={(doc) => {
                  setSelectedDocument(doc);
                  setShowUploadDocument(true);
                }}
                actionAdd={actionAdd}
                actionRemove={actionRemove}
                actionReplaceDoc={actionReplaceDoc}
                actionRenameDoc={actionRenameDoc}
                onChangeCheckbox={onChangeCheckbox}
                checkedDocuments={checkedDocuments}
                setIsConflictClick={setIsConflictClick}
                updateBankDetails={updateBankDetails}
                selectedLander={selectedLander}
                bankList={bankList}
                callbackUpdateDoc={(documents) => {
                  const tmp = [...documentListCat];
                  const findExistingItem = tmp.find((item) => {
                    return item.category === documents.category;
                  });
                  if (findExistingItem) {
                    findExistingItem.docs = documents.docs;
                  }
                  setDocumentListCat(tmp);
                }}
              />
            );
          })}

          {/* <DocCategory /> */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Space className="">
          <Button
            className="dynamic-btn-default"
            size="middle"
            style={btnDefaultBorderStyle}
            htmlType="button"
            disabled={failedCount < 3}
            onClick={() => {
              setShowSkipDoc(true);
            }}
          >
            Skip
          </Button>
          <Button
            className="dynamic-btn-primary"
            size="middle"
            style={btnPrimaryStyles}
            loading={loading}
            type="primary"
            htmlType="button"
            disabled={getDisableBtn()}
            onClick={() => {
              updateDocumentMapping();
            }}
          >
            Next
          </Button>
        </Space>
      </div>

      {loading && <AppLoading />}
    </>
  );
};

export default RequiredDocuments;
