import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Radio,
  Space,
  notification,
  InputNumber,
  List
} from "antd";
import API_SERVICE from "shared/services/api-service";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setScreenHeader } from "shared/redux/common-reducer";
import { RootState } from "shared/redux/store";
const { Title, Text } = Typography;

type Props = {};

const ViewEmployee: React.FunctionComponent<Props> = () => {
  const [genders, setGenders] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [bureauCreditDetails, setBureauCreditDetails] = useState(null as any)
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const history = useHistory();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const nameLocation = useLocation();
  const userName: any = nameLocation.state;
  let { id } = useParams() as any;

  if (!id) {
    history.goBack();
  }

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Employee",
        backScreenPath: "/employees",
        screenTitle: userName?.fullName,
      })
    );
    getAllTypes();
    getAllTypes12();
    getBureauCreditsData();
  }, []);

  const getAllTypes12 = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        let filteredGenderType = data.find(
          (item: any) => item.propertyName == "GENDER"
        );
        setGenders(filteredGenderType.keyAndDisplayValueList);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllTypes = () => {
    try {
      API_SERVICE.getUser(id)
        .then(({ data }) => {
          if (data) {
            let gender = data?.payload?.userDetails?.gender;
            gender = gender == "Male" ? "M" : gender == "Female" ? "F" : gender == "Other" ? "O" : gender
            setSelectedGender(gender);
            form.setFieldsValue({
              name: data?.payload?.userDetails?.fullName,
              phoneNumber: data?.payload?.userDetails?.phoneNumber,
              email: data?.payload?.userDetails?.emailId,
              dob: data?.payload?.userDetails?.dob,
              gender: gender,
              pan: data?.payload?.userDetails?.panCard,
              aadhar: data?.payload?.userDetails?.aadhar,
              department: data?.payload?.userDetails?.department,
              designation: data?.payload?.userDetails?.designation,
              branch: data?.payload?.userDetails?.branch,
              lineManager: data?.payload?.userDetails?.lineManager,
              bureauCredits: data?.payload?.userDetails?.bureauCredits,
            });
          }
        })
    }
    catch (e: any) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  };

  const getBureauCreditsData = () => {
    API_SERVICE.getBureauCredits(id)
      .then(({ data }) => {
        if (data) {
          setBureauCreditDetails(data?.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  }

  const bureauRequestsSummary = [
    {
      title: "Current Month Consumed:",
      list: (bureauCreditDetails?.currentMonthConsumed),
    },
    {
      title: "Current Month Remaining:",
      list: (bureauCreditDetails?.currentMonthRemaining),
    },
    {
      title: "Lifetime Consumed:",
      list: (bureauCreditDetails?.lifeTimeConsumed),
    },
  ];

  const btnRadioStyles: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};

  return (
    <div className="content-box">
      <Form
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        name="leadForm"
        form={form}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }} >User Details</label>
            <div className="form-box">
              <Form.Item
                label="Full Name"
                name="name"
                className="field-bg"
              >
                <Input className="custom-input" placeholder="Enter name" readOnly />
              </Form.Item>

              <Form.Item
                label="Mobile Number"
                name="phoneNumber"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                  readOnly
                />
              </Form.Item>

              <Form.Item
                label="Email ID"
                name="email"
                className="field-bg"
              >
                <Input className="custom-input" placeholder="Enter email." readOnly />
              </Form.Item>

              <Form.Item
                label="Date of Birth"
                name="dob"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter mobile number"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="PAN"
                name="pan"
                className="field-bg"
              >
                <Input className="custom-input" placeholder="Enter PAN number" readOnly />
              </Form.Item>
              <Form.Item
                label="Aadhar"
                name="aadhar"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter aadhar details"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Select Gender"
                name="gender"
                rules={[{ required: true, message: "Please select gender." }]}
              >
                <Radio.Group
                  className="custom-radio"
                  style={btnRadioStyles}
                  name="gender"
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((gender: any, i: number) => (
                        <Radio.Button disabled={gender.key != selectedGender} key={i} value={gender.key}>
                          {gender.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            <label style={{ marginBottom: "15px" }} >Select Roles</label>
            <div className="form-box">
              <Form.Item
                label="Department"
                name="department"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter aadhar details"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Location"
                name="branch"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter aadhar details"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Line Manager"
                name="lineManager"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter aadhar details"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="Designation"
                name="designation"
                className="field-bg"
              >
                <Input
                  className="custom-input"
                  placeholder="Enter designation"
                  readOnly
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8}>
            <label style={{ marginBottom: "15px" }} >Bureau Requests Tracking</label>
            <div className="form-box">
              <Form.Item
                label="Current Month Limit"
                name="bureauCredits"
                className="field-bg"
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Enter bureau score limit"
                  readOnly
                />
              </Form.Item>
              <label style={{ marginBottom: "15px" }} >Bureau Requests Summary</label>

              <List
                className="field-bg"
                itemLayout="horizontal"
                dataSource={bureauRequestsSummary}
                renderItem={(item) => {
                  return (
                    <>
                      <List.Item>
                        <Text style={{ color: "rgba(0, 0, 0, 0.65)" }}><Space>{item.title}{item.list}</Space></Text>
                      </List.Item>
                    </>
                  );
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ViewEmployee;
