import "./loan-product.scss";

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaPen, FaPlus } from "react-icons/fa";
import API_SERVICE from "shared/services/api-service";
import { hasUserRole } from "shared/services/Utility";
import type { ColumnsType } from "antd/es/table";
import blackRefreshIcon from "../../assets/images/blackReferesh.svg";
import {ReactComponent as IconEditBorder} from "../../assets/images/edit_border.svg";
import {ReactComponent as IconDeleteBorder} from "../../assets/images/delete_border.svg";
import { NUMBER_REGEX } from "shared/constants/AppConstants";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";

const { Title } = Typography;
const { Option } = Select;
type Props = {};

const LoanProduct: React.FunctionComponent<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [allConnectors, setAllConnectors] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>(undefined);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const userRolePermission = hasUserRole();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenHeader({ screenTitle: "Manage Loan Product" }));
    getAllLoanProducts();
  }, []);

  const handleDelete = (key: any) => {
    const newData = allConnectors.filter((item) => item !== key);
    const payload = newData;
    API_SERVICE.addLoanProduct(payload)
      .then(({ data }) => {
        if (data) {
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const edit = (record: any) => {
    setSelectedProduct(record);
    setIsFilterVisible(true);
  };

  const handleFilterModal = () => {
    setIsFilterVisible(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "loanProduct",
      key: "productType",
    },
    {
      title: "Product listing order",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Status",
      key: "status",
      render: (_: any, record: any) => (
        <Switch
          style={{'--bgColor': appColors?.appPrimaryColor ?? ''}as any}
          defaultChecked={record.active}
          onChange={(event) => onChangeStatus(event, record)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      align: 'center',
      width: 100,
      render: (_: any, record: any) => (
        <Space className="action-button" size="small">
          <Space
            className="action-button"
            size="small"
            style={{ marginLeft: "1%" }}
          >
            <Button
              className="custome-btn-icon-secondary"
              style={{'--bgColor': appColors?.appSecondaryColor ?? ""} as any}
              size="small"
              type="text"
              icon={<IconEditBorder />}
              hidden={
                !(
                  userRolePermission["UpdateOperations"] ||
                  userRolePermission["Admin"]
                )
              }
              onClick={() => edit(_)}
            />
          </Space>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(_)}
            okButtonProps={{ style: { backgroundColor: appColors?.appPrimaryColor ?? "#273896", border:"none" } }} 
            cancelButtonProps={{ style: { borderColor: appColors?.appSecondaryColor ?? "#C71C8E", color: appColors?.appSecondaryColor ?? "#C71C8E" } }} 
            >
              <Button
                style={{backgroundColor:"transparent", border:'none'}}
                size="small"
                type="text"
                icon={<IconDeleteBorder width={24} height={24}/>}
                hidden={
                  !(
                    userRolePermission["UpdateOperations"] ||
                    userRolePermission["Admin"]
                  )
                }
              />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onChangeStatus = (event: any, item: any) => {
    item.active = event;
    const newData1 = allConnectors.filter((item1) => item1 !== item);
    const newData = [...newData1, item];
    const payload = newData;
    API_SERVICE.addLoanProduct(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: "Status Updated Successfully" });
          setIsFilterVisible(false);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getAllLoanProducts = () => {
    setLoading(true);
    API_SERVICE.getLoanProduct()
      .then(({ data }) => {
        if (data.payload.length > 0) {
          setAllConnectors(data.payload);
        } else {
          notification.error({ message: "No Loan Product found." });
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFilterSubmit = (event: any) => {
    let pro = allConnectors;
    const filter = allConnectors.filter((item) => item !== selectedProduct);
    if ((filter.length ?? 0) > 0) {
      pro = filter;
    }
    let newPro = event;
    if (selectedProduct?.loanProductId == event?.loanProductId) {
      newPro = { ...selectedProduct, ...event }
    }
    setSelectedProduct(undefined);
    var newData = [...pro, newPro];
    const payload = newData;
    API_SERVICE.addLoanProduct(payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          setIsFilterVisible(false);
          setAllConnectors(data.payload);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "", width: "170px !important" };

  return (
    <div className="content-box">
      {isFilterVisible && (
        <Modal
          title={`${selectedProduct ? "Edit" : "Add"}` + " Loan Product"}
          centered
          width={700}
          onCancel={() => {
            setIsFilterVisible(false);
            setSelectedProduct(undefined);
          }}
          footer={null}
          visible={isFilterVisible}
        >
          <AddLoanProduct
            onSubmitFilter={onFilterSubmit}
            selectedValue={selectedProduct}
          />
        </Modal>
      )}

      <Row>
        <Col className="filter-bar" span={16}>
        </Col>
        <Col span={8} className="filter-bar filter-bar-right">
          <Button
            style={{ marginLeft: "50px" }}
            type="primary"
            className="refresh-button"
            onClick={() => {
              getAllLoanProducts();
            }}
            shape="round"
            //icon={<ReloadOutlined />}
            size={"middle"}
          > <img src={blackRefreshIcon} alt="" style={{ marginLeft: '-10px' }} /></Button>
          <Button
            type="primary"
            className="add-button11 dynamic-btn-primary"
            onClick={handleFilterModal}
            shape="round"
            icon={<FaPlus />}
            size={"middle"}
            style={btnPrimaryStyles}
            disabled={!(userRolePermission["Admin"] || userRolePermission["CreateOperations"])}
            hidden={
              !(
                userRolePermission["CreateOperations"] ||
                userRolePermission["Admin"]
              )
            }
          >
            Add Loan Product
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={allConnectors}
            scroll={{ x: "max-content" }}
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default LoanProduct;

export const AddLoanProduct: React.FunctionComponent<any> = ({
  onSubmitFilter,
  selectedValue,
}) => {
  const [products, setProducts] = useState([] as any);
  const { appColors }: any = useSelector((state: RootState) => state.common);
  const btnDefaultBorderStyle: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const btnPrimaryStyles: any = { '--btnColor': appColors?.appPrimaryColor ?? "" };
  const [form] = Form.useForm();

  useEffect(() => {
    if (!selectedValue) {
      form.resetFields();
    }
    getLoanProducts();
  }, []);

  const getLoanProducts = () => {
    API_SERVICE.getLoanProductListCommon().then(({ data }) => {
      if (data) {
        setProducts(data.payload);
        if (selectedValue) {
          form.setFieldsValue({
            loanProduct: selectedValue.loanProductId,
            sequence: selectedValue.sequence
          });
        }
      }
    });
  };

  const onFinish = (value: any) => {
    const sequence = value?.sequence
    const productFiltered = products?.filter(
      (item) => item.loanProductId == value.loanProduct
    );
    if ((productFiltered?.length ?? 0) > 0) {
      onSubmitFilter({
        loanProduct: productFiltered[0].description,
        loanProductId: productFiltered[0].loanProductId,
        active: true,
        sequence: sequence
      });
    }
  };

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      autoComplete="off"
      onFinish={onFinish}
    >
      <Row>
        <Col span={24} style={{ marginTop: "30px" }}>
          <Form.Item
            className="field-bg-border"
            name="loanProduct"
            label="Product"
            rules={[{ required: true, message: "Please select loan product" }]}
          >
            <Select
              showSearch
              className="custom-select"
              placeholder="Product"
              optionFilterProp="children"
              disabled={selectedValue}
              // onChange={(value, option) => changeProductHandler(value)}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase())
              }
            >
              {products?.
                sort(function (a: any, b: any) {
                  if (a.description.toLowerCase() < b.description.toLowerCase()) return -1;
                  if (a.description.toLowerCase() > b.description.toLowerCase()) return 1;
                  return 0;
                })?.map((product: any, i: number) => (
                  <Option key={i} value={product.loanProductId}>
                    {product.description}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name='sequence'
            label='Product listing order'
            rules={[
              {
                required: true,
                message: 'Please enter Product listing order'
              },
              {
                pattern: NUMBER_REGEX,
                message: 'Only Number Allow'
              }
            ]}
          >
            <Input className="custom-input" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: "right" }}>
          <br />
          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={() => form.resetFields()} className="dynamic-btn-default" style={btnDefaultBorderStyle}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit" className="dynamic-btn-primary" style={btnPrimaryStyles}>
                Add
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <br />
    </Form>
  );
};
