import { Button, Modal, notification } from "antd";
import { useEffect, useState } from "react";
import AppLoading from "shared/components/AppLoading/AppLoading";
import API_SERVICE from "shared/services/api-service";

interface IBankStatement {
  leadData: any;
  setIsFinBox: any;
  isFinBox: any;
  getVASActionStatus: any;
  setIsLoading: any;
}

const BankStatement: React.FC<IBankStatement> = (props: IBankStatement) => {
  const { leadData, setIsFinBox, isFinBox, getVASActionStatus, setIsLoading } =
    props;
  const [finBoxURL, setFinBoxURL] = useState("");
  // const [isFinBox, setIsFinBox] = useState(false)
  const [showDisclaimerMessage, setShowDisclaimerMessage] = useState(false);
  const [disclaimerMessage, setDisclaimerMessage] = useState("");

  useEffect(() => {
    getFinboxURL();
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event: any) => {
    if (event?.data?.type === "finbox-bankconnect") {
      console.log("Event -> ", event);
      if (event?.data?.status === "exit") {
        setIsFinBox(false);
      } else if (event?.data?.status === "success") {
        setFinBoxURL("");
        const entityId = event?.data?.payload?.entityId;
        const getAnalysis = localStorage.getItem("getAnalysis");
        if (getAnalysis !== "true") {
          localStorage.setItem("getAnalysis", "true");
          setIsLoading(true);
          API_SERVICE.getAnalysis(entityId, leadData?.caseDetails?.caseId ?? "")
            .then(({ data }) => {
              console.log("data", data);
              if ((data?.payload?.disclaimerMessage?.length ?? 0) > 0) {
                setShowDisclaimerMessage(true);
                setDisclaimerMessage(data?.payload?.disclaimerMessage);
              } else {
                setIsFinBox(false);
              }
              getVASActionStatus();
            })
            .catch((e: any) => {
              notification.error({ message: API_SERVICE.handleErrors(e) });
              setIsLoading(false);
            });
        }
      }
    }
  };

  const getFinboxURL = () => {
    localStorage.setItem("getAnalysis", "false");
    API_SERVICE.getFinboxURL(leadData?.caseDetails?.caseId ?? "caseId")
      .then(({ data }) => {
        if (data) {
          setIsFinBox((data?.redirect_url?.length ?? 0) > 0);
          setFinBoxURL(data?.redirect_url);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  console.log("showDisclaimerMessage", showDisclaimerMessage);

  const renderDisclaimerMessageModal = () => (
    <Modal
      title=""
      centered
      width={400}
      closable={false}
      footer={null}
      visible={showDisclaimerMessage}
    >
      <div style={{ padding: "10px" }}>
        <div style={{ textAlign: "center" }}>{disclaimerMessage}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Button
            size="large"
            type="primary"
            style={{ width: "140px" }}
            onClick={() => {
              setShowDisclaimerMessage(false);
              setIsFinBox(false);
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      {isFinBox && (
        <Modal
          // title="Upload New Document:"
          centered
          // width={400}
          onCancel={() => setFinBoxURL("")}
          closable={false}
          footer={null}
          visible={finBoxURL.length > 0}
          className="modal-finbox"
        >
          <iframe
            aria-hidden="false"
            frameBorder="0"
            style={{ height: "500px", width: "100%" }}
            src={finBoxURL}
            onDragExit={() => {
              console.log("On exit");
            }}
          />
        </Modal>
      )}
      {/* {isLoading && <AppLoading />} */}

      {renderDisclaimerMessageModal()}
    </>
  );
};

export default BankStatement;
