import React, { useEffect, useState } from "react";
import { Badge, Button, Drawer, Layout, Typography, notification } from "antd";
import {ReactComponent as IconMenu} from "../../../assets/images/menu.svg";
import SideBar from "./SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import { AuthConsumer } from "shared/contexts/AuthContext";
import headerNotificationIcon from "../../../assets/images/headerNotificationIcon.svg";
import { Link, useHistory } from "react-router-dom";
import API_SERVICE from "shared/services/api-service";
import { setNotificationCount } from "shared/redux/notificationCount-reducer";
import { setAppTitle } from "shared/services/Utility";
import { FaBars } from "react-icons/fa";
import "./AppLayout.scss";

const { Content, Sider } = Layout;
const { Title } = Typography;

export default function AppLayout({ children }: any) {
  //   const { screenTitle } = useSelector((state: RootState) => state.common);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [collapsedSlider, setCollapsedSider] = useState(false);
  const [showSiderMobile, setShowSiderMobile] = useState(false);
  const [userData, setUserData] = React.useState(null as any);

  const { notificationCount } = useSelector(
    (state: RootState) => state.notificationCount
  );
  const { screenHeader } = useSelector((state: RootState) => state.common);
  const { screenTitle, backScreenTitle, backScreenPath } = screenHeader;

  const history = useHistory();
  const {appColors}: any = useSelector((state: RootState) => state.common);
  const dispatch = useDispatch();

  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      setUserData(JSON.parse(user));
    }

    setAppTitle();
    getUnreadNotificationCnt();

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getUnreadNotificationCnt = () => {
    API_SERVICE.getUnreadNotificationCnt()
      .then(({ data }) => {
        dispatch(setNotificationCount(data?.payload?.unReadCount));
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const customStyles :any = {'--bgColor': appColors?.appPrimaryColor ?? ""}

  const renderHeader = () => {
    return (
      <div className={isMobile ? "app-header mview" : "app-header"}>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Button
              type="text"
              size={"middle"}
              onClick={() => setShowSiderMobile(true)}
              icon={<FaBars />}
            />
          ) : (
            <></>
            // <img
            //   src={iconMenu}
            //   alt=""
            //   className={"sider-menu-icon" + (collapsedSlider ? " collapsed" : "")}
            //   onClick={() => setCollapsedSider(!collapsedSlider)}
            // />
          )}
          <Title
            level={4}
            style={{
              marginBottom: "0px",
              marginLeft: "15px",
              fontWeight: "normal",
            }}
          >
            {(backScreenTitle?.length ?? 0) > 0 && (
              <>
                <Link className="link" to={backScreenPath ?? ""} style={customStyles}>
                  {backScreenTitle}
                </Link>
                &nbsp; &gt; &nbsp;
              </>
            )}
            {screenTitle}
          </Title>
        </div>
        <div className="header">
          <Badge count={notificationCount} className="notification-bell">
            <img
              src={headerNotificationIcon}
              style={{ width: "25px", height: "25px" }}
              onClick={() => history.push("/notificationbar")}
            />
          </Badge>
          <Button
            type="default"
            className="header-logo"
            shape="round"
            size={"middle"}
            onClick={() => history.push("/view-profile")}
          >
            {userData?.userDetails?.firstName
              ? userData?.userDetails?.firstName?.at(0)
              : "Welcome"}
          </Button>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <>
        {/* <Content><AppRoutes /></Content> */}
        <Content>{children}</Content>
      </>
    );
  };

  return (
    <AuthConsumer>
      {({ isAuth, apiCreds }) => {
        return (
          <>
            <Layout style={{ minHeight: "100vh" }} className={`desktop-layout`}>
              {isMobile ? (
                <Drawer
                  footer={false}
                  visible={showSiderMobile}
                  placement="left"
                  closable={false}
                  width={230}
                  className="navbar-box"
                  onClose={() => setShowSiderMobile(false)}
                >
                  <div style={{ width: "220px", marginLeft: "0px" }}>
                    <SideBar key={2} closeNavHandler={setShowSiderMobile} />
                  </div>
                </Drawer>
              ) : (
                <Sider collapsed={collapsedSlider}>
                  <>
                    <SideBar
                      key={2}
                      closeNavHandler={setShowSiderMobile}
                      collapsedSlider={collapsedSlider}
                    />
                    <IconMenu style={customStyles} height={24} width={24}  className="sider-icon-collapse"  onClick={() => setCollapsedSider(!collapsedSlider)} />
                  </>
                </Sider>
              )}

              <Layout>
                  {renderHeader()}
                <div className="content-wrapper">
                  <div className="body1">{renderBody()}</div>
                </div>
              </Layout>
            </Layout>
          </>
        );
      }}
    </AuthConsumer>
  );
}
