import { Card, Typography } from "antd";
import { useEffect, useState } from "react";
import API_SERVICE from "shared/services/api-service";
import "./help.scss";

type Props = {};
const { Title, Paragraph } = Typography;

const TermsNConditions: React.FunctionComponent<Props> = (props) => {
  const [termsAndConditions, setTermsAndConditions] = useState([]);

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = () => {
    API_SERVICE.termsAndConditions().then((data: any) => {
      if (data?.data?.payload.length > 0) {
        setTermsAndConditions(data.data.payload);
      }
    });
  };

  return (
    <>
     <Card className="custom-app-card">
        <div style={{ padding: "5px", height: "62vh",overflow:'auto'}}>
          <Title level={5}>TERMS OF USE (FOR CUSTOMERS)</Title>
          {termsAndConditions.map((data: any) => (
            <Paragraph>
              <pre style={{wordBreak:"break-word",backgroundColor:"transparent",border:"none"}}>{data.description}</pre>
            </Paragraph>
          ))}
        </div>
      </Card>
    </>
  );
};

export default TermsNConditions;
