import React, { useEffect, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  Space,
  Tabs,
  notification,
  Divider,
  InputNumber,
  Select,
  RadioChangeEvent,
  AutoComplete,
  DatePicker
} from "antd";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import debounce from "lodash/debounce";

import API_SERVICE from "shared/services/api-service";

//import * as _ from "lodash";
import "./index.scss";
import { GREATERTHEN_ZERO_REGEX, PHONE_NUMBER_REGEX, STRING_ONLY } from "shared/constants/AppConstants";
import moment from "moment";
import FormDOB from "components/FormDOB/FormDOB";
import { numberWithCommas } from "shared/services/Utility";
import { setScreenHeader } from "shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import LeadDetailNew from "../LeadNew/LeadDetail/LeadDetailNew";
import AddLeadNew from "../LeadNew/AddLeadNew/AddLeadNew";
import { RootState } from "shared/redux/store";
const { TabPane } = Tabs;
const { Title, Text } = Typography;
type Props = {};

const AddLeadForm: React.FunctionComponent<Props> = () => {
  const history = useHistory();
  const location = useLocation() as any;
  const [loading, setLoading] = useState(false);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [applicantTypes, setApplicantType] = useState([]);
  const [individualTypes, setIndividualType] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [allConnectors, setAllConnectors] = useState([]);
  const [selectedConnector, setSelectedConnector] = useState(null as any);
  const [assignToData, setAssignToData] = useState([] as any);
  const [selectedApplicationType, setSelectedApplicationType] =
    useState("INDIVIDUAL");
  const [leadForm] = Form.useForm() as any;
  const [selectedAssignTo, setSelectedAssignTo] = useState(null as any);
  const [activeSelfOrConnector, setActiveSelfOrConnector] = useState("2");
  const [isDisableStep, setIsDisableStep] = useState(true);
  const [isDisableCustomer, setIsDisableCustomer] = useState(true);
  const [isBureauScore, setIsBureauScore] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [isCustomerIndian, setIsCustomerIndian] = useState(false);
  const [searchedCustomers, setSearchedCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null as any);
  const [isSelectedCustomer, setIsSelectedCustomer] = useState(null as any);
  const [bureauScoreStatus, setBureauScoreStatus] = useState("");
  let { id } = useParams() as any;
  const [editData, setEditData] = useState({} as any);
  const [isPersonalLoan, setIsPersonalLoan] = useState(false);
  const [selectedLoanType, setSelectedLoanType] = useState("");
  const [hideBusinessDetails, setHideBusinessDetails] = useState(false);
  const [isCreditCardLoan, setIsCreditCard] = useState(false);
  const [selectedBackOfficeUser, setSelectedBackOfficeUser] = useState(
    null as any
  );
  const [assignToBackOfficeData, setAssignToBackOfficeData] = useState(
    [] as any
  );
  const [backOfficerUserId, setBackOfficerUserId] = useState(null as any);
  const [businessProfile, setBusinessProfile] = useState([] as any);
  const [placeOfStudys, setPlaceOfStudys] = useState([] as any);
  const [admissionStatus, setAdmissionStatus] = useState([] as any);
  const [propertyOwnership, setPropertyOwnership] = useState([] as any);
  const [isDisableBusinessData, setIsDisableBusinessData] = useState(false);
  const [tentativePayout, setTentativePayout] = useState(null as any);
  const [isClearBackOfficer, setIsClearBackOficer] = useState(false);
  const [isEditV2, setIsEditV2] = useState(false);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  let userDetails = localStorage.getItem("user") as any;
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  const dispatch = useDispatch();


  const getAllTypes = () => {
    API_SERVICE.getTypes().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  useEffect(() => {
    dispatch(
      setScreenHeader({
        backScreenTitle: "Lead",
        backScreenPath: "/lead",
        screenTitle: id ? "Edit Lead" : "Add Lead",
      })
    );

    leadForm.setFieldsValue({
      loanType: location?.state?.radioValue || "",
    });
    getAllTypes();
    setEnumValues();

    if (id) {
      setIsDisableStep(false);
      getLeadById();
    }
  }, []);

  useEffect(() => {
    console.log("selectedBackOfficeUser", selectedBackOfficeUser);
  });

  const getLeadById = () => {
    let user = localStorage.getItem("user") as any;
    if (user) {
      user = JSON.parse(user);
    }
    API_SERVICE.getLeadsById(id)
      .then(({ data }) => {
        if (data) {
          setEditData(data?.payload);
          const payoutType =
            data.payload.caseDetails.connectorDetails.payoutType;
          setTentativePayout(payoutType);
          if (data?.payload?.applicationType === null) {
            if (isDisableBusinessData === false) {
              setIsDisableBusinessData(true);
            }
          }
          prepareDataForEdit(
            data.payload,
            data.payload.caseDetails.assignedToUserDetails
          );
          if((data?.payload?.loanProductId ?? 0) == 0){
            setIsEditV2(true)
          }
          if (data?.payload?.productVersion == "V2") {
            if (data?.payload?.processInstanceId) {
              history.replace(`/lead/details/${data?.payload?.caseDetails?.caseId}`);
            } else {
              notification.error({
                message: "Process Instance Id Not Available",
              });
            }
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const prepareDataForEdit = (userData: any, assignTo) => {
    let enumValues = localStorage.getItem("enumValues") as any;
    let aType: any = null;
    let iType: any = null;
    let bType: any = null;

    // console.log("enumvalue", enumValues)
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let businessTypes = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );

      aType = filteredApplicationType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationType
      ) as any;
      iType = filteredIndivType.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
      bType = businessTypes.keyAndDisplayValueList.find(
        (item: any) => item.value === userData.applicationSubType
      ) as any;
    }

    let filteredLoanType = enumValues?.find(
      (item: any) => item.propertyName === "LOAN_TYPE"
    );

    let loanType = "";
    const loanTypesFiltered = filteredLoanType?.keyAndDisplayValueList?.filter(
      (item) => item.key === userData?.loanProductId
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const tmpIsPersonalLoan = loanType === "Personal Loan";
    const tmpIsEL = loanType === "Education Loan";
    setIsPersonalLoan(tmpIsPersonalLoan);
    setSelectedLoanType(loanType);
    setIsDisableBusinessData(false);

    const tmpIndiType = tmpIsPersonalLoan ? "SALARIED" : iType?.key;
    if(loanType === "Home loan" && tmpIndiType == "SALARIED") {
      setHideBusinessDetails(true)
    } else {
      setHideBusinessDetails(false)
    }

    const timeOfStudy = userData.caseDetails.loanDetails?.educationLoanDetails?.timeOfStudy

    setSelectedApplicationType((tmpIsPersonalLoan || tmpIsEL) ? "INDIVIDUAL" : aType?.key);
    setBackOfficerUserId(userData?.caseDetails?.backOfficeUserDetails?.userId);
    setActiveSelfOrConnector(userData?.sourceOfCase?.toUpperCase() === "SELF" ? "1" : "2");
    leadForm.setFieldsValue({
      connector:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData?.caseDetails?.connectorDetails?.userDetails?.fullName
          : "",
      tentativePayout:
        userData?.sourceOfCase?.toUpperCase() === "CONNECTOR"
          ? userData.caseDetails.loanDetails.expectedInterestRate
          : "",
      applicantType: (tmpIsPersonalLoan || tmpIsEL) ? "INDIVIDUAL" : aType?.key || "",
      individualType: tmpIsPersonalLoan ? "SALARIED" : iType?.key || "",
      businessType: bType?.key || "",
      gender: userData.contactDetails.gender,
      pan:
        userData.contactDetails.pan === "PENDING"
          ? ""
          : userData.contactDetails.pan,
      businessPan: userData?.contactDetails?.pan === "PENDING" ? "" : userData.contactDetails.pan,
      businessName: userData.contactDetails.firstName,
      searchCustomer: userData.contactDetails.firstName,
      phoneCode: "+91",
      mobileNumber: userData?.contactDetails?.mobileNumber?.startsWith("1") ? "" : userData?.contactDetails?.mobileNumber,
      email: userData.contactDetails.emailId,
      officePinCode: userData.officePincode,
      dateOfBirth:
        userData?.contactDetails?.dateOfBirth &&
        moment(userData.contactDetails.dateOfBirth),
      pincode: userData.address.pinCode,
      address1: userData.address.address,
      city: userData.address.city,
      state: userData.address.state,
      employername: userData.entityOrEmployerName,
      loanAmount: userData.caseDetails.loanDetails.loanAmount,
      loanTenure: userData.caseDetails.loanDetails.loanTenureInYears,
      monthlyInHandSalary:
        userData.caseDetails.loanDetails?.monthlyInHandSalary,
      currentEmis: userData.caseDetails.loanDetails?.currentEmis,
      loanType: userData?.loanProductId,
      comment: userData.caseDetails.lenderActivityHolder.comments,
      assignTo: assignTo?.fullName,
      assignToBackOffice:
        userData?.caseDetails?.backOfficeUserDetails?.fullName,
      // contactName: userData?.contactDetails?.contactName,
      firstName: userData?.contactDetails?.firstName,
      middleName: userData?.contactDetails?.middleName,
      lastName: userData?.contactDetails?.lastName,
      businessProfile: userData?.businessProfile,
      businessVintage: userData?.businessVintage,
      businessTurnover: userData?.businessTurnOver,
      monthlyEmi: userData?.monthlyEmi,
      propertyOwnership: userData?.caseDetails?.propertyOwnership,
      timeOfStudy: timeOfStudy ? moment(timeOfStudy) : null,
      placeOfStudy: userData.caseDetails.loanDetails?.educationLoanDetails?.placeOfStudy,
      admissionStatus: userData.caseDetails.loanDetails?.educationLoanDetails?.admissionStatus,
    });

    setBureauScoreStatus(
      userData?.leadAdditionalInfoDetails?.bureauScoreStatus ?? ""
    );
  };

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredLoanType = enumValues.find(
        (item: any) => item.propertyName === "LOAN_TYPE"
      );
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let filteredGenderType = enumValues.find(
        (item: any) => item.propertyName === "GENDER"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let businessProfileValue = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_PROFILE"
      );
      let propertyOwnershipValue = enumValues.find(
        (item: any) => item.propertyName === "PROPERTY_OWNERSHIP"
      );
      let placeStudyValue = enumValues.find(
        (item: any) => item.propertyName === "PLACE_STUDY"
      );
      let admissionStatusValue = enumValues.find(
        (item: any) => item.propertyName === "ADMISSION_STATUS"
      );

      if (businessProfileValue) {
        setBusinessProfile(businessProfileValue?.keyAndDisplayValueList);
      }
      if (filteredLoanType) {
        setLoanTypes(filteredLoanType.keyAndDisplayValueList);
      }
      if (filteredApplicationType) {
        setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      }
      if (filteredIndivType) {
        setIndividualType(filteredIndivType.keyAndDisplayValueList);
      }
      if (filteredGenderType) {
        setGenders(filteredGenderType.keyAndDisplayValueList);
      }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
      if (propertyOwnershipValue) {
        setPropertyOwnership(propertyOwnershipValue?.keyAndDisplayValueList);
      }
      if (placeStudyValue) {
        setPlaceOfStudys(placeStudyValue?.keyAndDisplayValueList);
      }
      if (admissionStatusValue) {
        setAdmissionStatus(admissionStatusValue?.keyAndDisplayValueList);
      }
    }
  };

  const onSearchExistingCustomer = (e: any) => {
    API_SERVICE.searchCustomers(e).then(({ data }: any) => {
      setSearchedCustomers(data?.payload ?? []);
    });
  };

  const onSelectExistingCustomer = (event: any) => {
    const customers = [...searchedCustomers];
    const customer = customers.find(
      (item: any) =>
        item?.customerId === event?.props?.children
    );
    setSelectedCustomer(customer);
  };

  const onSearchConnector = (e: any) => {
    const params = {
      name: e,
    };
    API_SERVICE.getConnector(params).then(({ data }: any) => {
      if (data.payload.content.length > 0) {
        const tmpConnectors = data.payload?.content?.filter(
          (item: any) => item?.userDetails?.active
        );
        setAllConnectors(tmpConnectors ?? []);
      }
    });
  };

  const onSelectConnector = (event: any) => {
    const connector = [...allConnectors];
    const connectorItem = connector.find(
      (item: any) =>
        item.userDetails.userId === event?.props?.children
    );
    setSelectedConnector(connectorItem);
  };

  // const checkPanDetails = (e: any) => {
  //   console.log("call");
  //   e.persist();
  //   const { value: panNumber } = e.target;
  //   const params = {
  //     panNumber: panNumber,
  //     consent: "Y",
  //   };
  //   if (e?.target?.value) {
  //     API_SERVICE.checkPanDetailsForProfile(params).then(({ data }) => {
  //       if (selectedApplicationType !== "INDIVIDUAL") {
  //         leadForm.setFieldsValue({
  //           employername: data?.payload?.result?.name,
  //         });
  //       } else {
  //         console.log("data else", data?.payload?.result);
  //         let date = moment(data?.payload?.result?.dob);
  //         console.log("date", date);
  //         leadForm.setFieldsValue({
  //           firstName: data?.payload?.result?.name,
  //           dateOfBirth: date,
  //         });
  //       }
  //     });
  //   }
  // };

  const checkPincode = (e: any) => {
    const { value: nextValue } = e.target;
    const params = {
      pin_code: nextValue,
    };
    API_SERVICE.checkPincode(params).then(({ data }) => {
      if (data) {
        leadForm.setFieldsValue({
          city: data.payload.city,
          state: data.payload.state,
        });
      }
    });
  };

  const createPayload = (formData: any) => {
    const isValidDob = moment(
      moment(leadForm.getFieldValue("dateOfBirth"))
        .format("YYYY-MM-DD")
        .toString()
    ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"));
    if (formData && formData.dateOfBirth && !isValidDob) {
      notification.error({
        message: "DOB should be greater than or equal to 18 yrs",
      });
      return;
    }
    const bureauScore =
      bureauScoreStatus.length !== 0
        ? bureauScoreStatus
        : isBureauScore
          ? "REQUEST_INITIATED"
          : null;

    const assignToIdTmp =
      selectedAssignTo?.userId ??
      editData?.caseDetails?.assignedToUserDetails?.userId;
    const assignToId = assignToIdTmp ?? userDetails?.userDetails?.userId;
    let backOfficeUserId = selectedBackOfficeUser?.userId
      ? selectedBackOfficeUser.userId
      : backOfficerUserId;

    backOfficeUserId = isClearBackOfficer ? "" : backOfficeUserId;

    const connectorIdTmp =
      selectedConnector?.userDetails?.userId ??
      editData?.caseDetails?.connectorDetails?.userDetails?.userId;
    const connectorId = connectorIdTmp;

    let loanType = "";
    const loanTypesFiltered = loanTypes?.filter(
      (item) => item.key === formData.loanType
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }

    const data = {
      loanType: loanType,
      loanProductId: formData.loanType,
      sourceOfCase: activeSelfOrConnector === "1" ? "SELF" : "CONNECTOR",
      address: {
        address: formData.address1,
        area: formData.city,
        city: formData.city,
        country: userDetails?.userDetails.address.country,
        geoCode: userDetails?.userDetails.address.geoCode,
        pinCode: formData.pincode,
        state: formData.state,
      },
      applicationSubType:
        formData.applicantType === "INDIVIDUAL"
          ? formData.individualType
          : formData.businessType,
      applicationType: formData.applicantType,
      officePincode: formData.officePinCode,
      comments: formData.comment,
      assignToUserId: assignToId,
      backOfficeUserId: backOfficeUserId,
      businessProfile: formData?.businessProfile,
      businessTurnOver: formData?.businessTurnover,
      monthlyEmi: formData?.monthlyEmi,
      propertyOwnership: formData?.propertyOwnership,
      businessVintage: formData?.businessVintage,
      commissionDetail: {
        connectorName: formData.firstName,
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: connectorId,
      customerId: selectedCustomer?.customerId || "",
      nationality: isCustomerIndian ? "INDIAN" : null,
      bureauScoreStatus: bureauScore,
      contactDetails: {
        gender: formData.gender,
        // contactName: formData.contactName,
        firstName: formData?.firstName,
        middleName: formData?.middleName,
        lastName: formData?.lastName,
        dateOfBirth:
          formData &&
            formData.dateOfBirth &&
            moment(
              moment(leadForm.getFieldValue("dateOfBirth"))
                .format("YYYY-MM-DD")
                .toString()
            ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"))
            ? moment(formData.dateOfBirth).format("YYYY-MM-DD").toString()
            : "",
        emailId: formData.email,
        mobileNumber: formData.mobileNumber,
        pan:
          formData.applicantType === "INDIVIDUAL"
            ? formData.pan
            : formData.businessPan,
      },
      entityOrEmployerName: formData.employername,
      loanDetails: {
        expectedInterestRate:
          formData.tentativePayout ?? selectedConnector?.payoutPercentage,
        loanAmount: isCreditCardLoan ? 100000 : formData.loanAmount,
        loanTenureInYears: isCreditCardLoan ? 1 : formData.loanTenure,
        monthlyInHandSalary: formData.monthlyInHandSalary,
        currentEmis: formData.currentEmis,
        educationLoanDetails: {
          timeOfStudy: formData?.timeOfStudy ? moment(formData.timeOfStudy).format("YYYY-MM").toString() : null,
          placeOfStudy: formData?.placeOfStudy ?? null,
          admissionStatus: formData?.admissionStatus ?? null
        }
      },
    };
    return data;
  };

  const editLead = (payload:any) => {
    setLoading(true);
    API_SERVICE.editLead(editData.caseDetails.caseId, payload)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data.message });
          history.push(`/lead/details/${id}`);
          // history.push("/lead");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onFinishForm = (values: any) => {
    if (!loading) {
      setLoading(true);
    }
    if (id) {
      const payload = createPayload(values);
      console.log("payload", payload);
      editLead(payload);
    } else {
      const payload = createPayload(values);
      console.log("payload", payload);
      setLoading(true);
      API_SERVICE.addLead(payload)
        .then(({ data }) => {
          if (data) {
            notification.success({ message: data.message });
            // history.push("/lead");
            history.push(`/lead/details/${data?.payload?.caseDetails?.caseId}`);
          }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getLeadAdditional = (pan: string) => {
    API_SERVICE.getLeadAdditional(pan)
      .then(({ data }) => {
        if (data) {
          setBureauScoreStatus(data?.payload?.bureauScoreStatus ?? "");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
        setFieldsValue();
      });
  };

  const onChangeApplicationType = (e: RadioChangeEvent) => {
    setSelectedApplicationType(e.target.value);
    if (e.target.value) {
      if (isDisableBusinessData === true) {
        setIsDisableBusinessData(false);
      }
    }
    leadForm.resetFields(["employername", "businessPan", "pan"]);
    setIsBureauScore(false);
    if(leadForm?.getFieldValue('loanType') == "LP00000006" && e.target.value == "INDIVIDUAL" && leadForm?.getFieldValue('individualType') == "SALARIED") {
      setHideBusinessDetails(true)
    } else {
      setHideBusinessDetails(false)
    }
  };

  const searchAssignTo = (ev: any) => {
    const params = {
      name: ev,
    };
    API_SERVICE.getUsersWithRole(params).then(({ data }) => {
      if (data) {
        setAssignToData(data.payload);
      }
    });
  };

  const disableDate = (current) => {
    // let eighteenYearsAgo = new Date();
    // let eightAgo = eighteenYearsAgo.setFullYear(
    //   eighteenYearsAgo.getFullYear()
    // );
    return current && current > new Date();
  };

  const disableTimeOfStudy = (current) => {
    return current && current < new Date();
  };

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select>
        <Select.Option value="+91">+91</Select.Option>
      </Select>
    </Form.Item>
  );

  if (!userDetails) return <></>;

  const onRadioChange = (e: any) => {
    let loanType = "";
    const loanTypesFiltered = loanTypes?.filter(
      (item) => item.key === e.target.value
    );
    if ((loanTypesFiltered?.length ?? 0) > 0) {
      loanType = loanTypesFiltered[0].value;
    }
    console.log('loanType', loanType);
    setIsPersonalLoan(loanType === "Personal Loan");
    setSelectedLoanType(loanType);
    setIsCreditCard(loanType == "Credit Card");
    if (loanType === 'Small Business Loan' || loanType === 'Business Installment Loan') {
      setSelectedApplicationType("BUSINESS");
      leadForm.setFieldsValue({
        applicantType: "BUSINESS",
        businessType: "SOLE_PROPRIETOR",
      });
    } else {
      setSelectedApplicationType("INDIVIDUAL");
      leadForm.setFieldsValue({
        applicantType: "INDIVIDUAL",
        individualType: "SALARIED",
      });
    }

    if(leadForm?.getFieldValue('applicantType') == "INDIVIDUAL" && e.target.value == "LP00000006") {
      setHideBusinessDetails(true)
    } else {
      setHideBusinessDetails(false)
    }
  };

  const changeTab = (key) => {
    setActiveSelfOrConnector(key);
    if (!id) {
      setIsDisableStep(true);
    }
    setIsDisableCustomer(true);
    setSelectedConnector(null);
    setSelectedCustomer(null);
    setIsSelectedCustomer(false);
    setIsBureauScore(false);
    setIsCustomerIndian(false);
    setBureauScoreStatus("");
    if (!id) {
      leadForm.setFieldsValue({
        searchCustomer: "",
        search: "",
        connector: "",
      });
    }
  };

  const selectAssignTo = (item, opt) => {
    setSelectedAssignTo(opt);
  };

  const setFieldsValue = () => {
    if (selectedCustomer != null) {
      const { contactDetails, address } = selectedCustomer;

      leadForm.setFieldsValue({
        firstName: contactDetails?.firstName,
        email: contactDetails?.emailId,
        pan: contactDetails?.pan === "PENDING" ? "" : contactDetails?.pan,
        gender: contactDetails?.gender,
        mobileNumber: contactDetails?.mobileNumber?.startsWith("1") ? "" : contactDetails?.mobileNumber,
        dateOfBirth: contactDetails?.dateOfBirth
          ? moment(contactDetails?.dateOfBirth)
          : "",
        city: address?.city,
        state: address?.state,
        pincode: address?.pinCode,
        address1: address?.address,
      });
      setIsSelectedCustomer(true);
      setIsDisableStep(false);
    }
  };

  const actionContinue = () => {
    if (selectedCustomer !== null) {
      const { contactDetails, address } = selectedCustomer;

      setBureauScoreStatus("");
      getLeadAdditional(contactDetails?.pan ?? "");
    }
  };

  const actionContinueConnector = () => {
    if (selectedConnector) {
      setIsDisableCustomer(false);
    }
  };

  const btnDefaultBorderStyle: any = {'--borderColor': appColors?.appSecondaryColor ?? ""};
  const tabStyles: any = { '--bgColor': appColors?.appSecondaryLightColor ?? "", '--color': '#fff' };
  const btnPrimaryStyles : any = {'--btnColor' :appColors?.appPrimaryColor ?? ""};
  const btnTabRadioStyles: any = { '--borderColor': appColors?.appSecondaryColor ?? "" };
  const btnRadioStyles: any = { '--borderColor': appColors?.appPrimaryColor ?? "" };


  const generateButtons = (type) => {
    return (
      <Form.Item style={{ marginTop: "10px" }}>
        <Space className="buttons">
          <Button
            size="large"
            htmlType="button"
            className="dynamic-btn-default"
            style={btnDefaultBorderStyle}
            onClick={() => setIsDisableStep(false)}
          >
            Skip
          </Button>
          <Button
            size="large"
            loading={loading}
            type="primary"
            htmlType="button"
            onClick={
              type === "connector" ? actionContinueConnector : actionContinue
            }
            className="dynamic-btn-primary"
            style={btnPrimaryStyles}
          >
            Continue
          </Button>
        </Space>
      </Form.Item>
    );
  };

  const valueExistingCustomer = (customer) => {
    return (
      <span key={customer?.contactDetails?.firstName}>
        {customer?.customerId}
      </span>
    );
  };

  const valueExistingConnector = (connector) => {
    return (
      <span key={connector?.userDetails?.fullName}>
        {connector?.userDetails?.userId}
      </span>
    );
  };

  const generateExistingCustomer = (type) => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0">
          Search Existing Customer
        </Divider>
        <Form.Item className="search-box" name="searchCustomer">
          <AutoComplete
            placeholder="Search with name or mobile no"
            dropdownClassName="custom-option-list"
            onSelect={onSelectExistingCustomer}
            onSearch={onSearchExistingCustomer}
          >
            {searchedCustomers?.map((customer: any) => (
              <Select.Option
                key={customer.customerId}
                {...customer}
                value={valueExistingCustomer(customer)}
              >
                <div>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={16}>
                      <span className="name">
                        {customer?.contactDetails?.firstName}
                      </span>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]} className="assign-list">
                    <Col className="left" span={8}>
                      <span>
                        {customer?.contactDetails?.mobileNumber.replace(
                          /\d(?=\d{4})/g,
                          "*"
                        )}
                      </span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                    <Col className="right" span={16}>
                      <span className="city">{customer?.address?.city}</span>
                      {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                    </Col>
                  </Row>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    textAlign: "right",
                    justifyContent: "space-between",
                  }}
                >
                  {customer?.contactDetails?.contactName}
                  <div
                    style={{
                      display: "grid",
                      fontSize: "14px",
                      color: "grey",
                      flex: 1,
                    }}
                  >
                    <span>{customer?.contactDetails?.mobileNumber}</span>
                    <span>{customer?.address?.city}</span>
                  </div>
                </div> */}
              </Select.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        {selectedCustomer && (
          <div className="search-result">
            <Space className="selected-item" direction="vertical">
              <Title level={5}>
                {selectedCustomer?.contactDetails?.firstName}
              </Title>
              <Text>{selectedCustomer?.contactDetails?.mobileNumber}</Text>
              <Text>{selectedCustomer?.address?.city}</Text>
            </Space>
          </div>
        )}
        {generateButtons(type)}
      </>
    );
  };

  const searchAssignToBackOffice = (ev: any) => {
    if (!ev) {
      setIsClearBackOficer(true);
    }
    const params = {
      searchParam: ev,
      department: "Back Office",
    };
    API_SERVICE.getUsersByDepartment(params).then(({ data }) => {
      if (data) {
        setAssignToBackOfficeData(data.payload);
      }
    });
  };

  const selectBackOfficeUser = (item, opt) => {
    // setSelectedBackOfiiceUserId()
    setIsClearBackOficer(false);
    setSelectedBackOfficeUser(opt);
  };

  const renderEducationDetails = () => {
    return <>
      <Divider orientation="left" orientationMargin="0">
        Education Details
      </Divider>

      <Form.Item
          className="field-bg"
          label="Time of Study (Starts from)"
          name="timeOfStudy"
          rules={[
            {
              required: true,
              message: "Please enter time of study",
            },
          ]}
        >
          <DatePicker 
            className="custom-input"
            picker="month" 
            placeholder="YYYY-MM"
            format={"yyyy-MM"}
            disabledDate={disableTimeOfStudy}
          />
      </Form.Item>

      <Form.Item
        className="field-bg"
        label="Preferred Place of Study"
        name="placeOfStudy"
        rules={[
          {
            required: true,
            message: "Please enter preferred place of study",
          },
        ]}
      >
        <Select
          placeholder="Please select"
          className="custom-select"
        >
          {placeOfStudys &&
            placeOfStudys?.map((loan: any, i: number) => (
              <Select.Option key={i} value={loan.key}>
                {loan.value}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        className="field-bg"
        label="Admission Status"
        name="admissionStatus"
        rules={[
          {
            required: true,
            message: "Please enter admission status",
          },
        ]}
      >
        <Select
          placeholder="Please select"
          className="custom-select"
        >
          {admissionStatus &&
            admissionStatus?.map((loan: any, i: number) => (
              <Select.Option key={i} value={loan.key}>
                {loan.value}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </>
  }

  return isEditV2 ? (
    <AddLeadNew
     isEditV2={isEditV2}
     setIsEditV2={setIsEditV2}
     editData={editData}
     editLead={editLead}
    />
) : (
    <div className="content-box">
      <Form
        style={{ marginTop: "50px" }}
        layout="vertical"
        initialValues={{
          phoneCode: "+91",
          applicantType: "INDIVIDUAL",
          gender: "M",
          assignTo: userDetails?.userDetails?.fullName,
        }}
        form={leadForm}
        onFinish={onFinishForm}
        autoComplete="off"
        name="leadForm"
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            {/* <Button className="step-button" shape="round">
              Step 1
            </Button> */}
            <div className="form-box">
              <Form.Item
                label="Loan Type"
                name="loanType"
                shouldUpdate={true}
                rules={[
                  { required: true, message: "Please select loan type." },
                ]}
              >
                <Radio.Group name="loanTypes" onChange={onRadioChange} className="dynamic-radio" style={btnRadioStyles}>
                  <Space direction="vertical">
                    {loanTypes &&
                      loanTypes.map((loan: any, i: number) => (
                        <Radio key={i} value={loan.key}>
                          {loan.value}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Tabs
                className="custom-tab"
                defaultActiveKey="2"
                activeKey={activeSelfOrConnector}
                onChange={changeTab}
                style={tabStyles}
              >
                <TabPane tab="Self" key="1" disabled={(id && editData?.sourceOfCase == "CONNECTOR")}>
                  {generateExistingCustomer("self")}
                </TabPane>
                <TabPane tab="Connector" key="2">
                  <Form.Item
                   label="Search Existing Connector"
                    name="connector"
                    rules={[
                      {
                        required:  editData?.sourceOfCase == "CONNECTOR" || activeSelfOrConnector == "2",
                        message: "Please select connector",
                      },
                    ]}
                    >
                    <AutoComplete
                    className="search-box"
                      placeholder="Search connector"
                      dropdownClassName="custom-option-list"
                      onSelect={onSelectConnector}
                      onSearch={onSearchConnector}
                    >
                      {allConnectors?.map((connector: any) => (
                        <Select.Option
                          key={connector.userDetails.userId}
                          {...connector}
                          value={valueExistingConnector(connector)}
                        >
                          <div>
                            <Row gutter={[16, 16]} className="assign-list">
                              <Col className="left" span={16}>
                                <span className="name">
                                  {connector?.userDetails?.fullName}
                                </span>
                                <br />
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]} className="assign-list">
                              <Col className="left" span={8}>
                                <span>
                                  {connector?.userDetails?.phoneNumber.replace(
                                    /\d(?=\d{4})/g,
                                    "*"
                                  )}
                                </span>
                                {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                              </Col>
                              <Col className="right" span={16}>
                                <span className="city">
                                  {connector?.address?.city}
                                </span>
                                {/* <span className="mob">{item?.userDetails?.phoneNumber && item?.userDetails?.phoneNumber}</span> */}
                              </Col>
                            </Row>
                          </div>
                          {/* <div
                            style={{
                              display: "flex",
                              textAlign: "right",
                              justifyContent: "space-between",
                            }}
                          >
                            {connector?.userDetails?.fullName}
                            <div
                              style={{
                                display: "grid",
                                fontSize: "14px",
                                color: "grey",
                                flex: 1,
                              }} 
                            >
                              <div>
                              <span>{connector?.userDetails?.phoneNumber.replace(/\d(?=\d{4})/g, "*")}</span>
                              <span>{connector?.address?.city}</span>
                              </div>
                            </div>
                          </div> */}
                        </Select.Option>
                      ))}
                    </AutoComplete>
                  </Form.Item>
                  {selectedConnector && (
                    <div className="search-result">
                      <Space className="selected-item" direction="vertical">
                        <Title level={5}>
                          {selectedConnector?.userDetails?.fullName}
                        </Title>
                        <Text>
                          {selectedConnector?.userDetails?.designation}
                        </Text>
                        <Text>{selectedConnector?.address?.city}</Text>
                      </Space>
                    </div>
                  )}
                  {activeSelfOrConnector === "2" &&
                    selectedConnector?.payoutType !== "FIXED" &&
                    tentativePayout !== "FIXED" && (
                      <Form.Item
                        label="Tentative Payout %"
                        name="tentativePayout"
                        rules={[
                          {
                            required: false,
                            message: "Please enter tentative payout %",
                          },
                          {
                            pattern: /^\d*(\.)?(\d{0,2})?$/,
                            message: "Please enter valid payout %",
                          },
                        ]}
                      >
                        <Input
                          className="payout-input"
                          max={100}
                          min={0}
                          placeholder="Enter payout %"
                        />
                      </Form.Item>
                    )}
                  {generateButtons("connector")}
                  <div style={{ position: "relative" }}>
                    {generateExistingCustomer("customer")}
                    {isDisableCustomer && (
                      <div
                        style={{
                          position: "absolute",
                          inset: "0",
                          backgroundColor: "#FFFFFF88",
                          zIndex: "9",
                        }}
                      />
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
          <Col xs={24} xl={8} span={8} className="col-border-right">
            {/* <Button className="step-button" shape="round">
              Step 2
            </Button> */}
            <div className={"form-box"}>
              <Form.Item
                label="Applicant Type"
                name="applicantType"
                rules={[
                  { required: true, message: "Please select loan type." },
                ]}
              >
                <Radio.Group
                  onChange={onChangeApplicationType}
                  className="custom-radio"
                  style={btnTabRadioStyles}
                  name="applicantType"
                >
                  <Space direction="horizontal">
                    {applicantTypes &&
                      applicantTypes.map((type: any, i: number) => {
                        if ((isPersonalLoan || selectedLoanType == "Education Loan") && type.key === "BUSINESS") {
                          return null;
                        }
                        return (
                          <Radio.Button key={i} value={type.key}>
                            {type.value}
                          </Radio.Button>
                        );
                      })}
                  </Space>
                </Radio.Group>
              </Form.Item>

              <div className="application-type">
                {selectedApplicationType === "INDIVIDUAL" ? (
                  <Form.Item
                    className="field-bg"
                    label="Individual Type"
                    name="individualType"
                    rules={[
                      {
                        required: true,
                        message: "Please select individual type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                      onChange={() => {
                        if(leadForm?.getFieldValue('loanType') == "LP00000006" && 
                        leadForm?.getFieldValue('applicantType') == "INDIVIDUAL" && 
                        leadForm?.getFieldValue('individualType') == "SALARIED") {
                          setHideBusinessDetails(true)
                        } else {
                          setHideBusinessDetails(false)
                        }
                      }}
                    >
                      {individualTypes &&
                        individualTypes.map((type: any, i: number) => {
                          if (isPersonalLoan && type.key === "SELF_EMPLOYED") {
                            return null;
                          }
                          return (
                            <Select.Option key={i} value={type.key}>
                              {type.value}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    className="field-bg"
                    label="Business Type"
                    name="businessType"
                    rules={[
                      {
                        required: true,
                        message: "Please select business type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                    >
                      {businessTypes &&
                        businessTypes.map((loan: any, i: number) => (
                          <Select.Option key={i} value={loan.key}>
                            {loan.value}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
              </div>

              {/* TODO - Bureau Score */}
              {/* {isSelectedCustomer &&
                bureauScoreStatus.length == 0 &&
                selectedApplicationType === "INDIVIDUAL" && (
                  <div style={{ display: "grid" }}>
                    <Form.Item name="bureauScore">
                      <Checkbox
                        name="bureauScore"
                        className=""
                        style={{ marginTop: "10px" }}
                        onChange={(ev) => setIsBureauScore(ev.target.checked)}
                      >
                        <span className="note-text">Get Bureau Score</span>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item name="isCustomerIndian">
                      <Checkbox
                        style={{ marginLeft: "0px", marginTop: "10px" }}
                        onChange={(ev) =>
                          setIsCustomerIndian(ev.target.checked)
                        }
                      >
                        <span className="note-text">
                          Confirm if Customer is an Indian National
                        </span>
                      </Checkbox>
                    </Form.Item>

                    <span className="note-text" style={{ marginTop: "10px" }}>
                      I confirm that Customer has been informed about the credit
                      Bureau Disclaimer.
                    </span>

                    <span className="note-text">
                      “I hereby appoint Oneinfinity as my authorised
                      representation to receive my credit information from
                      Experian or such credit bureaus.”
                    </span>
                  </div>
                )}

              {bureauScoreStatus.length != 0 &&
                selectedApplicationType === "INDIVIDUAL" && (
                  <BureauScoreView status={bureauScoreStatus} />
                )} */}

              {selectedApplicationType === "INDIVIDUAL" ? (
                <Divider orientation="left" orientationMargin="0">
                  Personal Details
                </Divider>
              ) : (
                <Divider orientation="left" orientationMargin="0">
                  Contact Details
                </Divider>
              )}
              <Form.Item
                label="Gender"
                name="gender"
                rules={[{ required: true, message: "Please select Gender" }]}
              >
                <Radio.Group
                  className="custom-radio"
                  name="loanTypes"
                  style={btnTabRadioStyles}
                  defaultValue={1}
                >
                  <Space direction="horizontal">
                    {genders &&
                      genders.map((loan: any, i: number) => (
                        <Radio.Button key={i} value={loan.key}>
                          {loan.value}
                        </Radio.Button>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>

              {selectedApplicationType === "INDIVIDUAL" && (
                <Form.Item
                  className="field-bg"
                  label="Pan"
                  name="pan"
                  rules={[
                    {
                      required: false, // isBureauScore || bureauScoreStatus.length != 0,
                      message: "Please enter pan number",
                    },
                    {
                      pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                      message: "Please enter valid pan number",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    // onChange={debounce(checkPanDetails, 1000)}
                    placeholder="Pan number"
                  />
                </Form.Item>
              )}

              {selectedApplicationType === "INDIVIDUAL" ? (
                <>
                  <Form.Item
                    className="field-bg"
                    label="Name as per PAN Card"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter name as per PAN card",
                      },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid name as per PAN card",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Name as per PAN Card"
                    />
                  </Form.Item>
                  {/* DO NOT DELETE THIS CODE, WE CAN ENABLE AS PER NEED IN FEATURE AS NOW FULL NAME IS PASSING IN FIRSTNAME IN API REQUEST */}
                  {/* <Form.Item
                    className="field-bg"
                    label="First Name as per PAN Card"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name as per PAN Card",
                      },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid first name as per PAN Card",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="First name" />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Middle Name as per PAN Card"
                    name="middleName"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter middle Name as per PAN Card",
                      // },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid middle name as per PAN Card",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="Middle name" />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Last Name as per PAN Card"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name as per PAN Card",
                      },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid last name as per PAN Card",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="Last name" />
                  </Form.Item> */}
                </>
              ) : (
                <>
                  <Form.Item
                    className="field-bg"
                    label="Contact Name"
                    name="firstName"
                    rules={[
                      { required: true, message: "Please enter contact name" },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid contact name",
                      },
                    ]}
                  >
                    <Input
                      className="custom-input"
                      placeholder="Contact Name"
                    />
                  </Form.Item>
                  {/* <Form.Item
                    className="field-bg"
                    label="First Name"
                    name="firstName"
                    rules={[
                      { required: true, message: "Please enter first name" },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid first name",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="First name" />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Middle Name"
                    name="middleName"
                    rules={[
                      // { required: true, message: "Please enter midle name" },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid middle name",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="Middle name" />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Last Name"
                    name="lastName"
                    rules={[
                      { required: true, message: "Please enter last name" },
                      {
                        pattern: STRING_ONLY,
                        message: "Please enter valid last name",
                      },
                    ]}
                  >
                    <Input className="custom-input" placeholder="Last name" />
                  </Form.Item> */}
                </>
              )}

              <Form.Item
                className="field-bg"
                label="Mobile Number"
                name="mobileNumber"
                rules={[
                  { required: false, message: "Please enter mobile number" },
                  {
                    pattern: PHONE_NUMBER_REGEX,
                    message: "Please enter valid number",
                  },
                ]}
              >
                <Input
                  className="custom-input-phone"
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Email"
                name="email"
                rules={[
                  { required: false, message: "Please enter email" },
                  { type: "email", message: "Please enter valid email" },
                ]}
              >
                <Input className="custom-input" placeholder="Email" />
              </Form.Item>

              {selectedApplicationType === "INDIVIDUAL" && (
                <FormDOB
                  form={leadForm}
                  disableDate={disableDate}
                  label="Date of Birth (YYYY-MM-DD)"
                  placeholder="Date of Birth"
                  name="dateOfBirth"
                  // isRequired={isBureauScore || bureauScoreStatus.length != 0}
                  isRequired={false}
                />
              )}

              <Divider orientation="left" orientationMargin="0">
                Current Address
              </Divider>
              <Form.Item
                className="field-bg"
                label="Pin Code"
                name="pincode"
                rules={[
                  { required: false, message: "Please enter pin code" },
                  {
                    pattern: /^[1-9][0-9]{5}$/,
                    message: "Please enter valid pincode",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  onChange={debounce(checkPincode, 1000)}
                  placeholder="Please enter pin code"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Address Line 1"
                name="address1"
                rules={[{ required: false, message: "Please enter addredd" }]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter address"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="City"
                name="city"
                rules={[
                  { required: false, message: "Please enter city" },
                  { pattern: STRING_ONLY, message: "Please enter valid city" },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter city"
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="State"
                name="state"
                rules={[
                  { required: false, message: "Please enter state" },
                  { pattern: STRING_ONLY, message: "Please enter valid state" },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Please enter state"
                />
              </Form.Item>
            </div>
            {isDisableStep && (
              <div
                style={{
                  position: "absolute",
                  inset: "0",
                  backgroundColor: "#FFFFFF88",
                  zIndex: "9",
                }}
              />
            )}
          </Col>
          <Col xs={24} xl={8} span={8}>
            {isDisableBusinessData && (
              <div
                style={{
                  position: "absolute",
                  inset: "0",
                  backgroundColor: "#FFFFFF88",
                  zIndex: "9",
                }}
              />
            )}
            {selectedApplicationType === "INDIVIDUAL" && selectedLoanType != "Education Loan" && (
              <div>
                <Divider orientation="left" orientationMargin="0">
                  Employer Details
                </Divider>
                <Form.Item
                  className="field-bg"
                  label="Employer / Entity name"
                  name="employername"
                  rules={[
                    {
                      required: false,
                      message: "Enter employer / entity name",
                    },
                  ]}
                >
                  <Input
                    className="custom-input"
                    placeholder="Employer / entity name"
                  />
                </Form.Item>
                {(isPersonalLoan || hideBusinessDetails) && (
                  <>
                    <Form.Item
                      className="field-bg"
                      label="Monthly In-hand Salary(INR)"
                      name="monthlyInHandSalary"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Monthly In-hand Salary",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Please enter Monthly In-hand Salary"
                        formatter={(value) => numberWithCommas(value)}
                        min={1}
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Office Pin Code"
                      name="officePinCode"
                      rules={[
                        { required: false, message: "Please enter office pin code" },
                        {
                          pattern: /^[1-9][0-9]{5}$/,
                          message: "Please enter valid pincode",
                        },
                      ]}
                    >
                      <Input
                        className="custom-input"
                        // onChange={debounce(checkPincode, 1000)}
                        placeholder="Please enter office pin code"
                      />
                    </Form.Item>
                    <Form.Item
                      className="field-bg"
                      label="Current EMIs (INR)"
                      name="currentEmis"
                      rules={[
                        {
                          required: false,
                          message: "Please enter Current EMIs (INR)",
                        },
                      ]}
                    >
                      <InputNumber
                        className="custom-input-number"
                        placeholder="Current EMIs (INR)"
                        formatter={(value) => numberWithCommas(value)}
                        min={0}
                      />
                    </Form.Item>
                  </>
                )}
              </div>
            )}
            <div className={"form-box"}>
              {selectedLoanType == "Education Loan" &&
                renderEducationDetails()
              }
              {!isPersonalLoan && selectedLoanType != "Education Loan" && !hideBusinessDetails && (
                <>
                  <Divider orientation="left" orientationMargin="0">
                    Business Details
                  </Divider>
                  {selectedApplicationType !== "INDIVIDUAL" && (
                    <>
                      <Form.Item
                        className="field-bg"
                        label="Business Name"
                        name="employername"
                        rules={[
                          {
                            required: false,
                            message: "Please enter business name",
                          },
                        ]}
                      >
                        <Input
                          className="custom-input"
                          placeholder="Business name"
                        />
                      </Form.Item>

                      <Form.Item
                        className="field-bg"
                        label="Business Pan"
                        name="businessPan"
                        rules={[
                          {
                            required: false,
                            message: "Please enter pan number",
                          },
                          {
                            pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                            message: "Please enter valid pan number",
                          },
                        ]}
                      >
                        <Input
                          className="custom-input"
                          // onChange={debounce(checkPanDetails, 1000)}
                          placeholder="Business pan number"
                        />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    className="field-bg"
                    label="Business Profile"
                    name="businessProfile"
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                    >
                      {businessProfile &&
                        businessProfile?.sort(function (a: any, b: any) {
                          if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                          if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                          return 0;
                        })?.map((loan: any, i: number) => (
                          <Select.Option key={i} value={loan.key}>
                            {loan.value}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="No of Years In Business"
                    name="businessVintage"
                    rules={[
                      {
                        required: false,
                        message: "Please enter no of years in business",
                      },
                      // {
                      //   pattern: /^[A-Za-z\\s ]+$/,
                      //   message: "Please enter valid name",
                      // },
                    ]}
                  >
                    <InputNumber
                      className="custom-input"
                      placeholder="No of Years In Business"
                      min={1}
                    />
                  </Form.Item>

                  <Form.Item
                    className="field-bg"
                    label="Business Turnover (Yearly)"
                    name="businessTurnover"
                    rules={[
                      {
                        required: false,
                        message: "Please enter business turnover",
                      },
                      // {
                      //   pattern: /^[A-Za-z\\s ]+$/,
                      //   message: "Please enter valid name",
                      // },
                    ]}
                  >
                    <InputNumber
                      className="custom-input-number"
                      placeholder="Business turnover"
                      formatter={(value) => numberWithCommas(value)}
                      min={1}
                    />
                  </Form.Item>
                  <Form.Item
                    className="field-bg"
                    label="Monthly Obligations (EMIs)"
                    name="monthlyEmi"
                    rules={[
                      {
                        required: false,
                        message: "Please enter monthly obligations (EMIs)",
                      },
                    ]}
                  >
                    <InputNumber
                      className="custom-input-number"
                      placeholder="Monthly Obligations (EMIs)"
                      formatter={(value) => numberWithCommas(value)}
                      min={0}
                    />
                  </Form.Item>
                  <Form.Item
                    className="field-bg"
                    label="Ownership"
                    name="propertyOwnership"
                  >
                    <Select
                      placeholder="Please select"
                      className="custom-select"
                    >
                      {propertyOwnership &&
                        propertyOwnership?.sort(function (a: any, b: any) {
                          if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                          if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                          return 0;
                        })?.map((loan: any, i: number) => (
                          <Select.Option key={i} value={loan.key}>
                            {loan.value}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </>
              )}

              <Divider orientation="left" orientationMargin="0">
                Loan Details
              </Divider>
              <Form.Item
                className="field-bg"
                label="Loan Amount"
                name="loanAmount"
                hidden={isCreditCardLoan}
                rules={[
                  {
                    required: !isCreditCardLoan,
                    message: "Please enter loan amount",
                  },
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Please enter loan amount"
                  formatter={(value) => numberWithCommas(value)}
                  min={1}
                />
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Loan Tenure(in Years)"
                hidden={isCreditCardLoan}
                name="loanTenure"
                rules={[
                  {
                    required: !isCreditCardLoan,
                    message: "Please enter Loan Tenure",
                  },
                  {
                    pattern: GREATERTHEN_ZERO_REGEX,
                    message: "Please enter valid Loan Tenure",
                  },
                ]}
              >
                <InputNumber
                  className="custom-input-number"
                  placeholder="Please enter Loan Tenure"
                />
              </Form.Item>

              {/* <Form.Item
                className="field-bg"
                label="Assign To"
                name="assignTo"
                rules={[{ required: true, message: "Please select assign to" }]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="input here"
                  dropdownClassName="custom-option-list"
                  onSelect={selectAssignTo}
                >
                  {assignToData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.address?.city && item?.address?.city}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item> */}

              <Form.Item
                className="field-bg"
                label="Assign to sales user"
                name="assignTo"
                rules={[
                  {
                    required: true,
                    message: "Please select assign to sales user",
                  },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignTo, 1000)}
                  placeholder="Search assign to sales user"
                  dropdownClassName="custom-option-list"
                  onSelect={selectAssignTo}
                >
                  {assignToData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.address?.city && item?.address?.city}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>

              <Form.Item
                className="field-bg"
                label="Assign to back office"
                name="assignToBackOffice"
                rules={[
                  {
                    required: false,
                    message: "Please select assign to back office",
                  },
                ]}
              >
                <AutoComplete
                  className="custom-auto-compleat"
                  onSearch={debounce(searchAssignToBackOffice, 1000)}
                  placeholder="Search assign to back office"
                  dropdownClassName="custom-option-list"
                  onSelect={selectBackOfficeUser}
                >
                  {assignToBackOfficeData.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      {...item}
                      value={item.fullName}
                    >
                      <div>
                        <Row gutter={[16, 16]} className="assign-list">
                          <Col className="left" span={16}>
                            <span className="name">{item.fullName}</span>
                            <br />
                            <span className="city">
                              {item?.designation && item?.designation}
                            </span>
                          </Col>
                          <Col className="right" span={8}>
                            <span className="mob">
                              {item?.phoneNumber && item?.phoneNumber}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Select.Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </div>
            {isDisableStep && (
              <div
                style={{
                  position: "absolute",
                  inset: "0",
                  backgroundColor: "#FFFFFF88",
                  zIndex: "9",
                }}
              />
            )}
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ justifyContent: "flex-end" }}>
          <Divider />
          <Col>
            <Form.Item>
              <Space>
                <Button
                  size="large"
                  className="button-submit dynamic-btn-default"
                  style={btnDefaultBorderStyle}
                  htmlType="button"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="button-submit dynamic-btn-primary"
                  style={btnPrimaryStyles}
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Continue
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddLeadForm;
