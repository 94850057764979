import { Button, Form, Select, Space, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "shared/redux/store";
import API_SERVICE from "shared/services/api-service";
import { getEnumKey } from "shared/services/Utility";

interface IProductFooter {
  dispositionList: any;
  setIsProductFooter: any;
  caseId: any;
  selectedStageDetail: any;
  caseStatus: any;
  getLeadById: any;
}

const ProductFooter: React.FC<IProductFooter> = (props: IProductFooter) => {
  const {
    dispositionList,
    setIsProductFooter,
    caseId,
    selectedStageDetail,
    caseStatus,
    getLeadById,
  } = props;
  const [dispositionList2, setDispositionList2] = useState([]);
  const {appColors}: any = useSelector((state: RootState) => state.common);

  const onDisposition1Select = (key: string) => {
    const displayDispositionList2 = dispositionList?.find((item) => {
      return item?.level1 == key;
    });
    setDispositionList2(displayDispositionList2?.level2 ?? []);
  };

  const onSubmit = (values: any) => {
    const payload = {
      status: getEnumKey("CASE_STATUS", caseStatus),
      comments: values?.comment,
      dispositionAndStagesRequest: {
        disposition1: values?.disposition1,
        disposition2: values?.disposition2,
        currentStage: {
          key: selectedStageDetail?.key,
          name: selectedStageDetail?.name,
          stages: null,
        },
      },
    };
    API_SERVICE.updateCaseStatus(caseId, payload)
      .then(({ data }) => {
        setIsProductFooter(false);
        getLeadById();
        notification.success({ message: data?.message });
      })
      .catch((e: any) => {
        if ((e?.response?.data?.payload?.comments?.length ?? 0) > 0) {
          notification.error({ message: e?.response?.data?.payload?.comments });
        } else {
          notification.error({ message: API_SERVICE?.handleErrors(e) });
        }
      });
  };

  return (
    <Form
      style={{ width: "100%" }}
      layout="vertical"
      size="large"
      onFinish={onSubmit}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "15px",
        }}
      >
        <Form.Item
          name="disposition1"
          label="Select Disposition 1"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please select Disposition 1" }]}
        >
          <Select
            placeholder="select disposition1"
            showSearch
            onSelect={onDisposition1Select}
          >
            {dispositionList?.map((item: any, i: number) => (
              <Select.Option value={item?.level1} key={i}>
                {item?.level1}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="disposition2"
          label="Select Disposition 2"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please select Disposition 2" }]}
        >
          <Select placeholder="select disposition2" showSearch>
            {dispositionList2?.map((item: any, i: number) => (
              <Select.Option value={item} key={i}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <Form.Item name="comment" className="custom-textarea">
        <TextArea
          autoSize={{ minRows: 5, maxRows: 8 }}
          placeholder="Add comment here..."
        />
      </Form.Item>
      <Space>
        <Form.Item>
          <Button
            className="dynamic-btn-default"
            style={{'--borderColor': appColors?.appSecondaryColor ?? "",minWidth: "100px"}as any}
            onClick={() => setIsProductFooter(false)}
          >
            Cancel
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            className="dynamic-btn-primary"
            type="primary"
            htmlType="submit"
            style={{'--btnColor' :appColors?.appPrimaryColor ?? "",minWidth: "100px"}as any}
          >
            Save
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};

export default ProductFooter;
