import { DatePicker, Form, Input, Space } from "antd";
import { Rule } from "antd/es/form";
import moment from "moment";
import { useEffect, useState } from "react";

interface IFormDOB {
  name: string;
  type?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  disableDate?: any;
  form?: any;
  onChange?: (day?: string, month?: string, year?: string) => void;
}

const FormDOB = (props: IFormDOB) => {
  const {
    name,
    type,
    label,
    isRequired,
    isDisabled,
    placeholder,
    defaultValue,
    disableDate,
    form,
    onChange,
  } = props;

  const [dateFormat, setDateFormat] = useState("YYYY-MM-DD");
  const [tmpDate, setTmpDate] = useState("");

  const onkeyDownDatePicker = (e) => {
    if (e.which == 13) {
      checkDateMessage();
    } else if (
      (e.which >= 96 && e.which <= 105) ||
      (e.which >= 48 && e.which <= 57)
    ) {
      let dateValue = e.target.value + e.key;
      if (dateValue.length == 4) {
        const tDate = new Date(dateValue + "-01-01");
        if (moment(tDate).isValid()) {
          form.setFieldValue(name, moment(tDate, "YYYY-MM-DD"));
          setDateFormat("YYYY-");
          e.target.value = dateValue + "-";
          setTmpDate(dateValue + "-");
        }
        e.preventDefault();
      } else if (dateValue.length == 7) {
        const tDate = new Date(dateValue + "-01");
        if (moment(tDate).isValid()) {
          form.setFieldValue(name, moment(tDate, "YYYY-MM-DD"));
          setDateFormat("YYYY-MM-");
          e.target.value = dateValue + "-";
          setTmpDate(dateValue + "-");
        }
        e.preventDefault();
      } else if (dateValue.length > 7) {
        if (dateValue.length > 9) {
          if (moment(dateValue).isValid()) {
            form.setFieldValue(name, moment(dateValue, "YYYY-MM-DD"));
            e.target.value = dateValue;
            setTmpDate(dateValue);
            setDateFormat("YYYY-MM-DD");
          }
        }
      }
    }
  };

  const onBlurDatePicker = (e) => {
    form.setFieldValue(name, form.getFieldValue(name));
    setDateFormat("YYYY-MM-DD");
    checkDateMessage();
  };

  const checkDateMessage = () => {
    var date = moment(form.getFieldValue(name), "YYYY-MM-DD", true);
    if (date.isValid()) {
      const com = moment().diff(date, "year");
      if (com < 18) {
        form.setFields([
          {
            name: name,
            errors: ["DOB should be greater than or equal to 18yrs"],
          },
        ]);
      } else {
        form.setFields([
          {
            name: name,
            errors: null,
          },
        ]);
      }
    } else {
      form.resetFields([name]);
      setDateFormat("YYYY-MM-DD");
    }
  };

  const onChangeDatePicker = () => {
    if (form.getFieldValue(name)) {
      if (
        moment(
          moment(form.getFieldValue(name)).format("YYYY-MM-DD").toString()
        ).isSameOrBefore(moment().subtract(18, "year").format("YYYY-MM-DD"))
      ) {
        setDateFormat("YYYY-MM-DD");
      } else {
        setDateFormat("YYYY-MM-DD");
      }
    }
  };

  const checkDOB = (_: any, value: any) => {
    if (value == null && !isRequired) {
      return Promise.resolve();
    }

    const com = moment().diff(value, "year");
    if (com < 18) {
      return Promise.reject(
        new Error(`DOB should be greater than or equal to 18yrs`)
      );
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Form.Item
      className="field-bg"
      name={name}
      label={label}
      rules={[
        {
          required: isRequired,
          message: `Please enter your ${placeholder ?? label ?? "Date"}`,
        },
        { validator: checkDOB },
      ]}
    >
      <DatePicker
       disabled={isDisabled}
        disabledDate={disableDate}
        className="custom-input"
        placeholder={placeholder}
        size="large"
        format={dateFormat}
        onKeyDown={(e) => onkeyDownDatePicker(e)}
        onBlur={onBlurDatePicker}
        onChange={onChangeDatePicker}
        onSelect={(e) => {
          setDateFormat("YYYY-MM-DD");
          checkDateMessage();
        }}
      />
    </Form.Item>
  );
};

export default FormDOB;
